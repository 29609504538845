import { keyBy } from "lodash";
import * as actions from "./action";
const selectTestDailySummary = (state) => {
    const { data, filters } = state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.dailySummary;
    return {
        data: [
            { name: "Breath Alcohol Screen", value: data.bas },
            { name: "Urine Drug Screen", value: data.uds },
            { name: "Oral Fluid Drug Screen", value: data.ods },
            { name: "Urine Drug Test", value: data.udt },
            { name: "Oral Fluid Collection", value: data.odt },
            { name: "Hair Drug Test", value: data.hdt },
        ],
        filters,
    };
};
const selectPatientDailySummary = (state) => {
    const { data } = state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.dailySummary;
    return [
        { name: "Male", value: data.male, percent: (data.male / data.totalGenders) * 100 },
        { name: "Female", value: data.female, percent: (data.female / data.totalGenders) * 100 },
        { name: "X", value: data.other, percent: (data.other / data.totalGenders) * 100 },
    ];
};
const selectProcessedTests = (state) => state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.processedTest;
const selectSampleResults = (state) => state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.sampleResult;
const selectListCollectionPoints = (state) => {
    const { collectionPoints } = state.authentication.app;
    return [{ collectionPointName: "All", id: "" }, ...collectionPoints];
};
const selectColorScheme = (state) => state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.colorScheme;
const selectCollectionTables = (state) => state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.collectionTables;
const selectCollectors = (state) => {
    const collectorMap = keyBy(state.dashboardPage.collectionDashboardPage.onsiteCollectionDashboard.collectors, "collectionPointId");
    return collectorMap;
};
export const thunkCollectionTableMap = {
    onsiteDailySummary: actions.getOnsiteDailySummary,
    processedTestByPeriod: actions.getProcessedTestByPeriod,
    testSampleResultsEachType: actions.getTestSampleResultsEachType,
    processedTest: actions.getProcessedTestDetails,
    patientTime: actions.getPatientTimeDetails,
    testTime: actions.getTestTimeDetails,
    testCategory: actions.getTestCategoryDetails,
    urineDrugScreen: actions.getUrineDrugScreenResultDetails,
    urineDrugTest: actions.getUrineDrugTestResultDetails,
    oralDrugScreen: actions.getOralDrugScreenResultDetails,
    oralDrugTest: actions.getOralDrugTestResultDetails,
    hairDrugTest: actions.getHairDrugTestResultDetails,
    breathAlcoholScreen: actions.getBreathAlcoholScreenResultDetails,
    negativeDrugClass: actions.getNegativeDrugClassResultDetails,
    nonNegativeDrugClass: actions.getNonNegativeDrugClassResultDetails,
    invalidDrugClass: actions.getInvalidDrugClassResultDetails,
    collectorPerformance: actions.getCollectorPerformanceDetails,
    collectorRanking: actions.getCollectorRankingDetails,
    patientGender: actions.getPatientGenderDetails,
    attendance: actions.getAttendanceDetails,
    donorCommunication: actions.getDonorCommunicationDetails,
};
export { selectTestDailySummary, selectPatientDailySummary, selectProcessedTests, selectSampleResults, selectListCollectionPoints, selectColorScheme, selectCollectionTables, selectCollectors, };
