import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import moment from "moment";
import React, { useEffect } from "react";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { SAColourScheme } from "src/constants";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import DayCalendar from "./components/DayCalendar";
import MonthCalendar from "./components/MonthCalendar";
import Toolbar from "./components/Toolbar";
import WeekCalendar from "./components/WeekCalendar";
import { getListOnsiteJobs } from "./store/action";
import { selectCollectionPoint, selectCurrentDate, selectCurrentView, selectIsExpandOnsiteView, selectListCollectionPoints, selectListOnsiteJobs, } from "./store/selector";
import { setCollectionPoint, setCurrentDate, setCurrentView } from "./store/slice";
import { CalendarView, OnsiteJobServiceTypeDetail } from "./typing";
export const CalendarPage = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const currentDate = useAppSelector(selectCurrentDate);
    const isLoading = useAppSelector(selectIsLoading);
    const onsiteJobs = useAppSelector(selectListOnsiteJobs);
    const currentCollectionPoint = useAppSelector(selectCollectionPoint);
    const currentView = useAppSelector(selectCurrentView);
    const isExpandOnsiteView = useAppSelector(selectIsExpandOnsiteView);
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    useEffect(() => {
        const view = searchParams.get("view");
        const date = searchParams.get("date");
        const collectionPointId = searchParams.get("collectionPointId");
        if (view && Object.values(CalendarView).includes(view)) {
            dispatch(setCurrentView(view));
        }
        if (date)
            dispatch(setCurrentDate(date));
        const collectionPoint = collectionPoints.find((point) => point.value === collectionPointId) || collectionPoints[0];
        if (collectionPoint)
            dispatch(setCollectionPoint({
                id: collectionPoint.name,
                collectionPointName: collectionPoint.value,
            }));
    }, []);
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getListOnsiteJobs());
            dispatch(setIsLoading(false));
        };
        if (currentCollectionPoint.value)
            loadData();
    }, [currentDate]);
    const renderCalendarByView = () => {
        switch (currentView) {
            case CalendarView.Day: {
                const events = onsiteJobs.map((job) => ({
                    id: job.onsiteJobId,
                    title: (_jsxs(TextContainer, { view: currentView, children: [_jsxs(NormalText, { children: [moment(job.startTime).format("HH:mm"), " - ", moment(job.endTime).format("HH:mm")] }), _jsxs(NormalText, { children: [_jsx(BoldText, { children: job.clientName }), " - ", job.clientLocation] }), _jsxs(NormalText, { children: [_jsx(BoldText, { children: job.noOfDonors }), " Donors"] }), _jsx(NormalText, { children: OnsiteJobServiceTypeDetail[job.serviceType] }), _jsx(NormalText, { children: job.referenceNumber })] })),
                    start: new Date(job.startTime),
                    end: new Date(job.endTime),
                    allDay: !isExpandOnsiteView,
                    isOnsite: true,
                }));
                return (_jsx(DayCalendar, { events: events, currentDate: new Date(currentDate), backgroundEvents: [], header: currentCollectionPoint?.name ?? "Service Office (Clinic)" }));
            }
            case CalendarView.Week: {
                const events = onsiteJobs.map((job) => ({
                    id: job.onsiteJobId,
                    title: (_jsxs(TextContainer, { view: currentView, children: [_jsxs(NormalText, { children: [moment(job.startTime).format("HH:mm"), " - ", moment(job.endTime).format("HH:mm")] }), _jsxs(NormalText, { children: [_jsx(BoldText, { children: job.clientName }), " - ", job.clientLocation] }), _jsxs(NormalText, { children: [_jsx(BoldText, { children: job.noOfDonors }), " Donors"] }), _jsx(NormalText, { children: OnsiteJobServiceTypeDetail[job.serviceType] }), _jsx(NormalText, { children: job.referenceNumber })] })),
                    start: new Date(job.startTime),
                    end: new Date(job.endTime),
                    allDay: !isExpandOnsiteView,
                    isOnsite: true,
                }));
                return (_jsx(WeekCalendar, { events: events, currentDate: new Date(currentDate), backgroundEvents: [], header: currentCollectionPoint?.name ?? "Service Office (Clinic)" }));
            }
            case CalendarView.Month: {
                const events = onsiteJobs.map((job) => ({
                    id: job.onsiteJobId,
                    title: (job.noOfDonors ?? 0).toString(),
                    start: new Date(job.startTime),
                    end: new Date(job.endTime),
                    allDay: !isExpandOnsiteView,
                    isOnsite: true,
                }));
                return (_jsx(MonthCalendar, { events: events, currentDate: new Date(currentDate), backgroundEvents: [], header: currentCollectionPoint?.name ?? "Service Office (Clinic)", selectEvent: (date) => {
                        dispatch(setCurrentView(CalendarView.Day));
                        dispatch(setCurrentDate(date.toISOString()));
                    } }));
            }
        }
    };
    return (_jsx(MainLayout, { children: _jsxs(CalendarPageWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(Toolbar, {}), renderCalendarByView()] }) }));
};
const CalendarPageWrapper = styled.div.attrs((props) => props) `
  background-color: ${(props) => (props.isPopup ? SAColourScheme.MID_BLUE : SAColourScheme.WHITE)};
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const TextContainer = styled.p `
  width: 100%;
  display: ${({ view }) => (view === CalendarView.Day ? "inline" : "flex")};
  flex-direction: column;
  gap: 0.5rem;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.25rem;
  text-overflow: ellipsis;
`;
const NormalText = styled.span `
  font-size: 0.875rem;
  margin-right: 0.25rem;
`;
const BoldText = styled.span `
  font-size: 0.875rem;
  font-weight: 700;
`;
