import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image } from "antd";
import { useContext, useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useDispatch } from "react-redux";
import ContentContext from "src/providers/content";
import { createQREntry } from "src/pages/InventoryManagement/store/action";
import { useAppSelector } from "src/store/hooks";
export const GenerateQRCode = () => {
    const pinData = useAppSelector((state) => state.inventoryPage.pinDetail);
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const [countdown, setCountdown] = useState(60);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 0) {
                    clearInterval(interval); // Stop the countdown when it reaches 0
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [countdown]);
    useEffect(() => {
        getPinData();
    }, []);
    const getPinData = async () => {
        openLoadingScreen();
        dispatch(createQREntry());
        setCountdown(60);
        setError("");
        closeLoadingScreen();
    };
    return (_jsxs("div", { style: { background: "white", marginBottom: "50px" }, children: [_jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    padding: "20px 20% 20px",
                }, children: [_jsx(Image, { src: "/assets/svg/inventory/scan-device-image.svg", preview: false }), _jsxs("div", { style: {
                            display: "flex",
                            margin: "20px",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            padding: "3px",
                            border: "1px solid #D9D9D9",
                        }, children: [_jsx(QRCode, { value: `${process.env.GUARD_CM_HOST}/ScanSatchelCodes/${pinData?.encodedPin}`, size: 260 }), _jsx(Image, { wrapperStyle: { position: "absolute", zIndex: 1 }, src: "/assets/svg/qr-logo.svg", preview: false })] }), _jsx(Image, { src: "/assets/svg/inventory/scan-person-image.svg", preview: false })] }), _jsxs("div", { style: {
                    margin: "auto",
                    padding: "10px",
                    background: "#00004C",
                    width: "350px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: "11px",
                    borderTopRightRadius: "11px",
                }, children: [_jsx("div", { children: _jsx("p", { style: { color: "white" }, children: "Scan QR code with your" }) }), _jsx("div", { children: _jsx("p", { style: { color: "white" }, children: "mobile device" }) })] }), _jsxs("div", { style: {
                    margin: "auto",
                    padding: "10px",
                    background: "#156CC9",
                    width: "350px",
                    textAlign: "center",
                    color: "white",
                    borderBottomLeftRadius: "11px",
                    borderBottomRightRadius: "11px",
                }, children: [_jsx("div", { children: _jsxs("p", { children: ["or go to", _jsx("a", { href: `${process.env.GUARD_CM_HOST}/ScanSatchelCodes/${pinData.encodedPin}`, style: {
                                        color: "white",
                                        display: "block",
                                        maxWidth: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        wordWrap: "break-word",
                                    }, target: "_blank", rel: "noopener noreferrer", children: `${process.env.GUARD_CM_HOST}/ScanSatchelCodes${pinData.encodedPin}` })] }) }), _jsx("div", { children: _jsx("p", { children: "and enter 6 Digit Unique Code" }) })] }), _jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                }, children: [_jsx("span", { style: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                        }, children: pinData?.sixDigitCode &&
                            pinData?.sixDigitCode.split("").map((digit, index) => (_jsx("span", { style: {
                                    border: "1px solid #1A8CFF",
                                    borderRadius: "4px",
                                    padding: "3px",
                                    margin: "10px",
                                    width: "50px",
                                    textAlign: "center",
                                }, children: _jsx("span", { style: { color: "#00DAFF", fontSize: "40px" }, children: digit }) }, index))) }), pinData ? (_jsxs("span", { style: { fontSize: "40px", color: "#156CC9", marginLeft: "30px" }, children: [countdown, " ", _jsx("span", { style: { fontSize: "15px" }, children: "seconds" })] })) : (error !== "" && (_jsx("span", { style: { fontSize: "16px", color: "red", marginTop: "10px" }, children: "Failed to generate QR code data, please refresh." })))] }), pinData && countdown === 0 && (_jsx("div", { style: {
                    textAlign: "center",
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                }, children: _jsxs("span", { style: { fontSize: "16px" }, children: ["Code expired. Please click", _jsx("a", { style: { fontWeight: "700" }, onClick: getPinData, children: "here" }), "to generate a new one."] }) }))] }));
};
