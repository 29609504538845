import ICCollectAssistLogoWhite from "../assets/svg/app-icons/ic-collect-assist-logo-white.svg";
import IC403ErrorCat from "../assets/images/ic-403-cat-error.svg";
export const mainAppColours = {
    BLUE: "#0047b3",
    BLUE_2: "#01B4D2",
    MID_BLUE: "#1a8cff",
    LIGHT_BLUE: "#9ec2ff",
    LIGHT_BLUE_2: "#EAFAFF",
};
export const SAColourScheme = {
    NAVY: "#00004c",
    GRAY_BLUE: "#4b5767",
    BRIGHT_PLASMA: "#CBF5FC",
    PLASMA: "#01b4d2",
    PLASSMA10: "#01b4d210",
    MID_BLUE: "#156cc9",
    RED: "#E62129",
    BLACK: "#332C2B",
    WHITE: "#ffffff",
    LIGHT_BLUE: "#C9EEF8",
    ODD_ROW: "#F7FCFD",
    TRANSPARENT: "#00000000",
    LIGHT_BLUE_3: "#E6F7FA",
    GRAY_BRIGHT: "#F7F8F8",
};
export const supportingColours = {
    BACKGROUND_GRAY: "#EEF5F6",
    BOX_GRAY: "#ADB3BC",
    BOX_LIGHT: "#EBEDF0",
    TEXT_LIGHT_GRAY: "#7F8E9D",
    TEXT_MID_GRAY: "#1A576F",
    TEXT_NAVY: "#00004c",
    TEXT_DARK_GRAY: "#2f3337",
};
export const fontWeight = {
    THIN: "100",
    EXTRA_LIGHT: "200",
    LIGHT: "300",
    REGULAR: "400",
    MEDIUM: "500",
    SEMI_BOLD: "600",
    BOLD: "700",
    EXTRA_BOLD: "800",
    BLACK: "900",
};
export const env = {
    DOMAIN: process.env.DOMAIN,
    NODE_ENV: process.env.NODE_ENV,
    DATA_COLLECTION: process.env.DATA_COLLECTION,
    TENANT_ID: process.env.COLLECTION_MANAGER_TENANT_ID,
    API_GG_MAP_KEY: process.env.API_GG_MAP_KEY,
};
export const appointmentDuration = [
    {
        label: "15 mins",
        value: "15m",
    },
    {
        label: "20 mins",
        value: "20m",
    },
    {
        label: "25 mins",
        value: "25m",
    },
    {
        label: "30 mins",
        value: "30m",
    },
    {
        label: "35 mins",
        value: "35m",
    },
    {
        label: "40 mins",
        value: "40m",
    },
];
export const MESSAGE_BOX_TYPE = {
    DIALOG_BLUE: "dialog_blue",
    DIALOG: "dialog",
    DIALOG_ERROR: "dialog_error",
    PROMPT: "prompt",
    PROMPT_GREEN: "prompt_green",
    PROMPT_BLUE: "prompt_blue",
};
export const APPOINTMENT_STATUS_DB = {
    CONFIRMED: 1,
    CANCELED: 2,
    PROCESSING: 3,
    COMPLETED: 4,
    RESUME: 5,
};
export const APPOINTMENT_STATUS_NUMBER = {
    CONFIRMED: 1,
    CANCELED: 2,
    PROCESSING: 3,
    COMPLETED: 4,
    RESUME: 5,
};
export const validateMessage = {
    pattern: {
        mismatch: "invalid format",
    },
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};
export var Test_Type;
(function (Test_Type) {
    Test_Type[Test_Type["DRUG_TEST"] = 1] = "DRUG_TEST";
    Test_Type[Test_Type["ALCOHOL_TEST"] = 2] = "ALCOHOL_TEST";
})(Test_Type || (Test_Type = {}));
export const DEFAULT_TIMEZONE = "Australia/Sydney";
export var collectorStatus;
(function (collectorStatus) {
    collectorStatus[collectorStatus["ALLOCATED"] = 0] = "ALLOCATED";
    collectorStatus[collectorStatus["ACCEPTED"] = 1] = "ACCEPTED";
    collectorStatus[collectorStatus["REJECTED"] = 2] = "REJECTED";
})(collectorStatus || (collectorStatus = {}));
export var organizationRole;
(function (organizationRole) {
    organizationRole[organizationRole["SERVICE_OWNER"] = 5] = "SERVICE_OWNER";
    organizationRole[organizationRole["SERVICE_MANAGER"] = 2] = "SERVICE_MANAGER";
    organizationRole[organizationRole["SERVICE_MANAGER_V2"] = 3] = "SERVICE_MANAGER_V2";
})(organizationRole || (organizationRole = {}));
export const labelSettings = [
    {
        id: 1,
        title: "Requesting Authority Label",
        criteria: [
            {
                id: 1,
                instruction: "Choose the term that best fits yours testing setting.",
                options: [
                    { id: 1, selected: false, labels: [{ id: 1, label: "Client" }] },
                    { id: 2, selected: false, labels: [{ id: 1, label: "Customer" }] },
                ],
            },
        ],
    },
    {
        id: 2,
        title: "Participant Label",
        criteria: [
            {
                id: 1,
                instruction: "Choose the term that best fits yours testing setting.",
                options: [
                    { id: 1, selected: false, labels: [{ id: 1, label: "Donor" }] },
                    { id: 2, selected: false, labels: [{ id: 1, label: "Patient" }] },
                    { id: 3, selected: false, labels: [{ id: 1, label: "Candidate" }] },
                ],
            },
        ],
    },
    {
        id: "3",
        title: "Test Name Label",
        criteria: [
            {
                id: 1,
                instruction: "Select the labelling format for Test Names.",
                options: [
                    {
                        id: 1,
                        selected: false,
                        labels: [
                            { id: 1, label: "Urine Drug Screen" },
                            { id: 2, label: "Oral Fluid Drug Screen" },
                            { id: 3, label: "Urine Drug Screen" },
                            { id: 4, label: "Oral Fluid Drug Test" },
                            { id: 5, label: "Hair Fluid Test" },
                        ],
                    },
                    {
                        id: 2,
                        selected: false,
                        labels: [
                            { id: 1, label: "Urine Drug Screen" },
                            { id: 2, label: "Oral Fluid Drug Screen" },
                            { id: 3, label: "Urine Collection" },
                            { id: 4, label: "Oral Fluid Collection" },
                            { id: 5, label: "Hair Collection" },
                        ],
                    },
                ],
            },
            {
                id: 2,
                selected: false,
                instruction: "",
                options: [
                    { id: 3, selected: false, labels: [{ id: 1, label: "Breath Alcohol Screen" }] },
                    { id: 4, selected: false, labels: [{ id: 1, label: "Breath Alcohol Test" }] },
                ],
            },
        ],
    },
    {
        id: "4",
        title: "Test Result Label",
        criteria: [
            {
                id: 1,
                instruction: "Select the labelling format for Drug Screening results.",
                options: [
                    {
                        id: 1,
                        selected: false,
                        labels: [
                            { id: 1, label: "NEGATIVE" },
                            { id: 2, label: "RTF (Requires Further Testing)" },
                            { id: 3, label: "INVALID" },
                        ],
                    },
                    { id: 2, selected: false, labels: [{ id: 1, label: "Breath Alcohol Screen" }] },
                ],
            },
            {
                id: 2,
                instruction: "Select the labelling format for Alcohol Screening results.",
                options: [
                    {
                        id: 1,
                        selected: false,
                        labels: [
                            { id: 1, label: "NEGATIVE" },
                            { id: 2, label: "POSITIVE" },
                        ],
                    },
                    {
                        id: 2,
                        selected: false,
                        labels: [
                            { id: 1, label: "NEGATIVE" },
                            { id: 2, label: "NON NEGATIVE" },
                        ],
                    },
                ],
            },
        ],
    },
];
export var clientType;
(function (clientType) {
    clientType[clientType["NORMAL"] = 1] = "NORMAL";
    clientType[clientType["CALL_OUT"] = 2] = "CALL_OUT";
})(clientType || (clientType = {}));
export const LEGAL_APP = {
    "collection-manager": {
        appNameSrc: IC403ErrorCat,
        iconAlt: "Collection Manager Logo",
        iconSrc: IC403ErrorCat,
        module: "collection-manager",
    },
    "collect-assist-onsite": {
        appNameSrc: ICCollectAssistLogoWhite,
        iconAlt: "Collect Assist Logo",
        iconSrc: ICCollectAssistLogoWhite,
        module: "collect-assist-onsite",
    },
    "lab-assist": {
        appNameSrc: IC403ErrorCat,
        iconAlt: "Lab Assist Logo",
        iconSrc: IC403ErrorCat,
        module: "lab-assist",
    },
    "report-assist": {
        appNameSrc: IC403ErrorCat,
        iconAlt: "Report Assist Logo",
        iconSrc: IC403ErrorCat,
        module: "report-assist",
    },
    "rto-assist": {
        appNameSrc: IC403ErrorCat,
        iconAlt: "RTO Assist Logo",
        iconSrc: IC403ErrorCat,
        module: "rto-assist",
    },
    "secure-assist": {
        appNameSrc: IC403ErrorCat,
        iconAlt: "Secure Assist Logo",
        iconSrc: IC403ErrorCat,
        module: "secure-assist",
    },
    "welcome-assist": {
        appNameSrc: IC403ErrorCat,
        iconAlt: "Welcome Assist Logo",
        iconSrc: IC403ErrorCat,
        module: "welcome-assist",
    },
    "work-assist": {
        appNameSrc: IC403ErrorCat,
        iconAlt: "Work Assist Logo",
        iconSrc: IC403ErrorCat,
        module: "work-assist",
    },
};
export const LEGAL = {
    "terms-and-conditions": {
        title: "Terms and Conditions",
    },
    privacy: {
        title: "Privacy Policy",
    },
};
