import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusCircleOutlined } from "@ant-design/icons";
import { uuidv4 } from "@antv/xflow-core";
import { Badge, Button, Radio, Space, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { FilterIcon } from "src/assets/svg/welcome-assist";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import { getCollectionPointInformation, selectIsLoading } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { setIsLoading } from "src/store/app/slice";
import { SARow, SAColumn, SASelect } from "src/components/Form";
import { routes } from "..";
import { getListKioskMessages } from "../KioskManagement/store/action";
import { KioskMessageType, ManageMessageView, KioskMessageStatus, } from "./typing";
import { NothingSelectMessage, ManageDefaultMessage, ManageEventMessage } from "./components";
const KioskMessagesPage = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const collectionPoints = useAppSelector(getCollectionPointInformation);
    const isLoading = useAppSelector(selectIsLoading);
    const [selectCollectionPointId, setSelectCollectionPointId] = React.useState("");
    const [selectMessageType, setSelectedMessageType] = React.useState(KioskMessageType.Default);
    const [listMessages, setListMessages] = React.useState([]);
    const [isRefresh, setRefresh] = React.useState(false);
    const messageId = searchParams.get("messageId");
    const view = searchParams.get("view");
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            const payload = await dispatch(getListKioskMessages({
                collectionPointId: selectCollectionPointId || undefined,
                type: selectMessageType,
            })).unwrap();
            setListMessages(payload.data || "");
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [selectCollectionPointId, isRefresh, selectMessageType]);
    React.useEffect(() => {
        setSearchParams();
    }, [selectCollectionPointId, selectMessageType]);
    const messageContentView = () => {
        if ((view === ManageMessageView.View && !messageId) || !view) {
            return _jsx(NothingSelectMessage, {});
        }
        switch (selectMessageType) {
            case KioskMessageType.Default:
                return _jsx(ManageDefaultMessage, { setRefresh: () => setRefresh(!isRefresh) });
            case KioskMessageType.Event:
                return _jsx(ManageEventMessage, { setRefresh: () => setRefresh(!isRefresh) });
            default:
                return _jsx(NothingSelectMessage, {});
        }
    };
    return (_jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsxs(KioskMessageContainer, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(SARow, { gutter: [0, 0], children: [_jsx(SAColumn, { sm: 24, lg: 8, children: _jsxs(ListMessageBox, { children: [_jsxs(MessageBoxHeader, { children: [_jsx(Typography.Title, { level: 3, children: "Welcome Assist Kiosk Messages" }), _jsxs(Space, { children: [_jsxs(GroupButton, { value: selectMessageType, onChange: (e) => {
                                                            setSelectedMessageType(e.target.value);
                                                            setSearchParams();
                                                        }, children: [_jsx(Radio.Button, { value: KioskMessageType.Default, children: "Default Messages" }), _jsx(Radio.Button, { value: KioskMessageType.Event, children: "Event Messages" })] }), _jsx(CreateButton, { type: "primary", icon: _jsx(PlusCircleOutlined, {}), onClick: () => {
                                                            setSearchParams({
                                                                view: ManageMessageView.Create,
                                                            });
                                                        }, children: "Create" })] })] }), _jsx(MessageBoxFilter, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, justify: "center", children: _jsx(Typography.Text, { type: "warning", children: "Published" }) }), _jsx(SAColumn, { span: 12, children: _jsx(SASelect, { fieldNames: { label: "collectionPointName", value: "id" }, options: [{ collectionPointName: "All", id: "" }, ...collectionPoints], value: selectCollectionPointId, onChange: setSelectCollectionPointId, children: "Show" }) }), _jsx(SAColumn, { span: 6, children: _jsx(Button, { icon: _jsx(FilterIcon, {}), disabled: true }) })] }) }), _jsx(MessageBoxBody, { children: listMessages
                                            .filter((message) => {
                                            const matchesType = message.type === selectMessageType;
                                            const matchesCollectionPoint = selectCollectionPointId === "" ||
                                                message.collectionPointId === selectCollectionPointId;
                                            return matchesType && matchesCollectionPoint;
                                        })
                                            .map((message) => (_jsxs(SingleMessageBox, { "$isSelected": message.id === messageId, onClick: () => {
                                                setSearchParams({
                                                    messageId: message.id,
                                                    view: ManageMessageView.View,
                                                });
                                            }, children: [_jsx(SAColumn, { span: 6, children: message.status === KioskMessageStatus.Published && (_jsx(Badge, { color: "#1A8CFF" })) }), _jsx(SAColumn, { span: 12, children: _jsxs(MessageTitle, { direction: "vertical", children: [_jsx(Typography.Title, { level: 5, children: message.label }), _jsxs(Typography.Text, { children: ["Created by: ", message.createdBy] })] }) }), _jsx(SAColumn, { span: 6, children: _jsxs(Space, { direction: "vertical", children: [_jsx(Typography.Text, { children: moment(message.createdAt).format("DD/MM/YY") }), _jsx(Typography.Text, { children: moment(message.createdAt).format("HH:MM A") })] }) })] }, uuidv4()))) })] }) }), _jsx(SAColumn, { sm: 24, lg: 16, children: messageContentView() })] })] }) }));
};
export default KioskMessagesPage;
const KioskMessageContainer = styled.div `
  margin: -2rem;
  height: 100%;
`;
const ListMessageBox = styled.div `
  width: 100%;
  height: 100%;
  border-right: 0.025rem solid #01b4d2;
`;
const MessageBoxHeader = styled.div `
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #cbf5fc;
  text-align: center;
`;
const GroupButton = styled(Radio.Group) `
  gap: 0.5rem;
  margin-right: auto;
  flex-wrap: wrap;

  label {
    margin-right: 0.5rem;

    span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-checked {
    border-radius: 0.3rem;
    border: 0;
    color: #ffffff;
    background-color: #d3cbcb;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: fit-content;
    font-size: 0.876rem;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #00004c;
  }

  @media screen and (max-width: 1400px) {
    span {
      font-size: 0.7rem !important;
      margin: 0;
    }
  }
`;
const CreateButton = styled(Button) `
  background-color: #ffffff;
  border-radius: 1rem;
  color: #1a8cff;
  margin-bottom: 0.25rem;
  padding: 0 0.5rem;
  height: fit-content;
`;
const MessageBoxFilter = styled.div `
  padding: 1rem 0;
  border-bottom: 0.025rem solid #d3d6db;
`;
const MessageBoxBody = styled.div `
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 63vh;
  flex: 1 1 63vh;

  @media screen and (max-width: 768px) {
    height: 30vh;
    flex: 1 1 30vh;
  }
`;
const SingleMessageBox = styled(SARow) `
  border-bottom: 0.025rem solid #d3d6db;
  padding: 0.5rem 0;
  width: 100%;
  cursor: pointer;
  background-color: ${({ $isSelected }) => ($isSelected ? "#E6F7FA" : "transparent")};
`;
const MessageTitle = styled(Space) `
  width: 100%;
  h5,
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;
