import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Select, Typography } from "antd";
import styled from "styled-components";
const TableSizeChanger = ({ value, onChange, options = [10, 15, 20], }) => (_jsxs(TableSizeChangerContainer, { children: [_jsx(Typography.Text, { children: "Show" }), _jsx(PaginationSizeChanger, { value: value, onChange: (size) => onChange(size), style: { width: "fit-content" }, children: options.map((size) => (_jsx(Select.Option, { value: size, children: size }, size))) })] }));
export default TableSizeChanger;
const TableSizeChangerContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
`;
const PaginationSizeChanger = styled(Select) `
  width: fit-content;
`;
