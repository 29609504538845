import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Quagga from "quagga";
export const QuaggaCode = ({ onDetected, isScanning }) => {
    const [scannerIsInitialized, setScannerIsInitialized] = useState(false);
    useEffect(() => {
        if (isScanning && !scannerIsInitialized) {
            Quagga.init({
                inputStream: {
                    name: "Live",
                    type: "LiveStream",
                    // target: document.querySelector("ean_reader"),
                    constraints: {
                        width: 640,
                        height: 480,
                        facingMode: "environment", // or 'user' for front camera
                    },
                },
                // locator: {
                //   patchSize: "medium",
                //   halfSample: true,
                // },
                // numOfWorkers: navigator.hardwareConcurrency || 4,
                // decoder: {
                //   readers: [
                //     "ean_reader",
                //     "upc_reader",
                //     "code_128_reader",
                //     "code_39_reader",
                //     "codabar_reader",
                //     "i2of5_reader",
                //     "2of5_reader",
                //     "code_93_reader",
                //   ],
                // },
                locate: true,
            }, (err) => {
                if (err) {
                    console.error(err);
                    return;
                }
                Quagga.start();
                setScannerIsInitialized(true);
            });
            Quagga.onDetected((result) => {
                onDetected(result);
                Quagga.stop(); // Stop scanning when barcode is detected
            });
            return () => {
                // Quagga.stop();
            };
        }
        else if (!isScanning && scannerIsInitialized) {
            // Quagga.stop();
            setScannerIsInitialized(false);
        }
    }, [isScanning, onDetected, scannerIsInitialized]);
    return (_jsxs("div", { id: "interactive", className: "viewport", children: [_jsx("video", { autoPlay: true, preload: "auto", src: "", muted: true, playsInline: true, style: { height: "500px", width: "500px", border: "1px solid black" } }), _jsx("canvas", { className: "drawingBuffer", width: "640px", height: "480px", style: { display: "none" } })] }));
};
