import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { uuidv4 } from "@antv/xflow-core";
import { Space, Table } from "antd";
import * as React from "react";
import styled from "styled-components";
import { getApplicationInformation } from "src/store/app/selector";
import { useAppSelector } from "src/store/hooks";
import { getDateByFormat } from "src/utils";
const LabDetailTable = ({ dataSource }) => {
    const appInformation = useAppSelector(getApplicationInformation);
    const labSampleSummary = [];
    dataSource?.forEach((job) => {
        const { noUrineLabs, noOralLabs, noHairLabs } = job;
        const totalSamples = noUrineLabs + noOralLabs + noHairLabs;
        if (noUrineLabs) {
            labSampleSummary.push({
                ...job,
                noSamples: noUrineLabs,
                totalSamples,
                specimenType: "Urine",
            });
        }
        if (noOralLabs) {
            labSampleSummary.push({
                ...job,
                noSamples: noOralLabs,
                totalSamples,
                specimenType: "Oral Fluid",
            });
        }
        if (noHairLabs) {
            labSampleSummary.push({
                ...job,
                noSamples: noHairLabs,
                totalSamples,
                specimenType: "Hair",
            });
        }
    });
    const groupedDataSource = labSampleSummary.map((item, index, array) => {
        if (index === 0 ||
            item.onsiteJobReference !== array[index - 1].onsiteJobReference ||
            !item.onsiteJobReference) {
            return item;
        }
        return {
            ...item,
            startAt: "",
            onsiteJobReference: "",
            onsiteClientName: "",
            totalSamples: "",
        };
    });
    const containsGroups = groupedDataSource.some((item, index) => index > 0 && item.onsiteJobReference === groupedDataSource[index - 1].onsiteJobReference);
    // Adds 'group-last-row' class to the last row of each group with the same job reference,
    // for visual separation. A row is the last if it's the final row or the next row has a different job reference.
    const rowClassName = containsGroups
        ? (record, index) => {
            const nextRowReference = groupedDataSource[index + 1]?.onsiteJobReference ?? undefined;
            const isLastInGroup = index === groupedDataSource.length - 1 ||
                (nextRowReference && nextRowReference !== record.onsiteJobReference);
            return isLastInGroup ? "group-last-row" : "";
        }
        : () => "";
    return (_jsxs(TestingTable, { rowClassName: rowClassName, dataSource: groupedDataSource?.map((entry) => ({ ...entry, id: uuidv4() })) ?? [], rowKey: "id", children: [_jsx(Table.Column, { title: "Date", dataIndex: "startAt", sorter: true, render: (startAt) => (_jsx(Space, { children: startAt
                        ? getDateByFormat(startAt, appInformation?.collectionOrganization?.timeFormat)
                        : "" })) }, "startAt"), _jsx(Table.Column, { title: "Job Reference", dataIndex: "onsiteJobReference", sorter: true }, "onsiteJobReference"), _jsx(Table.Column, { title: "Client", dataIndex: "onsiteClientName", sorter: true }, "onsiteClientName"), _jsx(Table.Column, { title: "Sample (Specimen)", dataIndex: "specimenType", sorter: true }, "specimenType"), _jsx(Table.Column, { title: "No", dataIndex: "noSamples", sorter: true }, "noSamples"), _jsx(Table.Column, { title: "Unit Cost", dataIndex: "unitCost", sorter: true, render: (unitCost) => (unitCost ? `$${unitCost}` : "-") }, "unitCost"), _jsx(Table.Column, { title: "Sub Total", sorter: true, render: (_, record) => record.noSamples && record.unitCost
                    ? `$${(Number(record.noSamples) * Number(record.unitCost)).toFixed(2)}`
                    : "" }), _jsx(Table.Column, { title: "GST", sorter: true, render: (_, record) => record.unitCostIncludingGST && record.unitCost
                    ? `$${Number((record.unitCostIncludingGST - record.unitCost) * record.noSamples).toFixed(2)}`
                    : "" }), _jsx(Table.Column, { title: "Total", sorter: true, render: (_total, record) => record.totalSamples && record.unitCostIncludingGST
                    ? `$${Number(record.totalSamples * record.unitCostIncludingGST).toFixed(2)}`
                    : "" })] }));
};
export default LabDetailTable;
const TestingTable = styled(Table) `
  .ant-table-tbody > tr.group-last-row > td {
    border-bottom: 0.1rem solid #01b4d2 !important;
  }
`;
