import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const BillingBox = ({ heading, packageName, packageValue, onClick, ...props }) => (_jsxs(BillingBoxContainer, { ...props, onClick: onClick, children: [_jsx(SARow, { children: _jsx(BoxHeading, { children: heading }) }), _jsxs(SARow, { gutter: [0, 0], children: [_jsx(SAColumn, { lg: 12, md: 24, children: _jsx(ContentSubtitle, { children: packageName }) }), _jsx(SAColumn, { lg: 12, md: 24, children: _jsx(ValueBox, { children: _jsxs(ContentSubtitle, { children: ["$ ", packageValue] }) }) })] })] }));
export default BillingBox;
const BillingBoxContainer = styled.div `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  min-height: 7rem;
  height: 100%;

  &:hover {
    cursor: pointer;
    background-color: #e6f7fa;
  }
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #4b5767;
`;
const ContentSubtitle = styled.p `
  font-size: 0.875rem;
  font-weight: 400;
  color: #4b5767;
  margin-right: auto;
  margin-block: auto;
`;
const ValueBox = styled.div `
  border: 0.025rem solid #edeef0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
`;
