import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DatePicker, Divider, Spin, Table, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { uuidv4 } from "@antv/xflow-core";
import SALabeledInput from "src/components/Form/SALabeledInput";
const CustomDateRangeTable = ({ showCollector, showTotal, dataSource, viewOption, setViewOptions, }) => (_jsxs(_Fragment, { children: [!dataSource.length && (_jsxs(ErrorBox, { children: [_jsx(Spin, {}), "Querying data or occurring an error"] })), dataSource.map((table, index) => (_jsx(StyledTableWrapper, { children: _jsx(Table, { dataSource: table.entries?.map((entry) => ({ ...entry, id: uuidv4() })) ?? [], rowKey: (record) => record.id, pagination: false, bordered: false, showHeader: index === 0, components: {
                    header: {
                        wrapper: (props) => (_jsx("thead", { ...props, children: _jsx("tr", { children: _jsx("td", { children: _jsxs(QueryByDateHeader, { children: [_jsx(SALabeledInput, { label: "From", children: _jsx(DatePicker, { suffixIcon: false, value: viewOption.startDate ? moment(viewOption.startDate) : null, placeholder: "DD/MM/YYYY", format: "DD/MM/YYYY", disabledDate: (current) => !!(current &&
                                                        viewOption.endDate &&
                                                        current.isAfter(viewOption.endDate, "day")), onChange: (date) => {
                                                        if (date) {
                                                            setViewOptions({
                                                                startDate: date.toISOString(),
                                                                endDate: viewOption.endDate,
                                                            });
                                                        }
                                                    } }) }), _jsx(SALabeledInput, { label: "To", children: _jsx(DatePicker, { suffixIcon: false, value: viewOption.endDate ? moment(viewOption.endDate) : null, placeholder: "DD/MM/YYYY", format: "DD/MM/YYYY", disabledDate: (current) => !!(current &&
                                                        viewOption.startDate &&
                                                        current.isBefore(viewOption.startDate, "day")), onChange: (date) => {
                                                        if (date) {
                                                            setViewOptions({
                                                                startDate: viewOption.startDate,
                                                                endDate: date.toISOString(),
                                                            });
                                                        }
                                                    } }) })] }) }) }) })),
                    },
                    body: {
                        wrapper: (props) => (_jsxs("tbody", { ...props, children: [showCollector && index === 0 && (_jsx("tr", { children: _jsx("td", { children: _jsx(CollectorBox, {}) }) })), table.groupTitle && (_jsx("tr", { children: _jsx("td", { children: _jsx(EmptyRow, {}) }) })), props.children, dataSource.length - index > 1 && (_jsx("tr", { children: _jsx("td", { style: { padding: "0 0.7rem" }, children: _jsx(Divider, { style: { marginBottom: "0.1rem" } }) }) }))] })),
                    },
                }, children: _jsx(Table.Column, { dataIndex: "total", align: "center", width: 50, render: (text, record) => {
                        if (showTotal && record.category === "Total") {
                            return (_jsx(TotalQuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                        }
                        return (_jsx(QuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                    } }, "total") }) }, index)))] }));
export default CustomDateRangeTable;
const StyledTableWrapper = styled.div `
  width: 100%;
`;
const QueryByDateHeader = styled.div `
  background: #f5f5f5;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 10rem;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #2e94f2;

  .ant-picker {
    max-width: 7rem;
  }
`;
const QuantityBox = styled.div `
  border: 1px solid #ebedf0;
  border-radius: 0.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  width: 4rem;
  margin: 0 auto;
`;
const CollectorBox = styled.div `
  height: 4rem;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
`;
const EmptyRow = styled.div `
  height: 2.4rem;
`;
const TotalQuantityBox = styled(QuantityBox) `
  font-weight: bold;
  background-color: #ebedf0;
`;
const ErrorBox = styled.div `
  height: 10rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
