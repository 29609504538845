import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CaretLeftOutlined, ClockCircleOutlined, DeleteOutlined, EditOutlined, } from "@ant-design/icons";
import { Button, Divider, Form, Input, message, Space, TimePicker, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { WelAssistTextLogo } from "src/assets/svg/app-icons";
import { HourGlassIcon } from "src/assets/svg/welcome-assist";
import { SAColumn, SADivider, SAFormItem, SARow, SASelect, SASwitch } from "src/components/Form";
import { getDisabledTime } from "src/infra/time";
import { getCollectionPointInformation, handleAfterSendingRequest } from "src/store/app/selector";
import { RequestAction } from "src/store/app/types";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { createKioskMessage, getKioskMessageById, updateKioskMessage, } from "../../KioskManagement/store/action";
import { WelcomeAssistError } from "../../KioskManagement/store/types";
import { EmptyEventMessage, KioskMessageStatus, KioskMessageType, ManageMessageView, } from "../typing";
import EditorNote from "./EditorNote";
const ManageEventMessage = ({ setRefresh }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const collectionPoints = useAppSelector(getCollectionPointInformation);
    const [currentMessage, setCurrentMessage] = useState(EmptyEventMessage);
    const [editingMessage, setEditingMessage] = useState(EmptyEventMessage);
    const messageId = searchParams.get("messageId");
    const view = searchParams.get("view");
    useEffect(() => {
        const loadMessage = async () => {
            const payload = await dispatch(getKioskMessageById(messageId)).unwrap();
            const { startAt, endAt } = payload.data;
            const payloadData = {
                ...(payload.data || EmptyEventMessage),
                endAt: endAt ? moment(endAt) : null,
                startAt: startAt ? moment(startAt) : null,
            };
            setCurrentMessage(payloadData);
        };
        if (messageId && view !== ManageMessageView.Create) {
            loadMessage();
        }
    }, [messageId, view]);
    useEffect(() => {
        if (view === ManageMessageView.Create) {
            setEditingMessage(EmptyEventMessage);
        }
        else {
            setEditingMessage({ ...EmptyEventMessage, ...currentMessage });
        }
    }, [currentMessage, view, form]);
    const displayManageButtons = () => {
        switch (view) {
            case ManageMessageView.Create:
                return (_jsx(SAColumn, { align: "end", children: _jsxs(Space, { children: [_jsx(Button, { danger: true, onClick: () => setSearchParams({ view: ManageMessageView.View }), children: "Cancel" }), _jsx(Button, { type: "primary", htmlType: "submit", children: "Save" })] }) }));
            case ManageMessageView.Edit:
                return (_jsxs(React.Fragment, { children: [_jsx(SAColumn, { span: 12, align: "start", children: _jsx(Button, { danger: true, icon: _jsx(DeleteOutlined, {}), onClick: async () => {
                                    if (!messageId)
                                        return;
                                    const payload = await dispatch(updateKioskMessage({ status: KioskMessageStatus.Deleted, id: messageId })).unwrap();
                                    if (!handleAfterSendingRequest(payload, RequestAction.Delete, WelcomeAssistError.EA001))
                                        return;
                                    setSearchParams({ view: ManageMessageView.View });
                                    setRefresh();
                                }, children: "Delete" }) }), _jsx(SAColumn, { span: 12, align: "end", children: _jsxs(Space, { children: [_jsx(Button, { danger: true, onClick: () => setSearchParams({ messageId: messageId || "", view: ManageMessageView.View }), children: "Cancel" }), _jsx(Button, { type: "primary", htmlType: "submit", children: "Save" })] }) })] }));
            default:
                return (_jsx(SAColumn, { span: 24, align: "end", onClick: () => {
                        setSearchParams({
                            messageId: messageId ?? "",
                            view: ManageMessageView.Edit,
                        });
                    }, children: _jsx(Button, { type: "primary", ghost: true, icon: _jsx(EditOutlined, {}), children: "Edit" }) }));
        }
    };
    return (_jsx(MessageContent, { children: _jsxs(Form, { form: form, colon: false, labelCol: { span: 24 }, wrapperCol: { span: 24 }, onFinishFailed: () => {
                message.error("The form has not been finished yet");
            }, onFinish: async () => {
                const editingData = {
                    label: editingMessage.label,
                    content: editingMessage.content,
                    contentPlainText: editingMessage.contentPlainText,
                    type: KioskMessageType.Event,
                    collectionPointId: editingMessage.collectionPointId,
                    status: editingMessage.status,
                    startAt: editingMessage.startAt,
                    endAt: editingMessage.endAt,
                    displayTime: editingMessage.displayTime,
                };
                const hasEmptyKey = Object.keys(editingData).some((key) => !editingData[key]);
                if (hasEmptyKey) {
                    message.error("The form has not been finished yet");
                    return;
                }
                let currentId = messageId;
                switch (view) {
                    case ManageMessageView.Create: {
                        const payload = await dispatch(createKioskMessage(editingData)).unwrap();
                        if (!handleAfterSendingRequest(payload, RequestAction.Create, WelcomeAssistError.EA001))
                            return;
                        currentId = payload.data;
                        break;
                    }
                    case ManageMessageView.Edit: {
                        if (!messageId)
                            return;
                        const payload = await dispatch(updateKioskMessage({
                            ...editingData,
                            id: messageId,
                        })).unwrap();
                        if (!handleAfterSendingRequest(payload, RequestAction.Update, WelcomeAssistError.EA001))
                            return;
                        break;
                    }
                    default:
                        message.error("Error when updating/creating a message");
                        break;
                }
                setRefresh();
                setSearchParams({ view: ManageMessageView.View, messageId: currentId || "" });
            }, children: [_jsx(LivePreviewArea, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 16, children: _jsxs(LivePreviewCard, { children: [_jsx(Typography.Title, { level: 4, children: moment().format("DD MMMM YYYY | HH:MM A") }), _jsx(SADivider, {}), _jsx("div", { dangerouslySetInnerHTML: { __html: editingMessage.content } })] }) }), _jsx(SAColumn, { span: 8, children: _jsxs(Space, { direction: "vertical", align: "center", children: [_jsx(WelAssistTextLogo, {}), _jsx(Button, { type: "primary", icon: _jsx(CaretLeftOutlined, {}), children: "Live Preview" }), _jsx(SASwitch, { uncheckedLabel: "", checkedLabel: "Published", disabled: view === ManageMessageView.View, checked: editingMessage.status === KioskMessageStatus.Published, onChange: (value) => {
                                                setEditingMessage((prev) => ({
                                                    ...prev,
                                                    status: value ? KioskMessageStatus.Published : KioskMessageStatus.Unpublished,
                                                }));
                                            } })] }) })] }) }), _jsx(EditContentArea, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 16, children: _jsxs(SARow, { gutter: [0, 16], children: [_jsx(SAColumn, { children: _jsx(SAFormItem, { label: "Location", children: _jsx(SASelect, { fieldNames: { label: "collectionPointName", value: "id" }, options: collectionPoints, disabled: view === ManageMessageView.View, value: editingMessage.collectionPointId, onChange: (collectionPointId) => {
                                                        setEditingMessage((prev) => ({ ...prev, collectionPointId }));
                                                    } }) }) }), _jsx(SAColumn, { children: _jsx(SAFormItem, { label: "Label", children: _jsx(Input, { readOnly: view === ManageMessageView.View, value: editingMessage.label, onChange: (e) => {
                                                        setEditingMessage((prev) => ({ ...prev, label: e.target.value }));
                                                    } }) }) }), _jsx(SAColumn, { span: 24, children: _jsx(EditorNote, { disabled: view === ManageMessageView.View, content: editingMessage.content, onChangeContent: (content, contentPlainText) => {
                                                    setEditingMessage((prev) => ({ ...prev, content, contentPlainText }));
                                                } }) }), displayManageButtons()] }) }), _jsx(SAColumn, { span: 8, children: _jsxs(SARow, { children: [_jsx(SAColumn, { align: "start", children: _jsxs(Space, { children: [_jsx(Typography.Text, { children: "Publish Time" }), " ", _jsx(ClockCircleOutlined, {})] }) }), _jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { children: _jsx(TimePicker, { format: "HH:mm", placeholder: "", value: editingMessage.startAt, disabledTime: () => getDisabledTime(editingMessage.endAt || undefined, true), onChange: (startAt) => {
                                                        if (!startAt)
                                                            return;
                                                        setEditingMessage((prev) => ({ ...prev, startAt }));
                                                    }, disabled: view === ManageMessageView.View }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { children: _jsx(TimePicker, { format: "HH:mm", placeholder: "", value: editingMessage.endAt, disabledTime: () => getDisabledTime(editingMessage.startAt || undefined, false), onChange: (endAt) => {
                                                        if (!endAt)
                                                            return;
                                                        setEditingMessage((prev) => ({ ...prev, endAt }));
                                                    }, disabled: view === ManageMessageView.View }) }) }), _jsxs(SAColumn, { align: "start", children: [_jsxs(Space, { children: [_jsx(Typography.Text, { children: "Message Display Time" }), _jsx(HourGlassIcon, {})] }), _jsx(Divider, {}), _jsxs(Space, { direction: "vertical", children: [_jsx(Typography.Text, { children: "For" }), _jsx(MinuteInput, { type: "number", min: 1, value: editingMessage.displayTime, onChange: (e) => setEditingMessage((prev) => ({
                                                                ...prev,
                                                                displayTime: +(e.target.value || 0),
                                                            })), disabled: view === ManageMessageView.View }), _jsx(Typography.Text, { children: "Minute(s)" }), _jsx(Typography.Text, { children: "Customize the time interval for displaying each message when multiple event messages are present." })] })] })] }) })] }) })] }) }));
};
export default ManageEventMessage;
const MessageContent = styled.div `
  width: 100%;
`;
const LivePreviewArea = styled.div `
  width: 100%;
  padding: 2rem;
  background-color: #ebedf0;
`;
const LivePreviewCard = styled.div `
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 1rem;
  border: 0.025rem solid #1a8cff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
`;
const EditContentArea = styled.div `
  width: 100%;
  padding: 2rem;

  .ant-form-item-label {
    text-align: left;
  }
`;
const MinuteInput = styled(Input) `
  width: fit-content;
`;
