import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { isNil, omitBy } from "lodash";
import { COLOR_SCHEMES } from "../../typing";
import * as actions from "./action";
import { initialCollectionTable, initialDashboardFilterOptions, initialTestStatistics, } from "./types";
export const initialState = {
    dailySummary: {
        filters: initialDashboardFilterOptions,
        data: initialTestStatistics,
    },
    processedTest: {
        filters: initialDashboardFilterOptions,
        data: [],
    },
    sampleResult: {
        filters: initialDashboardFilterOptions,
        data: [],
    },
    colorScheme: COLOR_SCHEMES[0].colors,
    collectors: [],
    collectionTables: {
        processedTest: initialCollectionTable,
        patientTime: initialCollectionTable,
        testTime: initialCollectionTable,
        testCategory: initialCollectionTable,
        urineDrugScreen: initialCollectionTable,
        urineDrugTest: initialCollectionTable,
        oralDrugScreen: initialCollectionTable,
        oralDrugTest: initialCollectionTable,
        hairDrugTest: initialCollectionTable,
        breathAlcoholScreen: initialCollectionTable,
        negativeDrugClass: initialCollectionTable,
        nonNegativeDrugClass: initialCollectionTable,
        invalidDrugClass: initialCollectionTable,
        collectorPerformance: initialCollectionTable,
        collectorRanking: initialCollectionTable,
        patientGender: initialCollectionTable,
        attendance: initialCollectionTable,
        donorCommunication: initialCollectionTable,
    },
};
const onsiteCollectionDashboardSlice = createSlice({
    name: "onsiteCollectionDashboard",
    initialState,
    reducers: {
        setDailySummaryFilter(state, action) {
            state.dailySummary.filters = { ...state.dailySummary.filters, ...action.payload };
        },
        setProcessedTestFilter(state, action) {
            state.processedTest.filters = { ...state.processedTest.filters, ...action.payload };
        },
        setSampleResultFilter(state, action) {
            state.sampleResult.filters = { ...state.sampleResult.filters, ...action.payload };
        },
        setColorScheme(state, action) {
            state.colorScheme = action.payload ?? COLOR_SCHEMES[0].colors;
        },
        setResetDetails(state, action) {
            if (state.collectionTables[action.payload]) {
                state.collectionTables[action.payload] = { ...initialCollectionTable };
            }
            else {
                console.warn(`Key "${action.payload}" does not exist in collectionTables.`);
            }
        },
        setCollectionTable(state, action) {
            state.collectionTables = { ...state.collectionTables, ...action.payload };
        },
        setResetPageState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(actions.getOnsiteDailySummary.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.dailySummary.data = data;
        })
            .addCase(actions.getOnsiteDailySummary.rejected, () => {
            message.error("Failed to fetch the onsite summary");
        })
            .addCase(actions.getProcessedTestByPeriod.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.processedTest.data = data;
        })
            .addCase(actions.getProcessedTestByPeriod.rejected, () => {
            message.error("Failed to fetch the onsite processed tests");
        })
            .addCase(actions.getTestSampleResultsEachType.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.sampleResult.data = data;
        })
            .addCase(actions.getTestSampleResultsEachType.rejected, () => {
            message.error("Failed to fetch the onsite sample results");
        })
            .addCase(actions.getProcessedTestDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.processedTest = {
                ...state.collectionTables.processedTest,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getProcessedTestDetails.rejected, () => {
            message.error("Failed to fetch processed test details");
        })
            .addCase(actions.getPatientTimeDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.patientTime = {
                ...state.collectionTables.patientTime,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getPatientTimeDetails.rejected, () => {
            message.error("Failed to fetch patient time details");
        })
            .addCase(actions.getTestTimeDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.testTime = {
                ...state.collectionTables.testTime,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getTestTimeDetails.rejected, () => {
            message.error("Failed to fetch test time details");
        })
            .addCase(actions.getTestCategoryDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.testCategory = {
                ...state.collectionTables.testCategory,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getTestCategoryDetails.rejected, () => {
            message.error("Failed to fetch test category details");
        })
            .addCase(actions.getUrineDrugScreenResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.urineDrugScreen = {
                ...state.collectionTables.urineDrugScreen,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getUrineDrugScreenResultDetails.rejected, () => {
            message.error("Failed to fetch urine drug screen result details");
        })
            .addCase(actions.getUrineDrugTestResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.urineDrugTest = {
                ...state.collectionTables.urineDrugTest,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getUrineDrugTestResultDetails.rejected, () => {
            message.error("Failed to fetch urine drug test result details");
        })
            .addCase(actions.getOralDrugScreenResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.oralDrugScreen = {
                ...state.collectionTables.oralDrugScreen,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getOralDrugScreenResultDetails.rejected, () => {
            message.error("Failed to fetch oral drug screen result details");
        })
            .addCase(actions.getOralDrugTestResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.oralDrugTest = {
                ...state.collectionTables.oralDrugTest,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getOralDrugTestResultDetails.rejected, () => {
            message.error("Failed to fetch oral drug test result details");
        })
            .addCase(actions.getHairDrugTestResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.hairDrugTest = {
                ...state.collectionTables.hairDrugTest,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getHairDrugTestResultDetails.rejected, () => {
            message.error("Failed to fetch hair drug test result details");
        })
            .addCase(actions.getBreathAlcoholScreenResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.breathAlcoholScreen = {
                ...state.collectionTables.breathAlcoholScreen,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getBreathAlcoholScreenResultDetails.rejected, () => {
            message.error("Failed to fetch breath alcohol screen result details");
        })
            .addCase(actions.getNegativeDrugClassResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.negativeDrugClass = {
                ...state.collectionTables.negativeDrugClass,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getNegativeDrugClassResultDetails.rejected, () => {
            message.error("Failed to fetch negative drug class details");
        })
            .addCase(actions.getNonNegativeDrugClassResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.nonNegativeDrugClass = {
                ...state.collectionTables.nonNegativeDrugClass,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getNonNegativeDrugClassResultDetails.rejected, () => {
            message.error("Failed to fetch non negative drug class details");
        })
            .addCase(actions.getInvalidDrugClassResultDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.invalidDrugClass = {
                ...state.collectionTables.invalidDrugClass,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getInvalidDrugClassResultDetails.rejected, () => {
            message.error("Failed to fetch invalid drug class details");
        })
            .addCase(actions.getCollectorPerformanceDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.collectorPerformance = {
                ...state.collectionTables.collectorPerformance,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getCollectorPerformanceDetails.rejected, () => {
            message.error("Failed to fetch collector performance details");
        })
            .addCase(actions.getCollectorRankingDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.collectorRanking = {
                ...state.collectionTables.collectorRanking,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getCollectorRankingDetails.rejected, () => {
            message.error("Failed to fetch collector ranking details");
        })
            .addCase(actions.getPatientGenderDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.patientGender = {
                ...state.collectionTables.patientGender,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getPatientGenderDetails.rejected, () => {
            message.error("Failed to fetch patient gender details");
        })
            .addCase(actions.getAttendanceDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.attendance = {
                ...state.collectionTables.attendance,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getAttendanceDetails.rejected, () => {
            message.error("Failed to fetch attendance details");
        })
            .addCase(actions.getDonorCommunicationDetails.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectionTables.donorCommunication = {
                ...state.collectionTables.donorCommunication,
                ...omitBy(data, isNil),
            };
        })
            .addCase(actions.getDonorCommunicationDetails.rejected, () => {
            message.error("Failed to fetch donor communication details");
        })
            .addCase(actions.getListCollectorsByCollectionPoint.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.collectors = data;
        })
            .addCase(actions.getListCollectorsByCollectionPoint.rejected, () => {
            message.error("Failed to fetch collectors");
        });
    },
});
export const { setDailySummaryFilter, setProcessedTestFilter, setSampleResultFilter, setColorScheme, setResetDetails, setResetPageState, setCollectionTable, } = onsiteCollectionDashboardSlice.actions;
export default onsiteCollectionDashboardSlice.reducer;
