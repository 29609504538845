import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button, Col, Input, Row, Typography } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import ServiceOfficeTable from "src/pages/CollectionOrganization/ServiceOffice/components/ServiceOfficeTable";
import AddNewServiceOffice from "src/pages/CollectionOrganization/ServiceOffice/components/AddNew";
import { getOrganizationDetail } from "src/pages/CollectionOrganization/ServiceOffice/store/action";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsOpen, setResetStore, setSearchKeyWord, } from "src/pages/CollectionOrganization/ServiceOffice/store/slice";
import { selectSearchKeyWord } from "src/pages/CollectionOrganization/ServiceOffice/store/selector";
import { ICServiceOffice } from "src/assets/svg/collection-organization";
import { breadcrumbs } from "../index";
const ServiceOffice = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const changeSearch = useAppSelector(selectSearchKeyWord);
    React.useEffect(() => () => {
        dispatch(setResetStore());
    }, [dispatch, location.pathname]);
    useEffect(() => {
        const loadData = async () => {
            await dispatch(getOrganizationDetail({}));
        };
        loadData();
    }, [changeSearch]);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(Row, { gutter: [0, 10], children: [_jsx(Col, { span: 24, children: _jsxs(Row, { justify: "space-between", children: [_jsx(Col, { span: 6, children: _jsxs(Row, { gutter: [20, 0], children: [_jsx(Col, { children: _jsx(ICServiceOffice, {}) }), _jsx(Col, { children: _jsx(Typography.Title, { level: 5, children: "Service Office | Clinic" }) })] }) }), _jsx(Col, { span: 14, children: _jsxs(Row, { gutter: [20, 0], justify: "end", children: [_jsx(Col, { children: _jsx(Button, { type: "primary", icon: _jsx(PlusOutlined, {}), onClick: () => dispatch(setIsOpen(true)), children: "Add New" }) }), _jsx(Col, { children: _jsx(Input, { prefix: _jsx(SearchOutlined, {}), placeholder: "Search", value: changeSearch, onChange: (e) => dispatch(setSearchKeyWord(e.target.value)) }) })] }) })] }) }), _jsxs(Col, { span: 24, children: [_jsx(ServiceOfficeTable, {}), _jsx(AddNewServiceOffice, {})] })] }) }));
};
export default ServiceOffice;
