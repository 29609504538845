import styled from "styled-components";
import { SAColourScheme, mainAppColours } from "src/constants";
export const NewSatchelWrapper = styled.div `
  width: 100%;
  padding: 3rem;
  border: 1px solid #01b4d2;
  display: flex;
  flex-direction: column;

  .ant-radio-inner {
    width: 22px;
    height: 22px;
  }

  .ant-form-item {
    margin-bottom: 15px !important;
  }
  .ant-input {
    border-radius: 4px;
  }
  .ant-form-item-label {
    padding: 0 0;
  }

  .triangle-right {
    width: 0;
    height: 35px;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 18px solid #61b1f8;
    margin-right: 18px;
  }

  .ant-select .ant-select-selector {
    height: 30px !important;
    background-color: white !important;
    border: 1px solid #d9d9d9 !important;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font-size: 15px !important;
    color: #adb3bc !important;
  }
`;
export const TableWrapper = styled.div `
  .menu-options {
    cursor: pointer;
  }

  .options-dropdown {
    border: 1px solid ${mainAppColours.MID_BLUE};
  }

  .menu-options:hover {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${SAColourScheme.PLASMA};
  }
`;
export const PageWrapper = styled.div `
  width: 100%;

  .title {
    margin-top: 8px;
    margin-left: 5px;
    color: #ffffff;
    font-size: 15px;
    margin-bottom: 0;
  }
  .sub-title {
    font-size: 12px;
    color: white;
    margin-bottom: 0;
  }
  .ant-form-item-control-input input {
    height: 40px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .info-element {
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    color: #2f3337;
    font-size: 16px;
  }
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
  }
  .check-box .ant-checkbox-inner {
    width: 16px;
    height: 16px;
  }
  .measurement {
    width: 97px;
    height: 32px;
    font-size: 16px;
    font-weight: 500;
    padding: 3px 24px;
    border-radius: 8px;
  }

  .select-label {
    font-size: 16px;
    color: #2f3337;
    margin-top: 7px;
  }
  .number-box input.text-input {
    border-radius: 4px;
    text-align: center;
  }

  .number-box .info-element {
    padding: 3px 0 !important;
    height: 32px;
    width: 40px;
  }
`;
