import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EllipsisOutlined, EyeInvisibleOutlined, EyeOutlined, UserAddOutlined, } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Space, Table, Tag, Typography } from "antd";
import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { KioskManagerIcon } from "src/assets/svg/welcome-assist";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getFullName } from "src/utils";
import { getListLicenseByCollectionPoint, removeLicenseManager } from "../../store/action";
import { selectCollectionPoint, selectListLicensesByCP } from "../../store/selector";
import { UserStatusMapping } from "../../store/types";
import EditManagerModal from "./EditManagerModal";
import InviteWAManagerModal from "./InviteWAManagerModal";
import ResetPasswordManagerModal from "./ResetPasswordManagerModal";
import ResetSecurityPinManagerModal from "./ResetSecurityPinManagerModal";
const LicenseManagerManagement = () => {
    const dispatch = useAppDispatch();
    const { openMessageBox } = useContext(ContentContext);
    const selectedCollectionPoint = useAppSelector(selectCollectionPoint);
    const listLicenses = useAppSelector(selectListLicensesByCP);
    const [isOpenInvitationBox, setIsOpenInvitationBox] = useState(false);
    const [isOpenEditBox, setIsOpenEditBox] = useState(false);
    const [isOpenResetPasswordBox, setIsOpenResetPasswordBox] = useState(false);
    const [isOpenResetPinBox, setIsOpenResetPinBox] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);
    const [showSecurityPins, setShowSecurityPins] = useState(false);
    const [, setSearchParams] = useSearchParams();
    return (_jsxs(React.Fragment, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, align: "start", children: _jsxs(Space, { children: [_jsx(KioskManagerIcon, { color: "#01b4d2" }), _jsxs(Typography.Title, { level: 5, children: ["Welcome Assist Kiosks Managers | ", selectedCollectionPoint?.collectionPointName] })] }) }), _jsx(SAColumn, { span: 12, align: "end", children: _jsx(Button, { icon: _jsx(UserAddOutlined, {}), type: "primary", ghost: true, onClick: () => {
                                if (!selectedCollectionPoint.id) {
                                    message.error("Please select a collection point first");
                                    return;
                                }
                                setIsOpenInvitationBox(true);
                            }, children: "Invite" }) }), _jsx(SAColumn, { span: 24, children: _jsxs(ManagerTable, { dataSource: listLicenses.userList, rowKey: "userId", bordered: false, children: [_jsx(Table.Column, { title: "WA Manager", render: (_, record) => getFullName(record) }), _jsx(Table.Column, { title: "Email", dataIndex: "email" }), _jsx(Table.Column, { title: "Mobile", dataIndex: "mobile" }), _jsx(Table.Column, { title: _jsxs(Button, { type: "link", onClick: () => setShowPasswords(!showPasswords), children: ["Password ", showPasswords ? _jsx(EyeInvisibleOutlined, {}) : _jsx(EyeOutlined, {})] }), align: "center", dataIndex: "password", render: (text) => (showPasswords ? text : "••••••••") }), _jsx(Table.Column, { title: _jsxs(Button, { type: "link", onClick: () => setShowSecurityPins(!showSecurityPins), children: ["Security PIN ", showSecurityPins ? _jsx(EyeInvisibleOutlined, {}) : _jsx(EyeOutlined, {})] }), align: "center", dataIndex: "pin", render: (text) => (showSecurityPins ? text : "••••••••") }), _jsx(Table.Column, { title: "Status", dataIndex: "status", align: "center", render: (userStatus) => {
                                        const { status, color } = UserStatusMapping[userStatus];
                                        return _jsx(Tag, { color: color, children: status });
                                    } }), _jsx(Table.Column, { title: "", dataIndex: "id", align: "center", render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { children: [_jsx(Menu.Item, { onClick: () => {
                                                        setSearchParams({
                                                            id: record.userId,
                                                        });
                                                        setIsOpenEditBox(true);
                                                    }, children: "Edit" }, "edit"), _jsx(Menu.Item, { onClick: () => {
                                                        setSearchParams({
                                                            id: record.userId,
                                                        });
                                                        setIsOpenResetPasswordBox(true);
                                                    }, children: "Reset Password" }, "resetPassword"), _jsx(Menu.Item, { onClick: () => {
                                                        setSearchParams({
                                                            id: record.userId,
                                                        });
                                                        setIsOpenResetPinBox(true);
                                                    }, children: "Reset Security PIN" }, "resetSecurity"), _jsx(Menu.Item, { onClick: () => {
                                                        openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${getFullName(record)} will be removed and will no longer have access to the Welcome Assist Kiosk.`, async () => {
                                                            await dispatch(removeLicenseManager(record.userId));
                                                            await dispatch(getListLicenseByCollectionPoint());
                                                        });
                                                    }, children: "Delete" }, "delete")] }), trigger: ["click"], children: _jsx(Button, { type: "link", children: _jsx(EllipsisOutlined, {}) }) })) }, "id")] }) })] }), _jsx(InviteWAManagerModal, { isOpen: isOpenInvitationBox, setIsOpen: setIsOpenInvitationBox }), _jsx(EditManagerModal, { isOpen: isOpenEditBox, setIsOpen: setIsOpenEditBox }), _jsx(ResetPasswordManagerModal, { isOpen: isOpenResetPasswordBox, setIsOpen: setIsOpenResetPasswordBox }), _jsx(ResetSecurityPinManagerModal, { isOpen: isOpenResetPinBox, setIsOpen: setIsOpenResetPinBox })] }));
};
export default LicenseManagerManagement;
const ManagerTable = styled(Table) `
  width: 100%;
`;
