import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { TestTypeRecordIndex } from "src/store/app/types";
import { applyCommonActionHandlers } from "../../store/slice";
import { initialTestingDeviceDetails } from "../store/types";
import { createUrineScreenTestingDevice, getUrineScreenTestingDeviceById, getTestingDevices, updateUrineScreenTestingDevice, } from "./action";
export const initialState = {
    listTestingDevices: [],
    listArchivedTestingDevices: [],
    selectedCollectionPointId: "",
    testingDeviceDetails: initialTestingDeviceDetails,
    inventoryOverview: {
        totalUnits: 0,
        usedUnits: 0,
        remainingUnits: 0,
    },
};
const urineScreenDeviceSlice = createSlice({
    name: "urineScreenDevice",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
        setCollectionPointId(state, action) {
            state.selectedCollectionPointId = action.payload;
        },
        setDeviceDetails(state, action) {
            const oldData = state.testingDeviceDetails.deviceDetail;
            state.testingDeviceDetails.deviceDetail = {
                ...oldData,
                ...action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        applyCommonActionHandlers(builder, TestTypeRecordIndex.RapidUrineDrugScreen);
        builder
            .addCase(getTestingDevices.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch testing devices");
            }
            else {
                if (action.meta.arg.isArchived) {
                    state.listArchivedTestingDevices = action.payload.data.testingDevicesData;
                    return;
                }
                state.listTestingDevices = action.payload.data.testingDevicesData;
                state.inventoryOverview = action.payload.data.inventoryOverview;
            }
        })
            .addCase(getTestingDevices.rejected, () => {
            message.error("Failed to fetch testing devices");
        })
            .addCase(createUrineScreenTestingDevice.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to create a new device");
            }
            else {
                message.success("Successfully created a new device");
            }
        })
            .addCase(createUrineScreenTestingDevice.rejected, () => {
            message.error("Failed to create a new device");
        })
            .addCase(getUrineScreenTestingDeviceById.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch the device information");
            }
            else {
                state.testingDeviceDetails.deviceDetail = action.payload.data?.deviceDetail ?? {};
                state.testingDeviceDetails.stockDetail = action.payload.data?.stockDetail ?? [];
            }
        })
            .addCase(getUrineScreenTestingDeviceById.rejected, () => {
            message.error("Failed to fetch the device information");
        })
            .addCase(updateUrineScreenTestingDevice.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update the device");
            }
            else {
                message.success("Successfully updated the device");
            }
        })
            .addCase(updateUrineScreenTestingDevice.rejected, () => {
            message.error("Failed to update a new device");
        });
    },
});
export const { setResetStore, setCollectionPointId, setDeviceDetails } = urineScreenDeviceSlice.actions;
export default urineScreenDeviceSlice.reducer;
