export const EmptyLaboratory = {
    address1: "",
    address2: "",
    collectionOrganizationId: "",
    name: "",
    country: "Australia",
    createdAt: "",
    doctor: "",
    email: "",
    fax: "",
    id: "",
    isValid: true,
    phoneNumber: "",
    postcode: "",
    state: "",
    suburb: "",
    timezone: "",
    updatedAt: "",
    urgentContact: "",
};
export var Status;
(function (Status) {
    Status["IDLE"] = "idle";
    Status["LOADING"] = "loading";
    Status["SUCCESS"] = "success";
    Status["ERROR"] = "error";
    Status["FAIL"] = "fail";
})(Status || (Status = {}));
