import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Image, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PinInput from "react-pin-input";
import { verifyQrPin } from "src/api/inventory/inventoryService";
import { Scanner } from "./Scanner";
export const Connection = () => {
    const [pinInput, setPinInput] = useState("");
    const [isConnectionEstablished, setIsConnectionEstablished] = useState(false);
    const [error, setError] = useState("");
    const [connectedRecordId, setConnectedRecordId] = useState(null);
    const [encodedUrl, setEncodedUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    useEffect(() => {
        const { pathname } = location;
        const encodedCode = pathname.split("/").pop();
        setEncodedUrl(encodedCode ?? "");
    }, [location.pathname]);
    const handleVerifyPin = async () => {
        setIsLoading(true);
        setError("");
        const response = await verifyQrPin(pinInput, encodedUrl);
        if (response.status === "success") {
            setConnectedRecordId(response.data);
            setIsConnectionEstablished(true);
            message.success("Pin Verified");
        }
        else {
            setIsConnectionEstablished(false);
            setError(response.message);
        }
        setIsLoading(false);
    };
    const handlePinInputChange = (value) => {
        setPinInput(value);
    };
    return (_jsxs("div", { style: { background: "white", paddingBottom: "70px" }, children: [_jsx("div", { style: {
                    width: "100%",
                    height: "50px",
                    background: "#01B4D2",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }, children: _jsx("span", { style: {
                        flex: 1,
                        textAlign: "center",
                        margin: "auto",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "white",
                    }, children: "Scan Assist" }) }), !isConnectionEstablished && (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "100px",
                        }, children: _jsx(Image, { src: "/assets/svg/inventory/ic-scan-assist.svg", preview: false }) }), _jsx("div", { style: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "70px",
                        }, children: _jsx(Image, { src: "/assets/svg/inventory/ic-pin-lock-icon.svg", preview: false, height: 100, width: 200 }) }), _jsxs("div", { children: [_jsx("div", { style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "70px",
                                }, children: _jsx(PinInput, { length: 6, initialValue: "", 
                                    // onChange={(value, index) => {}}
                                    type: "numeric", inputMode: "number", style: {
                                        textAlign: "left",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }, inputStyle: {
                                        border: "1px solid #1A8CFF",
                                        borderRadius: "4px",
                                        padding: "3px",
                                        margin: "10px",
                                        width: "40px",
                                        height: "50px",
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                        color: "#505153",
                                    }, onComplete: (value) => handlePinInputChange(value), autoSelect: true, regexCriteria: /^[ A-Za-z0-9_@./#&+-]*$/ }) }), _jsx("div", { style: {
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "70px",
                                }, children: _jsx(Button, { onClick: handleVerifyPin, style: {
                                        width: "300px",
                                        height: "50px",
                                        background: pinInput.length === 6 ? "#151349" : "#ADB3BC",
                                        color: "white",
                                        borderRadius: "10px",
                                    }, disabled: pinInput.length < 6, children: _jsx("span", { style: { fontSize: "18px" }, children: "Next" }) }) }), _jsx("div", { style: { display: "flex", justifyContent: "center", marginTop: "10px" }, children: _jsx(Spin, { spinning: isLoading, size: "large" }) })] }), error && (_jsx("div", { style: {
                            display: "flex",
                            justifyContent: "center",
                            padding: "5px",
                            marginTop: "30px",
                        }, children: _jsx("span", { children: error }) }))] })), isConnectionEstablished && (_jsx(_Fragment, { children: connectedRecordId && _jsx(Scanner, { connectedRecordId: connectedRecordId }) }))] }));
};
