import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import CollectAssistPage from "src/pages/PlatformManagementMenuPage/CollectAssistPage";
import ComingSoonPage from "../PageErrors/ComingSoonPage";
import PlatformManagementPage from "./PlatformManagementPage";
import WelcomeAssistPage from "./WelcomeAssist";
const PlatformManagementMenuPage = () => (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "", element: _jsx(PlatformManagementPage, {}) }), _jsx(Route, { path: "/welcome-assist/*", element: _jsx(WelcomeAssistPage, {}) }), _jsx(Route, { path: "/work-assist/*", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/collect-assist/*", element: _jsx(CollectAssistPage, {}) }), _jsx(Route, { path: "/secure-assist/*", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/lab-assist/*", element: _jsx(ComingSoonPage, {}) }), _jsx(Route, { path: "/report-assist/*", element: _jsx(ComingSoonPage, {}) })] }) }));
export default PlatformManagementMenuPage;
