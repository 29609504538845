import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { omit } from "lodash";
import { $get, $post } from "src/infra/http";
const getTestingDevices = createAsyncThunk("getOralTestTestingDevices", async ({ testTypeId, isArchived = false }, thunkApi) => {
    const state = thunkApi.getState().deviceManagementPage;
    const collectionPointId = state.oralCollectionDevicePage.selectedCollectionPointId;
    const queryString = stringify({
        testTypeId,
        isArchived,
        collectionPointId,
    });
    return await $get(`/collection-manager/testing-devices?${queryString}`);
});
const getOralTestTestingDeviceById = createAsyncThunk("getOralTestTestingDeviceById", async (data) => {
    const queryString = stringify({ page: data.page, limit: data.limit });
    return await $get(`/collection-manager/testing-devices/view/${data.testingDeviceId}?${queryString}`);
});
const createOralTestTestingDevice = createAsyncThunk("createOralTestTestingDevice", async (newData) => await $post(`/collection-manager/testing-devices/create`, newData));
const updateOralTestTestingDevice = createAsyncThunk("updateOralTestTestingDevice", async (newData) => await $post(`/collection-manager/testing-devices/update/${newData.id}`, omit(newData, "id")));
const createStockFluidCollection = createAsyncThunk("createStock", async (newData) => await $post(`/collection-manager/testing-devices/stock/device`, newData));
export { getTestingDevices, createOralTestTestingDevice, getOralTestTestingDeviceById, updateOralTestTestingDevice, createStockFluidCollection, };
