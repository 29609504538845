import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppConfiguration from "./AppConfiguration";
import ClockOnClockOffRoute from "./ClockOnClockOffPage/routes";
import CollectorCalendar from "./CollectorCalendar";
import CollectorCredentials from "./CollectorCredentials";
import CollectorInformationPageRoutes from "./CollectorInformation";
import CollectorsPage from "./CollectorsPage";
const CollectorAssistPage = () => (_jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "collectors", replace: true }) }), _jsx(Route, { path: "collectors", element: _jsx(CollectorsPage, {}) }), _jsx(Route, { path: "collectors/*", element: _jsx(CollectorInformationPageRoutes, {}) }), _jsx(Route, { path: "credentials", element: _jsx(CollectorCredentials, {}) }), _jsx(Route, { path: "calendar", element: _jsx(CollectorCalendar, {}) }), _jsx(Route, { path: "clock-on-clock-off/*", element: _jsx(ClockOnClockOffRoute, {}) }), _jsx(Route, { path: "app-configuration", element: _jsx(AppConfiguration, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/platform-management/collect-assist/", replace: true }) })] }));
export default CollectorAssistPage;
export const routes = [
    {
        path: "/platform-management",
        name: "Platform Management",
        level: 1,
    },
    {
        path: "/collect-assist",
        name: "Collector Assist",
        level: 2,
    },
    {
        path: "/collectors",
        name: "Collectors",
        level: 3,
    },
    {
        path: "/credentials",
        name: "Collector Credentials",
        level: 3,
    },
    {
        path: "/calendar",
        name: "Collector's Calendar",
        level: 3,
    },
    {
        path: "/clock-on-clock-off",
        name: "Clock On - Clock Off",
        level: 3,
    },
    {
        path: "/app-configuration",
        name: "App Configuration",
        level: 3,
    },
];
