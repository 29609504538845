var TimeFormat;
(function (TimeFormat) {
    TimeFormat[TimeFormat["TwelveHour"] = 12] = "TwelveHour";
    TimeFormat[TimeFormat["TwentyFourHour"] = 24] = "TwentyFourHour";
})(TimeFormat || (TimeFormat = {}));
export var CollectionOrgStatus;
(function (CollectionOrgStatus) {
    CollectionOrgStatus[CollectionOrgStatus["inacitive"] = 0] = "inacitive";
    CollectionOrgStatus[CollectionOrgStatus["acitive"] = 1] = "acitive";
})(CollectionOrgStatus || (CollectionOrgStatus = {}));
const appInitialState = {
    collectionOrganization: {
        collectionName: "",
        id: "",
        timeFormat: TimeFormat.TwelveHour,
        status: CollectionOrgStatus.inacitive,
    },
    user: {
        firstName: "",
        lastName: "",
        email: "",
        id: "",
    },
    collectionPoints: [],
    testTypes: [],
    testCategories: [],
};
var TestTypeRecordIndex;
(function (TestTypeRecordIndex) {
    TestTypeRecordIndex[TestTypeRecordIndex["NotSelected"] = 0] = "NotSelected";
    TestTypeRecordIndex[TestTypeRecordIndex["UrineDrugTest"] = 1] = "UrineDrugTest";
    TestTypeRecordIndex[TestTypeRecordIndex["BreathAlcoholTest"] = 2] = "BreathAlcoholTest";
    TestTypeRecordIndex[TestTypeRecordIndex["OralFluidDrugTest"] = 3] = "OralFluidDrugTest";
    TestTypeRecordIndex[TestTypeRecordIndex["RapidOralFluidDrugScreen"] = 4] = "RapidOralFluidDrugScreen";
    TestTypeRecordIndex[TestTypeRecordIndex["HairDrugTest"] = 5] = "HairDrugTest";
    TestTypeRecordIndex[TestTypeRecordIndex["RapidUrineDrugScreen"] = 6] = "RapidUrineDrugScreen";
})(TestTypeRecordIndex || (TestTypeRecordIndex = {}));
var RequestAction;
(function (RequestAction) {
    RequestAction["Create"] = "Create";
    RequestAction["Update"] = "Update";
    RequestAction["Delete"] = "Delete";
})(RequestAction || (RequestAction = {}));
var DateRangeOption;
(function (DateRangeOption) {
    DateRangeOption[DateRangeOption["ThisMonth"] = 1] = "ThisMonth";
    DateRangeOption[DateRangeOption["ThisQuarter"] = 2] = "ThisQuarter";
    DateRangeOption[DateRangeOption["ThisFinancialYear"] = 3] = "ThisFinancialYear";
    DateRangeOption[DateRangeOption["ThisCalendarYear"] = 4] = "ThisCalendarYear";
    DateRangeOption[DateRangeOption["SelectRange"] = 5] = "SelectRange";
})(DateRangeOption || (DateRangeOption = {}));
var DateRangeName;
(function (DateRangeName) {
    DateRangeName["ThisMonth"] = "This month";
    DateRangeName["ThisQuarter"] = "This quarter";
    DateRangeName["ThisFinancialYear"] = "This financial year";
    DateRangeName["ThisCalendarYear"] = "This calendar year";
    DateRangeName["SelectRange"] = "";
})(DateRangeName || (DateRangeName = {}));
export { appInitialState, TestTypeRecordIndex, TimeFormat, RequestAction, DateRangeOption, DateRangeName, };
