import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutlined } from "@ant-design/icons";
import { Form, Space, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState, useEffect } from "react";
import styled from "styled-components";
import SAFormItem from "src/components/Form/SAFormItem";
const NoteForm = ({ onSubmit, dataSource }) => {
    if (!dataSource)
        return _jsx(_Fragment, {});
    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    useEffect(() => {
        form.setFieldsValue(dataSource);
    }, []);
    return (_jsxs(StyledForm, { form: form, colon: false, onFinish: () => {
            onSubmit({ ...dataSource, ...form.getFieldsValue() });
            form.resetFields();
        }, onValuesChange: () => setIsEditing(dataSource?.notes !== form.getFieldValue("notes")), children: [_jsx(SAFormItem, { name: "notes", children: _jsx(StyledTextArea, { rows: 5 }) }), _jsxs(ActionContainer, { children: [_jsx(Button, { type: "link", onClick: () => {
                            onSubmit({ ...dataSource, notes: "" });
                            form.resetFields();
                        }, children: _jsx(DeleteIcon, {}) }), _jsxs(ButtonGroup, { children: [_jsx(Button, { onClick: () => {
                                    form.setFieldsValue(dataSource);
                                }, disabled: !isEditing, children: "Cancel" }), _jsx(Button, { type: "primary", htmlType: "submit", disabled: !isEditing, children: "Save" })] })] })] }));
};
export default NoteForm;
const StyledForm = styled(Form) `
  width: 20rem;
`;
const StyledTextArea = styled(TextArea) `
  background: #e1e2e5;
`;
const ActionContainer = styled(Space) `
  width: 100%;
  justify-content: space-between;
`;
const ButtonGroup = styled(Space) ``;
const DeleteIcon = styled(DeleteOutlined) `
  font-size: 1.2rem;
`;
