import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { getAllPatients } from "src/api/patientList/patientListService";
import ContentContext from "src/providers/content";
import { useAppSelector } from "src/store/hooks";
import { getRequestedTestTypeInformation } from "src/store/app/selector";
import { getRequestTestColor } from "src/utils/helper";
import { useSettings } from "src/providers/SettingsContextProvider";
import { HeaderFilter } from "./components/HeaderFilter";
import PatientList from "./components/PatientList";
import { PageWrapper } from "./index.style";
const PatientListPage = () => {
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const testTypeObj = useAppSelector(getRequestedTestTypeInformation);
    const { settings } = useSettings();
    const [_, setCollectionPoint] = useState("All");
    const [patientListData, setPatientListData] = useState([]);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [searchString, setSearchString] = useState("");
    const [reload, setReload] = useState(false);
    const [displayColumns, setDisplayColumns] = useState([
        "startAt",
        "time",
        "name",
        "Service Office",
        "requestTest",
        "Details",
    ]);
    const selectColumn = (cols) => {
        setDisplayColumns(cols);
    };
    const selectCollectionPoint = (point) => {
        setCollectionPoint(point);
    };
    const getData = async () => {
        const result = await getAllPatients(startDate, endDate);
        const tempList = result.data?.map((element, index) => {
            const testTypeName = testTypeObj?.filter((item) => element.requestTestIds.includes(item.id));
            const requestTestInfo = getRequestTestColor(testTypeName, element.status);
            const timezone = settings.organizationSetting.timezone ?? "Australia/Sydney";
            // Format date and time based on timezone
            const date = moment
                .tz(element.startAt, timezone ? timezone : "")
                .format(settings.organizationSetting.dateFormat);
            const time = moment
                .tz(element.startAt, timezone ? timezone : "")
                .format(settings.organizationSetting.timeFormat);
            return {
                key: index,
                index: index + 1,
                startAt: date,
                time: time,
                name: element.name,
                collectionPointName: element.collectionPointName,
                requestTest: {
                    text: requestTestInfo.testName,
                    background: requestTestInfo.background,
                    border: requestTestInfo.border,
                },
                requestTestText: requestTestInfo.testName,
                patientId: element.patientId ?? "",
                collectionPointId: element.collectionPointId,
                appointmentId: element.id,
            };
        });
        setPatientListData(tempList);
        setReload(false);
        closeLoadingScreen();
        setSearchString("");
        setTimeout(() => {
            setSearchString(searchString);
        }, 2000);
    };
    useEffect(() => {
        openLoadingScreen();
        getData();
    }, [startDate, endDate, reload, testTypeObj]);
    return (_jsxs(PageWrapper, { children: [_jsx(HeaderFilter, { selectColumn: selectColumn, selectCollectionPoint: selectCollectionPoint, selectStartDate: setStartDate, selectEndDate: setEndDate, onSearchString: setSearchString, startDate: startDate, endDate: endDate }), _jsx(PatientList, { displayColumns: displayColumns, searchString: searchString, patientListData: patientListData, reload: setReload })] }));
};
export default PatientListPage;
