import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { DeleteKeyIcon } from "src/assets/svg/welcome-assist";
import { MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import { selectCollectionOrganization, selectDateWithSetupTime } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { selectCollectionPoint, selectListLicensesByCP } from "../../store/selector";
import { KioskLicenseStatus, UserStatus, UserStatusMapping } from "../../store/types";
import { deactivateLicense, getListLicenseByCollectionPoint } from "../../store/action";
const LicenseManagement = () => {
    const dispatch = useAppDispatch();
    const { openMessageBox } = useContext(ContentContext);
    const selectedCollectionPoint = useAppSelector(selectCollectionPoint);
    const listLicenses = useAppSelector(selectListLicensesByCP);
    const collectionOrg = useAppSelector(selectCollectionOrganization);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    return (_jsxs(SARow, { children: [_jsx(SAColumn, { span: 24, align: "start", children: _jsxs(Typography.Title, { level: 5, children: ["Welcome Assist Kiosks License | ", selectedCollectionPoint?.collectionPointName] }) }), _jsx(SAColumn, { span: 24, children: _jsxs(ListLicenseTable, { dataSource: listLicenses.currentLicense, rowKey: "id", bordered: false, pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        onChange: (page, pageSize) => {
                            setPagination({ current: page, pageSize });
                        },
                    }, children: [_jsx(Table.Column, { title: "Kiosk", render: (_, __, index) => (pagination.current - 1) * pagination.pageSize + index + 1 }), _jsx(Table.Column, { title: "License Key", dataIndex: "key" }), _jsx(Table.Column, { title: "Status", dataIndex: "status", align: "center", render: (status) => {
                                if (status === KioskLicenseStatus.ACTIVE) {
                                    return (_jsx(Button, { type: "link", icon: _jsx(CheckCircleFilled, { style: { color: UserStatusMapping[UserStatus.ACTIVE].color } }), children: _jsx(Typography.Text, { children: "Active" }) }));
                                }
                                return (_jsx(Button, { type: "link", danger: true, children: "Not Assigned" }));
                            } }), _jsx(Table.Column, { title: "Device Name", dataIndex: "deviceName" }), _jsx(Table.Column, { title: "Device Model", dataIndex: "deviceModel" }), _jsx(Table.Column, { title: "Last Login By", dataIndex: "loginBy" }), _jsx(Table.Column, { title: "Last Login At", dataIndex: "lastLoginAt", render: (lastLoginAt) => selectDateWithSetupTime(lastLoginAt, collectionOrg.timeFormat) }), _jsx(Table.Column, { title: "Delete License", dataIndex: "id", align: "center", render: (id) => (_jsx(Button, { type: "link", onClick: () => {
                                    openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `The license will be removed, and the registered kiosk device will be deactivated.`, async () => {
                                        await dispatch(deactivateLicense(id));
                                        await dispatch(getListLicenseByCollectionPoint());
                                    });
                                }, children: _jsx(DeleteKeyIcon, {}) })) }, "id")] }) })] }));
};
export default LicenseManagement;
const ListLicenseTable = styled(Table) `
  width: 100%;
`;
