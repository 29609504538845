import { jsx as _jsx } from "react/jsx-runtime";
import { uuidv4 } from "@antv/xflow-core";
import { Table } from "antd";
import styled from "styled-components";
const SATable = ({ dataSource, columns, ...rest }) => (_jsx(StyledTable, { columns: columns, dataSource: dataSource.map((item) => ({ ...item, id: uuidv4() })), rowKey: "id", ...rest }));
export default SATable;
const StyledTable = styled(Table) `
  .ant-table-thead .ant-table-cell {
    background-color: #e6f7fa;
    border-bottom: 0.025rem solid #adb3bc;
    border-top: 0.025rem solid #adb3bc;
  }

  .ant-table {
    border-bottom: 0.025rem solid #01b4d2;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0.025rem solid #ebedf0;
  }

  .ant-table-tbody > tr > td:last-child {
    background-color: #f7f8f8;
  }
`;
