import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import RemainInventory from "src/assets/svg/inventory/remain-inventory.svg";
import UsedInventory from "src/assets/svg/inventory/used-inventory.svg";
import { SARow } from "src/components/Form";
import SAColumn from "src/components/Form/Table/SAColumn";
import { selectInventorySummary, selectReorderInventorySummary, } from "src/pages/InventoryManagement/store/selector";
import { useAppSelector } from "src/store/hooks";
import SummaryBarChart from "./SummaryBarChart";
const InfoCard = ({ title, subtitle, highlight, bgColor, color }) => (_jsxs(StyledCard, { bgColor: bgColor, children: [_jsx(Typography.Title, { level: 5, style: {
                color,
            }, children: title }), _jsx(Typography.Text, { children: subtitle }), _jsx(Highlight, { color: color, children: highlight })] }));
const GraphInventoryView = () => {
    const reorderSummary = useAppSelector(selectReorderInventorySummary);
    const inventorySummary = useAppSelector(selectInventorySummary);
    return (_jsxs(React.Fragment, { children: [_jsx(SAColumn, { lg: 20, children: _jsxs(SectionContainer, { children: [_jsx(Typography.Title, { level: 5, children: "Inventory Summary" }), _jsx(InventoryCardContainer, { children: _jsx(SummaryBarChart, { dataSource: reorderSummary }) })] }) }), _jsx(SAColumn, { md: 24, lg: 4, children: _jsxs(SARow, { gutter: [0, 32], children: [_jsx(SAColumn, { children: _jsxs(SectionContainer, { children: [_jsxs(Space, { children: [_jsx(RemainInventory, {}), _jsx(Typography.Title, { level: 5, children: "Remaining" })] }), _jsx(InfoCard, { title: "Total", highlight: inventorySummary.remainingDevices, bgColor: "#5CD22B", color: "#ffffff" })] }) }), _jsx(SAColumn, { children: _jsxs(SectionContainer, { children: [_jsxs(Space, { children: [_jsx(UsedInventory, {}), _jsx(Typography.Title, { level: 5, children: "Used" })] }), _jsx(InfoCard, { title: "Total", highlight: inventorySummary.deviceUsed || 0, bgColor: "#1A8CFF", color: "#ffffff" })] }) })] }) })] }));
};
export default GraphInventoryView;
const SectionContainer = styled.div `
  width: 100%;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;
const InventoryCardContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  min-height: 29.5rem;
  height: 100%;
`;
const StyledCard = styled.div `
  width: 100%;
  height: 10rem;
  border-radius: 1rem;
  background-color: ${(props) => props.bgColor || "#e6f7ff"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  border: none;
`;
const Highlight = styled.div `
  color: ${(props) => props.color || "#1890ff"};
  font-size: 20px;
`;
