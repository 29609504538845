const DefaultPagingMeta = {
    page: 1,
    limit: 10,
    totalItem: 0,
    totalPage: 0,
    hasPreviousPage: false,
    hasNextPage: false,
};
var Order;
(function (Order) {
    Order["ASC"] = "ASC";
    Order["DESC"] = "DESC";
})(Order || (Order = {}));
const DefaultRequestPagination = {
    page: 1,
    limit: 10,
    orderField: "createdAt",
    order: Order.DESC,
};
export { DefaultPagingMeta, Order, DefaultRequestPagination, };
