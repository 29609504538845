import { combineReducers } from "@reduxjs/toolkit";
import collectAssistReducer from "src/pages/PlatformManagementMenuPage/CollectAssistPage/store/slice";
import clockOnClockOffReducer from "src/pages/PlatformManagementMenuPage/CollectAssistPage/ClockOnClockOffPage/store/slice";
import collectAssistCalendarReducer from "src/pages/PlatformManagementMenuPage/CollectAssistPage/CollectorCalendar/store/slice";
import welcomeAssistReducer from "./WelcomeAssist/slice";
const platformManagementReducer = combineReducers({
    collectAssist: collectAssistReducer,
    collectAssistCalendar: collectAssistCalendarReducer,
    clockOnClockOffPage: clockOnClockOffReducer,
    welcomeAssist: welcomeAssistReducer,
});
export default platformManagementReducer;
