import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Drawer, Typography, Upload } from "antd";
import React from "react";
import styled from "styled-components";
import EmptyImage from "src/assets/svg/device-management/upload-file.svg";
import { deleteFile, uploadFile } from "src/infra/file";
const { Dragger } = Upload;
const FileUploadDrawer = ({ isOpen, setOpen, uploadUrl, uploadPrefix, setUploadUrl, setFileName, onSaveFile, }) => {
    const handleChange = async (file) => {
        const uploadedUrl = await uploadFile(file, uploadPrefix);
        if (file?.name && setFileName) {
            setFileName(file.name);
        }
        setUploadUrl(uploadedUrl);
    };
    const handleRemove = async () => {
        if (!uploadUrl)
            return;
        await deleteFile(uploadUrl);
        setUploadUrl("");
        if (setFileName) {
            setFileName(null);
        }
    };
    const onClose = () => {
        setOpen(false);
        handleRemove();
    };
    return (_jsx(CustomDrawer, { title: "Upload File", placement: "right", size: "large", onClose: onClose, open: isOpen, children: _jsxs(DrawerBody, { children: [_jsxs(Typography.Text, { children: ["Supported file types: ", "JPG, JPEG, PNG"] }), _jsx(UploadBox, { maxCount: 1, name: "file", multiple: false, showUploadList: false, beforeUpload: (file) => {
                        handleChange(file);
                        return false;
                    }, openFileDialogOnClick: !uploadUrl, accept: "image/png, image/jpeg, image/jpg", children: uploadUrl ? (_jsxs(ImageContainer, { children: [_jsx(StyledImage, { src: uploadUrl ?? "", alt: "uploaded" }), _jsx(IconContainer, { children: _jsx(DeleteIcon, { onClick: handleRemove }) })] })) : (_jsxs(React.Fragment, { children: [_jsx(EmptyImage, {}), _jsxs(GuideTextBox, { children: [_jsx(GuideText, { children: "Drag and Drop here" }), _jsx(Typography.Text, { children: "Or" }), _jsx(GuideText, { children: "Click to upload" })] })] })) }), _jsxs(ButtonContainer, { children: [_jsx(Button, { danger: true, onClick: onClose, children: "Cancel" }), _jsx(Button, { type: "primary", onClick: () => {
                                onSaveFile();
                            }, children: "Save" })] })] }) }));
};
export default FileUploadDrawer;
const CustomDrawer = styled(Drawer) `
  .ant-drawer-header {
    background-color: #cbf5fc;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }
  .ant-drawer-title {
    text-align: center;
  }
  .ant-drawer-body {
    padding: 4rem;
  }

  span:nth-child(2) {
    width: 100%;
    height: 50%;
  }

  .ant-upload.ant-upload-drag .ant-upload-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;
const DrawerBody = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
`;
const UploadBox = styled(Dragger) `
  .ant-upload.ant-upload-drag {
    width: 100%;
    height: 50vh;
  }
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
`;
const GuideTextBox = styled.div `
  p:last-child {
    color: #1a8cff;
  }
`;
const GuideText = styled.p `
  font-size: 1rem;
  font-weight: 700;
`;
const ImageContainer = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
`;
const StyledImage = styled.img `
  width: 100%;
  max-height: 100%;
  border-radius: 0.25rem;
  object-fit: cover;
  object-position: center;
  margin: 0 auto;
`;
const IconContainer = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;

  cursor: pointer;
  z-index: 1;
  svg {
    display: none;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:hover svg {
    display: inline-block;
    z-index: 99 !important;
    color: #ffffff;
  }
`;
const DeleteIcon = styled(DeleteOutlined) `
  font-size: 24px;
  border-radius: 50%;
  transition:
    color 0.3s ease,
    z-index 0.3s ease;
`;
