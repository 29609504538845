import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import CollectorPerformanceIcon from "src/assets/svg/dashboard/tables/collector-performance-icon.svg";
import PatientCommunicationIcon from "src/assets/svg/dashboard/tables/patient-communication.svg";
import PatientIcon from "src/assets/svg/dashboard/tables/patient-icon.svg";
import PatientTimeIcon from "src/assets/svg/dashboard/tables/patient-time-icon.svg";
import ProcessedTestIcon from "src/assets/svg/dashboard/tables/processed-test-icon.svg";
import TestCategoryIcon from "src/assets/svg/dashboard/tables/test-category-icon.svg";
import UrineResultIcon from "src/assets/svg/dashboard/tables/urine-result-icon.svg";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import OnsiteCollectionTable from "./components/OnsiteCollectionTable";
import { selectCollectionTables, thunkCollectionTableMap, } from "./store/selector";
import { setResetDetails } from "./store/slice";
const OnsiteCollectionMetrics = () => {
    const dispatch = useAppDispatch();
    const collectionTables = useAppSelector(selectCollectionTables);
    const { processedTest, patientTime, testTime, testCategory, urineDrugScreen, urineDrugTest, oralDrugScreen, oralDrugTest, hairDrugTest, breathAlcoholScreen, negativeDrugClass, nonNegativeDrugClass, invalidDrugClass, collectorPerformance, collectorRanking, patientGender, attendance, donorCommunication, } = collectionTables;
    const dispatchDataSourceDetails = (isRetrieved, tableKey) => {
        if (isRetrieved) {
            dispatch(thunkCollectionTableMap[tableKey]());
        }
        else {
            dispatch(setResetDetails(tableKey));
        }
    };
    return (_jsxs(CollectionMetricContainer, { children: [_jsx(OnsiteCollectionTable, { tableKey: "processedTest", label: "Tests Processed", logo: _jsx(ProcessedTestIcon, {}), mainColumnLabel: "Tests", showTotal: true, dataSource: processedTest, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "processedTest");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "patientTime", label: "Patient Time", logo: _jsx(PatientTimeIcon, {}), mainColumnLabel: "Patient Time", showCollector: true, dataSource: patientTime, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "patientTime");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "testTime", label: "Test Time", logo: _jsx(PatientTimeIcon, {}), mainColumnLabel: "Test Time", dataSource: testTime, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "testTime");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "testCategory", label: "Test Categories", logo: _jsx(TestCategoryIcon, {}), mainColumnLabel: "Results", dataSource: testCategory, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "testCategory");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "urineDrugScreen", label: "Urine Drug Screen Results", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: "Results", showTotal: true, dataSource: urineDrugScreen, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "urineDrugScreen");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "urineDrugTest", label: "Urine Drug Test Results (Laboratory Test)", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: "Results", showTotal: true, dataSource: urineDrugTest, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "urineDrugTest");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "oralDrugScreen", label: "Oral Fluid Drug Screen Results", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: "Results", showTotal: true, dataSource: oralDrugScreen, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "oralDrugScreen");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "oralDrugTest", label: "Oral Fluid Drug Test Results (Laboratory Test)", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: "Results", showTotal: true, dataSource: oralDrugTest, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "oralDrugTest");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "hairDrugTest", label: "Hair Drug Test (Laboratory Test)", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: "Results", showTotal: true, dataSource: hairDrugTest, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "hairDrugTest");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "breathAlcoholScreen", label: "Breath Alcohol Screen Results", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: "Results", showTotal: true, dataSource: breathAlcoholScreen, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "breathAlcoholScreen");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "negativeDrugClass", label: "Drug Classes - Negative Results", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: _jsxs(React.Fragment, { children: [_jsx(Typography.Text, { strong: true, children: "Result" }), _jsx(ResultText, { children: "Negative" })] }), dataSource: negativeDrugClass, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "negativeDrugClass");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "nonNegativeDrugClass", label: "Drug Classes - Non Negative Results", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: _jsxs(React.Fragment, { children: [_jsx(Typography.Text, { strong: true, children: "Result" }), _jsx(ResultText, { children: "Non Negative" })] }), dataSource: nonNegativeDrugClass, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "nonNegativeDrugClass");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "invalidDrugClass", label: "Drug Classes - Invalid Results", logo: _jsx(UrineResultIcon, {}), mainColumnLabel: _jsxs(React.Fragment, { children: [_jsx(Typography.Text, { strong: true, children: "Result" }), _jsx(ResultText, { children: "Invalid" })] }), dataSource: invalidDrugClass, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "invalidDrugClass");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "collectorPerformance", label: "Collector Performance", logo: _jsx(CollectorPerformanceIcon, {}), mainColumnLabel: "Processed Tests", dataSource: collectorPerformance, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "collectorPerformance");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "collectorRanking", label: "Collector Ranking", logo: _jsx(CollectorPerformanceIcon, {}), mainColumnLabel: "Ranking", dataSource: collectorRanking, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "collectorRanking");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "patientGender", label: "Patient Gender", logo: _jsx(PatientIcon, {}), mainColumnLabel: "Appointments", dataSource: patientGender, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "patientGender");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "attendance", label: "Attendance", logo: _jsx(PatientIcon, {}), mainColumnLabel: "Appointments", dataSource: attendance, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "attendance");
                } }), _jsx(OnsiteCollectionTable, { tableKey: "donorCommunication", label: "Donor Communication", logo: _jsx(PatientCommunicationIcon, {}), mainColumnLabel: "Donor Requests", dataSource: donorCommunication, getDataSource: (isRetrieved) => {
                    dispatchDataSourceDetails(isRetrieved, "donorCommunication");
                } })] }));
};
export default OnsiteCollectionMetrics;
const CollectionMetricContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ResultText = styled(Typography.Text) `
  font-size: 1rem;
  color: #2e94f2;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
`;
