export const emptyPatient = {
    firstName: "",
    lastName: "",
    dob: null,
    sex: null,
    email: "",
    mobile: "",
    landline: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postCode: "",
};
