import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { Button, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import PinIcon from "src/assets/svg/dashboard/ic-pin.svg";
import PrintIcon from "src/assets/svg/dashboard/ic-print.svg";
import SASelect from "src/components/Form/SASelect";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectCollectionTables, selectListCollectionPoints, thunkCollectionTableMap, } from "../store/selector";
import { setCollectionTable } from "../store/slice";
const TableHeader = ({ label, logo, isOpen, setOpen, tableKey, }) => {
    const dispatch = useAppDispatch();
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    const collectionTables = useAppSelector(selectCollectionTables);
    const updateFilters = (key, options) => ({
        ...collectionTables[key],
        filters: {
            ...collectionTables[key]?.filters,
            ...options,
        },
    });
    return (_jsx(HeaderContainer, { children: _jsxs(SARow, { gutter: [0, 0], children: [_jsx(SAColumn, { span: 14, direction: "row", align: "center", justify: "flex-start", onClick: () => setOpen(!isOpen), children: _jsxs(TitleContainer, { children: [logo ?? _jsx(_Fragment, {}), _jsx(StyledTitle, { level: 5, children: label ?? "" }), _jsx(StyledButton, { icon: isOpen ? _jsx(CaretUpFilled, {}) : _jsx(CaretDownFilled, {}), type: "text" })] }) }), _jsx(SAColumn, { span: 6, children: _jsx(SASelect, { options: collectionPoints, fieldNames: { label: "collectionPointName", value: "id" }, value: collectionTables[tableKey]?.filters.collectionPointId, onChange: (value) => {
                            dispatch(setCollectionTable({
                                [tableKey]: updateFilters(tableKey, {
                                    collectionPointId: value,
                                    queryType: undefined,
                                }),
                            }));
                            dispatch(thunkCollectionTableMap[tableKey]());
                        }, children: "Location" }) }), _jsxs(SAColumn, { span: 4, direction: "row", justify: "flex-end", children: [_jsx(Button, { icon: _jsx(PrintIcon, {}), type: "text" }), _jsx(Button, { icon: _jsx(PinIcon, {}), type: "text" })] })] }) }));
};
export default TableHeader;
const HeaderContainer = styled.div `
  background-color: #2e94f2;
  color: #ffffff;
  padding: 0.5rem;
`;
const TitleContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;
const StyledTitle = styled(Typography.Title) `
  && {
    color: #ffffff;
    margin: 0;
  }
`;
const StyledButton = styled(Button) `
  && {
    color: #ffffff;
  }
`;
