import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { mainAppColours, SAColourScheme } from "src/styles";
import { generateCalendarMonth } from "src/utils";
import { CalendarView } from "../../typing";
const localizer = momentLocalizer(moment);
moment.updateLocale("en", {
    week: {
        dow: 1,
    },
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MonthCalendarView = (props) => {
    const { events, date } = props;
    const [calendar, setCalendar] = useState(undefined);
    useEffect(() => {
        const currentDate = date ? moment(date) : moment();
        const monthCalendar = generateCalendarMonth(currentDate.year(), currentDate.month());
        const calendarData = [];
        for (const week of monthCalendar) {
            const currentWeek = [];
            for (const day of week) {
                const eventData = events?.filter((event) => moment(day).isSame(event.start, "day"));
                currentWeek.push({
                    date: day,
                    event: eventData ?? [],
                });
            }
            calendarData.push(currentWeek);
        }
        setCalendar(calendarData);
    }, [date]);
    const getOnsiteEvents = (curEvents) => {
        const renderEvents = curEvents.slice(0, 3).map((event, index) => (_jsxs(EventContainer, { children: [_jsx(OnsiteTitle, { children: "On-Site" }), event.title] }, index)));
        const additionalEventsCount = curEvents.length - 3;
        if (additionalEventsCount > 0) {
            renderEvents.push(_jsxs(EventContainer, { children: ["+ ", additionalEventsCount, " more"] }, "more-events"));
        }
        return renderEvents;
    };
    return !calendar ? null : (_jsxs("div", { className: "rbc-month-view", children: [_jsx("div", { className: "rbc-row rbc-month-header", children: moment.weekdays(true).map((day, index) => (_jsx("div", { className: "rbc-header", children: day }, index))) }), calendar.map((week, index) => (_jsx("div", { className: "rbc-month-row", children: _jsx(DayCellContainer, { children: week.map((day) => (_jsxs(DayCellWrapper, { backgroundEvent: false, onClick: (e) => {
                            if (props.onSelectEvent) {
                                props.onSelectEvent({ start: day.date }, e);
                            }
                        }, defaultValue: day.date.toISOString(), children: [_jsx(OnsiteSummaryContainer, { backgroundEvent: false, children: getOnsiteEvents(day.event) }), _jsxs(InClinicSummaryContainer, { children: [_jsx(DateCell, { backgroundEvent: false, isToday: moment().isSame(moment(day.date), "day"), children: day.date.getDate().toString() }), _jsx(TotalAppointments, { backgroundEvent: false, children: "0" }), _jsx(InClinicSubTitle, { backgroundEvent: false, children: "In-Clinic Appointments" })] })] }, day.date.toISOString()))) }) }, index)))] }));
};
const MonthCalendarCalendar = ({ events, currentDate, header, backgroundEvents, selectEvent, ...props }) => (_jsxs(CustomCalendarWrapper, { children: [_jsx(MainHeader, { children: header }), _jsx(CustomCalendar, { view: CalendarView.Month, onView: () => void 0, onNavigate: () => void 0, events: events, localizer: localizer, backgroundEvents: backgroundEvents, date: currentDate, selectable: true, showAllEvents: true, onSelectEvent: (event) => {
                selectEvent(moment(event.start));
            }, timeslots: 12, step: 5, formats: {
                weekdayFormat: "dddd",
                eventTimeRangeFormat: () => "",
            }, components: {
                toolbar: () => _jsx(Toolbar, {}),
            }, views: {
                month: MonthCalendarView,
                week: true,
                day: true,
            }, ...props })] }));
export default MonthCalendarCalendar;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
MonthCalendarView.navigate = (date, action) => {
    switch (action) {
        case "PREV":
            return moment(date).subtract(1, "month").toDate();
        case "NEXT":
            return moment(date).add(1, "month").toDate();
        default:
            return date;
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
MonthCalendarView.title = (date, { localizer }) => localizer.format(date, "MMMM YYYY");
const CustomCalendarWrapper = styled.div `
  width: 100%;
  height: 100%;

  .rbc-month-header {
    width: 100%;
    min-width: fit-content;
  }
  .rbc-header {
    padding: 0.3rem;
    border: 0px;
    font-size: 0.875rem;
    font-weight: normal;
    border-bottom: 1px solid;
    min-width: 10rem;
    width: 100%;
  }

  .rbc-month-view {
    border: none;
    border-bottom: 1px solid;
    width: 100%;
    min-width: 80rem;
  }

  .rbc-month-row + .rbc-month-row {
    border-top: #adb3bc;
  }
  .rbc-month-row {
    min-height: 10rem;
    width: 100%;
    min-width: 80rem;
  }
  overflow: auto;
`;
const CustomCalendar = styled(Calendar) `
  height: 70vh;
  border-bottom: 0.063rem solid #bfbdcb;
  min-height: 50rem;
  width: 100%;
  min-width: fit-content;
`;
const Toolbar = styled.div `
  width: 100%;
`;
const MainHeader = styled.div `
  background-color: ${mainAppColours.BLUE};
  font-size: 1rem;
  color: ${SAColourScheme.WHITE};
  border-radius: 0.2rem;
  text-align: center;
  padding: 0.2rem 0;
`;
const DayCellContainer = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  background: #fff;
`;
const DayCellWrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
  padding: 1rem;
  background: ${(props) => (props.backgroundEvent ? "#F7F8F8" : "#e4f6fb")};
  cursor: pointer;
  height: 100%;
  min-width: 10rem;
`;
const OnsiteSummaryContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 0.2rem;
  > div {
    ${(props) => (props.backgroundEvent ? "display: none" : "display: flex;")}
  }
`;
const EventContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 0.3rem;
  padding-inline: 0.3rem;
  font-size: 0.875rem;
`;
const OnsiteTitle = styled.span `
  color: #35b700;
  font-weight: 700;
`;
const InClinicSummaryContainer = styled.div `
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  justify-content: space-between;
`;
const TotalAppointments = styled.span `
  font-size: 2rem;
  padding-top: 1rem;
  ${(props) => (props.backgroundEvent ? "display: none" : "display: block;")}
`;
const InClinicSubTitle = styled.span `
  color: #7f8e9d;
  font-size: 0.625rem;
  ${(props) => (props.backgroundEvent ? "display: none" : "display: block;")}
`;
const DateCell = styled.p `
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.backgroundEvent ? "none" : "#9ac7e1")};
  background-color: ${(props) => (props.isToday ? "#1a8cff" : "#9ac7e1")};
  padding-inline: 0.3rem;
  text-align: center;
  color: ${(props) => (props.backgroundEvent ? "#7F8E9D" : "#fff")};
  font-size: 1rem;
  width: 3rem;
  margin: -1rem;
`;
