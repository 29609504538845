import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Layout, Menu, Typography } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ICCollapseArrow from "src/assets/svg/side-menu/collapse-arrow.svg";
import ICAccounting from "src/assets/svg/side-menu/ic-accounting.svg";
import ICMenuCollectionOrganisation from "src/assets/svg/side-menu/ic-cm-collection-organisation.svg";
import ICMenuUsers from "src/assets/svg/side-menu/ic-cm-users.svg";
import ICCollectionReports from "src/assets/svg/side-menu/ic-collection-reports.svg";
import ICCollectorsWhite from "src/assets/svg/side-menu/ic-collectors-white.svg";
import ICDeviceManagement from "src/assets/svg/side-menu/ic-device-management.svg";
import ICInventoryManagement from "src/assets/svg/side-menu/ic-inventory-management.svg";
import ICMenuAppointment from "src/assets/svg/side-menu/ic-menu-appointment.svg";
import ICMenuAuthorisingAuthority from "src/assets/svg/side-menu/ic-menu-authority.svg";
import ICMenuCalendar from "src/assets/svg/side-menu/ic-menu-calendar.svg";
import ICMenuClientManagement from "src/assets/svg/side-menu/ic-menu-client-mgmt.svg";
import ICMenuDashboard from "src/assets/svg/side-menu/ic-menu-dashboard.svg";
import ICMenuOutlinedSelected from "src/assets/svg/side-menu/ic-menu-outlined-selected.svg";
import ICMenuOutlined from "src/assets/svg/side-menu/ic-menu-outlined.svg";
import ICMenuPatientRecord from "src/assets/svg/side-menu/ic-menu-patient-record.svg";
import ICMenuPlatformManagement from "src/assets/svg/side-menu/ic-menu-platform-management.svg";
import ICMenuSupport from "src/assets/svg/side-menu/ic-menu-support.svg";
import ICOnlineBookingManagement from "src/assets/svg/side-menu/ic-online-booking-management.svg";
import ICSettings from "src/assets/svg/side-menu/ic-settings.svg";
import ICMenuJobs from "src/assets/svg/side-menu/ic-onsite-job.svg";
import ICMenuAccountManager from "src/assets/svg/side-menu/ic-menu-account-manager.svg";
import { useHandleNavigation } from "src/hooks/useBeforeNavigate";
import { Header } from "./Header";
import { Footer } from "./Footer";
const { Sider, Content } = Layout;
const CustomIcon = ({ icon }) => React.cloneElement(icon, { height: 24, width: 24 });
const menuItems = [
    { key: "/dashboard", icon: _jsx(ICMenuDashboard, {}), label: "Dashboard" },
    { key: "/calendar", icon: _jsx(ICMenuCalendar, {}), label: "Calendar" },
    {
        key: "/in-clinic-appointments/create-appointment",
        icon: _jsx(ICMenuAppointment, {}),
        label: "In-Clinic Appointments",
    },
    { key: "/job-management/onsite-jobs", icon: _jsx(ICMenuJobs, {}), label: "On-Site Jobs Management" },
    {
        key: "/online-booking-management",
        icon: _jsx(ICOnlineBookingManagement, {}),
        label: "Online Booking Management",
    },
    { key: "/client-management", icon: _jsx(ICMenuClientManagement, {}), label: "Client Management" },
    {
        key: "/testing-devices",
        icon: _jsx(ICDeviceManagement, {}),
        label: "Device Management",
    },
    {
        key: "/inventory",
        icon: _jsx(ICInventoryManagement, {}),
        label: "Inventory Management",
    },
    { key: "/patient-records", icon: _jsx(ICMenuPatientRecord, {}), label: "Donor Records" },
    { key: "/collection-reports", icon: _jsx(ICCollectionReports, {}), label: "Collection Reports" },
];
const additionalMenuItems = [
    {
        key: "/collection-organisation",
        icon: _jsx(ICMenuCollectionOrganisation, {}),
        label: "Collection Organisation",
    },
    {
        key: "/authorising-authority",
        icon: _jsx(ICMenuAuthorisingAuthority, {}),
        label: "Authorising Authority",
    },
    { key: "/users", icon: _jsx(ICMenuUsers, {}), label: "Users" },
    { key: "/accounting", icon: _jsx(ICAccounting, {}), label: "Accounting" },
    { key: "/settings", icon: _jsx(ICSettings, {}), label: "Settings" },
    { key: "/support", icon: _jsx(ICMenuSupport, {}), label: "Support" },
    { key: "/account-manager", icon: _jsx(ICMenuAccountManager, {}), label: "Account Manager" },
];
export const SiderMenu = ({ children }) => {
    const handleNavigation = useHandleNavigation();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const isSelected = (key) => {
        const getBasePath = (path) => {
            const segments = path.split("/");
            return segments[1] || "";
        };
        const currentBasePath = getBasePath(location.pathname);
        const keyBasePath = getBasePath(key);
        return currentBasePath === keyBasePath;
    };
    const toggleOnCLick = () => {
        setCollapsed(!collapsed);
    };
    React.useEffect(() => {
        if (window.innerWidth <= 1250) {
            setCollapsed(true);
        }
        else {
            setCollapsed(false);
        }
        const handleResize = () => {
            if (window.innerWidth <= 1250) {
                setCollapsed(true);
            }
            else {
                setCollapsed(false);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const handleSelectItem = (e) => {
        handleNavigation(e.key);
    };
    return (_jsxs(Layout, { children: [_jsxs(CustomSlider, { width: 260, collapsed: collapsed, collapsedWidth: 56, children: [_jsx("div", { onClick: () => {
                            handleNavigation("/");
                        }, children: _jsxs(LogoBox, { children: [_jsx(ICCollectorsWhite, {}), _jsx(LogoTitle, { "$isCollapsed": collapsed, children: "Collection Manager\u2122" })] }) }), _jsx("div", { onClick: () => {
                            handleNavigation("/platform-management");
                        }, children: _jsxs(PlatformBox, { "$isCollapsed": collapsed, children: [_jsx(ICMenuPlatformManagement, {}), _jsxs(PlatformTitle, { children: ["Platform Management", location.pathname.includes("/platform-management") ? (_jsx(ICMenuOutlined, { color: "#00004c" })) : (_jsx(ICMenuOutlinedSelected, { color: "rgba(255, 255, 255, 0.5)" }))] })] }) }), _jsxs(MenuContainer, { children: [_jsx(MainMenu, { onSelect: handleSelectItem, selectedKeys: [location.pathname], mode: "inline", "$isCollapsed": collapsed, children: menuItems.map(({ key, icon, label }) => (_jsx(CustomMenuItem, { icon: _jsx(CustomIcon, { icon: icon }), selected: isSelected(key), children: label }, key))) }), _jsx(AdditionalMenu, { onSelect: (e) => handleSelectItem(e), selectedKeys: [location.pathname], mode: "inline", "$isCollapsed": collapsed, children: additionalMenuItems.map(({ key, icon, label }) => (_jsx(CustomMenuItem, { icon: _jsx(CustomIcon, { icon: icon }), selected: isSelected(key), children: label }, key))) })] }), _jsx(CollapsedButton, { onClick: toggleOnCLick, "$isCollapsed": collapsed, children: _jsx(ICCollapseArrow, {}) })] }), _jsx(Layout, { children: _jsxs(MainLayoutContainer, { "$isCollapsed": collapsed, children: [_jsx(FixedHeader, { "$isCollapsed": collapsed, children: _jsx(Header, {}) }), _jsxs(ContentContainer, { children: [_jsx(Content, { children: children }), _jsx(Footer, {})] })] }) })] }));
};
const CustomSlider = styled(Sider) `
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
`;
const LogoBox = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7.5rem;
  background-color: #00004c;
  gap: 1rem;
  cursor: pointer;
`;
const LogoTitle = styled(Typography) `
  display: ${(props) => (props.$isCollapsed ? "none" : "block")};
  color: white;
  font-size: 1rem;
`;
const PlatformBox = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.$isCollapsed ? "center" : "flex-start")};
  align-items: center;
  height: 2.5rem;
  background-color: #0047b3;
  padding: 1rem;
  padding-left: 1rem;
  width: 100%;
  cursor: pointer;
  padding: ${(props) => props.$isCollapsed && 0};

  h5 {
    display: ${(props) => (props.$isCollapsed ? "none" : "inline")};
  }
`;
const PlatformTitle = styled.h5 `
  color: white;
  font-size: 0.875rem;
  padding: 0.5rem;
  margin: 0;
  svg {
    margin-left: 0.5rem;
    margin-bottom: -0.1rem;
  }
`;
const MenuContainer = styled.div `
  height: calc(100% - 12rem);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  scrollbar-width: 0;
  overflow-x: hidden;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding-inline: 0.5rem;
`;
const MenuItem = styled(Menu.Item) `
  column-gap: 0.5rem;
  padding-left: 0 !important;
  color: #1a576f;
  :hover {
    color: #1a576f !important;
    background-color: rgba(244, 219, 36, 0.2);
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const MainMenu = styled(Menu) `
  background-color: #ffffff;
  border: none;
  span {
    display: ${({ $isCollapsed }) => $isCollapsed && "none"};
  }
  .ant-menu-item-selected,
  .ant-menu-item-selected::after {
    background-color: #ffffff !important;
    border: 0 !important;
    color: #1a8cff !important;
  }
  .ant-menu-item {
    padding: ${({ $isCollapsed }) => ($isCollapsed ? "0 !important" : "0.5rem !important")};
  }
`;
const AdditionalMenu = styled(Menu) `
  background-color: #e6f7fa;
  border-radius: 0.25rem;
  border: none;

  span {
    display: ${({ $isCollapsed }) => $isCollapsed && "none"};
  }

  .ant-menu-item {
    padding: ${({ $isCollapsed }) => ($isCollapsed ? "0 !important" : "0.5rem !important")};
  }
`;
const CustomMenuItem = styled(MenuItem) `
  ${({ selected }) => selected &&
    `
      border: none !important;
      color: #1a8cff !important;
  `}
`;
const CollapsedButton = styled(Button) `
  display: flex;
  justify-content: ${({ $isCollapsed }) => ($isCollapsed ? "center" : "flex-end")};
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 2rem;

  svg {
    transition: transform 0.3s ease;
    transform: ${({ $isCollapsed }) => ($isCollapsed ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;
const MainLayoutContainer = styled(Content) `
  transition: margin-left 0.2s;
  margin-left: ${({ $isCollapsed }) => ($isCollapsed ? "56px" : "260px")};
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-height: 100%;
`;
const FixedHeader = styled.div `
  position: fixed;
  width: calc(100% - ${({ $isCollapsed }) => ($isCollapsed ? "56px" : "260px")});
  z-index: 22;
`;
const ContentContainer = styled.div `
  margin-top: 5rem;
  overflow-y: scroll;
  scrollbar-width: 0;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
`;
