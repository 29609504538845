var YearFilterOptions;
(function (YearFilterOptions) {
    YearFilterOptions[YearFilterOptions["CalendarYear"] = 1] = "CalendarYear";
    YearFilterOptions[YearFilterOptions["FinancialYear"] = 2] = "FinancialYear";
})(YearFilterOptions || (YearFilterOptions = {}));
var TestingDeviceTypes;
(function (TestingDeviceTypes) {
    TestingDeviceTypes[TestingDeviceTypes["Device"] = 1] = "Device";
    TestingDeviceTypes[TestingDeviceTypes["Cup"] = 2] = "Cup";
    TestingDeviceTypes[TestingDeviceTypes["Vial"] = 3] = "Vial";
})(TestingDeviceTypes || (TestingDeviceTypes = {}));
export { YearFilterOptions, TestingDeviceTypes };
