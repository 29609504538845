var TestSummaryIndex;
(function (TestSummaryIndex) {
    TestSummaryIndex["UrineScreen"] = "urineScreen";
    TestSummaryIndex["OralScreen"] = "oralScreen";
    TestSummaryIndex["HairTest"] = "hairTest";
    TestSummaryIndex["UrineCup"] = "urineCup";
    TestSummaryIndex["UrineVial"] = "urineVial";
    TestSummaryIndex["OralDrug"] = "oralDrug";
})(TestSummaryIndex || (TestSummaryIndex = {}));
var InventorySummaryView;
(function (InventorySummaryView) {
    InventorySummaryView["List"] = "list";
    InventorySummaryView["Graph"] = "graph";
})(InventorySummaryView || (InventorySummaryView = {}));
var InventoryItemStatus;
(function (InventoryItemStatus) {
    InventoryItemStatus[InventoryItemStatus["Active"] = 1] = "Active";
    InventoryItemStatus[InventoryItemStatus["Inactive"] = 2] = "Inactive";
})(InventoryItemStatus || (InventoryItemStatus = {}));
export { TestSummaryIndex, InventorySummaryView, InventoryItemStatus, };
