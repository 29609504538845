import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Form, Input, message, Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { CheckCircleOutlined } from "@ant-design/icons";
import { ResetPasswordIcon } from "src/assets/svg/welcome-assist";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppDispatch } from "src/store/hooks";
import { getFullName } from "src/utils";
import { getListLicenseByCollectionPoint, getManagerInformation, resetPasswordLicenseManager, sendMailUpdatedPassword, } from "../../store/action";
import { WelcomeAssistError } from "../../store/types";
const ResetPasswordManagerModal = ({ isOpen, setIsOpen, }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("id");
    const [newPassword, setNewPassword] = useState(null);
    React.useEffect(() => {
        const loadData = async () => {
            if (!userId) {
                message.error("Invalid user");
                return;
            }
            const payload = await dispatch(getManagerInformation(userId)).unwrap();
            const { error, data } = payload;
            if (error) {
                message.error("Failed to fetch manager information");
                setIsOpen(false);
                return;
            }
            form.setFieldsValue({
                email: data.email,
                name: getFullName(data),
                password: data.password,
            });
        };
        if (isOpen)
            loadData();
    }, [isOpen]);
    const resetModal = () => {
        form.resetFields();
        setIsOpen(false);
        setSearchParams();
        setNewPassword(null);
    };
    return (_jsx(Modal, { open: isOpen, footer: null, onCancel: resetModal, centered: true, width: "50%", title: _jsxs(Space, { children: [_jsx(ResetPasswordIcon, {}), _jsx(Typography.Title, { level: 5, children: "Reset Password" })] }), children: _jsxs(FormContainer, { children: [newPassword ? (_jsxs(Space, { children: [_jsx(CheckCircleOutlined, {}), _jsx(Typography.Title, { level: 5, children: "The password has been reset successfully" })] })) : (_jsx(_Fragment, {})), _jsxs(Form, { form: form, labelCol: { span: 6 }, wrapperCol: { span: 24 }, onFinish: async (values) => {
                        if (!userId) {
                            message.error("Invalid user");
                            return;
                        }
                        if (!newPassword) {
                            const payload = await dispatch(resetPasswordLicenseManager(userId)).unwrap();
                            const { error, data } = payload.data;
                            if (error) {
                                message.error(error === WelcomeAssistError.EA001
                                    ? payload.message
                                    : "There is error when trying to reset your password");
                                return;
                            }
                            setNewPassword(data);
                            form.setFieldsValue({
                                ...values,
                                password: data,
                            });
                        }
                        else {
                            const payload = await dispatch(sendMailUpdatedPassword(userId)).unwrap();
                            const { error } = payload.data;
                            if (error) {
                                message.error(error === WelcomeAssistError.EA001
                                    ? payload.message
                                    : "There is error when trying to send the email");
                                return;
                            }
                            message.success("Sent mail to the manager successfully");
                            dispatch(getListLicenseByCollectionPoint());
                            resetModal();
                        }
                    }, children: [_jsxs(SARow, { children: [_jsx(SAColumn, { children: _jsx(SAFormItem, { label: "User Name", name: "name", children: _jsx(Input, { readOnly: true }) }) }), _jsx(SAColumn, { children: _jsx(SAFormItem, { label: "Email Address", name: "email", children: _jsx(Input, { readOnly: true }) }) }), _jsx(SAColumn, { children: _jsx(SAFormItem, { label: `${newPassword ? "New" : "Current"} Password`, name: "password", children: _jsx(Input, { readOnly: true }) }) }), _jsx(SAColumn, { children: _jsx(SAFormItem, { label: "Email the sign-in info to", name: "email", labelCol: { span: 24 }, wrapperCol: { span: 24 }, hidden: !newPassword, children: _jsx(Input, { readOnly: true }) }) })] }), _jsx(FooterButton, { children: _jsx(Button, { type: "primary", htmlType: "submit", children: newPassword ? "Send" : "Reset Password" }) })] })] }) }));
};
export default ResetPasswordManagerModal;
const FormContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .ant-form-item-label {
    text-align: left;
  }
`;
const FooterButton = styled(Space) `
  display: flex;
  width: fit-content;
  margin: auto;
`;
