import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import MoreIcon from "src/assets/svg/device-management/more.svg";
import SAStatusButton from "src/components/Form/SAStatusButton";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import SASwitch from "src/components/Form/Table/SASwitch";
import { selectCollectionPoints } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import BreathCupIcon from "src/assets/svg/device-management/breath.svg";
import AttachmentBox from "../../components/AttachmentBox";
import { DeviceCardContainer } from "../../components/DeviceCardContainer";
import InformationBox from "../../components/InformationBox";
import { updateInUseTestingDevice } from "../../store/action";
import { getTestingDevices } from "../store/action";
import DeviceActionsDropdown from "./DeviceActionsDropdown";
export const DeviceCard = (props) => {
    const { id, deviceName, isInUse, fontImageUrl, serialNumber, certificateNumber, collectionPointIds, calibrationDueAt, calibrationCertificateUrl, calibrationReportUrl, isArchived, } = props.device;
    const collectionPoints = useAppSelector(selectCollectionPoints);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const testTypeIdParam = searchParams.get("testTypeId");
    const loadUpdatedData = async () => {
        if (searchParams.get("isArchiveShown") === "true") {
            await dispatch(getTestingDevices({
                testTypeId: String(testTypeIdParam),
                isArchived: true,
            }));
        }
        await dispatch(getTestingDevices({
            testTypeId: String(testTypeIdParam),
            isArchived: false,
        }));
    };
    return (_jsxs(DeviceCardContainer, { image: fontImageUrl, Fallback: BreathCupIcon, children: [_jsx(SARow, { children: _jsxs(HeadingContainer, { children: [_jsx(HeadingTitle, { onClick: () => navigate(`/testing-devices/breath/breathalyser/update?id=${id}`), children: deviceName }), _jsx(SASwitch, { uncheckedLabel: "In Use", checked: isInUse, onChange: async () => {
                                await dispatch(updateInUseTestingDevice({ isInUse: !isInUse, id: id }));
                                await loadUpdatedData();
                            } })] }) }), _jsxs(SARow, { children: [_jsx(InformationCol, { span: 8, children: _jsx(InformationBox, { title: "Serial Number", content: serialNumber }) }), _jsx(InformationCol, { span: 8, children: _jsx(InformationBox, { title: "Certificate No.", content: certificateNumber }) }), _jsx(InformationCol, { span: 8, children: _jsx(InformationBox, { title: _jsxs(_Fragment, { children: ["Collection Point ", _jsx("br", {}), "(Usage Location)"] }), content: collectionPointIds.length > 1
                                ? "Multiple Locations"
                                : collectionPoints.find((point) => point.id === collectionPointIds[0])
                                    ?.collectionPointName }) })] }), _jsxs(SARow, { children: [_jsx(InformationCol, { span: 8, children: _jsx(InformationBox, { title: "Calibration Due Date", content: moment(calibrationDueAt).isSameOrBefore(moment()) ? (_jsx(SAStatusButton, { color: "#FD0000", children: "Over Due" })) : (moment(calibrationDueAt).format("DD MMM YYYY")) }) }), _jsx(InformationCol, { span: 8, children: _jsx(AttachmentBox, { title: "Certificate", contentUrl: calibrationCertificateUrl }) }), _jsx(InformationCol, { span: 8, children: _jsx(AttachmentBox, { title: "Report", contentUrl: calibrationReportUrl }) }), _jsx(IconButton, { children: _jsx(DeviceActionsDropdown, { id: id, icon: _jsx(MoreIcon, {}), isArchived: isArchived, reload: async () => {
                                if (searchParams.get("isArchiveShown") === "true") {
                                    await dispatch(getTestingDevices({
                                        testTypeId: String(testTypeIdParam),
                                        isArchived: true,
                                    }));
                                }
                                await dispatch(getTestingDevices({
                                    testTypeId: String(testTypeIdParam),
                                    isArchived: false,
                                }));
                            } }) })] })] }));
};
const HeadingContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const HeadingTitle = styled.p `
  font-size: 1rem;
  color: #2a44ff;
  font-weight: 500;
  cursor: pointer;
`;
const InformationCol = styled(SAColumn) `
  height: 5rem;

  div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  button {
    padding: 0.2rem 1rem;
    height: fit-content;
    min-width: fit-content;
  }
`;
const IconButton = styled(Button) `
  padding: 0;
  background: none;
  border: 0;
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 0;
`;
