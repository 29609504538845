export var RequestingAuthorityLabel;
(function (RequestingAuthorityLabel) {
    RequestingAuthorityLabel["CLIENT"] = "client";
    RequestingAuthorityLabel["CUSTOMER"] = "customer";
})(RequestingAuthorityLabel || (RequestingAuthorityLabel = {}));
export var ParticipantLabel;
(function (ParticipantLabel) {
    ParticipantLabel["DONOR"] = "donor";
    ParticipantLabel["PATIENT"] = "patient";
    ParticipantLabel["CANDIDATE"] = "candidate";
})(ParticipantLabel || (ParticipantLabel = {}));
export var NameConvention;
(function (NameConvention) {
    NameConvention["LAST_NAME"] = "lastName";
    NameConvention["FIRST_NAME"] = "firstName";
})(NameConvention || (NameConvention = {}));
export var GenderOption;
(function (GenderOption) {
    GenderOption["WITH_OTHER"] = "withOther";
    GenderOption["WITHOUT_OTHER"] = "withoutOther";
})(GenderOption || (GenderOption = {}));
export var TestNameLabelDrug;
(function (TestNameLabelDrug) {
    TestNameLabelDrug["NORMAL"] = "normal";
    TestNameLabelDrug["COLLECTION"] = "collection";
})(TestNameLabelDrug || (TestNameLabelDrug = {}));
export var TestNameLabelAlcohol;
(function (TestNameLabelAlcohol) {
    TestNameLabelAlcohol["NORMAL"] = "normal";
    TestNameLabelAlcohol["TEST"] = "test";
})(TestNameLabelAlcohol || (TestNameLabelAlcohol = {}));
export var TestResultLabelDrug;
(function (TestResultLabelDrug) {
    TestResultLabelDrug["WITH_RTF"] = "withRtf";
    TestResultLabelDrug["WITHOUT_RTF"] = "withoutRtf";
})(TestResultLabelDrug || (TestResultLabelDrug = {}));
export var TestResultLabelAlcohol;
(function (TestResultLabelAlcohol) {
    TestResultLabelAlcohol["WITH_POSITIVE"] = "withPositive";
    TestResultLabelAlcohol["NON_NEGATIVE"] = "nonNegative";
})(TestResultLabelAlcohol || (TestResultLabelAlcohol = {}));
