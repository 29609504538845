import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import TestDetailTable from "src/pages/Accounting/Dashboard/components/TestDetailTable";
import MedicalTestOverviewBox from "src/pages/Accounting/components/MedicalTestOverviewBox";
import ViewDetailButton from "src/pages/Accounting/components/ViewDetailButton";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getCurrentOnsiteJobDetails } from "../store/action";
import { selectDisplayCost, selectIsIncludedGst, selectTestingInvoice } from "../store/selector";
import TestingDetailTable from "./TestingDetailTable";
const DrugAlcoholTestBox = () => {
    const dispatch = useAppDispatch();
    const invoice = useAppSelector(selectTestingInvoice);
    const displayCost = useAppSelector(selectDisplayCost);
    const isIncludedGst = useAppSelector(selectIsIncludedGst);
    const [isOpenDetailView, setIsOpenDetailView] = useState(false);
    const [isOpenInClinicDetailView, setIsOpenInClinicDetailView] = useState(false);
    const [isOpenOnsiteDetailView, setIsOpenOnsiteDetailView] = useState(false);
    const [onsiteData, setOnsiteData] = useState([]);
    const { onsiteDonor } = invoice;
    useEffect(() => {
        setIsOpenDetailView(false);
        setIsOpenInClinicDetailView(false);
        setIsOpenOnsiteDetailView(false);
    }, [onsiteDonor]);
    return (_jsxs(PackageWrapper, { children: [_jsxs(PackageContentRow, { children: [_jsx(SAColumn, { lg: 4, md: 24, children: _jsxs(PackageTitleSection, { children: [_jsx(BoxHeading, { children: "DRUG & ALCOHOL TESTS" }), _jsx(SubText, { children: "Tested Donors" })] }) }), _jsx(SAColumn, { lg: 14, md: 24, children: _jsx(MedicalTestOverviewBox, { subTitle: "Tested Donors", inClinic: 0, onSite: onsiteDonor.noDonors }) }), _jsx(SAColumn, { lg: 6, md: 24, children: _jsxs(PriceBox, { children: [_jsxs(PriceText, { span: 21, children: [" $ ", onsiteDonor[displayCost]?.toFixed(2)] }), _jsxs(GSTText, { span: 3, children: [" GST ", isIncludedGst ? "inclusive" : "exclusive"] })] }) })] }), _jsx(ViewDetailButton, { isOpen: isOpenDetailView, setIsOpen: setIsOpenDetailView, children: "Detail View" }), isOpenDetailView && (_jsxs(TestCollectionTablesContainer, { children: [_jsx(ViewDetailButton, { isOpen: isOpenInClinicDetailView, setIsOpen: setIsOpenInClinicDetailView, children: "In-Clinic Collection" }), isOpenInClinicDetailView && _jsx(TestDetailTable, { dataSource: [] }), _jsx(ViewDetailButton, { isOpen: isOpenOnsiteDetailView, setIsOpen: async (isOpen) => {
                            if (isOpen) {
                                const actionResult = await dispatch(getCurrentOnsiteJobDetails()).unwrap();
                                setOnsiteData(actionResult.data);
                            }
                            setIsOpenOnsiteDetailView(isOpen);
                        }, children: "Onsite Collection" }), isOpenOnsiteDetailView && _jsx(TestingDetailTable, { dataSource: onsiteData })] }))] }));
};
export default DrugAlcoholTestBox;
const PackageWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-block: 2rem;
  gap: 2rem;
`;
const PackageContentRow = styled(SARow) `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 2rem 1.5rem;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 0;
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #00004c;
`;
const PriceBox = styled(SARow) `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #f7f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled(SAColumn) `
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  text-align: center;
  width: 100%;
`;
const GSTText = styled(PriceText) `
  font-size: 0.75rem;
  white-space: break-spaces;
  font-weight: 500;
`;
const SubText = styled.p `
  font-size: 0.875rem;
`;
const PackageTitleSection = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
const TestCollectionTablesContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    margin-left: 0 !important;
    margin-right: auto;
  }
`;
