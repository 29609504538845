import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { getCurrentTestingInvoice } from "./action";
import { initialInvoice } from "./types";
export const initialState = {
    invoice: initialInvoice,
    isIncludedGst: true,
};
const accountingTestingSlice = createSlice({
    name: "accountingTesting",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
        setIsIncludedGst(state, action) {
            state.isIncludedGst = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrentTestingInvoice.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.invoice = data;
        })
            .addCase(getCurrentTestingInvoice.rejected, () => {
            message.error("Failed to fetch testing invoice data");
        });
    },
});
export const { setResetStore, setIsIncludedGst } = accountingTestingSlice.actions;
export default accountingTestingSlice.reducer;
