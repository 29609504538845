import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Text } from "src/components/base";
import { fontWeight, mainAppColours } from "src/constants";
import { SAColourScheme } from "src/styles";
import { useSettings } from "src/providers/SettingsContextProvider";
export const WarmingLeaverPagePopUp = ({ handleContinue, handleCancel, }) => {
    const [isLoading] = useState(false);
    const { setIsDataDirty } = useSettings();
    const handleContinueFunc = () => {
        setIsDataDirty(false);
        handleContinue();
    };
    const handleCancelFunc = () => {
        setIsDataDirty(false);
        handleCancel();
    };
    return (_jsxs(WarmingContainer, { children: [_jsx("div", { className: "header", children: _jsx(ExclamationCircleFilled, { style: {
                        color: "#FD0000",
                        // backgroundColor: SAColourScheme.RED,
                        marginTop: 10,
                        fontSize: 40,
                    } }) }), _jsx("div", { className: "set-up-title", children: _jsx(Text, { className: "title", name: "Leaving without saving?" }) }), _jsx(Text, { className: "alert", name: "If you don't save, your changes will be lost." }), _jsxs(Footer, { children: [_jsx(Button, { className: "save-button", disabled: isLoading, onClick: handleContinueFunc, children: "Leave" }), _jsx(Button, { className: "save-button", disabled: isLoading, onClick: handleCancelFunc, children: "Cancel" })] })] }));
};
const WarmingContainer = styled.div `
  width: 100%;
  background-color: ${SAColourScheme.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    width: 100%;
    height: 60px;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .icon {
    position: absolute;
    top: 50px; /* Adjusts overlap with header */
    transform: translateY(-50%);
  }

  .set-up-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title.ant-typography {
    margin-top: 10px;
    font-size: 18px;
    font-weight: ${fontWeight.BOLD};
    text-align: center;
    color: #222222 !important;
  }

  .alert.ant-typography {
    font-size: 14px;
    font-weight: ${fontWeight.REGULAR};
    color: #000000 !important;
    margin: 13px 0;
    text-align: center;
  }

  .save-button {
    border-radius: 8px;
    margin: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 30px;
    border: 1px solid ${mainAppColours.MID_BLUE};

    span {
      color: #000000;
      font-size: 16px;
      font-weight: ${fontWeight.MEDIUM};
    }
  }
`;
const Footer = styled.div `
  display: flex;
`;
