/* eslint-disable no-param-reassign */
import { map, range, reduce } from "lodash";
import moment from "moment";
import { YearFilterOptions } from "./types";
const generateYearRangesAndDates = () => {
    const currentYear = new Date().getFullYear();
    const calendarYears = range(currentYear, currentYear - 5, -1);
    const financialYears = map(calendarYears, (year) => `${year}-${year - 1}`);
    const calendarYearDates = reduce(calendarYears, (acc, year) => {
        acc[year] = {
            label: year,
            startAt: moment(`${year}-01-01`).startOf("day").toDate(),
            endAt: moment(`${year}-12-31`).endOf("day").toDate(),
        };
        return acc;
    }, {});
    const financialYearDates = reduce(financialYears, (acc, yearRange) => {
        const [endYear, startYear] = yearRange.split("-").map(Number);
        acc[yearRange] = {
            label: `${endYear} - ${startYear}`,
            startAt: moment(`${startYear}-07-01`).startOf("day").toDate(),
            endAt: moment(`${endYear}-06-30`).endOf("day").toDate(),
        };
        return acc;
    }, {});
    return {
        [YearFilterOptions.CalendarYear]: {
            label: "Calendar Year",
            value: YearFilterOptions.CalendarYear,
            listYearRange: Object.values(calendarYearDates).reverse(),
        },
        [YearFilterOptions.FinancialYear]: {
            label: "Financial Year",
            value: YearFilterOptions.FinancialYear,
            listYearRange: Object.values(financialYearDates),
        },
    };
};
export { generateYearRangesAndDates };
