var TestingProductType;
(function (TestingProductType) {
    TestingProductType[TestingProductType["Onsite"] = 1] = "Onsite";
    TestingProductType[TestingProductType["InClinic"] = 2] = "InClinic";
})(TestingProductType || (TestingProductType = {}));
const initialInvoice = {
    currentDate: "",
    billingCycleType: "",
    nextInvoiceIssueDate: "",
    startAt: "",
    endAt: "",
    onsiteDonor: {
        noDonors: 0,
        unitCost: 0,
        unitCostIncludingGST: 0,
    },
    onsiteLab: {
        noSamples: 0,
        unitCost: 0,
        unitCostIncludingGST: 0,
    },
};
var TestingError;
(function (TestingError) {
    TestingError["EFB001"] = "ECB001";
})(TestingError || (TestingError = {}));
export { initialInvoice, TestingError, TestingProductType, };
