import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckCircleOutlined } from "@ant-design/icons";
import { uuidv4 } from "@antv/xflow-core";
import { Button, message, Space, Table, Typography } from "antd";
import { groupBy, keyBy } from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { KeyIcon, PhoneIcon } from "src/assets/svg/welcome-assist";
import SASelect from "src/components/Form/SASelect";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import { getCollectionPointInformation, selectCollectionOrganization, } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { routes } from "../..";
import { getListLicenseOrganizations } from "../store/action";
import { setCollectionPoint } from "../store/slice";
import { KioskLicenseStatus, UserStatus, UserStatusMapping } from "../store/types";
const KioskManagementHomePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const collectionOrganization = useAppSelector(selectCollectionOrganization);
    const collectionPoints = useAppSelector(getCollectionPointInformation);
    const [selectCollectionPointId, setSelectCollectionPointId] = React.useState("");
    const [licenses, setLicenses] = React.useState([]);
    const collectionPointMap = keyBy(collectionPoints, "id");
    const hideDuplicateCollectionPoint = (currentLicenses) => currentLicenses.map((item, index, array) => {
        if (index === 0 ||
            item.collectionPointId !== array[index - 1].collectionPointId ||
            !item.collectionPointId) {
            return item;
        }
        return {
            ...item,
            location: "",
            collectionPointId: "",
            noKiosks: "",
        };
    });
    React.useEffect(() => {
        const loadData = async () => {
            const response = await dispatch(getListLicenseOrganizations()).unwrap();
            if (response.error) {
                message.error("Failed to fetch list licenses");
                return;
            }
            const noKioskByCollectionPoint = groupBy(response.data, "collectionPointId");
            const updatedLicenses = (response.data || []).map((licenseInfo) => ({
                location: collectionPointMap[licenseInfo.collectionPointId]?.collectionPointName,
                noKiosks: noKioskByCollectionPoint[licenseInfo.collectionPointId]?.length || 0,
                licenseKey: licenseInfo.key,
                status: licenseInfo.status,
                manager: licenseInfo.manager,
                collectionPointId: licenseInfo.collectionPointId,
            }));
            setLicenses(hideDuplicateCollectionPoint(updatedLicenses));
        };
        loadData();
    }, []);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsx(KioskTableContainer, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 18, align: "flex-start", children: _jsxs(Space, { children: [_jsx(PhoneIcon, {}), _jsx(Typography.Title, { level: 5, children: "Kiosk Management" })] }) }), _jsx(SAColumn, { span: 6, align: "flex-end", children: _jsx(SASelect, { fieldNames: { label: "collectionPointName", value: "id" }, options: [{ collectionPointName: "All", id: "" }, ...collectionPoints], value: selectCollectionPointId, onChange: (collectionPointId) => {
                                setSelectCollectionPointId(collectionPointId);
                            }, children: "Show" }) }), _jsx(SAColumn, { span: 24, children: _jsxs(KioskTable, { dataSource: licenses.map((item) => ({ ...item, id: uuidv4() })), rowKey: "id", bordered: false, children: [_jsx(Table.Column, { title: "Organization", render: () => collectionOrganization.collectionName }), _jsx(Table.Column, { title: "Location", dataIndex: "location" }), _jsx(Table.Column, { title: "Kiosks", dataIndex: "noKiosks" }), _jsx(Table.Column, { title: "License Key", dataIndex: "licenseKey" }), _jsx(Table.Column, { title: "Status", dataIndex: "status", align: "center", render: (status) => {
                                        if (status === KioskLicenseStatus.ACTIVE) {
                                            return (_jsx(Button, { type: "link", icon: _jsx(CheckCircleOutlined, { style: { color: UserStatusMapping[UserStatus.ACTIVE].color } }), children: _jsx(Typography.Text, { children: "Active" }) }));
                                        }
                                        return (_jsx(Button, { type: "link", danger: true, children: "Not Assigned" }));
                                    } }), _jsx(Table.Column, { title: "Kiosk Manager", dataIndex: "manager" }), _jsx(Table.Column, { title: "Manage License", dataIndex: "collectionPointId", align: "center", render: (collectionPointId) => (_jsx(_Fragment, { children: collectionPointId ? (_jsx(Button, { type: "text", onClick: () => {
                                                const selectedCollectionPoint = collectionPoints.find((p) => p.id === collectionPointId);
                                                if (selectedCollectionPoint) {
                                                    dispatch(setCollectionPoint(selectedCollectionPoint));
                                                    navigate(`manage/`);
                                                }
                                            }, children: _jsx(KeyIcon, {}) })) : (_jsx(_Fragment, {})) })) })] }) })] }) }) }));
};
export default KioskManagementHomePage;
const KioskTableContainer = styled.div `
  width: 100%;
`;
const KioskTable = styled(Table) `
  width: 100%;
`;
