import { KioskEntryStatus } from "../Dashboard/typing";
var CustomerType;
(function (CustomerType) {
    CustomerType[CustomerType["VISITOR"] = 1] = "VISITOR";
    CustomerType[CustomerType["CONTRACTOR"] = 2] = "CONTRACTOR";
    CustomerType[CustomerType["VOLUNTEER"] = 3] = "VOLUNTEER";
    CustomerType[CustomerType["PATIENT"] = 4] = "PATIENT";
})(CustomerType || (CustomerType = {}));
var CheckOutType;
(function (CheckOutType) {
    CheckOutType[CheckOutType["MANUAL"] = 1] = "MANUAL";
    CheckOutType[CheckOutType["AUTO"] = 2] = "AUTO";
})(CheckOutType || (CheckOutType = {}));
const KioskEntryStatusMapping = {
    [KioskEntryStatus.CHECKED_IN]: { label: "Checked In", color: "orange" },
    [KioskEntryStatus.CHECKED_OUT]: { label: "Checked Out", color: "green" },
    [KioskEntryStatus.IN_COMPLETE]: { label: "Incomplete", color: "red" },
};
const CustomerTypeMapping = {
    [CustomerType.CONTRACTOR]: "Contractor",
    [CustomerType.PATIENT]: "Patient",
    [CustomerType.VISITOR]: "Visitor",
    [CustomerType.VOLUNTEER]: "Volunteer",
};
const CheckOutTypeStatusMapping = {
    [CheckOutType.MANUAL]: "Manual",
    [CheckOutType.AUTO]: "Auto",
};
export { CustomerType, CheckOutType, KioskEntryStatusMapping, CustomerTypeMapping, CheckOutTypeStatusMapping, };
