var DateRangeOption;
(function (DateRangeOption) {
    DateRangeOption[DateRangeOption["ThisMonth"] = 1] = "ThisMonth";
    DateRangeOption[DateRangeOption["ThisQuarter"] = 2] = "ThisQuarter";
    DateRangeOption[DateRangeOption["ThisFinancialYear"] = 3] = "ThisFinancialYear";
    DateRangeOption[DateRangeOption["ThisCalendarYear"] = 4] = "ThisCalendarYear";
    DateRangeOption[DateRangeOption["SelectRange"] = 5] = "SelectRange";
})(DateRangeOption || (DateRangeOption = {}));
var DateRangeName;
(function (DateRangeName) {
    DateRangeName["ThisMonth"] = "This month";
    DateRangeName["ThisQuarter"] = "This quarter";
    DateRangeName["ThisFinancialYear"] = "This financial year";
    DateRangeName["ThisCalendarYear"] = "This calendar year";
    DateRangeName["SelectRange"] = "";
})(DateRangeName || (DateRangeName = {}));
const initialOverallCost = {
    collectionManager: {
        noAdmins: 0,
        noLicenses: 0,
        totalCost: "",
        totalCostIncludingGST: "",
        licenseId: "",
    },
    collectAssist: {
        noCollectors: 0,
        noLicenses: 0,
        totalCost: "",
        totalCostIncludingGST: "",
        licenseId: "",
    },
    secureAssist: {
        planName: "",
        totalCost: "",
        totalCostIncludingGST: "",
        billingCycleType: "",
        startAt: "",
    },
    reportAssist: {
        planName: "",
        totalCost: "",
        totalCostIncludingGST: "",
        billingCycleType: "",
        startAt: "",
    },
    workAssist: {
        planName: "",
        totalCost: "",
        totalCostIncludingGST: "",
        billingCycleType: "",
        startAt: "",
    },
    welcomeAssist: {
        noDevices: 0,
        noLicenses: 0,
        totalCost: "",
        totalCostIncludingGST: "",
        licenseId: "",
    },
    onsiteDonor: {
        noDonors: 0,
        totalCost: "",
        totalCostIncludingGST: "",
    },
    onsiteLab: {
        noSamples: 0,
        totalCost: "",
        totalCostIncludingGST: "",
    },
    training: {
        name: "",
        description: "",
        totalCost: "",
        totalCostIncludingGST: "",
    },
    serviceEstablishment: {
        name: "",
        description: "",
        totalCost: "",
        totalCostIncludingGST: "",
    },
    platformTraining: {
        name: "",
        description: "",
        totalCost: "",
        totalCostIncludingGST: "",
    },
    totalCost: "",
    totalCostIncludingGST: "",
};
export { DateRangeName, DateRangeOption, initialOverallCost, };
