import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import SunEditor from "suneditor-react";
import "src/assets/css/suneditor.min.css";
const EditorNote = ({ content, onChangeContent, disabled }) => {
    const editorCore = React.useRef();
    const wordEndHTMLTag = "<br></p>";
    const getSunEditorInstance = (sunEditor) => {
        editorCore.current = sunEditor;
    };
    const onFocusEditor = () => {
        if (!editorCore.current?.core.getContents(true).endsWith(wordEndHTMLTag))
            editorCore.current?.core.functions.appendContents("");
    };
    return (_jsx(SunEditor, { autoFocus: false, setDefaultStyle: "font-size: 14px; font-family: Roboto, sans-serif", height: "20rem", setOptions: {
            buttonList: [["formatBlock", "font", "bold", "italic", "underline", "align", "list"]],
            formats: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
            charCounter: false,
            addTagsWhitelist: "br",
        }, onChange: () => {
            onChangeContent(editorCore.current?.core.getContents(true) || "", editorCore.current?.getText() || "");
        }, setContents: content, disable: disabled, getSunEditorInstance: getSunEditorInstance, onFocus: onFocusEditor }));
};
export default EditorNote;
