import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix this type
import { useRef, useState } from "react";
import ContentContext from "./content";
// TODO: This provider was moved from side menu layout, which should be removed in the future
export const ContentContextProvider = ({ children }) => {
    const toastRef = useRef(null);
    const [messageBox, setMessageBox] = useState({
        visible: false,
        message: null,
        callback: null,
        type: "dialog",
    });
    const [popupBox, setPopupBox] = useState({
        visible: false,
        component: [],
        callback: null,
        popupProps: [],
    });
    const [bodyPopupBox, setBodyPopupBox] = useState({
        visible: false,
        component: null,
        props: {},
        callback: null,
    });
    const [loading, setLoading] = useState({
        visible: false,
    });
    const openMessageBox = (type, message, callback = null) => {
        setMessageBox({
            visible: true,
            message,
            callback,
            type,
        });
    };
    const closeMessageBox = () => {
        setMessageBox({
            visible: false,
            message: null,
            callback: null,
        });
    };
    const openPopupBox = (component, callback = null, popupProps = null) => {
        setPopupBox({
            visible: true,
            component: [...popupBox.component, component],
            popupProps: [...popupBox.popupProps, popupProps],
            callback,
        });
    };
    const closePopupBox = () => {
        const componentArr = popupBox.component;
        componentArr.pop();
        const popupPropsArr = popupBox.popupProps || [];
        popupPropsArr.pop && popupPropsArr.pop();
        if (componentArr.length !== 0) {
            setPopupBox({
                visible: true,
                component: componentArr,
                callback: popupBox.callback,
                popupProps: popupPropsArr,
            });
        }
        else {
            setPopupBox({
                visible: false,
                component: [],
                callback: null,
                popupProps: [],
            });
        }
    };
    const openBodyPopupBox = (component, props, callback = null) => {
        setBodyPopupBox({
            visible: true,
            component: component,
            props: props,
            callback,
        });
    };
    const closeBodyPopupBox = () => {
        setBodyPopupBox({
            visible: false,
            component: null,
            props: {},
            callback: null,
        });
    };
    const openLoadingScreen = () => {
        setLoading({ visible: true });
    };
    const closeLoadingScreen = () => {
        setLoading({ visible: false });
    };
    const openToast = (message, options) => {
        toastRef.current?.showMessage(message, options);
    };
    return (_jsx(ContentContext.Provider, { value: {
            messageBox,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            openMessageBox,
            closeMessageBox,
            popupBox,
            openPopupBox,
            closePopupBox,
            loadingScreen: loading,
            openLoadingScreen,
            closeLoadingScreen,
            bodyPopupBox,
            openBodyPopupBox,
            closeBodyPopupBox,
            openToast,
        }, children: children }));
};
