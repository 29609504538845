import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "antd";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import AddIcon from "src/assets/svg/add-icon.svg";
import SatchelIcon from "src/assets/svg/inventory/ic-satchel.svg";
import { archiveTransportSatchel, restoreTransportSatchel, } from "src/api/inventory/inventoryService";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import SAColumn from "src/components/Form/Table/SAColumn";
import { useAppSelector } from "src/store/hooks";
import { selectTransportSatchel } from "src/pages/InventoryManagement/store/selector";
import { getTransportSatchel } from "src/pages/InventoryManagement/store/action";
import { ArchiveSatchels } from "./Archieve";
import { SatchelsData } from "./SatchelsData";
export const TransportSatchels = () => {
    const transportSatchels = useAppSelector(selectTransportSatchel);
    const navigate = useNavigate();
    const { openMessageBox, openLoadingScreen, closeLoadingScreen, openToast } = useContext(ContentContext);
    const dispatch = useDispatch();
    useEffect(() => {
        openLoadingScreen();
        dispatch(getTransportSatchel());
        closeLoadingScreen();
    }, []);
    const archiveSatchel = async (id) => {
        openLoadingScreen();
        const response = await archiveTransportSatchel(id);
        if (response.status === "success") {
            openToast("Archived successfully");
            dispatch(getTransportSatchel());
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    const restoreSatchel = async (id) => {
        openLoadingScreen();
        const response = await restoreTransportSatchel(id);
        if (response.status === "success") {
            openToast("Restored successfully");
            dispatch(getTransportSatchel());
        }
        else {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG_ERROR, "Something went wrong. Please try again");
        }
        closeLoadingScreen();
    };
    return (_jsxs("div", { children: [_jsxs(HeaderWrapper, { children: [_jsxs(SAColumn, { span: 16, direction: "row", children: [_jsx(SatchelIcon, {}), _jsx(TitleSatchel, { children: "Satchel Inventory" })] }), _jsx(SAColumn, { span: 8, direction: "row", justify: "flex-end", align: "center", children: _jsxs(AddNewButton, { onClick: () => navigate(`/inventory/add`), children: [_jsx(AddIcon, {}), _jsx("span", { children: "Add New" })] }) })] }), _jsx(SatchelsData, { transportSatchels: transportSatchels.activeSatchels, archiveSatchel: archiveSatchel }), _jsx(ArchiveSatchels, { transportSatchels: transportSatchels.archivedSatchels, restoreSatchel: restoreSatchel })] }));
};
const AddNewButton = styled(Button) `
  border: 1px solid ${mainAppColours.MID_BLUE};
  align-items: center;
  display: flex;
  justify-content: center;
  color: #1a8cff;
  cursor: pointer;
`;
const TitleSatchel = styled.span `
  margin-left: 10px;
  font-size: 16px;
  color: #000000;
`;
const HeaderWrapper = styled.div `
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  align-items: center;
`;
