import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cmUserPageReducer from "src/pages/UsersPage/store/slice";
import calendarSlice from "src/pages/CalendarPage/store/slice";
import clientManagementPageReducer from "src/pages/ClientManagement/store/clientManagementReducer";
import inclinicReducer from "src/pages/InClinicAppointmentsPage/store/slice";
import platformManagementReducer from "src/pages/PlatformManagementMenuPage/slice";
import userPageDetailReducer from "src/pages/UserProfilePage/store/slice";
import authorisingAuthorityReducer from "src/pages/AuthorisingAuthorityPage/store/slice";
import deviceManagementReducer from "src/pages/TestingDevicePage/store/slice";
import accountingReducer from "src/pages/Accounting/slice";
import LegalPageReducer from "src/pages/Legal/store/slice";
import dashboardPageReducer from "src/pages/Dashboard/slice";
import collectionOrganizationReducer from "src/pages/CollectionOrganization/store/slice";
import inventoryStoreReducer from "src/pages/InventoryManagement/store/slice";
import collectionReportsSlice from "../pages/CollectionReports/store/slice";
import HomePageReducer from "../pages/HomePage/store/slice";
import onsiteApprovalsSlice from "../pages/JobManagement/OnSiteApprovals/store/slice";
import JobManagementPageReducer from "../pages/JobManagement/store/slice";
import AuthenticationReducer from "./app/slice";
const combinedReducer = combineReducers({
    homePage: HomePageReducer,
    authentication: AuthenticationReducer,
    jobManagementPage: JobManagementPageReducer,
    clientManagementPage: clientManagementPageReducer,
    collectionReportsPage: collectionReportsSlice,
    onsiteApprovalsPage: onsiteApprovalsSlice,
    platformManagementPage: platformManagementReducer,
    calendarPage: calendarSlice,
    cmUserPage: cmUserPageReducer,
    userDetailPage: userPageDetailReducer,
    inclinicAppointment: inclinicReducer,
    authorisingAuthorityPage: authorisingAuthorityReducer,
    deviceManagementPage: deviceManagementReducer,
    accountingPage: accountingReducer,
    legaPage: LegalPageReducer,
    dashboardPage: dashboardPageReducer,
    inventoryPage: inventoryStoreReducer,
    collectionOrganizationPage: collectionOrganizationReducer,
});
const rootReducer = (state, action) => {
    let newState = state;
    if (action.type === "RESET") {
        newState = undefined;
    }
    return combinedReducer(newState, action);
};
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});
