import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { stringify } from "qs";
import { $get } from "src/infra/http";
import { Domain } from "src/types";
const getCollectorCalendarEvents = createAsyncThunk("collectAssist/getCollectorCalendarEvents", async (_, thunkApi) => {
    const { selectedCollectionPoint, selectedCollectorId, currentDate, currentView } = thunkApi.getState().platformManagementPage.collectAssistCalendar;
    const requestData = {
        collectionPointId: selectedCollectionPoint || null,
        collectorId: selectedCollectorId || null,
        startAt: moment(currentDate).clone().startOf(currentView).toISOString(),
        endAt: moment(currentDate).clone().endOf(currentView).toISOString(),
    };
    return await $get(`/platform/collect-assist/calendar/events?${stringify(requestData, { skipNulls: true })}`, Domain.CM);
});
const getStaffCollectionPoints = createAsyncThunk("collectAssist/getStaffCollectionPoints", async () => await $get(`/platform/collect-assist/calendar/staff`, Domain.CM));
export { getCollectorCalendarEvents, getStaffCollectionPoints };
