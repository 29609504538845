import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { uuidv4 } from "@antv/xflow-core";
import { Table } from "antd";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import ViewDetailButton from "src/pages/Accounting/components/ViewDetailButton";
import { useAppSelector } from "src/store/hooks";
import { selectDisplayCost, selectOverallCostSummary } from "../store/selector";
import ITServiceBox from "./ITServiceBox";
const ServiceEstablishmentBox = forwardRef((_, ref) => {
    const overallCostSummary = useAppSelector(selectOverallCostSummary);
    const displayCost = useAppSelector(selectDisplayCost);
    const { serviceEstablishment } = overallCostSummary;
    const [isOpenDetailView, setIsOpenDetailView] = useState(false);
    useEffect(() => {
        setIsOpenDetailView(false);
    }, [overallCostSummary]);
    return (_jsxs(PackageWrapper, { ref: ref, children: [_jsxs(PackageContentRow, { children: [_jsx(SAColumn, { lg: 5, md: 24, children: _jsxs(PackageTitleSection, { children: [_jsx(BoxHeading, { children: "SA Service Establishment" }), _jsx(SubText, { children: "Platform Setup" })] }) }), _jsx(SAColumn, { lg: 15, md: 24, children: _jsx(ITServiceBox, { packageName: "Service Establish Package Name", packageValue: serviceEstablishment.name || "", description: serviceEstablishment.description || "" }) }), _jsx(SAColumn, { lg: 4, md: 24, children: _jsx(PriceBox, { children: _jsxs(PriceText, { children: [" $ ", serviceEstablishment[displayCost]] }) }) })] }), _jsx(ViewDetailButton, { isOpen: isOpenDetailView, setIsOpen: async () => {
                    setIsOpenDetailView(!isOpenDetailView);
                }, children: "Detail View" }), isOpenDetailView && (_jsxs(PackageTable, { dataSource: [serviceEstablishment]?.map((entry) => ({ ...entry, id: uuidv4() })) ?? [], rowKey: "id", pagination: false, children: [_jsx(Table.Column, { title: "Date", dataIndex: "startAt" }, "startAt"), _jsx(Table.Column, { title: "Training Package", dataIndex: "name" }, "name"), _jsx(Table.Column, { title: "Payment Plan", render: () => "One-Time" }), _jsx(Table.Column, { title: "Unit Cost", dataIndex: "totalCost", render: (totalCost) => `$ ${totalCost}` }, "totalCost"), _jsx(Table.Column, { title: "GST", render: () => `$ ${(Number(serviceEstablishment.totalCostIncludingGST) - Number(serviceEstablishment.totalCost)).toFixed(2)}` }), _jsx(Table.Column, { title: "Total", dataIndex: "totalCostIncludingGST", render: (totalCostIncludingGST) => `$ ${totalCostIncludingGST}` }, "totalCostIncludingGST")] }))] }));
});
ServiceEstablishmentBox.displayName = "ServiceEstablishmentBox";
export default ServiceEstablishmentBox;
const PackageWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-block: 4rem;
  gap: 2rem;
`;
const PackageContentRow = styled(SARow) `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #00004c;
`;
const PriceBox = styled.div `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #ccf0f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled.p `
  font-size: 1rem;
  font-weight: 700;
  margin: auto;
`;
const SubText = styled.p `
  font-size: 0.875rem;
`;
const PackageTitleSection = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
const PackageTable = styled(Table) `
  width: 100%;
`;
