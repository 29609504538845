import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Calendar, Space, Typography } from "antd";
import { uniqueId } from "lodash";
import moment from "moment";
import * as React from "react";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { AccountIcon } from "src/assets/svg/common";
import { useAppSelector } from "src/store/hooks";
import { selectCalendarEvents } from "../store/selector";
import { CollectorCalendarEventType } from "../store/types";
const EventSideBar = () => {
    const calendarEvents = useAppSelector(selectCalendarEvents);
    const { unavailableEvents, leaveEvents, unavailableCount, leaveCount } = calendarEvents.reduce((acc, event) => {
        if (event.eventType === CollectorCalendarEventType.Unavailable) {
            acc.unavailableEvents.push(event);
            acc.unavailableCount++;
        }
        else if (event.eventType === CollectorCalendarEventType.Leave) {
            acc.leaveEvents.push(event);
            acc.leaveCount++;
        }
        return acc;
    }, {
        unavailableEvents: [],
        leaveEvents: [],
        unavailableCount: 0,
        leaveCount: 0,
    });
    return (_jsxs(React.Fragment, { children: [_jsx(DisplayCalendar, { children: _jsx(Calendar, { fullscreen: false, headerRender: (date) => (_jsxs(Space, { children: [_jsx(Typography.Title, { level: 5, children: moment(date.value).format("MMMM") }), _jsxs(Typography.Text, { children: [" ", moment(date.value).format("YYYY")] })] })) }) }), _jsxs(InformationCard, { children: [_jsxs(EventCard, { children: [_jsxs(EventHeading, { children: [_jsx(Typography.Title, { level: 5, children: "Unavailable" }), _jsxs(Typography.Text, { children: ["Total ", unavailableCount] })] }), unavailableEvents.map((event) => (_jsxs(EventContainer, { children: [_jsx(AccountIcon, {}), _jsxs(EventContent, { children: [_jsx(Typography.Title, { level: 5, children: event.collectorName }), _jsxs(Typography.Text, { children: [moment(event.startAt).format("HH:mm"), " - ", moment(event.endAt).format("HH:mm")] }), _jsx(Typography.Text, { children: event.reasonNote })] })] }, uniqueId())))] }), _jsxs(EventCard, { "$isLeave": true, children: [_jsxs(EventHeading, { "$isLeave": true, children: [_jsx(Typography.Title, { level: 5, children: "Leave" }), _jsxs(Typography.Text, { children: ["Total ", leaveCount] })] }), leaveEvents.map((event) => (_jsxs(EventContainer, { children: [_jsx(AccountIcon, {}), _jsxs(EventContent, { "$isLeave": true, children: [_jsx(Typography.Title, { level: 5, children: event.collectorName }), _jsx(Typography.Text, { children: event.isAllDay
                                                    ? "All Day"
                                                    : `${moment(event.startAt).format("HH:mm")} - ${moment(event.endAt).format("HH:mm")}` }), _jsx(Typography.Text, { children: event.reasonNote })] })] }, uniqueId())))] })] })] }));
};
export default EventSideBar;
const InformationCard = styled.div `
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
`;
const DisplayCalendar = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 0.1rem;
  pointer-events: none;
  .ant-picker-calendar .ant-space {
    display: flex;
    justify-content: end;
  }

  .ant-picker-calendar .ant-picker-panel {
    border: none;
  }
  thead {
    border-bottom: 0.025rem solid #adb3bc;
  }

  th {
    color: #adb3bc;
    font-weight: 700;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    border-radius: 1rem;
    background: #4b5767;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 1rem;
    border-color: #4b5767;
  }
`;
const EventCard = styled.div `
  width: 100%;
  height: 24vh;
  overflow: auto;
  scrollbar-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  svg {
    color: ${({ $isLeave }) => ($isLeave ? "#EB41E4" : "#FD0000")};
  }
`;
const EventHeading = styled.div `
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ $isLeave }) => ($isLeave ? "#F2E5FF" : "#FAE3E2")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const EventContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`;
const EventContent = styled.div `
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  height: 100%;
  border-bottom: ${({ $isLeave }) => $isLeave ? "0.025rem solid #F2E5FF" : "0.025rem solid #FAE3E2"};
`;
