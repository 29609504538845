import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ContentContext from "src/providers/content";
import { WarmingLeaverPagePopUp } from "src/components/WarmingLeavePagePopup";
import { useSettings } from "src/providers/SettingsContextProvider";
export const useHandleNavigation = () => {
    const navigate = useNavigate();
    const { isDataDirty } = useSettings();
    const { openPopupBox, closePopupBox } = useContext(ContentContext);
    return useCallback((key, callBackInCaseNoDirty) => {
        if (isDataDirty) {
            openPopupBox(_jsx(WarmingLeaverPagePopUp, { handleContinue: () => {
                    closePopupBox();
                    navigate(key);
                }, handleCancel: () => {
                    closePopupBox();
                } }), null, {
                popupStyle: {
                    marginTop: "80px",
                    width: "30%",
                    borderRadius: "16px",
                    border: `2px solid #FD0000`,
                },
                closeIcon: false,
            });
        }
        else {
            callBackInCaseNoDirty && callBackInCaseNoDirty();
            navigate(key);
        }
    }, [isDataDirty, navigate]);
};
