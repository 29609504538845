var CalendarView;
(function (CalendarView) {
    CalendarView["Day"] = "day";
    CalendarView["Week"] = "week";
    CalendarView["Month"] = "month";
})(CalendarView || (CalendarView = {}));
const CalendarViewLabelList = [
    { value: CalendarView.Day, label: "Day View" },
    { value: CalendarView.Week, label: "Week View" },
    { value: CalendarView.Month, label: "Month View" },
];
export { CalendarView, CalendarViewLabelList };
