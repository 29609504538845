import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get } from "src/infra/http";
import { Domain } from "src/types";
const getListClients = createAsyncThunk("accounting/getListClients", async () => await $get("/accounting/dashboard/clients", Domain.CM));
const getOverallCostSummary = createAsyncThunk("accounting/getOverallCostSummary", async (_, thunkApi) => {
    const state = thunkApi.getState().accountingPage.dashboardPage;
    const { filterOptions } = state;
    const queryString = {};
    if (filterOptions.selectedCollectionPointId) {
        queryString.collectionPointId = filterOptions.selectedCollectionPointId;
    }
    if (filterOptions.selectedOnsiteClientId) {
        queryString.onsiteClientId = filterOptions.selectedOnsiteClientId;
    }
    if (filterOptions.startDate) {
        queryString.startDate = filterOptions.startDate.toDate();
    }
    if (filterOptions.endDate) {
        queryString.endDate = filterOptions.endDate.toDate();
    }
    return await $get(`/accounting/dashboard/summary?${stringify(queryString)}`, Domain.CM);
});
const getLicenseTransactions = createAsyncThunk("accounting/getLicenseTransactions", async (organizationLicenseId) => await $get(`/accounting/dashboard/transaction/${organizationLicenseId}`, Domain.CM));
export { getListClients, getOverallCostSummary, getLicenseTransactions };
