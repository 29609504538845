import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Input } from "antd";
import styled from "styled-components";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const ITServiceBox = ({ packageName, packageValue, description }) => (_jsx(StyledPlanBox, { children: _jsxs(SARow, { children: [_jsxs(SAColumn, { span: 24, flex: "flex-start", align: "flex-start", children: [_jsx(HeadingTitle, { children: packageName }), _jsx(Input, { value: packageValue })] }), _jsxs(SAColumn, { span: 24, flex: "flex-start", align: "flex-start", children: [_jsx(HeadingTitle, { children: "Description" }), _jsx(Input.TextArea, { rows: 3, value: description })] })] }) }));
export default ITServiceBox;
const StyledPlanBox = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const HeadingTitle = styled.p `
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #7f8e9d;
`;
