import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, Modal, Select, Space, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import { CalendarOutlined } from "@ant-design/icons";
import { uniqueId } from "lodash";
import SARow from "src/components/Form/Table/SARow";
import SAColumn from "src/components/Form/Table/SAColumn";
import { InfoIcon } from "src/assets/svg/accounting";
const SubscriptionPriceCard = ({ day, price }) => (_jsxs(PriceContainer, { children: [_jsxs(GradientTopSection, { children: [_jsx(Icon, {}), _jsx(DayText, { children: day }), _jsxs(Typography.Text, { children: ["day", day > 1 ? "s" : ""] })] }), _jsxs(BottomSection, { children: [_jsxs(Typography.Title, { level: 4, children: ["$ ", price.toFixed(2)] }), _jsx(Typography.Text, { children: "/ Subscription" })] })] }));
const SubscriptionDurationRange = [1, 14, 30, 365];
const EstimateSubscriptionPriceModal = ({ isOpen, setOpen, isIncludedGst, productName, plans, displayCost, }) => {
    if (!plans)
        return _jsx(_Fragment, {});
    const [selectedPlan, setSelectedPlan] = React.useState(plans[0]);
    const unitCost = (selectedPlan?.[displayCost] ?? 0);
    const licensePricesByDuration = SubscriptionDurationRange.map((day) => ({
        price: day * unitCost,
        day,
    }));
    React.useEffect(() => {
        setSelectedPlan(plans[0]);
    }, [plans]);
    return (_jsx(StyledSubscriptionModal, { open: isOpen, onCancel: () => setOpen(false), footer: null, title: null, centered: true, width: 1000, children: _jsx(StyledModalContent, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, align: "start", children: _jsxs(Space, { children: [_jsx(Button, { type: "text", icon: _jsx(InfoIcon, {}) }), _jsx(Typography.Text, { children: productName })] }) }), _jsx(SAColumn, { span: 6, align: "end", children: _jsxs(Space, { children: [_jsx(Typography.Text, { children: "Subscription:" }), _jsx(SelectSubscription, { options: plans, fieldNames: { label: "name", value: "planId" }, value: selectedPlan?.planId || "", onChange: (planId) => {
                                        const newPlan = plans.find((plan) => plan.planId === planId) || null;
                                        setSelectedPlan(newPlan);
                                    } })] }) }), _jsx(SAColumn, { span: 6, align: "end", children: _jsx(Typography.Text, { children: "Cost per Day (s)" }) }), _jsx(SAColumn, { span: 24, children: _jsx(Divider, {}) }), _jsx(SAColumn, { span: 24, align: "end", children: _jsxs(Typography.Text, { children: ["GST ", isIncludedGst ? "Inclusive" : "Exclusive"] }) }), licensePricesByDuration.map((priceDay) => (_jsx(SAColumn, { md: 12, lg: 6, children: _jsx(SubscriptionPriceCard, { price: priceDay.price, day: priceDay.day }) }, uniqueId())))] }) }) }));
};
export default EstimateSubscriptionPriceModal;
const StyledSubscriptionModal = styled(Modal) `
  .ant-modal-content {
    padding: 1rem;
  }
`;
const StyledModalContent = styled.div `
  display: flex;
  flex-direction: column;
`;
const PriceContainer = styled.div `
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5.31px 1.33px #0000002b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
`;
const GradientTopSection = styled.div `
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  position: relative;
  background: linear-gradient(220.27deg, #01b4d2 23.91%, #9acdd5 95.9%);
  padding: 1rem;

  span {
    color: #ffffff;
  }
`;
const Icon = styled(CalendarOutlined) `
  font-size: 5rem;
  color: #ffffff;
`;
const DayText = styled.span `
  font-size: 1.75rem;
  margin: 0 auto;
  position: absolute;
  color: #ffffff;
`;
const BottomSection = styled.div `
  width: 100%;
  border: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
`;
const SelectSubscription = styled(Select) `
  width: 7rem;
  border: none;
`;
