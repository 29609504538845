import { $get, $put } from "src/infra/http";
export const getCollectionManagerSetting = async () => {
    const response = await $get(`/collection-manager/setting/cm`);
    return response?.data || {};
};
export const updateCollectionManagerSetting = async (requestBody) => await $put(`/collection-manager/setting/cm`, requestBody);
export const updateCommunicationSetting = async (requestBody) => await $put(`/collection-manager/setting/cm/communication`, requestBody);
export const updateGeoSetting = async (requestBody) => await $put(`/collection-manager/setting/cm/geoFence`, requestBody);
export const updateCalendarSetting = async (requestBody) => await $put(`/collection-manager/setting/cm/calendar`, requestBody);
export const updateCMGeofenceSetting = async (isActive) => await $put(`/collection-manager/setting/cm/geo/setting`, { isGeofenceActive: isActive });
