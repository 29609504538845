import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, Menu } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { mainAppColours } from "src/constants";
import { useAppDispatch } from "src/store/hooks";
import { archiveTestingDevice, deleteTestingDevice, duplicateTestingDevice, restoreTestingDevice, } from "../../store/action";
const DeviceActionsDropdown = ({ id, icon, isArchived, reload, }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const menuItems = (_jsxs(StyledMenu, { children: [_jsx(StyledMenuItem, { onClick: () => {
                    navigate(`/testing-devices/oral/screen-device/update?id=${id}`);
                }, children: "View Details" }, "View"), _jsx(StyledMenuItem, { onClick: async () => {
                    await dispatch(duplicateTestingDevice(id));
                    reload();
                }, children: "Duplicate" }, "Duplicate"), _jsx(StyledMenuItem, { onClick: async () => {
                    await dispatch(archiveTestingDevice(id));
                    reload();
                }, children: "Archive" }, "Archive"), _jsx(Menu.Divider, {}), _jsx(StyledMenuItem, { onClick: async () => {
                    await dispatch(deleteTestingDevice(id));
                    reload();
                }, children: "Delete" }, "Delete"), isArchived && (_jsx(StyledMenuItem, { onClick: async () => {
                    await dispatch(restoreTestingDevice(id));
                    reload();
                }, children: "Restore" }, "Restore"))] }));
    return (_jsx(Dropdown, { overlay: menuItems, trigger: ["click"], children: _jsx(MenuOptionsWrapper, { children: icon }) }));
};
export default DeviceActionsDropdown;
const StyledMenu = styled(Menu) `
  border: 1px solid ${mainAppColours.MID_BLUE};
  width: 150px;
`;
const StyledMenuItem = styled(Menu.Item) `
  &:nth-child(4) {
    text-align: right;
  }
`;
const MenuOptionsWrapper = styled.div `
  cursor: pointer;
`;
