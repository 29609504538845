import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CalendarOutlined, CloseOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Popover, Select, Space, Table, Typography } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ExcelIcon } from "src/assets/svg/brands";
import AddStockIcon from "src/assets/svg/device-management/add-stock-icon.svg";
import DocumentIcon from "src/assets/svg/device-management/document.svg";
import { SpaceBetweenContainer } from "src/components/Container/Flex";
import SADivider from "src/components/Form/SADivider";
import TableSizeChanger from "src/components/TableSizeChanger";
import { MESSAGE_BOX_TYPE } from "src/constants";
import { downloadFromBlob } from "src/infra/export";
import ContentContext from "src/providers/content";
import { useAppDispatch } from "src/store/hooks";
import { exportActiveStockRecords } from "../store/action";
import { InventoryItemStatus } from "../typing";
import NoteForm from "./NoteForm";
import StockForm from "./StockForm";
import StockSummary from "./StockSummary";
const INITIAL_STATE = {
    isOpen: false,
    data: null,
};
const StockManagement = ({ dataSource, onSubmit, requireProductName = false, testTypeRecordIndex, type, testingDeviceId, onChangePagination, }) => {
    const dispatch = useAppDispatch();
    const { openMessageBox } = useContext(ContentContext);
    const { stockData, stockOverview } = dataSource;
    const [editingStock, setEditingStock] = useState(INITIAL_STATE);
    const [editingNote, setEditingNote] = useState(INITIAL_STATE);
    return (_jsxs(StockManagementContainer, { children: [_jsx(HighlightedStockSummary, { totalUnits: stockOverview.totalUnits, usedUnits: stockOverview.usedUnits, remainingUnits: stockOverview.remainingUnits }), _jsxs(Space, { align: "start", children: [_jsx(AddStockIcon, {}), _jsx(Typography.Title, { level: 5, children: "Stock Entry" })] }), _jsx(SADivider, {}), !requireProductName && (_jsx(WarningText, { children: "Please ensure this stock entry matches the same device and lot number. To add stock for a different Lot Number, please add new device." })), _jsx(StockForm, { handleSubmit: (submittedData) => {
                    onSubmit(submittedData);
                    setEditingStock(INITIAL_STATE);
                }, requireProductName: requireProductName }), _jsxs(TableContainer, { children: [_jsxs(TableFilter, { children: [_jsx(CalendarOutlined, {}), _jsx(Select, { options: [{ label: "Show All", value: 1 }], value: 1 })] }), _jsxs(Table, { dataSource: dataSource?.stockData.data, rowKey: "id", bordered: false, pagination: {
                            current: stockData.pagingMeta?.page,
                            pageSize: stockData.pagingMeta?.limit,
                            total: stockData.pagingMeta?.totalItem ?? 0,
                        }, onChange: (newPagination) => {
                            onChangePagination(newPagination.current || 1, newPagination.pageSize || 10);
                        }, children: [_jsx(Table.Column, { title: "Date", dataIndex: "date" }), requireProductName && _jsx(Table.Column, { title: "Product Name", dataIndex: "productName" }), _jsx(Table.Column, { title: "Batch/Order No.", dataIndex: "batchNumber" }), _jsx(Table.Column, { title: "Units", dataIndex: "noOfUnits" }), _jsx(Table.Column, { title: "Unit Price", dataIndex: "unitPrice", render: (unitPrice) => `$ ${unitPrice}` }), _jsx(Table.Column, { title: "Total", dataIndex: "total", render: (total) => `$ ${total}` }), _jsx(Table.Column, { title: "Entered By", dataIndex: "enteredBy" }), _jsx(Table.Column, { title: "Note", dataIndex: "notes", align: "center", render: (text, record) => (_jsx(Popover, { placement: "bottomRight", title: _jsxs(SpaceBetweenContainer, { children: [_jsx(Typography.Text, { children: "Note" }), _jsx(Button, { type: "link", onClick: () => setEditingNote({
                                                    isOpen: false,
                                                    data: null,
                                                }), children: _jsx(CloseOutlined, {}) })] }), content: _jsx(NoteForm, { onSubmit: (submittedData) => {
                                            onSubmit(submittedData);
                                            setEditingNote(INITIAL_STATE);
                                        }, dataSource: editingNote.data }), trigger: ["click"], open: editingNote.data?.id === record.id, onOpenChange: (isOpen) => {
                                        if (isOpen) {
                                            setEditingNote({ isOpen: isOpen, data: record });
                                        }
                                    }, children: _jsx(Button, { type: "link", children: _jsx(DocumentIcon, { color: text ? "#1A8CFF" : "#EDEEF0" }) }) })) }), _jsx(Table.Column, { title: "", dataIndex: "id", align: "center", render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(CustomMenu, { children: [_jsx(Menu.Item, { onClick: () => {
                                                    setEditingStock({
                                                        isOpen: true,
                                                        data: record,
                                                    });
                                                }, children: "Edit" }, "edit"), _jsx(Menu.Item, { onClick: () => {
                                                    openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `${record.productName || ""} (Batch/Order No: ${record.batchNumber} - ${record.noOfUnits} units) will be removed from stock inventory.
                          The deleted item will be recorded in Historical Stock Records.`, async () => {
                                                        await onSubmit({
                                                            ...record,
                                                            status: InventoryItemStatus.Inactive,
                                                        });
                                                    });
                                                }, children: "Delete" }, "delete")] }), trigger: ["click"], children: _jsx(Button, { type: "link", children: _jsx(EllipsisOutlined, {}) }) })) }, "id")] }), _jsxs(SizeChangerContainer, { "$hidden": !stockData?.pagingMeta.totalItem, children: [_jsx(TableSizeChanger, { value: stockData.pagingMeta.limit, onChange: (size) => {
                                    onChangePagination(stockData.pagingMeta.page, size);
                                } }), _jsx(ExportButton, { icon: _jsx(ExcelIcon, {}), onClick: async () => {
                                    const response = await dispatch(exportActiveStockRecords({
                                        testTypeRecordIndex,
                                        type,
                                        testingDeviceId,
                                        startAt: undefined,
                                        endAt: undefined,
                                    })).unwrap();
                                    downloadFromBlob(response, "stocks.xlsx");
                                }, children: "Export file" })] })] }), _jsx(Modal, { open: editingStock.isOpen, onCancel: () => setEditingStock(INITIAL_STATE), footer: null, title: null, centered: true, width: 1000, children: _jsx(StockForm, { stockData: editingStock.data, handleSubmit: (submittedData) => {
                        onSubmit(submittedData);
                        setEditingStock(INITIAL_STATE);
                    }, requireProductName: requireProductName, isEditing: true }) })] }));
};
export default StockManagement;
const StockManagementContainer = styled.div `
  margin: 2rem 0;
`;
const HighlightedStockSummary = styled(StockSummary) `
  & div:nth-child(3) {
    background-color: #e1e2e5;
  }
`;
const TableContainer = styled.div `
  width: 100%;
  position: relative;
  margin: 1rem 0;
`;
const TableFilter = styled.div `
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
  margin-bottom: 1rem;
`;
const SizeChangerContainer = styled.div `
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-width: 5rem;
  bottom: 0.5rem;
  white-space: nowrap;
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
`;
const ExportButton = styled(Button) `
  color: #01b4d2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
const CustomMenu = styled(Menu) `
  width: 10rem;
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
`;
const WarningText = styled.p `
  color: #2a44ff;
`;
