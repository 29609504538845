import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import ComingSoonPage from "../PageErrors/ComingSoonPage";
import AccountingDashboard from "./Dashboard";
import AccountingInvoices from "./Invoices";
import AccountingITServices from "./ITServices";
import AccountingLicensing from "./Licensing";
import AccountingTesting from "./Testing";
const AccountingPage = () => (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "dashboard", replace: true }) }), _jsx(Route, { path: "dashboard", element: _jsx(AccountingDashboard, {}) }), _jsx(Route, { path: "test", element: _jsx(AccountingTesting, {}) }), _jsx(Route, { path: "licensing", element: _jsx(AccountingLicensing, {}) }), _jsx(Route, { path: "it-services", element: _jsx(AccountingITServices, {}) }), _jsx(Route, { path: "invoices", element: _jsx(AccountingInvoices, {}) }), _jsx(Route, { path: "payment", element: _jsx(ComingSoonPage, {}) })] }) }));
export default AccountingPage;
export const breadcrumbs = [
    {
        path: "/accounting",
        name: "Accounting",
        level: 1,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        level: 2,
    },
    {
        path: "/test",
        name: "Tests",
        level: 2,
    },
    {
        path: "/licensing",
        name: "Licensing",
        level: 2,
    },
    {
        path: "/it-services",
        name: "IT Services",
        level: 2,
    },
    {
        path: "/invoices",
        name: "Invoices",
        level: 2,
    },
    {
        path: "/payment",
        name: "Payment",
        level: 2,
    },
];
