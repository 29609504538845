import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { uuidv4 } from "@antv/xflow-core";
import { Button, Divider, Form, Input, message, Space, Table, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import { MailOutlined } from "@ant-design/icons";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectIsIncludedGst } from "../../Dashboard/store/selector";
import { selectDisplayCost, selectITServiceInvoice } from "../store/selector";
import { requestNewService, getCurrentITServiceInvoice } from "../store/action";
import { ITServiceError } from "../store/types";
const PlatformTrainingBox = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const displayCost = useAppSelector(selectDisplayCost);
    const invoice = useAppSelector(selectITServiceInvoice);
    const isIncludedGst = useAppSelector(selectIsIncludedGst);
    const { platformTraining, startAt } = invoice;
    if (!platformTraining?.acceptedAt)
        return (_jsxs(PackageContentRow, { children: [_jsxs(SAColumn, { md: 24, lg: 4, align: "start", children: [_jsx(BoxHeading, { children: "SA Platform Training" }), _jsx(Typography.Text, { children: "Initial Training" })] }), _jsx(SAColumn, { md: 24, lg: 16, children: _jsx(Input.TextArea, { readOnly: true, value: "Sample Assist offers a range of Platform Training Packages. Please contact your Sample Assist Account Manager.", rows: 6 }) }), _jsx(SAColumn, { md: 24, lg: 4, children: _jsxs(Button, { type: "primary", onClick: async () => {
                            const updateResult = await dispatch(requestNewService({
                                productId: platformTraining?.productId || "",
                            })).unwrap();
                            const { error } = updateResult;
                            if (error) {
                                message.error(error === ITServiceError.EFB001
                                    ? updateResult.message
                                    : "There was an error requesting the new service");
                                return;
                            }
                            message.success("Service is requested successfully");
                            await dispatch(getCurrentITServiceInvoice());
                        }, children: ["Request ", _jsx(MailOutlined, {})] }) })] }));
    return (_jsxs(PackageContentRow, { children: [_jsxs(SAColumn, { md: 24, lg: 4, align: "start", children: [_jsx(BoxHeading, { children: "SA Platform Training" }), _jsx(Typography.Text, { children: "Initial Training" })] }), _jsx(SAColumn, { md: 24, lg: 20, children: _jsx(CustomForm, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, colon: false, children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 16, children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 24, children: _jsx(SAFormItem, { label: "Platform Training Package Name", children: _jsx(Input, { readOnly: true, value: platformTraining.name }) }) }), _jsx(SAColumn, { span: 24, children: _jsx(SAFormItem, { label: "Description", children: _jsx(Input.TextArea, { readOnly: true, value: platformTraining.description, rows: 6 }) }) })] }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsxs(PriceBox, { children: [_jsxs(PriceText, { span: 21, children: [" $ ", platformTraining[displayCost]] }), _jsxs(GSTText, { span: 3, children: [" GST ", isIncludedGst ? "inclusive" : "exclusive"] })] }) }), _jsx(SAColumn, { span: 24, align: "end", children: _jsxs(Space, { children: [_jsxs(Typography.Text, { children: ["Invoice Number: ", invoice.invoiceReference] }), _jsxs(Typography.Text, { children: ["Payment Due Date: ", invoice.dueAt] })] }) }), _jsx(SAColumn, { span: 24, children: _jsx(Divider, {}) }), _jsx(SAColumn, { span: 24, children: _jsxs(PackageTable, { dataSource: [
                                        {
                                            date: startAt,
                                            description: "Platform Training Package Cost",
                                            unitCost: platformTraining.unitCost,
                                            total: platformTraining.unitCostIncludingGST,
                                        },
                                    ]?.map((entry) => ({ ...entry, id: uuidv4() })) ?? [], rowKey: "id", pagination: false, children: [_jsx(Table.Column, { title: "Date", dataIndex: "date" }, "date"), _jsx(Table.Column, { title: "Description", dataIndex: "description" }, "description"), _jsx(Table.Column, { title: "Unit Cost", dataIndex: "unitCost", render: (unitCost) => `$ ${unitCost}` }, "unitCost"), _jsx(Table.Column, { title: "GST", render: () => "10%" }), _jsx(Table.Column, { title: "Total", dataIndex: "total", render: (total) => `$ ${total}` }, "total")] }) })] }) }) })] }));
};
export default PlatformTrainingBox;
const PackageContentRow = styled(SARow) `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 2rem 1.5rem;
  width: 100%;
  justify-content: space-between;
  margin-block: 2rem;
`;
const CustomForm = styled(Form) `
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #00004c;
`;
const PriceBox = styled(SARow) `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #f7f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled(SAColumn) `
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  text-align: center;
  width: 100%;
`;
const GSTText = styled(PriceText) `
  font-size: 0.75rem;
  white-space: break-spaces;
  font-weight: 500;
`;
const PackageTable = styled(Table) `
  width: 100%;
`;
