import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Modal, Space, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import { CalendarOutlined } from "@ant-design/icons";
import { uniqueId } from "lodash";
import ICInfo from "src/assets/svg/accounting/ic-info.svg";
import SARow from "src/components/Form/Table/SARow";
import SAColumn from "src/components/Form/Table/SAColumn";
const LicensePriceCard = ({ day, price }) => (_jsxs(PriceContainer, { children: [_jsxs(GradientTopSection, { children: [_jsx(Icon, {}), _jsx(DayText, { children: day }), _jsxs(Typography.Text, { children: ["day", day > 1 ? "s" : ""] })] }), _jsxs(BottomSection, { children: [_jsxs(Typography.Title, { level: 4, children: ["$ ", price.toFixed(2)] }), _jsx(Typography.Text, { children: "/ License" })] })] }));
const LicenseDurationRange = [1, 14, 30, 365];
const EstimateLicensePriceModal = ({ isOpen, setOpen, unitCost, isIncludedGst, productName, }) => {
    const licensePricesByDuration = LicenseDurationRange.map((day) => ({
        price: day * Number(unitCost),
        day,
    }));
    return (_jsx(StyledLicenseModal, { open: isOpen, onCancel: () => setOpen(false), footer: null, title: null, centered: true, width: 1000, children: _jsx(StyledModalContent, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, align: "start", children: _jsxs(Space, { children: [_jsx(Button, { type: "text", icon: _jsx(ICInfo, {}) }), _jsx(Typography.Text, { children: productName })] }) }), _jsx(SAColumn, { span: 12, align: "end", children: _jsx(Typography.Text, { children: "One License Cost per Day (s)" }) }), _jsx(SAColumn, { span: 24, children: _jsx(Divider, {}) }), _jsx(SAColumn, { span: 24, align: "end", children: _jsxs(Typography.Text, { children: ["GST ", isIncludedGst ? "Inclusive" : "Exclusive"] }) }), licensePricesByDuration.map((priceDay) => (_jsx(SAColumn, { md: 12, lg: 6, children: _jsx(LicensePriceCard, { price: priceDay.price, day: priceDay.day }) }, uniqueId())))] }) }) }));
};
export default EstimateLicensePriceModal;
const StyledLicenseModal = styled(Modal) `
  .ant-modal-content {
    padding: 1rem;
  }
`;
const StyledModalContent = styled.div `
  display: flex;
  flex-direction: column;
`;
const PriceContainer = styled.div `
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5.31px 1.33px #0000002b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
`;
const GradientTopSection = styled.div `
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: none;
  position: relative;
  background: linear-gradient(220.27deg, #156cc9 23.91%, #959fff 95.9%);
  padding: 1rem;

  span {
    color: #ffffff;
  }
`;
const Icon = styled(CalendarOutlined) `
  font-size: 5rem;
  color: #ffffff;
`;
const DayText = styled.span `
  font-size: 1.75rem;
  margin: 0 auto;
  position: absolute;
  color: #ffffff;
`;
const BottomSection = styled.div `
  width: 100%;
  border: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
`;
