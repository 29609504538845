import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image, Typography } from "antd";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
const { Text } = Typography;
import { AddKioskIcon, EntryManagementIcon, KioskManagementIcon, KioskMessageIcon, ResourceIcon, } from "src/assets/svg/welcome-assist";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import { routes } from "..";
const WelcomeAssistHomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return (_jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 2, hiddenTab: true, children: _jsx(OutlineLayout, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { lg: 12, md: 24, children: _jsxs(InlineLayout, { children: [_jsxs(CardContainer, { onClick: () => navigate(location.pathname + "/kiosk-management/manage"), children: [_jsx(AddKioskIcon, {}), _jsx(TitleTextBox, { children: "Add Kiosk" })] }), _jsxs(CardContainer, { onClick: () => navigate(location.pathname + "/kiosk-management"), children: [_jsx(KioskManagementIcon, {}), _jsx(TitleTextBox, { children: "Kiosk Management" })] }), _jsxs(CardContainer, { onClick: () => navigate(location.pathname + "/kiosk-messages"), children: [_jsx(KioskMessageIcon, {}), _jsx(TitleTextBox, { children: "Kiosk Messages" })] }), _jsxs(CardContainer, { onClick: () => navigate(location.pathname + "/resources"), children: [_jsx(ResourceIcon, {}), _jsx(TitleTextBox, { children: "Resources" })] }), _jsxs(CardContainer, { onClick: () => navigate(location.pathname + "/entry-management"), children: [_jsx(EntryManagementIcon, {}), _jsx(TitleTextBox, { children: "Entry Management" })] })] }) }), _jsx(SAColumn, { lg: 12, md: 24, children: _jsx(InlineLayout, { children: _jsx(StyledImage, { src: "/assets/images/welcome-ipad-example.png", alt: "iPad Example", preview: false }) }) })] }) }) }));
};
export default WelcomeAssistHomePage;
const StyledImage = styled(Image) `
  width: 100%;
  height: auto;
  max-width: 600px;
  min-width: 257px;
  min-height: 355px;
  object-fit: cover;
`;
const TitleTextBox = styled(Text) `
  font-size: 16px;
  font-weight: 700;
  color: #000;
  text-align: left;
  flex: 1;
  display: flex;
`;
const CardContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 5rem;
  background-color: #edeef0;
  border-radius: 8px;
  cursor: pointer;
  margin: 8px;
`;
const InlineLayout = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;
`;
const OutlineLayout = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
`;
