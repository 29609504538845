import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Drawer, Form, Input, Row, Typography } from "antd";
import styled from "styled-components";
import { isEqual } from "lodash";
import { Link } from "react-router-dom";
import { supportingColours, validateMessage } from "src/constants";
import { createServiceOffice, getOrganizationDetail, updateServiceOffice, } from "src/pages/CollectionOrganization/ServiceOffice/store/action";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectedServiceOffice, selectIsOpen, } from "src/pages/CollectionOrganization/ServiceOffice/store/selector";
import { setIsOpen, setResetSelectedServiceOffice, } from "src/pages/CollectionOrganization/ServiceOffice/store/slice";
import { setIsLoading } from "src/store/app/slice";
import { ICTimeZone } from "src/assets/svg/collection-organization";
import { Status } from "src/pages/CollectionOrganization/Laboratory/store/types";
const AddNewServiceOffice = () => {
    const dispatch = useAppDispatch();
    const [isFormValuesChange, setIsFormValuesChange] = useState(false);
    const [form] = Form.useForm();
    const serviceOffice = useAppSelector(selectedServiceOffice);
    const isOpen = useAppSelector(selectIsOpen);
    const getData = async () => {
        form.setFieldsValue(serviceOffice);
        setIsFormValuesChange(true);
    };
    useEffect(() => {
        getData();
    }, [serviceOffice, isOpen]);
    const onCancel = () => {
        form.resetFields();
        form.setFieldsValue(serviceOffice);
        setIsFormValuesChange(true);
    };
    const onSave = async () => {
        const serviceOfficeDataList = form.getFieldsValue();
        const updateData = [{ ...serviceOfficeDataList, id: serviceOffice.id }];
        const result = await dispatch(serviceOffice.id ? updateServiceOffice(updateData) : createServiceOffice(updateData));
        const payload = result.payload;
        if (payload.status !== Status.SUCCESS) {
            return;
        }
        form.resetFields();
        dispatch(setIsOpen(false));
        dispatch(setResetSelectedServiceOffice());
        dispatch(setIsLoading(true));
        await dispatch(getOrganizationDetail({}));
        dispatch(setIsLoading(false));
        return;
    };
    const onValuesChange = (changedValues) => {
        const isChanged = isEqual(serviceOffice, { ...serviceOffice, ...changedValues });
        setIsFormValuesChange(isChanged);
    };
    return (_jsx(StyledDrawer, { title: "Service Office | Clinic", onClose: () => {
            dispatch(setResetSelectedServiceOffice());
            dispatch(setIsOpen(false));
        }, open: isOpen, width: "50%", children: _jsx(Form, { layout: "vertical", form: form, onValuesChange: onValuesChange, validateMessages: validateMessage, children: _jsxs(Row, { gutter: [0, 20], children: [_jsx(Col, { span: 24, children: _jsxs(Row, { justify: "end", gutter: [5, 0], children: [_jsx(Col, { children: _jsx(Button, { danger: true, onClick: onCancel, children: "Cancel" }) }), _jsx(Col, { children: _jsx(Form.Item, { children: _jsx(Button, { type: "primary", onClick: onSave, disabled: isFormValuesChange, htmlType: "submit", children: "Save" }) }) })] }) }), _jsxs(Col, { span: 24, children: [_jsx(Row, { justify: "start", children: _jsx(Typography, { children: "Service Office | Clinic" }) }), _jsx(StyledDivider, {}), _jsx(StyledFormItem, { name: "collectionPointName", rules: [
                                    {
                                        required: true,
                                        message: "Please enter a service office name",
                                    },
                                ], children: _jsx(Input, {}) })] }), _jsxs(Col, { span: 24, children: [_jsx(Row, { justify: "start", children: _jsx(Typography, { children: "Contact Details" }) }), _jsx(StyledDivider, {}), _jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { span: 12, children: _jsx(StyledFormItem, { label: "Email Address", name: "email", rules: [
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message: "Please enter a valid email address",
                                                },
                                            ], children: _jsx(Input, {}) }) }), _jsx(Col, { span: 12, children: _jsx(StyledFormItem, { label: "Urgent Contact (Mobile Preferred)", name: "urgentContact", children: _jsx(Input, {}) }) }), _jsx(Col, { span: 12, children: _jsx(StyledFormItem, { label: "Phone Number (Landline)", name: "phoneNumber", rules: [
                                                {
                                                    required: true,
                                                    pattern: new RegExp("^[0-9]*$"),
                                                    message: "Please enter a valid phone number",
                                                },
                                            ], children: _jsx(Input, {}) }) }), _jsx(Col, { span: 12, children: _jsx(StyledFormItem, { label: "Fax", name: "fax", children: _jsx(Input, {}) }) })] })] }), _jsxs(Col, { span: 24, children: [_jsxs(Row, { justify: "space-between", align: "middle", children: [_jsx(Typography, { children: "Address" }), _jsx(StyledFormItem, { name: "country", initialValue: "Australia", children: _jsx(CountryInput, { type: "text", bordered: false, readOnly: true }) })] }), _jsx(StyledDivider, {}), _jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { span: 12, children: _jsx(StyledFormItem, { label: "Address Line 1", name: "address1", rules: [
                                                {
                                                    required: true,
                                                    message: "Please enter an address 1",
                                                },
                                            ], children: _jsx(Input, {}) }) }), _jsx(Col, { span: 12, children: _jsx(StyledFormItem, { label: "Address Line 2", name: "address2", children: _jsx(Input, {}) }) }), _jsx(Col, { span: 12, children: _jsx(StyledFormItem, { label: "City/Suburb", name: "suburb", rules: [
                                                {
                                                    required: true,
                                                    message: "Please enter a city/suburb",
                                                },
                                            ], children: _jsx(Input, {}) }) }), _jsx(Col, { span: 6, children: _jsx(StyledFormItem, { label: "State", name: "state", rules: [
                                                {
                                                    required: true,
                                                    message: "Please enter a state",
                                                },
                                            ], children: _jsx(Input, {}) }) }), _jsx(Col, { span: 6, children: _jsx(StyledFormItem, { label: "Postcode", name: "postcode", rules: [
                                                {
                                                    required: true,
                                                    pattern: new RegExp("^[0-9]*$"),
                                                    message: "Please enter a valid postcode",
                                                },
                                            ], children: _jsx(Input, {}) }) })] })] }), _jsx(Col, { span: 24, children: _jsxs(Row, { justify: "space-between", align: "middle", gutter: [5, 0], children: [_jsx(Col, { span: 4, children: _jsxs(Row, { justify: "space-between", children: [_jsx(ICTimeZone, {}), _jsx(Typography, { children: "Time Zone" })] }) }), _jsx(Col, { span: 20, children: _jsx(StyledFormItem, { name: "timezone", children: _jsx(Input, { placeholder: "The time zone will be automatically detected.", readOnly: true }) }) })] }) }), _jsx(Col, { span: 24, children: _jsx(Row, { justify: "end", children: _jsxs(Typography, { children: ["You can adjust this in the ", _jsx(Link, { to: "/settings/", children: "Settings" }), " if needed."] }) }) })] }) }) }, serviceOffice.id));
};
export default AddNewServiceOffice;
const StyledDivider = styled(Divider) `
  margin: 0px 0px 5px 0px;
`;
const StyledDrawer = styled(Drawer) `
  text-align: center;
`;
const StyledFormItem = styled(Form.Item) `
  margin: 0px;
  .ant-form-item-label {
    padding: 0px;
  }

  label {
    color: ${supportingColours.TEXT_LIGHT_GRAY};
    font-size: 11px;
  }
`;
const CountryInput = styled(Input) `
  text-align: end;
`;
