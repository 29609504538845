import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, Form, message, Select, Space, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { InfoIcon } from "src/assets/svg/accounting";
import { renewSubscriptionWithNewPlan } from "../store/action";
import { selectDisplayCost, selectLicensingInvoice, selectIsIncludedGst } from "../store/selector";
import { LicenseError } from "../store/types";
import EstimateSubscriptionPriceModal from "./EstimateSubscriptionPriceModal";
const ReportAssistBox = ({ plans }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const displayCost = useAppSelector(selectDisplayCost);
    const invoice = useAppSelector(selectLicensingInvoice);
    const isIncludedGst = useAppSelector(selectIsIncludedGst);
    const { reportAssist, endAt, startAt } = invoice;
    const [selectedPlan, setSelectedPlan] = React.useState(reportAssist.planId);
    const [totalCost, setTotalCost] = React.useState(0);
    const [refresh, setRefresh] = React.useState(false);
    const [displayMessage, setDisplayMessage] = React.useState("");
    const [isOpenPriceInfo, setIsOpenPriceInfo] = React.useState(false);
    const estimateSubscriptionTotalCost = (unitCost) => {
        const durationInDays = moment(endAt, "DD MMMM YYYY").diff(moment(startAt, "DD MMMM YYYY"), "days");
        return unitCost * durationInDays || 0;
    };
    React.useEffect(() => {
        if (!reportAssist || !reportAssist.planId)
            return;
        const subscriptionTotalCost = estimateSubscriptionTotalCost(Number(reportAssist[displayCost]));
        setTotalCost(subscriptionTotalCost);
        setSelectedPlan(reportAssist.planId);
    }, [reportAssist, displayCost, refresh]);
    React.useEffect(() => {
        const selectingPlan = plans?.find((p) => p.planId === selectedPlan);
        if (selectingPlan) {
            const subscriptionTotalCost = estimateSubscriptionTotalCost(Number(selectingPlan[displayCost]));
            setTotalCost(subscriptionTotalCost);
        }
    }, [selectedPlan]);
    return (_jsxs(PackageContentRow, { children: [_jsxs(SAColumn, { md: 24, lg: 4, align: "start", children: [_jsx(BoxHeading, { children: "Report Assist" }), _jsx(Typography.Text, { children: "Digital Client Reporting" })] }), _jsx(SAColumn, { md: 24, lg: 20, children: _jsx(CustomForm, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, colon: false, children: _jsxs(SARow, { children: [_jsx(SAColumn, { md: 12, lg: 8, children: _jsx(SAFormItem, { label: "Current Subscription", children: _jsx(Select, { options: plans, fieldNames: { label: "name", value: "planId" }, value: selectedPlan, onChange: (planId) => {
                                            setSelectedPlan(planId);
                                            setDisplayMessage("");
                                        } }) }) }), _jsxs(SAColumn, { md: 12, lg: 8, justify: "end", children: [_jsxs(Space, { children: [_jsx(Typography.Text, { children: "Cost per Day" }), _jsxs(Typography.Text, { children: ["$ ", reportAssist[displayCost]] }), _jsx(Button, { type: "text", icon: _jsx(InfoIcon, {}), onClick: () => setIsOpenPriceInfo(true) })] }), _jsx(Divider, {})] }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsxs(PriceBox, { children: [_jsxs(PriceText, { span: 21, children: [" $ ", totalCost.toFixed(2)] }), _jsxs(GSTText, { span: 3, children: [" GST ", isIncludedGst ? "inclusive" : "exclusive"] })] }) }), _jsx(SAColumn, { span: 24, children: _jsx(Divider, {}) }), _jsx(SAColumn, { span: 18, align: "start", children: displayMessage ? _jsx(Typography.Text, { children: displayMessage }) : _jsx(_Fragment, {}) }), _jsx(SAColumn, { span: 6, align: "end", children: selectedPlan !== reportAssist.planId && (_jsxs(Space, { children: [_jsx(Button, { type: "primary", onClick: async () => {
                                                const updateResult = await dispatch(renewSubscriptionWithNewPlan({
                                                    subscriptionId: reportAssist.subscriptionId,
                                                    planId: selectedPlan,
                                                })).unwrap();
                                                const { error } = updateResult;
                                                if (error) {
                                                    message.error(error === LicenseError.EFB001
                                                        ? updateResult.message
                                                        : "There was an error updating the subscription");
                                                    return;
                                                }
                                                message.success("Subscription updated successfully");
                                                if (updateResult.message) {
                                                    setDisplayMessage(updateResult.message);
                                                }
                                                setRefresh(!refresh);
                                            }, children: "Update" }), _jsx(Button, { type: "primary", ghost: true, onClick: () => setRefresh(!refresh), children: "Cancel" })] })) })] }) }) }), _jsx(EstimateSubscriptionPriceModal, { isOpen: isOpenPriceInfo, setOpen: setIsOpenPriceInfo, displayCost: displayCost, isIncludedGst: isIncludedGst, plans: plans, productName: "Report Assist" })] }));
};
export default ReportAssistBox;
const PackageContentRow = styled(SARow) `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 2rem 1.5rem;
  width: 100%;
  justify-content: space-between;
  margin-block: 2rem;
`;
const CustomForm = styled(Form) `
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: 100%;
  width: 100%;

  input {
    text-align: center;
    padding: 0.5rem 0;
  }
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #00004c;
`;
const PriceBox = styled(SARow) `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #f7f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled(SAColumn) `
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  text-align: center;
  width: 100%;
`;
const GSTText = styled(PriceText) `
  font-size: 0.75rem;
  white-space: break-spaces;
  font-weight: 500;
`;
