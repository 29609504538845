import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Image } from "antd";
import { useState } from "react";
import { setBarcodeData } from "src/api/inventory/inventoryService";
import { SAColourScheme } from "src/constants";
import { QuaggaCode } from "./QuaggaCode";
export const Scanner = ({ connectedRecordId }) => {
    const [isScanComplete, setIsScanComplete] = useState(false);
    const handleDetect = async (result) => {
        if (!result)
            return;
        const { code, format } = result.codeResult;
        await setBarcodeData(connectedRecordId, code, format);
        setIsScanComplete(true);
    };
    return (_jsxs(_Fragment, { children: [!isScanComplete && (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                        }, children: _jsx(Image, { src: "/assets/svg/inventory/satchel-device.svg", preview: false, height: 155, width: 120 }) }), _jsx("div", { style: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                        }, children: _jsx(QuaggaCode, { onDetected: handleDetect, isScanning: !isScanComplete }) })] })), isScanComplete && (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                        }, children: _jsx(Image, { src: "/assets/svg/inventory/scan-person-image.svg", preview: false, height: 180, width: 250 }) }), _jsx("div", { style: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                        }, children: _jsx("p", { style: { color: "#151349", fontSize: "20px" }, children: "Your scan is complete." }) }), _jsx("div", { style: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "50px",
                        }, children: _jsx(Button, { style: {
                                background: "#1A8CFF",
                                color: SAColourScheme.WHITE,
                                border: "1px solid white",
                                borderRadius: "5px",
                                marginLeft: "10px",
                                width: "150px",
                            }, onClick: () => {
                                setIsScanComplete(false);
                            }, children: "Scan Again" }) })] }))] }));
};
