var PackageType;
(function (PackageType) {
    PackageType[PackageType["DrugAlcoholTests"] = 1] = "DrugAlcoholTests";
    PackageType[PackageType["RequestedLabTests"] = 2] = "RequestedLabTests";
    PackageType[PackageType["CollectionManager"] = 3] = "CollectionManager";
    PackageType[PackageType["CollectionManagerMobile"] = 4] = "CollectionManagerMobile";
    PackageType[PackageType["CollectionAssist"] = 5] = "CollectionAssist";
    PackageType[PackageType["SecureAssist"] = 6] = "SecureAssist";
    PackageType[PackageType["ReportAssist"] = 7] = "ReportAssist";
    PackageType[PackageType["WorkAssist"] = 8] = "WorkAssist";
    PackageType[PackageType["WelcomeAssist"] = 9] = "WelcomeAssist";
    PackageType[PackageType["Support"] = 10] = "Support";
    PackageType[PackageType["Training"] = 11] = "Training";
    PackageType[PackageType["SoftwareDevelopment"] = 12] = "SoftwareDevelopment";
    PackageType[PackageType["SAServiceEstablishment"] = 13] = "SAServiceEstablishment";
    PackageType[PackageType["SAPlatformTraining"] = 14] = "SAPlatformTraining";
})(PackageType || (PackageType = {}));
var PRODUCT_TYPE;
(function (PRODUCT_TYPE) {
    PRODUCT_TYPE[PRODUCT_TYPE["DRUG_ALCOHOL_ONSITE"] = 1] = "DRUG_ALCOHOL_ONSITE";
    PRODUCT_TYPE[PRODUCT_TYPE["DRUG_ALCOHOL_INCLINIC"] = 2] = "DRUG_ALCOHOL_INCLINIC";
    PRODUCT_TYPE[PRODUCT_TYPE["LAB_ONSITE"] = 3] = "LAB_ONSITE";
    PRODUCT_TYPE[PRODUCT_TYPE["LAB_INCLINIC"] = 4] = "LAB_INCLINIC";
    PRODUCT_TYPE[PRODUCT_TYPE["COLLECTION_MANAGER"] = 5] = "COLLECTION_MANAGER";
    PRODUCT_TYPE[PRODUCT_TYPE["COLLECTION_MANAGER_MOBILE"] = 6] = "COLLECTION_MANAGER_MOBILE";
    PRODUCT_TYPE[PRODUCT_TYPE["COLLECT_ASSIST"] = 7] = "COLLECT_ASSIST";
    PRODUCT_TYPE[PRODUCT_TYPE["WELCOME_ASSIST"] = 8] = "WELCOME_ASSIST";
    PRODUCT_TYPE[PRODUCT_TYPE["SECURE_ASSIST"] = 9] = "SECURE_ASSIST";
    PRODUCT_TYPE[PRODUCT_TYPE["REPORT_ASSIST"] = 10] = "REPORT_ASSIST";
    PRODUCT_TYPE[PRODUCT_TYPE["WORK_ASSIST"] = 11] = "WORK_ASSIST";
    PRODUCT_TYPE[PRODUCT_TYPE["SUPPORT"] = 12] = "SUPPORT";
    PRODUCT_TYPE[PRODUCT_TYPE["SA_SERVICE_ESTABLISHMENT"] = 13] = "SA_SERVICE_ESTABLISHMENT";
    PRODUCT_TYPE[PRODUCT_TYPE["SA_PLATFORM_TRAINING"] = 14] = "SA_PLATFORM_TRAINING";
    PRODUCT_TYPE[PRODUCT_TYPE["TRAINING"] = 15] = "TRAINING";
    PRODUCT_TYPE[PRODUCT_TYPE["SOFTWARE_DEVELOPMENT"] = 16] = "SOFTWARE_DEVELOPMENT";
})(PRODUCT_TYPE || (PRODUCT_TYPE = {}));
var SUBSCRIPTION_PLAN;
(function (SUBSCRIPTION_PLAN) {
    SUBSCRIPTION_PLAN[SUBSCRIPTION_PLAN["NONE"] = 1] = "NONE";
    SUBSCRIPTION_PLAN[SUBSCRIPTION_PLAN["BRONZE"] = 2] = "BRONZE";
    SUBSCRIPTION_PLAN[SUBSCRIPTION_PLAN["SILVER"] = 3] = "SILVER";
    SUBSCRIPTION_PLAN[SUBSCRIPTION_PLAN["GOLD"] = 4] = "GOLD";
    SUBSCRIPTION_PLAN[SUBSCRIPTION_PLAN["PLATINUM"] = 5] = "PLATINUM";
    SUBSCRIPTION_PLAN[SUBSCRIPTION_PLAN["BESPOKE"] = 6] = "BESPOKE";
})(SUBSCRIPTION_PLAN || (SUBSCRIPTION_PLAN = {}));
const SubscriptionPlan = {
    [SUBSCRIPTION_PLAN.NONE]: "",
    [SUBSCRIPTION_PLAN.BRONZE]: "BRONZE",
    [SUBSCRIPTION_PLAN.SILVER]: "SILVER",
    [SUBSCRIPTION_PLAN.GOLD]: "GOLD",
    [SUBSCRIPTION_PLAN.PLATINUM]: "PLATINUM",
    [SUBSCRIPTION_PLAN.BESPOKE]: "BESPOKE",
};
export { PackageType, SubscriptionPlan, SUBSCRIPTION_PLAN, PRODUCT_TYPE };
