import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LeftOutlined, PrinterOutlined, RightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, Switch, Typography } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getListOnsiteJobs } from "../../store/action";
import { selectCollectionPoint, selectCurrentDate, selectCurrentView, selectListCollectionPoints, } from "../../store/selector";
import { setCollectionPoint, setCurrentDate, setCurrentView, setIsExpandOnsiteView, } from "../../store/slice";
import { CalendarView, CalendarViewLabelList } from "../../typing";
const Toolbar = () => {
    const dispatch = useAppDispatch();
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    const currentCollectionPoint = useAppSelector(selectCollectionPoint);
    const currentDate = useAppSelector(selectCurrentDate);
    const currentView = useAppSelector(selectCurrentView);
    const getCalendarDateFormat = (date) => {
        switch (currentView) {
            case CalendarView.Day: {
                return date.format(`dd, DD MMM YYYY`);
            }
            case CalendarView.Week: {
                const startOfWeek = date.startOf("week");
                return `${startOfWeek.format("DD MMM")} - ${startOfWeek.add(6, "days").format("DD MMM")} ${date.format("YYYY")}`;
            }
            case CalendarView.Month: {
                return date.format(`MMMM YYYY`);
            }
        }
    };
    return (_jsx(CalendarToolbarWrapper, { children: _jsxs(NavigationBar, { children: [_jsx(SAColumn, { xl: 6, lg: 12, md: 24, align: "flex-start", children: _jsxs(NavigationDate, { children: [_jsx(Button, { onClick: () => {
                                    dispatch(setCurrentDate(moment(currentDate).subtract(1, currentView).toISOString()));
                                }, children: _jsx(LeftOutlined, {}) }), _jsx(TitleBox, { format: getCalendarDateFormat, value: moment(currentDate), picker: currentView === CalendarView.Day ? undefined : currentView, suffixIcon: null, clearIcon: null, onChange: (date) => {
                                    if (!date)
                                        return;
                                    dispatch(setCurrentDate(date.toISOString()));
                                } }), _jsx(Button, { onClick: () => {
                                    dispatch(setCurrentDate(moment(currentDate).add(1, currentView).toISOString()));
                                }, children: _jsx(RightOutlined, {}) })] }) }), _jsx(SAColumn, { xl: 2, lg: 12, md: 24, align: "flex-start", children: _jsx(CalendarViewOption, { value: currentView, options: CalendarViewLabelList, onChange: (value) => {
                            dispatch(setCurrentView(value));
                            if (!currentCollectionPoint.value)
                                return;
                            dispatch(getListOnsiteJobs());
                        } }) }), _jsx(SAColumn, { xl: 2, lg: 12, md: 24, align: "flex-start", children: _jsx(Button, { onClick: () => {
                            dispatch(setCurrentDate(moment().toISOString()));
                            dispatch(setCurrentView(CalendarView.Day));
                        }, children: "Today" }) }), _jsx(SAColumn, { xl: 6, lg: 12, md: 24, align: "flex-start", children: _jsx(Select, { placeholder: "Please select service office", value: currentCollectionPoint.name, options: collectionPoints, onChange: async (_, option) => {
                            dispatch(setIsLoading(true));
                            const selectedValue = option;
                            await dispatch(setCollectionPoint({
                                id: selectedValue.name,
                                collectionPointName: selectedValue.value,
                            }));
                            await dispatch(getListOnsiteJobs());
                            dispatch(setIsLoading(false));
                        } }) }), _jsx(SAColumn, { xl: 2, lg: 12, md: 24, align: "flex-start", children: _jsx(Button, { children: _jsx(PrinterIcon, {}) }) }), _jsxs(SAColumn, { xl: 4, lg: 12, md: 24, direction: "row", align: "flex-start", children: [_jsx(Switch, { onClick: (prev) => dispatch(setIsExpandOnsiteView(prev)) }), _jsx(DisplayOnsiteText, { children: "Display On-Site" })] })] }) }));
};
export default Toolbar;
const CalendarToolbarWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  max-height: 25rem;
  margin-bottom: 1rem;
`;
const NavigationBar = styled(SARow) `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
`;
const NavigationDate = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  width: 100%;
`;
const CalendarViewOption = styled(Select) `
  width: 100%;
  color: #fff;

  > div {
    background: #1a8cff !important;
  }

  > span {
    color: #fff;
  }
`;
const PrinterIcon = styled(PrinterOutlined) `
  font-size: 1.2rem;
`;
const DisplayOnsiteText = styled(Typography.Text) `
  margin-left: 1rem;
  width: 100%;
`;
const TitleBox = styled(DatePicker) `
  width: 100%;
  background: #ffffff;
  border-radius: 0;
  height: 100%;

  .ant-picker-input {
    input {
      text-align: center;
    }
  }
`;
