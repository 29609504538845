import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dropdown, Menu, Space } from "antd";
import React, { useContext } from "react";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MESSAGE_BOX_TYPE, mainAppColours } from "src/constants";
import ContentContext from "src/providers/content";
import { useSettings } from "src/providers/SettingsContextProvider";
import { getDateFormat } from "src/utils/helper";
import { DataTable } from "src/components/commonElement/DataTable";
export const SatchelsData = ({ transportSatchels, archiveSatchel }) => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { openMessageBox } = useContext(ContentContext);
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Barcode Number",
            dataIndex: "barcodeNumber",
            key: "barcodeNumber",
            sorter: (a, b) => a.barcodeNumber.localeCompare(b.barcodeNumber),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(Space, { children: _jsx("a", { onClick: () => navigate(`/inventory/add`, { state: record }), style: { color: "#2A44FF", textDecoration: "underline" }, children: text }) })),
        },
        {
            title: "Commission Date",
            dataIndex: "commissionDate",
            key: "commissionDate",
            sorter: (a, b) => a.commissionDate.localeCompare(b.commissionDate),
            render: (text, record) => {
                const { commissionDate } = record;
                let formattedDate = "";
                if (commissionDate !== "") {
                    formattedDate = getDateFormat(new Date(commissionDate), settings);
                }
                return _jsx(Space, { children: formattedDate });
            },
        },
        {
            title: "Status",
            dataIndex: "",
            key: "",
            sorter: (a, b) => {
                const getStatusOrder = (record) => {
                    if (record.inStock) {
                        return 1;
                    }
                    else if (record.inService) {
                        return 2;
                    }
                    else if (record.outOfService) {
                        return 3;
                    }
                    else {
                        return 4;
                    }
                };
                return getStatusOrder(a) - getStatusOrder(b);
            },
            render: (record) => {
                const { inStock, inService, outOfService } = record;
                if (inStock) {
                    return "In Stock";
                }
                else if (inService) {
                    return "In Service";
                }
                else if (outOfService) {
                    return "Out of Service";
                }
                else {
                    return (_jsx(_Fragment, { children: _jsx("div", { style: {
                                width: "90px",
                                height: "25px",
                                background: "#FD0000",
                                fontSize: "13px",
                                color: "#FFFFFF",
                                paddingTop: "2px",
                                borderRadius: "15px",
                                textAlign: "center",
                            }, children: "Incomplete" }) }));
                }
            },
        },
        {
            title: "Days in Service",
            dataIndex: "daysInService",
            key: "daysInService",
            sorter: false,
            render: (text, record) => (_jsx(Space, { children: record.commissionDate ? moment().diff(moment(record.commissionDate), "days") : 0 })),
        },
        {
            title: "Issues",
            dataIndex: "securityIssues",
            key: "securityIssues",
            sorter: (a, b) => a.securityIssues - b.securityIssues,
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "Location",
            dataIndex: "region",
            key: "region",
            sorter: (a, b) => a.region.localeCompare(b.region),
            render: (text) => _jsx(Space, { children: text }),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => navigate(`/inventory/add`, {
                                state: record,
                            }), children: "Edit" }, "1"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, `Transport satchel with Barcode No. ${record.barcodeNumber} will be archived and no longer can be used.`, () => {
                                    archiveSatchel(record.id);
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Archive" }) }, "2")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    return (_jsx(DataTable, { columns: columns, dataSource: transportSatchels.length > 0
            ? transportSatchels.map((item) => ({ ...item, key: item.id }))
            : [] }));
};
