var ITServiceProductType;
(function (ITServiceProductType) {
    ITServiceProductType[ITServiceProductType["TRAINING"] = 1] = "TRAINING";
    ITServiceProductType[ITServiceProductType["SA_SERVICE_ESTABLISHMENT"] = 2] = "SA_SERVICE_ESTABLISHMENT";
    ITServiceProductType[ITServiceProductType["SA_PLATFORM_TRAINING"] = 3] = "SA_PLATFORM_TRAINING";
})(ITServiceProductType || (ITServiceProductType = {}));
const initialInvoice = {
    currentDate: "",
    billingCycleType: "",
    nextInvoiceIssueDate: "",
    startAt: "",
    endAt: "",
    invoiceReference: "",
    dueAt: "",
    training: null,
    serviceEstablishment: null,
    platformTraining: null,
};
var ITServiceError;
(function (ITServiceError) {
    ITServiceError["EFB001"] = "EFB001";
    ITServiceError["EFB002"] = "EFB002";
})(ITServiceError || (ITServiceError = {}));
export { initialInvoice, ITServiceError, ITServiceProductType, };
