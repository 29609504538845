import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from "antd";
import React from "react";
import styled from "styled-components";
const FormItem = ({ label, name, requiredText, children, ...rest }) => (_jsx(CustomFormItem, { label: label ? (_jsxs("span", { children: [_jsx(LabelCustom, { children: label }), " ", !!requiredText && _jsx(RequireMark, { children: "*" })] })) : (""), name: name ?? undefined, rules: [
        {
            required: !!requiredText,
            message: requiredText,
        },
    ], ...rest, children: children }));
export default FormItem;
const CustomFormItem = styled(Form.Item) `
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: "";
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  width: 100%;
`;
const RequireMark = styled.span `
  color: red;
  margin-left: 0.25rem;
`;
const LabelCustom = styled.span `
  color: #7f8e9d;
  font-size: 13px;
  font-weight: 550;
`;
