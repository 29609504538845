export const EmptyServiceOffice = {
    address1: "",
    address2: "",
    collectionOrganizationId: "",
    collectionPointName: "",
    country: "Australia",
    createdAt: "",
    doctor: "",
    email: "",
    fax: "",
    id: "",
    isValid: true,
    phoneNumber: "",
    postcode: "",
    state: "",
    suburb: "",
    timezone: "",
    updatedAt: "",
    urgentContact: "",
};
export var MessageDuration;
(function (MessageDuration) {
    MessageDuration[MessageDuration["SHORT"] = 2] = "SHORT";
    MessageDuration[MessageDuration["MEDIUM"] = 5] = "MEDIUM";
    MessageDuration[MessageDuration["LONG"] = 8] = "LONG";
})(MessageDuration || (MessageDuration = {}));
