import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { uuidv4 } from "@antv/xflow-core";
import { Space, Table } from "antd";
import React from "react";
import styled from "styled-components";
import { getApplicationInformation } from "src/store/app/selector";
import { useAppSelector } from "src/store/hooks";
import { getDateByFormat } from "src/utils";
const TestingDetailTable = ({ dataSource }) => {
    const appInformation = useAppSelector(getApplicationInformation);
    return (_jsxs(TestingTable, { dataSource: dataSource?.map((entry) => ({ ...entry, id: uuidv4() })) ?? [], rowKey: "id", children: [_jsx(Table.Column, { title: "Date", dataIndex: "startAt", sorter: true, render: (startAt) => (_jsx(Space, { children: startAt
                        ? getDateByFormat(startAt, appInformation?.collectionOrganization?.timeFormat)
                        : "" })) }, "startAt"), _jsx(Table.Column, { title: "Job Reference", dataIndex: "onsiteJobReference", sorter: true }, "onsiteJobReference"), _jsx(Table.Column, { title: "Client", dataIndex: "onsiteClientName", sorter: true }, "onsiteClientName"), _jsx(Table.Column, { title: "No. of Donors", dataIndex: "noDonors", sorter: true }, "noDonors"), _jsx(Table.Column, { title: "Unit Cost", dataIndex: "unitCost", sorter: true, render: (unitCost) => (unitCost ? `$${unitCost}` : "-") }, "unitCost"), _jsx(Table.Column, { title: "Sub Total", sorter: true, render: (_, record) => record.noDonors && record.unitCost
                    ? `$${(Number(record.noDonors) * Number(record.unitCost)).toFixed(2)}`
                    : "" }), _jsx(Table.Column, { title: "GST", sorter: true, render: (_, record) => record.unitCostIncludingGST && record.unitCost
                    ? `$${Number((record.unitCostIncludingGST - record.unitCost) * record.noDonors).toFixed(2)}`
                    : "" }), _jsx(Table.Column, { title: "Total", sorter: true, render: (_total, record) => record.noDonors && record.unitCostIncludingGST
                    ? `$${Number(record.noDonors * record.unitCostIncludingGST).toFixed(2)}`
                    : "" })] }));
};
export default TestingDetailTable;
const TestingTable = styled(Table) `
  margin: 1rem 0;
`;
