import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { createServiceOffice, deleteOrganizationServiceOffice, getOrganizationDetail, updateServiceOffice, } from "src/pages/CollectionOrganization/ServiceOffice/store/action";
import { EmptyServiceOffice, MessageDuration, } from "src/pages/CollectionOrganization/ServiceOffice/store/types";
export const initialState = {
    listServiceOffice: [],
    searchKeyWord: "",
    organizationDetail: {},
    selectedServiceOffice: EmptyServiceOffice,
    isOpen: false,
    pagingMetaData: null,
};
const serviceOfficeSlice = createSlice({
    name: "serviceOffice",
    initialState,
    reducers: {
        setResetSelectedServiceOffice(state) {
            state.selectedServiceOffice = EmptyServiceOffice;
        },
        setResetStore() {
            return initialState;
        },
        setSearchKeyWord(state, action) {
            state.searchKeyWord = action.payload;
        },
        setSelectedServiceOffice(state, action) {
            state.selectedServiceOffice = action.payload;
        },
        setIsOpen(state, action) {
            state.isOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrganizationDetail.fulfilled, (state, action) => {
            if (action.payload.status !== "success" || !action.payload.data) {
                state.listServiceOffice = [];
                state.organizationDetail = {};
            }
            else {
                state.listServiceOffice = action.payload.data.listServiceOffice;
                state.organizationDetail = action.payload.data.organizationDetail;
                state.pagingMetaData = action.payload.data.pagingMeta;
            }
        })
            .addCase(getOrganizationDetail.rejected, () => {
            message.error("Failed to fetch service office list");
        })
            .addCase(updateServiceOffice.fulfilled, (state, action) => {
            if (action.payload.status !== "success") {
                message.error("Failed to update service office", MessageDuration.SHORT);
            }
            else {
                message.success("Service office updated successfully");
            }
        })
            .addCase(updateServiceOffice.rejected, () => {
            message.error("Failed to update service office");
        })
            .addCase(createServiceOffice.fulfilled, (state, action) => {
            if (action.payload.status !== "success") {
                message.error("Failed to create service office", MessageDuration.SHORT);
            }
            else {
                message.success("Service office created successfully");
            }
        })
            .addCase(createServiceOffice.rejected, () => {
            message.error("Failed to create service office", MessageDuration.SHORT);
        })
            .addCase(deleteOrganizationServiceOffice.fulfilled, (state, action) => {
            if (action.payload.status !== "success") {
                message.error("Failed to delete service office", MessageDuration.SHORT);
            }
            else {
                message.success("Service office deleted successfully");
            }
        })
            .addCase(deleteOrganizationServiceOffice.rejected, () => {
            message.error("Failed to delete service office");
        });
    },
});
export const { setResetStore, setSearchKeyWord, setSelectedServiceOffice, setResetSelectedServiceOffice, setIsOpen, } = serviceOfficeSlice.actions;
export default serviceOfficeSlice.reducer;
