import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { CalendarView } from "../typing";
import { getCollectorCalendarEvents, getStaffCollectionPoints } from "./action";
export const initialState = {
    selectedCollectionPoint: "",
    selectedCollectorId: "",
    calendarEvents: [],
    collectionPointStaffs: [],
    staffs: [],
    currentView: CalendarView.Day,
    currentDate: new Date().toISOString(),
    fullStaffs: [],
    isListView: false,
};
const collectorCalendarSlice = createSlice({
    name: "collectorCalendar",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
        setCurrentDate(state, action) {
            state.currentDate = action.payload;
        },
        setCollectionPoint(state, action) {
            state.selectedCollectionPoint = action.payload;
            if (!state.selectedCollectionPoint) {
                state.staffs = state.fullStaffs;
                return;
            }
            state.staffs =
                state.collectionPointStaffs.find((staff) => staff.collectionPointId === state.selectedCollectionPoint)?.collectors || [];
        },
        setCollectorId(state, action) {
            state.selectedCollectorId = action.payload;
        },
        setCurrentView(state, action) {
            state.currentView = action.payload;
        },
        setIsListView(state, action) {
            state.isListView = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStaffCollectionPoints.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch staffs");
            }
            else {
                state.collectionPointStaffs = action.payload.data;
                state.fullStaffs = Array.from(new Map(state.collectionPointStaffs
                    .flatMap((staff) => staff.collectors)
                    .map((collector) => [collector.collectorId, collector])).values());
            }
        })
            .addCase(getStaffCollectionPoints.rejected, () => {
            message.error("Failed to fetch staffs");
        })
            .addCase(getCollectorCalendarEvents.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch collector calendars");
            }
            else {
                state.calendarEvents = action.payload.data;
            }
        })
            .addCase(getCollectorCalendarEvents.rejected, () => {
            message.error("Failed to fetch collector calendars");
        });
    },
});
export const { setResetStore, setCurrentDate, setCollectionPoint, setCurrentView, setCollectorId, setIsListView, } = collectorCalendarSlice.actions;
export default collectorCalendarSlice.reducer;
