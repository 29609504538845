import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Form, Input, message, Modal, Space, Typography } from "antd";
import React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { KioskManagerIcon } from "src/assets/svg/welcome-assist";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppDispatch } from "src/store/hooks";
import { getListLicenseByCollectionPoint, getManagerInformation, updateLicenseManager, } from "../../store/action";
import { WelcomeAssistError } from "../../store/types";
const EditManagerModal = ({ isOpen, setIsOpen }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("id");
    React.useEffect(() => {
        const loadData = async () => {
            if (!userId) {
                message.error("Invalid user");
                return;
            }
            const payload = await dispatch(getManagerInformation(userId)).unwrap();
            const { error, data } = payload;
            if (error) {
                message.error("Failed to fetch manager information");
                setIsOpen(false);
                return;
            }
            form.setFieldsValue({
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                mobile: data.mobile,
            });
        };
        if (isOpen)
            loadData();
    }, [isOpen]);
    return (_jsx(Modal, { open: isOpen, onCancel: () => {
            form.resetFields();
            setIsOpen(false);
            setSearchParams();
        }, footer: null, centered: true, width: "50%", title: _jsxs(Space, { children: [_jsx(KioskManagerIcon, {}), _jsx(Typography.Title, { level: 5, children: "Welcome Assist Kiosk Manager" })] }), children: _jsxs(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, onFinish: async (values) => {
                const payload = await dispatch(updateLicenseManager({ ...values, userId })).unwrap();
                const { error } = payload;
                if (error) {
                    message.error(error === WelcomeAssistError.EA001
                        ? payload.message
                        : "There is error when trying to update");
                    return;
                }
                message.success("Updated manager information successfully");
                form.resetFields();
                dispatch(getListLicenseByCollectionPoint());
                setIsOpen(false);
            }, children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { label: "First Name", name: "firstName", children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { label: "Last Name", name: "lastName", children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { label: "Email", name: "email", children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { label: "Mobile Number", name: "mobile", children: _jsx(Input, {}) }) })] }), _jsx(Divider, {}), _jsx(FooterButton, { children: _jsx(Button, { type: "primary", htmlType: "submit", children: "Update" }) })] }) }));
};
export default EditManagerModal;
const FooterButton = styled(Space) `
  display: flex;
  width: fit-content;
  margin-left: auto;
`;
