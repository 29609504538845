import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get, $post } from "src/infra/http";
import { Domain } from "src/types";
const duplicateTestingDevice = createAsyncThunk("duplicateTestingDevice", async (testingDeviceId) => await $get(`/collection-manager/testing-devices/duplicate/${testingDeviceId}`));
const archiveTestingDevice = createAsyncThunk("archiveTestingDevice", async (testingDeviceId) => await $get(`/collection-manager/testing-devices/archive/${testingDeviceId}`));
const deleteTestingDevice = createAsyncThunk("deleteTestingDevice", async (testingDeviceId) => await $get(`/collection-manager/testing-devices/delete/${testingDeviceId}`));
const restoreTestingDevice = createAsyncThunk("restoreTestingDevice", async (testingDeviceId) => await $get(`/collection-manager/testing-devices/restore/${testingDeviceId}`));
const updateInUseTestingDevice = createAsyncThunk("updateInUseTestingDevice", async (newData) => await $post(`/collection-manager/testing-devices/update-inuse/${newData.id}`, newData));
const getHistoricalStockRecords = createAsyncThunk("getHistoricalStockRecords", async (filters) => {
    const queryString = stringify(filters);
    return await $get(`/inventory/history-stock?${queryString}`, Domain.CM);
});
const exportActiveStockRecords = createAsyncThunk("exportActiveStockRecords", async (filters) => {
    const queryString = stringify(filters);
    return await $get(`/inventory/export-stock?${queryString}`, Domain.CM, {
        responseType: "blob",
    });
});
export { archiveTestingDevice, deleteTestingDevice, duplicateTestingDevice, restoreTestingDevice, updateInUseTestingDevice, getHistoricalStockRecords, exportActiveStockRecords, };
