import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from "@mui/material";
import moment from "moment";
import React, { useContext } from "react";
import styled from "styled-components";
import ICSwapButtonIcon from "src/assets/svg/ic-cm-swap-button.svg";
import { Col, Form, FormTitle, InputWithLabel, Row, Text } from "src/components/base";
import { APPOINTMENT_STATUS_NUMBER, MESSAGE_BOX_TYPE, SAColourScheme, supportingColours, } from "src/constants";
import { CreateAppointmentButton } from "src/pages/InClinicAppointmentsPage/CreateAppointmentPage/components/PatientDetailsComponents/CreateAppointmentButton";
import { ResendButton } from "src/pages/InClinicAppointmentsPage/CreateAppointmentPage/components/PatientDetailsComponents/ResendButton";
import ContentContext from "src/providers/content";
import { getApplicationInformation, getRequestedTestTypeInformation } from "src/store/app/selector";
import { TimeFormat } from "src/store/app/types";
import { useAppSelector } from "src/store/hooks";
import { AppointmentDetailWrapper } from "./AppointmentDetails.style";
import { CustomSelect, DurationSelect, RequestTest } from "./InputComponents/index";
import { CancelButton } from "./PatientDetailsComponents/CancelButton";
export const AppointmentDetails = ({ collectionPoints, requestTestCategory, appointmentStatus, duration, onInputChange, isLoading, isEdit, onEditing, enableEdit, restoreAppointment, cancelAppointment, updateAppointment, testTypeUUIDList, isNewForm, onResendEmail, onSwapSection, }) => {
    const requestedTestType = useAppSelector(getRequestedTestTypeInformation);
    const appInfo = useAppSelector(getApplicationInformation);
    const { openMessageBox } = useContext(ContentContext);
    const renderButtons = () => {
        if (isNewForm) {
            return (_jsx(Col, { id: "center", span: 6, children: _jsx(CreateAppointmentButton, { isLoading: isLoading }) }));
        }
        else {
            return (_jsxs(Col, { span: 6, style: { alignSelf: "end" }, children: [_jsx(RowButtonWrapper, { children: !onEditing && (_jsx(CancelButton, { appointmentStatus: appointmentStatus, cancelAppointment: cancelAppointment, openMessageBox: openMessageBox })) }), !onEditing && (_jsx(RowButtonWrapper, { children: _jsx(ResendButton, { appointmentStatus: appointmentStatus, onResendEmail: onResendEmail, openMessageBox: openMessageBox }) })), _jsx(RowButtonWrapper, { children: appointmentStatus === APPOINTMENT_STATUS_NUMBER.CANCELED ? (_jsx(UpdateButton, { onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT, "Please confirm to restore this appointment?", () => {
                                    restoreAppointment();
                                });
                            }, children: "Restore Appointment" })) : (_jsx(UpdateButton, { onClick: () => {
                                if (onEditing) {
                                    enableEdit(false);
                                    updateAppointment();
                                }
                                else {
                                    enableEdit(true);
                                }
                            }, children: !onEditing ? "Edit Appointment" : "Save Appointment" })) })] }));
        }
    };
    return (_jsxs(AppointmentDetailWrapper, { children: [_jsx(FormTitle, { title: "Appointment Details", iconButton: _jsx(IconButton, { onClick: onSwapSection, children: _jsx(ICSwapButtonIcon, {}) }) }), _jsxs(AppointmentInfoWrapper, { children: [_jsxs(Row, { children: [_jsx(Col, { className: "column-form", span: 9, children: _jsx(InputWithLabel, { label: "Collection Point Location", field: "collectionPointUUID", inputProps: {
                                        type: "select",
                                        options: collectionPoints?.map((item) => ({
                                            label: item.collectionPointName,
                                            value: item.id,
                                        })),
                                        disabled: isEdit && !onEditing,
                                    }, onInputChange: onInputChange, rules: [{ required: true }] }) }), _jsx(Col, { className: "column-form", span: 9, children: _jsx(InputWithLabel, { label: "Date", field: "date", inputProps: {
                                        type: "date",
                                        format: "DD MMM YYYY",
                                        placeholder: "DD/MM/YYYY",
                                        disabled: isEdit && !onEditing,
                                        disabledDate: (current) => current < moment().startOf("D"),
                                    }, onInputChange: onInputChange, rules: [{ required: true }] }) }), _jsx(Col, { span: 6, children: _jsxs(Row, { children: [_jsx(Col, { className: "column-form", span: 14, children: _jsx(InputWithLabel, { label: "Time", field: "time", inputProps: {
                                                    type: "time",
                                                    disabled: isEdit && !onEditing,
                                                    minuteStep: 5,
                                                    onSelect: onInputChange,
                                                    placeholder: appInfo?.collectionOrganization.timeFormat === TimeFormat.TwelveHour
                                                        ? "HH:MM am/pm"
                                                        : "HH:MM",
                                                    format: appInfo?.collectionOrganization.timeFormat === TimeFormat.TwelveHour
                                                        ? "hh:mm a"
                                                        : "HH:mm",
                                                }, onInputChange: onInputChange, rules: [{ required: true }] }) }), _jsxs(Col, { span: 10, children: [_jsx(Text, { className: "item-title", name: "Duration" }), _jsx(Form.Item, { rules: [{ required: true }], name: "duration", children: _jsx(DurationSelect, { value: duration, className: "item-input", field: "duration", onInputChange: onInputChange, disabled: isEdit && !onEditing }) }, "duration")] })] }) })] }), _jsxs(Row, { children: [_jsxs(Col, { span: 18, children: [_jsxs(Row, { children: [_jsxs(Col, { className: "column-form", span: 12, children: [_jsx(Text, { className: "item-title", name: "Requested Test" }), _jsx(Form.Item, { name: "parentTestDataList", rules: [{ required: true }], children: _jsx(RequestTest, { requestedTestType: requestedTestType, testTypeUUIDList: testTypeUUIDList, className: "item-input", field: "parentTestDataList", onInputChange: onInputChange, disabled: isEdit && !onEditing }) })] }), _jsxs(Col, { className: "column-form", span: 12, children: [_jsx(Text, { className: "item-title", name: "Test Category" }), _jsx(Form.Item, { name: "testCategory", rules: [{ required: true }], children: _jsx(CustomSelect, { className: "item-input", options: requestTestCategory.map((item) => ({
                                                                label: item.name,
                                                                value: item.id,
                                                            })), field: "testCategory", onInputChange: onInputChange, disabled: isEdit && !onEditing }) })] })] }), _jsx(Row, { children: _jsx(Col, { className: "column-form", span: 24, children: _jsx(InputWithLabel, { label: "Note", field: "notes", onInputChange: onInputChange, inputProps: {
                                                    disabled: isEdit && !onEditing,
                                                } }) }) })] }), renderButtons()] })] })] }));
};
const UpdateButton = styled.button `
  background-color: ${supportingColours.TEXT_LIGHT_GRAY};
  width: 190px;
  height: 30px;
  color: ${SAColourScheme.WHITE};
  border-radius: 4px;
  border: 1px solid ${SAColourScheme.WHITE};
  font-size: 15px;
  text-align: left;
  padding: 4px 25px;
  line-height: 1;
`;
const RowButtonWrapper = styled.div `
  // height: 50%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  justify-content: center;
`;
const AppointmentInfoWrapper = styled.div `
  background-color: ${SAColourScheme.WHITE}33;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  height: fit-content;

  .input-label {
    color: ${SAColourScheme.WHITE};
  }

  .column-form {
    padding-right: 10px;
  }
`;
