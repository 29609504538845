import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BodyTitle, Button, Col, Divider, Image, Row, Text } from "src/components/base";
import ContentContext from "src/providers/content";
import { SAColourScheme } from "src/styles";
import { getDate } from "src/utils/helper";
import { getOnsiteDonorData, getOnsiteDonorTestData } from "../../store/action";
import { Test_Type } from "../DonorRecord/types";
import { AlcoholScreen } from "./components/AlcoholScreen";
import { ClientTestInfo } from "./components/ClientTestInfo";
import { DonorDeclaration } from "./components/DonorDeclaration";
import { DonorInfo } from "./components/DonorInfo";
import { DonorReportNotes } from "./components/DonorReportNotes";
import { DrugScreen } from "./components/DrugScreen";
import { PatientTime } from "./components/PatientTime";
import { ReqAuthority } from "./components/ReqAuthority";
//set default values for Patient DEtails
const defaultPatientDetails = {
    testReference: "",
    testDate: null,
    lastName: "",
    firstName: "",
    dob: "",
    age: null,
    sex: "",
    requestingAuthorityName: "",
    authorisedRepresentative: "",
    siteLocation: "",
    donorTestReason: "",
    testProtocol: "",
    testInterval: "",
    collectionOrganisation: "",
    serviceOffice: "",
    collector: "",
    signedAt: null,
    patientSignature: "",
};
export const OnSiteDonorResultDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const [donorDetailsData, setDonorDetailsData] = useState(defaultPatientDetails);
    const [donorTestDetailsData, setDonorTestDetailsData] = useState();
    const [testType, setTestType] = useState();
    const [alcoholData, setAlcoholData] = useState();
    const [drugData, setDrugData] = useState();
    const renderBackButton = () => (_jsx(Button, { className: "back-button", icon: _jsx(Image, { className: "back-icon", src: "/assets/svg/ic-back.svg", color: "red", preview: false }), onClick: () => navigate(-1), children: "Back" }));
    const getPatientDetailsData = async (appointmentId) => {
        const result = await getOnsiteDonorData(appointmentId);
        if (result.status === "success") {
            setDonorDetailsData(result.data);
        }
        else {
            message.error("Failed to fetch patient details");
        }
    };
    const getPatientTestDetailsData = async (appointmentId) => {
        const result = await getOnsiteDonorTestData(appointmentId);
        if (result.status === "success") {
            setDonorTestDetailsData(result.data);
            let hasTestType1 = false;
            let hasTestType2 = false;
            result?.data?.validSummaries?.forEach((medicalTest) => {
                if (medicalTest.testType === Test_Type.DRUG_TEST) {
                    hasTestType1 = true;
                    setDrugData(medicalTest);
                }
                if (medicalTest.testType === Test_Type.ALCOHOL_TEST) {
                    hasTestType2 = true;
                    setAlcoholData(medicalTest);
                }
            });
            if (hasTestType1 && hasTestType2) {
                setTestType(Test_Type.DRUG_AND_ALCOHOL_TEST);
            }
            else if (hasTestType1) {
                setTestType(Test_Type.DRUG_TEST);
            }
            else if (hasTestType2) {
                setTestType(Test_Type.ALCOHOL_TEST);
            }
        }
        else {
            message.error("Failed to fetch test results details");
        }
    };
    useEffect(() => {
        openLoadingScreen();
        const appointmentId = location.state;
        if (appointmentId) {
            Promise.all([
                getPatientDetailsData(appointmentId),
                getPatientTestDetailsData(appointmentId),
            ]).then(() => closeLoadingScreen());
        }
    }, [location.state]);
    return (_jsxs(PageWrapper, { children: [_jsx(BodyTitle, { name: "Donor Test Result", left: renderBackButton() }), _jsxs(BodyWrapper, { children: [_jsxs(StyledRow, { gutter: [0, 10], children: [_jsxs(StyledCol, { xs: 24, md: 12, lg: 8, justifyContent: "flex-start", children: [_jsx(Text, { name: "Test Reference", className: "header-label" }), _jsx(Divider, { type: "vertical" }), _jsx(Text, { name: donorDetailsData?.testReference, className: "header-value" })] }), _jsxs(StyledCol, { xs: 24, md: 12, lg: 8, justifyContent: "center", children: [_jsx(Text, { name: "Test Date", className: "header-label" }), _jsx(Divider, { type: "vertical" }), _jsx(Text, { name: (donorDetailsData?.testDate &&
                                            moment(getDate(donorDetailsData?.testDate)).format("DD/MM/YYYY")) ??
                                            "", className: "header-value" })] }), _jsx(StyledCol, { xs: 24, lg: 8, justifyContent: "flex-end", children: _jsx(StyledButton, { icon: _jsx(Image, { src: "/assets/svg/ic-pdf.svg", alt: "ic-print", height: 24, width: 24, preview: false }), children: "Donor Result Report" }) })] }), _jsxs(StyledTable, { children: [_jsx(DonorInfo, { donorDetailsData: donorDetailsData }), _jsx(ReqAuthority, { donorDetailsData: donorDetailsData }), _jsx(ClientTestInfo, { donorDetailsData: donorDetailsData }), _jsx(DonorDeclaration, { donorDetailsData: donorDetailsData }), _jsx(PatientTime, { donorTestDetailsData: donorTestDetailsData }), (testType === Test_Type.ALCOHOL_TEST ||
                                testType === Test_Type.DRUG_AND_ALCOHOL_TEST) && (_jsx(AlcoholScreen, { alcoholData: alcoholData })), testType === Test_Type.DRUG_AND_ALCOHOL_TEST && _jsx(StyledDivider, {}), (testType === Test_Type.DRUG_TEST || testType === Test_Type.DRUG_AND_ALCOHOL_TEST) && (_jsx(DrugScreen, { drugData: drugData })), _jsx(DonorReportNotes, { donorResultReportNote: donorTestDetailsData?.donorResultReportNote })] })] })] }));
};
export const PageWrapper = styled.div `
  border-radius: 8px;
  background: white;
  margin-bottom: 30px;

  button.ant-btn.ant-btn-default.back-button {
    color: #0047b3;
    font-size: 20px;
    background: none;
    border: none;
    padding-left: 5px;
  }
  img.ant-image-img.back-icon {
    margin-bottom: 5px;
    margin-right: 8px;
  }
`;
const BodyWrapper = styled.div `
  padding: 2em 5em;

  @media (max-width: 992px) {
    padding: 2em 2em;
  }
`;
const StyledRow = styled(Row) `
  background: #b6e7f0;
  border: 1px solid ${SAColourScheme.PLASSMA};
  padding: 1em 4em;
  border-radius: 8px 8px 0 0;

  .ant-divider {
    border-left: 2px solid ${SAColourScheme.WHITE} !important;
    height: 25px !important;
  }
`;
const StyledCol = styled(Col) `
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: center;

  .header-label {
    font-size: 1rem;
    color: #2f3337 !important;
    font-weight: 700;
  }

  .header-value {
    font-size: 1rem;
    color: #1a576f !important;
    font-weight: 400;
  }

  .ant-select {
    margin-right: 10px;
  }

  @media (max-width: 992px) {
    justify-content: ${(props) => props.justifyContent === "center" && "flex-end"};
    .ant-select {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    justify-content: ${(props) => props.justifyContent === "center" && "flex-start"};
    .ant-select {
      width: 100%;
    }
  }
`;
const StyledTable = styled.div `
  border: solid 1px #01b4d2;
  border-top: 0;
  padding: 2em 3em;
`;
const StyledDivider = styled(Divider) `
  border-top: 4px solid #e4f6fb !important;
`;
const StyledButton = styled(Button) `
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border: none !important;

  .ant-image {
    margin-left: 1em;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
