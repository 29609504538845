import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Select, Spin, Table, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import { uuidv4 } from "@antv/xflow-core";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const COLOR_LEVEL = {
    1: "#01B4D2",
    2: "#2889B5",
    3: "#156CC9",
};
const CurrentPeriodSummaryTable = ({ showCollector, showTotal, columnHeading, dataSource, collectors, selectCollectorId, onCollectorChange, }) => {
    const renderCollectorBox = () => (_jsxs(StyledCollectorRow, { children: [_jsx(SAColumn, { span: 8, align: "flex-start", children: _jsx(Typography.Title, { level: 5, children: "Collector" }) }), _jsx(SAColumn, { span: 16, children: _jsx(Select, { placeholder: "Select Collector", options: collectors ?? [], fieldNames: { label: "collectorName", value: "collectorId" }, value: selectCollectorId, onChange: (collectorId) => {
                        onCollectorChange?.(collectorId);
                    } }) })] }));
    return (_jsxs(React.Fragment, { children: [!dataSource.length && (_jsxs(ErrorBox, { children: [_jsx(Spin, {}), "Querying data or occurring an error"] })), dataSource.map((table, index) => (_jsx(StyledTableWrapper, { children: _jsxs(Table, { dataSource: table.entries?.map((entry) => ({ ...entry, id: uuidv4() })) ?? [], rowKey: (record) => record.id, pagination: false, bordered: false, showHeader: index === 0, components: {
                        body: {
                            wrapper: (props) => (_jsxs("tbody", { ...props, children: [showCollector && index === 0 && (_jsx("tr", { children: _jsx("td", { colSpan: 5, children: renderCollectorBox() }) })), table.groupTitle && (_jsxs("tr", { children: [_jsx("td", { colSpan: 1, children: _jsx(TriangleBoxGroupTitle, { children: _jsx(Typography.Text, { children: table.groupTitle }) }) }), _jsx("td", { colSpan: 4, style: { textAlign: "right", paddingRight: "1rem" }, children: _jsx(Typography.Text, { children: table.groupSubtitle }) })] })), props.children, dataSource.length - index > 1 && (_jsx("tr", { children: _jsx("td", { colSpan: 5, style: { padding: "0 0.7rem" }, children: _jsx(Divider, { style: { marginBottom: "0.1rem" } }) }) }))] })),
                        },
                    }, children: [_jsx(Table.Column, { title: typeof columnHeading === "string" ? (_jsx(Typography.Text, { strong: true, children: columnHeading })) : (columnHeading), dataIndex: "category", align: "center", render: (text, record) => {
                                if (showTotal && record.category === "Total") {
                                    return (_jsx(TotalQuantityLabelBox, { children: _jsx(Typography.Text, { children: text }) }));
                                }
                                return (_jsx(TriangleBox, { children: _jsx(Typography.Text, { children: text }) }));
                            }, width: 200 }, "category"), _jsx(Table.Column, { title: "Today", dataIndex: "day", align: "center", width: 100, render: (text, record) => {
                                if (showTotal && record.category === "Total") {
                                    return (_jsx(TotalQuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                                }
                                return (_jsx(QuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                            } }, "day"), _jsx(Table.Column, { title: "Current Week", dataIndex: "week", align: "center", width: 100, render: (text, record) => {
                                if (showTotal && record.category === "Total") {
                                    return (_jsx(TotalQuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                                }
                                return (_jsx(QuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                            } }, "week"), _jsx(Table.Column, { title: "Current Month", dataIndex: "month", align: "center", width: 100, render: (text, record) => {
                                if (showTotal && record.category === "Total") {
                                    return (_jsx(TotalQuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                                }
                                return (_jsx(QuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                            } }, "month"), _jsx(Table.Column, { title: "Current Year", dataIndex: "year", align: "center", width: 100, render: (text, record) => {
                                if (showTotal && record.category === "Total") {
                                    return (_jsx(TotalQuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                                }
                                return (_jsx(QuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                            } }, "year")] }) }, uuidv4())))] }));
};
export default CurrentPeriodSummaryTable;
const StyledTableWrapper = styled.div `
  width: 100%;
`;
const StyledCollectorRow = styled(SARow) `
  background-color: #e6f7fa;
  padding: 1rem;
  margin-bottom: 1rem;
  height: 4rem;
  margin-top: -0.5rem;

  .ant-select {
    width: 100%;
  }
`;
const TriangleBox = styled.div `
  position: relative;
  background-color: ${COLOR_LEVEL[1]};
  color: #fff;
  padding: 0.5rem;
  text-align: left;
  height: 2.4rem;
  width: 80%;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -1.15rem;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-left: 1.2rem solid ${COLOR_LEVEL[1]};
  }

  span {
    color: #ffffff;
  }
`;
const TriangleBoxGroupTitle = styled(TriangleBox) `
  background-color: ${COLOR_LEVEL[2]};
  width: 100%;
  margin-left: 0.7rem;

  span {
    color: #ffffff;
    font-weight: 700;
  }

  &::after {
    border-left: 1.2rem solid ${COLOR_LEVEL[2]};
  }
`;
const QuantityBox = styled.div `
  border: 1px solid #ebedf0;
  border-radius: 0.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  width: 4rem;
  margin: 0 auto;
`;
const TotalQuantityBox = styled(QuantityBox) `
  font-weight: bold;
  background-color: #ebedf0;
`;
const TotalQuantityLabelBox = styled(QuantityBox) `
  width: 100%;
  justify-content: right;
  padding: 0 0.5rem;
  font-weight: bold;
  background-color: #ebedf0;
  margin-left: 0.5rem;
`;
const ErrorBox = styled.div `
  height: 10rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
