import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import ServiceOffice from "./ServiceOffice";
import ServiceOwner from "./ServiceOwner";
import OrganizationInfo from "./OrganizationInfo";
import ServiceManager from "./ServiceManager";
import Laboratory from "./Laboratory";
const CollectionOrganizationRoute = () => (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "information", replace: true }) }), _jsx(Route, { path: "information/*", element: _jsx(OrganizationInfo, {}) }), _jsx(Route, { path: "service-owner/*", element: _jsx(ServiceOwner, {}) }), _jsx(Route, { path: "service-manager/*", element: _jsx(ServiceManager, {}) }), _jsx(Route, { path: "service-office/*", element: _jsx(ServiceOffice, {}) }), _jsx(Route, { path: "laboratory/*", element: _jsx(Laboratory, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/collection-organisation/information/", replace: true }) })] }) }));
export default CollectionOrganizationRoute;
export const breadcrumbs = [
    {
        path: "/collection-organisation",
        name: "Collection Organization",
        level: 1,
    },
    {
        path: "/information",
        name: "Organization",
        level: 2,
    },
    {
        path: "/service-owner",
        name: "Service Owner",
        level: 2,
    },
    {
        path: "/service-manager",
        name: "Service Manager",
        level: 2,
    },
    {
        path: "/service-office",
        name: "Service Office",
        level: 2,
    },
    {
        path: "/laboratory",
        name: "Laboratory",
        level: 2,
    },
];
