import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import moment from "moment";
import { getListClients, getOverallCostSummary } from "./action";
import { initialOverallCost } from "./types";
export const initialState = {
    listClients: [],
    filterOptions: {
        selectedCollectionPointId: null,
        selectedOnsiteClientId: null,
        startDate: moment(),
        endDate: moment(),
    },
    overallCostSummary: initialOverallCost,
    isIncludedGst: true,
};
const accountingDashboardSlice = createSlice({
    name: "accountingDashboard",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
        setFilterOption(state, action) {
            state.filterOptions = { ...state.filterOptions, ...action.payload };
        },
        setIsIncludedGst(state, action) {
            state.isIncludedGst = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListClients.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.listClients = data;
        })
            .addCase(getListClients.rejected, () => {
            message.error("Failed to fetch list clients");
        })
            .addCase(getOverallCostSummary.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.overallCostSummary = data;
        })
            .addCase(getOverallCostSummary.rejected, () => {
            message.error("Failed to fetch overall summary");
        });
    },
});
export const { setResetStore, setFilterOption, setIsIncludedGst } = accountingDashboardSlice.actions;
export default accountingDashboardSlice.reducer;
