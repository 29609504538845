import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { Col, Divider, Form, Input, message, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { keyBy } from "lodash";
import Flag from "react-country-flag";
import { getCollectionOrganizationDetails } from "src/pages/CollectionOrganization/OrganizationInfo/store/action";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import GeneralOrganizationInfo from "src/pages/CollectionOrganization/components/GeneralOrganizationInfo";
import { organizationRole, SAColourScheme, supportingColours } from "src/constants";
import { getFullName } from "src/utils";
import { convertToValueLabelArray, getAllCountryPhone } from "src/utils/helper";
import { breadcrumbs } from "../index";
const ServiceManager = () => {
    const [form] = Form.useForm();
    const [convertedCountry, setConvertedCountry] = useState();
    const [admin, setAdmin] = useState({
        serviceManagerName: "",
        serviceOwnerName: "",
        serviceOwnerAbn: "",
    });
    const [collectionOrganizationDetail, setCollectionOrganizationDetail] = useState();
    const [serviceManager, setServiceManager] = useState();
    useEffect(() => {
        getData();
        const converted = convertToValueLabelArray(getAllCountryPhone());
        setConvertedCountry(converted);
    }, []);
    useEffect(() => {
        form.setFieldsValue({
            honorific: serviceManager?.honorific,
            firstName: serviceManager?.firstName,
            lastName: serviceManager?.lastName,
            email: serviceManager?.email,
            mobile: serviceManager?.mobile,
            collectionOrganizationId: collectionOrganizationDetail?.id,
        });
    }, [serviceManager]);
    const getData = async () => {
        const result = await getCollectionOrganizationDetails();
        if (result.error) {
            message.error("Failed to fetch organization details");
            return;
        }
        setCollectionOrganizationDetail(result.collectionOrganizationDetail);
        const adminInfoMap = keyBy(result.adminInfo, "permission");
        const serviceManagerData = adminInfoMap[organizationRole.SERVICE_MANAGER] ||
            adminInfoMap[organizationRole.SERVICE_MANAGER_V2];
        const serviceOwnerData = adminInfoMap[organizationRole.SERVICE_OWNER];
        setServiceManager(serviceManagerData);
        setAdmin({
            serviceManagerName: getFullName(serviceManagerData),
            serviceOwnerName: getFullName(serviceOwnerData),
            serviceOwnerAbn: serviceOwnerData.abn,
        });
    };
    const prefixSelector = (_jsx(Form.Item, { noStyle: true, children: _jsx(FlagSelect, { open: false, defaultValue: "AU", options: convertedCountry?.map((country) => ({
                value: country.value,
                label: (_jsxs(FlagWrapper, { children: [_jsx(StyledFlag, { countryCode: country.value }), _jsx(Typography.Text, { children: `+${country.label}` })] })),
            })) }) }));
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsx(PageWrapper, { children: _jsxs(Row, { gutter: [60, 0], children: [_jsx(Col, { sm: 24, lg: 6, children: _jsx(GeneralOrganizationInfo, { abn: admin.serviceOwnerAbn, medicalProviderNumber: collectionOrganizationDetail?.medicalNumber || undefined, organizationName: collectionOrganizationDetail?.collectionName, serviceManagerName: admin.serviceManagerName, serviceOwnerName: admin.serviceOwnerName }) }), _jsx(Col, { sm: 24, lg: 18, children: _jsx(Row, { gutter: [0, 10], children: _jsx(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, children: _jsx(Row, { children: _jsxs(Col, { span: 24, children: [_jsx(InputSessionTitle, { children: "Service Manager" }), _jsx(StyledDivider, {}), _jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { span: 4, children: _jsx(StyledFormItem, { name: "honorific", label: "Title (Optional)", children: _jsx(Select, { open: false, options: [
                                                                    { label: "MR", value: "MR" },
                                                                    { label: "MRS", value: "MRS" },
                                                                    { label: "MISS", value: "MISS" },
                                                                    { label: "MS", value: "MS" },
                                                                    { label: "DR", value: "DR" },
                                                                ] }) }) }), _jsx(Col, { span: 10, children: _jsx(StyledFormItem, { label: "First Name", name: "firstName", children: _jsx(Input, { readOnly: true }) }) }), _jsx(Col, { span: 10, children: _jsx(StyledFormItem, { label: "Last Name", name: "lastName", children: _jsx(Input, { readOnly: true }) }) })] }), _jsxs(Row, { gutter: [10, 0], children: [_jsx(Col, { span: 12, children: _jsx(StyledFormItem, { label: "Email Address", name: "email", children: _jsx(Input, { readOnly: true }) }) }), _jsx(Col, { span: 12, children: _jsx(StyledFormItem, { name: "mobile", label: "Mobile Number", children: _jsx(Input, { addonBefore: prefixSelector, readOnly: true }) }) })] })] }) }) }) }) })] }) }) }));
};
export default ServiceManager;
const PageWrapper = styled.div `
  margin-right: 60px;
`;
const FlagSelect = styled(Select) `
  min-width: 120px;
`;
const InputSessionTitle = styled(Typography) `
  width: 100%;
  color: ${supportingColours.TEXT_DARK_GRAY};
  font-size: 16px;
`;
const StyledDivider = styled(Divider) `
  margin: 0px 0px 10px 0px;
  border-color: ${SAColourScheme.PLASMA};
`;
const StyledFormItem = styled(Form.Item) `
  .ant-form-item-label {
    padding: 0px;
  }

  label {
    color: ${supportingColours.TEXT_LIGHT_GRAY};
  }
`;
const FlagWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const StyledFlag = styled(Flag) `
  width: 20px;
  margin-right: 8px;
`;
