import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment";
import momentTz from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { message } from "antd";
import { Form } from "src/components/base";
import { DEFAULT_TIMEZONE, MESSAGE_BOX_TYPE } from "src/constants";
import ContentContext from "src/providers/content";
import { AppointmentDetails } from "src/pages/InClinicAppointmentsPage/CreateAppointmentPage/components/AppointmentDetails";
import PatientDetails from "src/pages/InClinicAppointmentsPage/CreateAppointmentPage/components/PatientDetails";
import { AppointmentPageWrapper } from "src/pages/InClinicAppointmentsPage/CreateAppointmentPage/index.style";
import { usePatientRecords } from "src/pages/PatientRecordsPage/hooks";
import { useSettings } from "src/providers/SettingsContextProvider";
import { useCancelAppointment, useCommonDetails, useCreateAppointment, useGetAppointmentById, useResendEmailAppointment, useRestoreAppointment, useUpdateAppointment, } from "../hooks";
import { emptyPatient, } from "../store/types";
const validateMessage = {
    required: "is required",
    whitespace: "cannot be empty",
};
export const CreateAppointmentPage = ({ appointmentId, submitCallback, }) => {
    const [form] = Form.useForm();
    const { settings } = useSettings();
    const isEdit = !!appointmentId;
    const isNewForm = !appointmentId;
    const { openMessageBox, openLoadingScreen, closeLoadingScreen } = useContext(ContentContext);
    const [onEditing, setOnEditing] = useState(false);
    const [requestTest, setRequestTest] = useState([]);
    const [isExistedPatient, setIsExistedPatient] = useState(false);
    const [isSectionSwap, setIsSectionSwap] = useState(false);
    const [dataSuggestPatient, setDataSuggestPatient] = useState([]);
    const { collectionPoints, testCategories } = useCommonDetails();
    const { createAppointment, isLoadingCreatingAppointment } = useCreateAppointment();
    const { getAppointmentById } = useGetAppointmentById();
    const { cancelAppointment: cancelAppointmentById } = useCancelAppointment();
    const { resendEmailAppointment } = useResendEmailAppointment();
    const { restoreAppointment: restoreAppointmentById } = useRestoreAppointment();
    const { updateAppointmentById } = useUpdateAppointment();
    const { getPatientRecords } = usePatientRecords();
    const [appointmentInit, setAppointmentInit] = useState({});
    useEffect(() => {
        getData();
        if (appointmentId) {
            initFormDataOnEdit(appointmentId);
        }
    }, [appointmentId]);
    const getData = async () => {
        openLoadingScreen();
        try {
            const response = await getPatientRecords();
            const patientData = unwrapResult(response);
            if (patientData.status === "success") {
                setDataSuggestPatient(patientData.data);
            }
        }
        catch (error) {
            closeLoadingScreen();
        }
        closeLoadingScreen();
    };
    function getTimezone(collectionPointId) {
        const collectionPoint = settings.collectionPointSetting.find((cp) => cp.collectionPointId === collectionPointId);
        return collectionPoint?.timezone;
    }
    const handleSwapSection = () => {
        setIsSectionSwap(!isSectionSwap);
    };
    const handleSelectRowRecord = (record) => {
        form.setFieldsValue({
            ...record,
            city: record.suburb,
            dob: record.dob ? moment(record.dob) : null,
        });
        setIsExistedPatient(true);
    };
    const onInputChange = (key) => async ({ field, value }) => {
        if (key !== "appointmentInfo") {
            return;
        }
        if (field === "time") {
            form.setFieldsValue({
                [field]: value ? moment(value, "HH:mm") : null,
            });
            return;
        }
        if (field === "parentTestDataList") {
            form.setFieldsValue({ parentTestDataList: null });
            form.setFieldsValue({ parentTestDataList: value });
            return;
        }
        form.setFieldsValue({ [field]: value });
    };
    const buildDataAppointment = (formData) => {
        const timezone = getTimezone(formData.collectionPointUUID) || DEFAULT_TIMEZONE;
        const datePart = formData.date.format("YYYY-MM-DD");
        const timePart = formData.time.format("HH:mm:ss");
        const combinedMoment = momentTz(`${datePart} ${timePart}`, "YYYY-MM-DD HH:mm:ss", timezone)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
        return {
            appointmentDetail: {
                collectionPointId: formData.collectionPointUUID,
                startAt: combinedMoment,
                durationMinutes: Number(formData.duration.replace("m", "")),
                testTypeIds: formData.parentTestDataList.map((testType) => testType.uuid),
                testCategoryId: formData.testCategory,
                note: formData.notes,
            },
            patientDetail: {
                lastName: formData.lastName,
                firstName: formData.firstName,
                dob: formData.dob.format("YYYY-MM-DD").toString(),
                sex: formData.sex,
                email: formData.email,
                mobile: formData.mobile,
                landline: formData.landline,
                address1: formData.address1,
                address2: formData.address2,
                suburb: formData.city,
                state: formData.state,
                postcode: formData.postCode,
            },
        };
    };
    const onFinish = async () => {
        const formData = form.getFieldsValue();
        const appointmentBody = buildDataAppointment(formData);
        try {
            const { status } = await createAppointment(appointmentBody).unwrap();
            if (status === "fail") {
                message.error("Create appointment failed");
            }
            else {
                setIsExistedPatient(false);
                form.resetFields();
                message.success("New Appointment has been created and added to the Patient List");
            }
        }
        catch (error) {
            message.error("Create appointment failed");
        }
        closeLoadingScreen();
    };
    const cancelAppointment = async () => {
        if (!appointmentId) {
            return;
        }
        openLoadingScreen();
        try {
            const responseCancel = await cancelAppointmentById(appointmentId);
            const result = unwrapResult(responseCancel);
            if (result.status === "fail") {
                message.error("The Appointment has been cancelled");
            }
            else {
                message.success("The Appointment has been cancelled");
            }
        }
        catch (error) {
            message.error("The Appointment has been cancelled");
        }
        closeLoadingScreen();
    };
    const restoreAppointment = async () => {
        if (!appointmentId) {
            return;
        }
        openLoadingScreen();
        try {
            const response = await restoreAppointmentById(appointmentId);
            const result = unwrapResult(response);
            if (result.status === "fail") {
                message.error("Restore appointment failed");
            }
            else {
                message.success("Restore appointment successfully");
            }
        }
        catch (error) {
            message.error("Restore appointment failed");
        }
        closeLoadingScreen();
    };
    const onResendEmail = async () => {
        if (!appointmentId) {
            return;
        }
        openLoadingScreen();
        try {
            const response = await resendEmailAppointment(appointmentId);
            const result = unwrapResult(response);
            if (result.status === "fail") {
                message.error("Resend email failed");
            }
            else {
                message.success("Resend email successfully");
            }
        }
        catch (error) {
            message.error("Resend email failed");
        }
        closeLoadingScreen();
    };
    const editAppointment = async () => {
        if (!appointmentId) {
            return;
        }
        const formData = form.getFieldsValue();
        const appointmentBody = buildDataAppointment(formData);
        try {
            const response = await updateAppointmentById(appointmentId, appointmentBody);
            const responseUpdate = unwrapResult(response);
            const msgCancelAppointment = responseUpdate.status === "success"
                ? "The Appointment has been changed successfully"
                : "The Appointment has been changed failed";
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG, msgCancelAppointment, submitCallback);
        }
        catch (error) {
            openMessageBox(MESSAGE_BOX_TYPE.DIALOG, "Update appointment failed");
        }
    };
    const initFormDataOnEdit = async (id) => {
        const resultAction = await getAppointmentById(id);
        const dataAppointment = unwrapResult(resultAction).data;
        if (isEmpty(dataAppointment)) {
            return;
        }
        const { appointmentDetail, patientDetail } = dataAppointment;
        setAppointmentInit(appointmentDetail);
        const timezone = getTimezone(appointmentDetail.collectionPointId) || DEFAULT_TIMEZONE;
        const appointmentData = {
            collectionPointUUID: appointmentDetail.collectionPointId,
            date: moment(appointmentDetail.startAt).tz(timezone),
            time: moment(appointmentDetail.startAt).tz(timezone),
            duration: appointmentDetail.durationMinutes + "m",
            parentTestUUID: appointmentDetail.testCategoryId,
            notes: appointmentDetail.note,
            testCategory: appointmentDetail.testCategoryId,
            lastName: patientDetail.lastName,
            firstName: patientDetail.firstName,
            dob: moment(patientDetail.dob),
            sex: patientDetail.sex,
            email: patientDetail.email,
            mobile: patientDetail.mobile,
            landline: patientDetail.landline,
            address1: patientDetail.address1,
            address2: patientDetail.address2,
            city: patientDetail.suburb,
            state: patientDetail.state,
            postCode: patientDetail.postcode,
        };
        form.setFieldsValue({ ...appointmentData });
        setRequestTest(dataAppointment.appointmentDetail.testTypeIds);
    };
    const onClearPatient = () => {
        const formData = form.getFieldsValue();
        setIsExistedPatient(false);
        form.setFieldsValue({ ...formData, ...emptyPatient });
    };
    const onUpdatePatient = async () => {
        openLoadingScreen();
        closeLoadingScreen();
        openMessageBox(MESSAGE_BOX_TYPE.PROMPT_BLUE, "The patient’s details will be updated once you create the appointment");
    };
    return (_jsx("div", { style: {
            width: "100%",
            background: "white",
        }, children: _jsx(AppointmentPageWrapper, { style: {
                boxShadow: "none",
            }, children: _jsx(Form, { initialValues: {}, form: form, style: { width: "100%" }, onFinish: onFinish, validateMessages: validateMessage, children: isSectionSwap ? (_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "20px" }, children: [_jsx(AppointmentDetails, { collectionPoints: collectionPoints, requestTestCategory: testCategories, onInputChange: onInputChange("appointmentInfo"), isLoading: isLoadingCreatingAppointment, isEdit: isEdit, onEditing: onEditing, enableEdit: (value) => {
                                setOnEditing(value);
                                setIsExistedPatient(true);
                            }, appointmentStatus: appointmentInit.status, cancelAppointment: cancelAppointment, restoreAppointment: restoreAppointment, updateAppointment: editAppointment, testTypeUUIDList: requestTest, isNewForm: isNewForm, onResendEmail: onResendEmail, onSwapSection: handleSwapSection }), _jsx(PatientDetails, { onSwapSection: handleSwapSection, patientData: dataSuggestPatient, onInputChange: handleSelectRowRecord, isNewForm: isNewForm, onClearPatient: onClearPatient, isExistedPatient: isExistedPatient, onUpdatePatient: onUpdatePatient, onEditing: onEditing })] })) : (_jsxs("div", { style: { display: "flex", flexDirection: "column", gap: "20px" }, children: [_jsx(PatientDetails, { onSwapSection: handleSwapSection, patientData: dataSuggestPatient, onInputChange: handleSelectRowRecord, isNewForm: isNewForm, onClearPatient: onClearPatient, isExistedPatient: isExistedPatient, onUpdatePatient: onUpdatePatient, onEditing: onEditing }), _jsx(AppointmentDetails, { collectionPoints: collectionPoints, requestTestCategory: testCategories, onInputChange: onInputChange("appointmentInfo"), isLoading: isLoadingCreatingAppointment, isEdit: isEdit, onEditing: onEditing, enableEdit: (value) => {
                                setOnEditing(value);
                                setIsExistedPatient(true);
                            }, appointmentStatus: appointmentInit.status, cancelAppointment: cancelAppointment, restoreAppointment: restoreAppointment, testTypeUUIDList: requestTest, updateAppointment: editAppointment, isNewForm: isNewForm, onResendEmail: onResendEmail, onSwapSection: handleSwapSection })] })) }) }) }));
};
