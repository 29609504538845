import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, Space, Typography } from "antd";
import * as React from "react";
import { ClockCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import moment from "moment";
import styled from "styled-components";
import { AccountIcon } from "src/assets/svg/common";
import { CollectorCalendarEventType } from "../store/types";
const EventDetailPopover = ({ children, event, }) => {
    const eventTime = event.allDay
        ? "All Day"
        : `${moment(event.start).format("HH:mm (DD MMM YYYY)")} - ${moment(event.end).format("HH:mm (DD MMM YYYY)")}`;
    const content = (_jsx(ContentContainer, { "$isLeaveType": event.eventType === CollectorCalendarEventType.Leave, children: _jsxs(Space, { direction: "vertical", children: [_jsxs(Space, { children: [_jsx(AccountIcon, {}), _jsx(Typography.Text, { children: event.title })] }), _jsxs(Space, { children: [_jsx(ClockCircleOutlined, {}), _jsx(Typography.Text, { children: eventTime })] }), _jsxs(Space, { children: [_jsx(FileTextOutlined, {}), _jsx(Typography.Text, { children: event.note })] })] }) }));
    return (_jsx(Popover, { content: content, title: "", placement: "right", children: children }));
};
export default EventDetailPopover;
const ContentContainer = styled.div `
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem;

  svg {
    color: ${({ $isLeaveType }) => ($isLeaveType ? "#EB41E4" : "#FD0000")};
  }
  div + div svg:first-child {
    color: #01b4d2;
  }
`;
