import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectCollectionTables, selectCollectors, thunkCollectionTableMap, } from "../store/selector";
import { setCollectionTable } from "../store/slice";
import { QueryType, } from "../store/types";
import { getListCollectorsByCollectionPoint } from "../store/action";
import CurrentPeriodSummaryTable from "./CurrentPeriodSummaryTable";
import CustomDateRangeTable from "./CustomDateRangeTable";
import CustomPeriodSummaryTable from "./CustomPeriodSummaryTable";
import OnsiteCollectionTableTopBar from "./OnsiteCollectionTableTopBar";
const OnsiteCollectionTable = (props) => {
    const { dataSource, getDataSource, tableKey, logo, label, showCollector, showTotal, mainColumnLabel, } = props;
    const [isOpenContent, setIsOpenContent] = React.useState(false);
    const dispatch = useAppDispatch();
    const collectionTables = useAppSelector(selectCollectionTables);
    const collectorData = useAppSelector(selectCollectors);
    const updateFilters = (key, options) => ({
        ...collectionTables[key],
        filters: {
            ...collectionTables[key]?.filters,
            ...options,
        },
    });
    React.useEffect(() => {
        if (showCollector && isEmpty(collectorData)) {
            dispatch(getListCollectorsByCollectionPoint());
        }
    }, []);
    return (_jsxs(TableContainer, { children: [_jsx(OnsiteCollectionTableTopBar, { label: label, logo: logo, isOpen: isOpenContent, setOpen: async (isOpen) => {
                    getDataSource(isOpen);
                    setIsOpenContent(isOpen);
                }, tableKey: tableKey }), isOpenContent && (_jsxs(TableContent, { children: [_jsx(TableColumn, { md: 24, lg: 10, children: _jsx(CurrentPeriodSummaryTable, { dataSource: dataSource?.currentPeriod ?? [], showCollector: showCollector, showTotal: showTotal, columnHeading: mainColumnLabel, collectors: collectionTables[tableKey]?.filters.collectionPointId
                                ? collectorData[collectionTables[tableKey]?.filters.collectionPointId].collectors
                                : Object.values(collectorData).flatMap((value) => value.collectors), selectCollectorId: collectionTables[tableKey]?.filters.collectorId ?? undefined, onCollectorChange: async (collectorId) => {
                                if (collectorId) {
                                    dispatch(setCollectionTable({
                                        [tableKey]: updateFilters(tableKey, {
                                            collectorId: collectorId,
                                            queryType: undefined,
                                        }),
                                    }));
                                    dispatch(thunkCollectionTableMap[tableKey]());
                                }
                            } }) }), _jsx(TableColumn, { md: 24, lg: 10, children: _jsx(CustomPeriodSummaryTable, { showCollector: showCollector, showTotal: showTotal, dataSource: dataSource?.customPeriod ?? [], viewOption: {
                                year: collectionTables[tableKey]?.filters.year,
                                month: collectionTables[tableKey]?.filters.month,
                            }, setViewOptions: (options) => {
                                dispatch(setCollectionTable({
                                    [tableKey]: updateFilters(tableKey, {
                                        ...options,
                                        queryType: QueryType.CustomPeriod,
                                    }),
                                }));
                                dispatch(thunkCollectionTableMap[tableKey]());
                            } }) }), _jsx(TableColumn, { md: 24, lg: 4, children: _jsx(CustomDateRangeTable, { showCollector: showCollector, showTotal: showTotal, dataSource: dataSource?.customDateRange ?? [], viewOption: {
                                startDate: collectionTables[tableKey]?.filters.startDate,
                                endDate: collectionTables[tableKey]?.filters.endDate,
                            }, setViewOptions: (options) => {
                                dispatch(setCollectionTable({
                                    [tableKey]: updateFilters(tableKey, {
                                        ...options,
                                        queryType: QueryType.CustomDateRange,
                                    }),
                                }));
                                dispatch(thunkCollectionTableMap[tableKey]());
                            } }) })] }))] }));
};
export default OnsiteCollectionTable;
const TableContainer = styled.div `
  width: 100%;
  height: 100%;
  border: 0.1rem solid #2e94f2;

  .ant-table-thead {
    height: 10rem;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .ant-table-thead > tr > th {
    border-bottom: 0.1rem solid #2e94f2;
    padding: 0;
  }
  .ant-table-wrapper {
    padding-bottom: 1rem;
  }
  .ant-table-cell {
    padding: 0.2rem;
  }
  th {
    padding: 0.2rem !important;
  }
  tbody > tr > td {
    padding: 0;
  }
  tbody > tr:first-child > td {
    padding-top: 0.5rem;
  }
  .ant-table-tbody > tr > td {
    border: 0;
  }
  .ant-typography {
    margin-bottom: 0;
  }
`;
const TableContent = styled(SARow) `
  display: flex;
  width: 100%;
  max-height: 50rem;
  overflow-y: auto;
`;
const TableColumn = styled(SAColumn) `
  border: 0.1rem solid #2e94f2;
  padding: 0 !important;
  > div {
    width: 100%;
  }
`;
