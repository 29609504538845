import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CalendarOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import SAForm from "src/components/Form/SAForm";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectDateRangeOptions, selectFilterOptions, selectListClients, selectListCollectionPoints, } from "./store/selector";
import { setFilterOption } from "./store/slice";
import { DateRangeOption } from "./store/types";
import { getOverallCostSummary } from "./store/action";
const FilterToolBar = () => {
    const dispatch = useAppDispatch();
    const collectionPoints = useAppSelector(selectListCollectionPoints);
    const listClients = useAppSelector(selectListClients);
    const dateRangeOptions = useAppSelector(selectDateRangeOptions);
    const filterOptions = useAppSelector(selectFilterOptions);
    const [currentFilters, setCurrentFilter] = React.useState({
        ...filterOptions,
        range: DateRangeOption.ThisMonth,
    });
    return (_jsx(FilterToolBarContainer, { children: _jsx(SAForm, { onFinish: () => {
                dispatch(setFilterOption({ ...currentFilters }));
                dispatch(getOverallCostSummary());
            }, disabled: true, children: _jsxs(SARow, { children: [_jsx(SAColumn, { xs: 24, md: 6, lg: 4, children: _jsx(SAFormItem, { label: "Service Office", children: _jsx(Select, { options: collectionPoints, value: currentFilters.selectedCollectionPointId, onChange: (value) => {
                                    setCurrentFilter((prev) => ({ ...prev, selectedCollectionPointId: value }));
                                }, fieldNames: { label: "collectionPointName", value: "id" } }) }) }), _jsx(SAColumn, { xs: 24, md: 6, lg: 4, children: _jsx(SAFormItem, { label: "Client", children: _jsx(Select, { disabled: true, options: listClients, value: currentFilters.selectedOnsiteClientId, onChange: (value) => {
                                    setCurrentFilter((prev) => ({ ...prev, selectedOnsiteClientId: value }));
                                }, fieldNames: { label: "name", value: "id" } }) }) }), _jsx(SAColumn, { xs: 24, md: 6, lg: 6, children: _jsx(SAFormItem, { label: "Date Range", children: _jsxs(Select, { value: currentFilters.range, onChange: (value) => {
                                    const selectedRange = dateRangeOptions.find((option) => option.key === value);
                                    if (value === DateRangeOption.SelectRange) {
                                        const currentDate = moment();
                                        setCurrentFilter((prev) => ({
                                            ...prev,
                                            startDate: currentDate,
                                            endDate: currentDate.clone().add(1, "month"),
                                            range: value,
                                        }));
                                    }
                                    if (selectedRange)
                                        setCurrentFilter((prev) => ({
                                            ...prev,
                                            startDate: selectedRange.startDate,
                                            endDate: selectedRange.endDate,
                                            range: value,
                                        }));
                                }, children: [dateRangeOptions.map((option) => (_jsx(Select.Option, { value: option.key, children: _jsxs(DateWrapper, { children: [_jsx(Typography.Text, { children: option.label }), _jsxs(Typography.Text, { children: [option.startDate.format("MMM YYYY"), " - ", option.endDate.format("MMM YYYY")] })] }) }, option.key))), _jsx(Select.Option, { value: DateRangeOption.SelectRange, children: _jsxs(DateWrapper, { children: [_jsxs(IconText, { children: [_jsx(CalendarOutlined, {}), "Select Year and Month"] }), _jsx(Typography.Text, { children: "Select" })] }) })] }) }) }), _jsx(SAColumn, { xs: 24, md: 6, lg: 3, children: _jsx(SAFormItem, { label: "Start Date", children: _jsx(FullDatePicker, { value: currentFilters.startDate, picker: "month", onChange: (startDate) => {
                                    setCurrentFilter((prev) => ({
                                        ...prev,
                                        startDate,
                                        range: DateRangeOption.SelectRange,
                                    }));
                                }, format: "MMM YYYY" }) }) }), _jsx(SAColumn, { xs: 24, md: 6, lg: 3, children: _jsx(SAFormItem, { label: "End Date", children: _jsx(FullDatePicker, { value: currentFilters.endDate, picker: "month", onChange: (endDate) => {
                                    setCurrentFilter((prev) => ({
                                        ...prev,
                                        endDate,
                                        range: DateRangeOption.SelectRange,
                                    }));
                                }, format: "MMM YYYY" }) }) }), _jsx(SAColumn, { xs: 24, md: 6, lg: 4, children: _jsx(SAFormItem, { label: " ", children: _jsx(SubmitButton, { htmlType: "submit", children: "Update" }) }) })] }) }) }));
};
export default FilterToolBar;
const FilterToolBarContainer = styled.div `
  background-color: #f7f8f8;
  width: calc(100% + 4rem);
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  margin: -2rem;
  margin-bottom: 0rem;
`;
const DateWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const SubmitButton = styled(Button) `
  width: 100%;
  background-color: #156cc9;
  color: #ffffff;
`;
const IconText = styled(Typography.Text) `
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
const FullDatePicker = styled(DatePicker) `
  width: 100%;
`;
