import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dropdown, Image, Menu, Space } from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MESSAGE_BOX_TYPE, SAColourScheme, mainAppColours } from "src/constants";
import ICMenuOptions from "/public/assets/svg/ic-options.svg";
import ContentContext from "src/providers/content";
import { useSettings } from "src/providers/SettingsContextProvider";
import { getDateFormat } from "src/utils/helper";
import { DataTable } from "src/components/commonElement/DataTable";
import SatchelIcon from "src/assets/svg/inventory/ic-satchel.svg";
import { TableWrapper } from "../NewSatchel/index.style";
export const ArchiveSatchels = ({ transportSatchels, restoreSatchel }) => {
    const navigate = useNavigate();
    const { settings } = useSettings();
    const { openMessageBox } = useContext(ContentContext);
    const columns = [
        {
            title: "No.",
            dataIndex: "no",
            key: "no",
            width: 75,
            render: (_, record, index) => index + 1,
        },
        {
            title: "Barcode Number",
            dataIndex: "barcodeNumber",
            key: "barcodeNumber",
            sorter: (a, b) => a.barcodeNumber.localeCompare(b.barcodeNumber),
            sortDirections: ["ascend", "descend"],
            render: (text, record) => (_jsx(_Fragment, { children: _jsx("a", { onClick: () => navigate(`/inventory/add`, {
                        state: record,
                    }), style: { color: "#2A44FF", textDecoration: "underline" }, children: text }) })),
        },
        {
            title: "Commission Date",
            dataIndex: "commissionDate",
            key: "commissionDate",
            sorter: (a, b) => a.commissionDate.localeCompare(b.commissionDate),
            render: (text, record) => {
                const { commissionDate } = record;
                let formattedDate = "";
                if (commissionDate) {
                    formattedDate = getDateFormat(new Date(commissionDate), settings);
                }
                return _jsx(Space, { children: formattedDate });
            },
        },
        {
            title: "Location",
            dataIndex: "region",
            key: "region",
            sorter: (a, b) => a.region.localeCompare(b.region),
        },
        {
            title: "",
            key: "action",
            width: 55,
            render: (_, record) => (_jsx(Dropdown, { overlay: _jsxs(Menu, { style: {
                        border: `1px solid ${mainAppColours.MID_BLUE}`,
                        width: "150px",
                    }, children: [_jsx(Menu.Item, { onClick: () => navigate(`/inventory/add`, {
                                state: record,
                            }), children: "View Details" }, "1"), _jsx(Menu.Item, { style: {
                                borderTop: "1px solid #ADB3BC",
                                width: "80%",
                                margin: "0 auto",
                            }, onClick: () => {
                                openMessageBox(MESSAGE_BOX_TYPE.PROMPT_BLUE, `Transport satchel with Barcode No. ${record.barcodeNumber} will be restored and available for use.`, () => {
                                    restoreSatchel(record.id);
                                });
                            }, children: _jsx("span", { style: { display: "flex", justifyContent: "flex-end" }, children: "Restore" }) }, "2")] }), trigger: ["click"], children: _jsx("div", { className: "menu-options", children: _jsx(ICMenuOptions, {}) }) })),
        },
    ];
    const [tableVisible, setTableVisible] = useState(false);
    const handleButtonClick = () => {
        setTableVisible(!tableVisible);
    };
    return (_jsx(TableWrapper, { children: _jsxs("div", { style: { marginTop: 30 }, children: [_jsxs(Button, { onClick: handleButtonClick, style: {
                        border: `1px solid #D9D9D9`,
                        borderRadius: "4px",
                        marginBottom: 10,
                        background: `${SAColourScheme.PLASSMA10}`,
                    }, children: [_jsx("span", { style: { color: `${mainAppColours.MID_BLUE}` }, children: tableVisible ? "Hide Archive" : "Show Archive" }), _jsx("span", { style: { marginLeft: 12 }, children: tableVisible ? (_jsx(Image, { src: "/assets/svg/minus-icon.svg", preview: false })) : (_jsx(Image, { src: "/assets/svg/ic-history-add.svg", preview: false })) })] }), _jsx("div", { style: {
                        borderBottom: "2px solid #01B4D2",
                        marginBottom: "20px",
                    } }), _jsxs("div", { style: {
                        display: "flex",
                        justifyContent: "flex-start",
                        visibility: tableVisible ? "visible" : "hidden",
                        marginBottom: "10px",
                        alignItems: "center",
                    }, children: [_jsx(SatchelIcon, {}), _jsx("span", { style: { marginLeft: "10px", fontSize: "16px", color: "#000000" }, children: "Archived Satchel Inventory" })] }), tableVisible && (_jsx(DataTable, { columns: columns, dataSource: transportSatchels.length > 0
                        ? transportSatchels.map((item) => ({ ...item, key: item.id }))
                        : [] }))] }) }));
};
