import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch } from "src/store/hooks";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import { breadcrumbs } from "..";
import { setResetStore } from "./store/slice";
import { getCurrentITServiceInvoice } from "./store/action";
import { ITServiceBillingCycleHeader, PlatformTrainingBox, ServiceEstablishmentBox, SupportBox, } from "./components";
import TrainingBox from "./components/TrainingBox";
const AccountingITServices = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    React.useEffect(() => () => {
        dispatch(setResetStore());
    }, [dispatch, location.pathname]);
    useEffect(() => {
        dispatch(getCurrentITServiceInvoice());
    }, []);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(React.Fragment, { children: [_jsx(ITServiceBillingCycleHeader, {}), _jsxs(NotificationMessage, { children: [_jsx(Typography.Title, { level: 5, children: "Current costs as of today " }), _jsx(Typography.Text, { children: "(updated daily 12:00 am)" })] }), _jsxs(Panels, { children: [_jsx(SupportBox, {}), _jsx(HeadingText, { level: 5, children: "Additional IT Services" }), _jsx(TrainingBox, {}), _jsx(ServiceEstablishmentBox, {}), _jsx(PlatformTrainingBox, {})] })] }) }));
};
export default AccountingITServices;
const Panels = styled.div `
  padding: 0;
`;
const NotificationMessage = styled.div `
  margin: 1rem 0;
  margin-top: 2rem;
  background-color: #f7f8f8;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.5rem 1rem;

  h5 {
    color: #1a8cff;
  }
`;
const HeadingText = styled(Typography.Title) `
  color: #01b4d2 !important;
  border-bottom: 0.025rem solid #01b4d2;
`;
