import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
const PremiseBarChart = ({ dataSource }) => (_jsx(ResponsiveContainer, { width: "100%", height: "100%", children: _jsxs(BarChart, { width: 500, height: 300, data: dataSource, margin: {
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
        }, children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "label" }), _jsx(YAxis, {}), _jsx(Tooltip, {}), _jsx(Bar, { dataKey: "count", fill: "#00004C" })] }) }));
export default PremiseBarChart;
