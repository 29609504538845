import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Checkbox, DatePicker, Divider, Spin, Table, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { uuidv4 } from "@antv/xflow-core";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const CustomPeriodSummaryTable = ({ showCollector, showTotal, dataSource, viewOption, setViewOptions, }) => {
    const currentYear = new Date().getFullYear();
    return (_jsxs(React.Fragment, { children: [!dataSource.length && (_jsxs(ErrorBox, { children: [_jsx(Spin, {}), "Querying data or occurring an error"] })), dataSource.map((table, index) => (_jsx(StyledTableWrapper, { children: _jsx(Table, { dataSource: table.entries?.map((entry) => ({ ...entry, id: uuidv4() })) ?? [], rowKey: (record) => record.id, pagination: false, bordered: false, showHeader: index === 0, components: {
                        header: {
                            wrapper: (props) => (_jsxs("thead", { ...props, children: [_jsx("tr", { children: _jsx("th", { colSpan: 6, style: { background: "#f5f5f5", height: "5rem" }, children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, justify: "center", children: _jsx(Typography.Text, { children: "Options:" }) }), _jsx(SAColumn, { span: 6, justify: "center", children: _jsx(Checkbox, { checked: Boolean(viewOption.year && !viewOption.month), onChange: () => {
                                                                setViewOptions({ year: currentYear, month: null });
                                                            }, children: "Year View" }) }), _jsx(SAColumn, { span: 6, justify: "center", onChange: () => {
                                                            setViewOptions({ year: currentYear, month: 12 });
                                                        }, children: _jsx(Checkbox, { checked: Boolean(viewOption.month && viewOption.month > 0), children: "Month View" }) }), _jsx(SAColumn, { span: 6, justify: "center", children: _jsx(DatePicker, { picker: "year", placeholder: "YYYY", disabled: !viewOption.month, value: viewOption.month && viewOption.month > 0
                                                                ? moment().year(viewOption.year)
                                                                : null, onChange: (date) => {
                                                                if (date) {
                                                                    setViewOptions({ year: date.year(), month: 12 });
                                                                }
                                                                else {
                                                                    setViewOptions({ year: currentYear, month: null });
                                                                }
                                                            }, disabledDate: (current) => current && current.year() > currentYear, suffixIcon: false }) })] }) }) }), props.children, _jsx("tr", { children: _jsxs("td", { colSpan: 6, children: [_jsx(OptionButton, { "$isLeft": true, disabled: Boolean(viewOption.month && viewOption.month <= 6), onClick: () => {
                                                        if (viewOption.year && !viewOption.month) {
                                                            setViewOptions({ year: viewOption.year - 6, month: null });
                                                        }
                                                        if (viewOption.year && viewOption.month && viewOption.month >= 6) {
                                                            setViewOptions({
                                                                year: viewOption.year,
                                                                month: viewOption.month - 6,
                                                            });
                                                        }
                                                    }, children: _jsx(LeftOutlined, {}) }), _jsx(OptionButton, { disabled: Boolean((viewOption.month && viewOption.month >= 12) ||
                                                        (!viewOption.month && viewOption.year >= currentYear)), onClick: () => {
                                                        if (viewOption.year && !viewOption.month) {
                                                            setViewOptions({ year: viewOption.year + 6, month: null });
                                                        }
                                                        if (viewOption.year && viewOption.month && viewOption.month > 0) {
                                                            setViewOptions({
                                                                year: viewOption.year,
                                                                month: viewOption.month + 6,
                                                            });
                                                        }
                                                    }, children: _jsx(RightOutlined, {}) })] }) })] })),
                        },
                        body: {
                            wrapper: (props) => (_jsxs("tbody", { ...props, children: [showCollector && index === 0 && (_jsx("tr", { children: _jsx("td", { children: _jsx(CollectorBox, {}) }) })), table.groupTitle && (_jsx("tr", { children: _jsx("td", { children: _jsx(EmptyRow, {}) }) })), props.children, dataSource.length - index > 1 && (_jsx("tr", { children: _jsx("td", { colSpan: 6, style: { padding: "0 1.1rem" }, children: _jsx(Divider, { style: { marginBottom: "0.1rem" } }) }) }))] })),
                        },
                    }, children: table.entries.length &&
                        Object.keys(table.entries[0])
                            .filter((key) => key !== "category")
                            .map((period) => (_jsx(Table.Column, { title: period, dataIndex: period, align: "center", width: 50, render: (text, record) => {
                                if (showTotal && record.category === "Total") {
                                    return (_jsx(TotalQuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                                }
                                return (_jsx(QuantityBox, { children: _jsx(Typography.Text, { children: text }) }));
                            } }, period))) }) }, index)))] }));
};
export default CustomPeriodSummaryTable;
const StyledTableWrapper = styled.div `
  width: 100%;

  .ant-table-thead > tr:nth-child(2) th {
    background-color: #71b9f8;
    color: #ffffff;
    border: none !important;
  }
  .ant-table-thead > tr:nth-child(3) td {
    background-color: #71b9f8;
    color: #ffffff;
    border-bottom: 0.1rem solid #2e94f2 !important;
  }
`;
const QuantityBox = styled.div `
  border: 0.025rem solid #ebedf0;
  border-radius: 0.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.4rem;
  width: 4rem;
  margin: 0 auto;
`;
const CollectorBox = styled.div `
  height: 4rem;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
`;
const EmptyRow = styled.div `
  height: 2.4rem;
`;
const TotalQuantityBox = styled(QuantityBox) `
  font-weight: bold;
  background-color: #ebedf0;
`;
const ErrorBox = styled.div `
  height: 10rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const OptionButton = styled(Button) `
  background-color: #ffffff;
  padding: 0 0.1rem;
  margin: 0;
  border: 0.025rem solid #2e94f2;
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 6.5rem;
  z-index: 99;

  left: ${(props) => (props.$isLeft ? "0.2rem" : "auto")};
  right: ${(props) => (props.$isLeft ? "auto" : "0.2rem")};
  svg {
    color: #2e94f2;
  }
`;
