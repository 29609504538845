import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Legend, Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList, } from "recharts";
const SummaryBarChart = ({ dataSource }) => (_jsx(ResponsiveContainer, { width: "100%", height: "100%", children: _jsxs(BarChart, { width: 500, height: 300, data: dataSource, margin: {
            top: 5,
            right: 30,
            left: 10,
            bottom: 5,
        }, children: [_jsx(CartesianGrid, { vertical: false, strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "title" }), _jsx(YAxis, {}), _jsx(Tooltip, {}), _jsx(Bar, { dataKey: "remaining", fill: "#5CD22B", name: "Remaining Inventory", children: _jsx(LabelList, { dataKey: "remaining", position: "top", fill: "#000" }) }), _jsx(Bar, { dataKey: "used", fill: "#1A8CFF", name: "Used Inventory", children: _jsx(LabelList, { dataKey: "used", position: "top", fill: "#000" }) }), _jsx(Legend, {})] }) }));
export default SummaryBarChart;
