var KioskEntryStatus;
(function (KioskEntryStatus) {
    KioskEntryStatus[KioskEntryStatus["CHECKED_IN"] = 1] = "CHECKED_IN";
    KioskEntryStatus[KioskEntryStatus["CHECKED_OUT"] = 2] = "CHECKED_OUT";
    KioskEntryStatus[KioskEntryStatus["IN_COMPLETE"] = 3] = "IN_COMPLETE";
})(KioskEntryStatus || (KioskEntryStatus = {}));
const InitialEntryDashboard = {
    category: KioskEntryStatus.CHECKED_IN,
    total: 0,
    byMonth: [],
    byQuarter: [],
};
export { KioskEntryStatus, InitialEntryDashboard };
