import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const PriceItem = ({ name, cost }) => (_jsxs(SARow, { gutter: [0, 16], children: [_jsx(SAColumn, { span: 16, align: "flex-start", children: _jsx(ItemName, { children: _jsx(Text, { children: name }) }) }), _jsx(SAColumn, { span: 8, align: "flex-end", children: _jsx(ItemPrice, { children: _jsxs(Text, { children: ["$ ", cost] }) }) })] }));
export default PriceItem;
const ItemName = styled.div `
  width: 80%;
  border-bottom: 0.025rem solid #ffffff;
  display: flex;
  justify-content: space-between;
  padding-block: 0.25rem;
`;
const ItemPrice = styled.div `
  width: 80%;
  border-bottom: 0.025rem solid #ffffff;
  display: flex;
  justify-content: flex-end;
  padding-block: 0.25rem;
`;
const Text = styled.p `
  font-size: 0.875rem;
`;
