var LicenseProductType;
(function (LicenseProductType) {
    LicenseProductType[LicenseProductType["COLLECTION_MANAGER"] = 1] = "COLLECTION_MANAGER";
    LicenseProductType[LicenseProductType["COLLECT_ASSIST"] = 2] = "COLLECT_ASSIST";
    LicenseProductType[LicenseProductType["WELCOME_ASSIST"] = 3] = "WELCOME_ASSIST";
})(LicenseProductType || (LicenseProductType = {}));
var SubscribedProductType;
(function (SubscribedProductType) {
    SubscribedProductType[SubscribedProductType["SECURE_ASSIST"] = 1] = "SECURE_ASSIST";
    SubscribedProductType[SubscribedProductType["REPORT_ASSIST"] = 2] = "REPORT_ASSIST";
    SubscribedProductType[SubscribedProductType["WORK_ASSIST"] = 3] = "WORK_ASSIST";
})(SubscribedProductType || (SubscribedProductType = {}));
const initialLicense = {
    type: "",
    licenseId: "",
    noLicenses: 0,
    unitCost: "",
    unitCostIncludingGST: "",
};
const initialSubscription = {
    type: "",
    subscriptionId: "",
    planId: "",
    unitCost: "",
    unitCostIncludingGST: "",
};
const initialInvoice = {
    currentDate: "",
    billingCycleType: "",
    nextInvoiceIssueDate: "",
    startAt: "",
    endAt: "",
    collectionManager: { ...initialLicense, noAdmins: 0 },
    collectAssist: { ...initialLicense, noCollectors: 0 },
    secureAssist: initialSubscription,
    reportAssist: initialSubscription,
    workAssist: initialSubscription,
    welcomeAssist: { ...initialLicense, noDevices: 0 },
};
var LicenseError;
(function (LicenseError) {
    LicenseError["EFB001"] = "ECB001";
})(LicenseError || (LicenseError = {}));
export { LicenseProductType, SubscribedProductType, initialInvoice, LicenseError, initialLicense, initialSubscription, };
