import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get, $post } from "src/infra/http";
const getOrganizationDetail = createAsyncThunk("getServiceOffice", async ({ newPage = 1, size = 10, field, order } = {}, thunkApi) => {
    const { searchKeyWord } = thunkApi.getState().collectionOrganizationPage.serviceOfficePage;
    const params = {
        searchKeyWord,
        field,
        order,
        page: newPage,
        limit: size,
    };
    const searchQuery = stringify(params);
    return await $get(`/collection-manager/collection-organization/service-office/search?${searchQuery}`);
});
const updateServiceOffice = createAsyncThunk("updateService", async (updatedServiceOffice, thunkApi) => {
    const { organizationDetail } = thunkApi.getState().collectionOrganizationPage.serviceOfficePage;
    return await $post(`/collection-manager/collection-organization/update`, {
        organizationDetail: organizationDetail,
        newCollectionPoints: [],
        oldCollectionPoints: updatedServiceOffice,
    });
});
const createServiceOffice = createAsyncThunk("createService", async (newServiceOffice, thunkApi) => {
    const { organizationDetail } = thunkApi.getState().collectionOrganizationPage.serviceOfficePage;
    return await $post(`/collection-manager/collection-organization/update`, {
        organizationDetail: organizationDetail,
        newCollectionPoints: newServiceOffice,
        oldCollectionPoints: [],
    });
});
const deleteOrganizationServiceOffice = createAsyncThunk("deleteService", async (serviceOfficeId) => await $get(`/collection-manager/collection-organization/collection-point/delete/${serviceOfficeId}`));
export { updateServiceOffice, createServiceOffice, deleteOrganizationServiceOffice, getOrganizationDetail, };
