import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer, } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { AccountIcon } from "src/assets/svg/common";
import { mainAppColours, supportingColours } from "src/styles";
import { CollectorCalendarEventType } from "../store/types";
import { CalendarView } from "../typing";
import EventDetailPopover from "./EventDetailPopover";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const CustomEvent = ({ event }) => {
    const { allDay, title, start, end, adjustedStartDate, adjustedEndDate, note } = event;
    const startDate = adjustedStartDate
        ? moment(adjustedStartDate).format("HH:mm (DD MMM YYYY)")
        : moment(start).format("HH:mm");
    const endDate = adjustedEndDate
        ? moment(adjustedEndDate).format("HH:mm (DD MMM YYYY)")
        : moment(end).format("HH:mm");
    const eventTime = allDay ? "All Day" : `${startDate} - ${endDate}`;
    const isShowInAllDay = event.eventType === CollectorCalendarEventType.Leave;
    return (_jsx(EventDetailPopover, { event: event, children: _jsxs(EventContainer, { allDay: isShowInAllDay, children: [_jsx(SideBar, { allDay: isShowInAllDay }), _jsx(EventDetails, { children: _jsxs(Space, { align: isShowInAllDay ? "center" : "start", children: [_jsx(AccountIcon, {}), _jsxs(Space, { direction: isShowInAllDay && allDay ? "horizontal" : "vertical", children: [_jsx(Typography.Title, { level: 5, children: title }), !(isShowInAllDay && allDay) && (_jsxs(React.Fragment, { children: [_jsx(Typography.Text, { children: eventTime }), _jsx(Typography.Text, { children: note })] }))] })] }) })] }) }));
};
const eventPropGetter = () => {
    const newStyle = {
        backgroundColor: "#ffffff",
        borderColor: "transparent",
        color: `${supportingColours.TEXT_DARK_GRAY}`,
        borderRadius: "0.25rem",
    };
    return {
        className: "",
        style: newStyle,
    };
};
const CollectorWeekCalendar = ({ events, currentDate, ...props }) => {
    const firstEvent = events.sort((a, b) => a.start.getTime() - b.start.getTime())[0]
        ?.start ?? new Date();
    return (_jsx(WeekCalendarWrapper, { children: _jsx(CustomDnDCalendar, { view: CalendarView.Week, onView: () => void 0, date: currentDate, onNavigate: () => void 0, events: events, localizer: localizer, defaultDate: firstEvent, scrollToTime: firstEvent, showAllEvents: true, timeslots: 12, step: 5, formats: {
                eventTimeRangeFormat: () => "",
            }, components: {
                toolbar: () => _jsx(Toolbar, {}),
                timeGutterHeader: () => (_jsx(TimeGutterHeader, { children: _jsx(TimeGutterHeaderContent, { children: "All Day" }) })),
                dateCellWrapper: (prop) => _jsx(DateCellWrapper, { children: prop.children }),
                timeSlotWrapper: (prop) => _jsx(TimeSlotWrapper, { children: prop.children }),
                eventContainerWrapper: (prop) => (_jsx(EventContainerWrapper, { children: prop.children })),
                event: CustomEvent,
            }, eventPropGetter: eventPropGetter, ...props }) }));
};
export default CollectorWeekCalendar;
const WeekCalendarWrapper = styled.div `
  width: 100%;
  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
  }
  .rbc-events-container .rbc-background-event {
    background-color: #f5e2e2;
  }

  .rbc-events-container .rbc-background-event .rbc-event-content {
    color: #01b4d2;
    font-weight: bold;
  }

  .rbc-time-header.rbc-overflowing {
    border-right: none;
  }
  .rbc-time-header-content {
    border-left: none;
    border-right: none;
    margin-right: 0.5rem;
  }

  .rbc-timeslot-group {
    min-height: 10rem;
  }

  .rbc-background-event {
    background-color: #e5e5e5;
    border-color: transparent;
    border-radius: unset;
  }

  .rbc-timeslot-group,
  .rbc-time-view,
  .rbc-time-content > .rbc-time-gutter > .rbc-timeslot-group,
  .rbc-day-slot .rbc-time-slot {
    border: none;
  }
  .rbc-timeslot-group {
    border-left: 0.063rem solid #ebedf0;
  }
  .rbc-day-slot > div > div:nth-child(1) {
    border-top: none;
    border-bottom: 0.063rem solid #ebedf0;
  }

  .rbc-time-content {
    border-top: 0.1rem solid #01b4d2;
    .rbc-today {
      background-color: transparent;
      color: ${mainAppColours.MID_BLUE};
    }

    .rbc-current-time-indicator {
      display: none;
    }
  }

  .rbc-time-header-content {
    .rbc-today {
      background-color: transparent;
      color: ${mainAppColours.MID_BLUE};
    }

    .rbc-current-time-indicator {
      display: none;
    }
  }

  .rbc-time-view .rbc-allday-cell {
    max-height: 10rem;
    overflow: auto;
  }

  .rbc-time-header-cell .rbc-header,
  .rbc-time-header-gutter {
    border-bottom: 0.063rem solid #01b4d2;
  }
`;
const CustomDnDCalendar = styled(DnDCalendar) `
  height: 70vh;
  border-bottom: 0.063rem solid #01b4d2;
`;
const Toolbar = styled.div `
  width: 100%;
`;
const TimeGutterHeader = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: none;
  height: 50%;
`;
const TimeGutterHeaderContent = styled.p `
  color: #4b5767;
  font-weight: bold;
`;
const TimeSlotWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: white;
  color: #7f8e9d;
`;
const DateCellWrapper = styled.div `
  display: flex;
  flex-direction: column;
  background-color: #01b4d2;
  align-items: flex-end;
  padding: 0;
  margin: 0 5px;
`;
const EventContainerWrapper = styled.div `
  background-color: #ffffff;
  color: ${supportingColours.TEXT_DARK_GRAY};
`;
const EventContainer = styled.div `
  display: flex;
  background-color: ${({ allDay }) => (allDay ? "#F2E5FF" : "#FAE3E2")};
  color: #000;
  border-radius: 0.25rem;
  height: 100%;

  svg {
    color: ${({ allDay }) => (allDay ? "#EB41E4" : "#FD0000")};
  }
`;
const SideBar = styled.div `
  background-color: ${({ allDay }) => (allDay ? "#EB41E4" : "#FD0000")};
  color: white;
  padding: 0.1rem;
  text-align: center;
  font-weight: bold;
  word-wrap: break-word;
  white-space: normal;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`;
const EventDetails = styled.div `
  padding: 0.5rem;
  word-wrap: break-word;
  white-space: normal;
`;
