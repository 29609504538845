import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BellOutlined, CalendarOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import * as React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AddInventory from "src/assets/svg/inventory/add-inventory.svg";
import SASelect from "src/components/Form/SASelect";
import SAColumn from "src/components/Form/Table/SAColumn";
import SASwitch from "src/components/Form/Table/SASwitch";
import { selectInventorySummaryView } from "src/pages/InventoryManagement/store/selector";
import { selectDateRangeOptions } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getInventoryDetail } from "../store/action";
import { InventorySummaryView } from "../store/types";
import GraphInventoryView from "./components/GraphInventoryView";
import ListInventoryView from "./components/ListInventoryView";
import SummaryView from "./components/SummaryView";
const InventorySummary = () => {
    const dispatch = useAppDispatch();
    const summaryView = useAppSelector(selectInventorySummaryView);
    const [dateRange, setDateRange] = React.useState("");
    const dateRangeOptions = React.useMemo(() => selectDateRangeOptions(), []);
    const [filters, setFilters] = React.useState({});
    useEffect(() => {
        const selectedRange = dateRangeOptions.find((option) => option.key === dateRange);
        setFilters((prev) => ({
            ...prev,
            startAt: selectedRange?.startDate.toDate(),
            endAt: selectedRange?.endDate.toDate(),
        }));
    }, [dateRange]);
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getInventoryDetail(filters));
        };
        fetchData();
    }, [filters]);
    return (_jsxs(React.Fragment, { children: [_jsx(SAColumn, { md: 12, align: "start", children: _jsxs(Space, { children: [_jsx(SummaryView, {}), _jsx(Typography.Title, { level: 4, children: "Inventory Summary" })] }) }), _jsx(SAColumn, { md: 6, children: _jsx(SASelect, { value: dateRange || "", onChange: (value) => setDateRange(value || ""), options: [{ label: "Show All", key: "" }, ...dateRangeOptions], fieldNames: { label: "label", value: "key" }, children: _jsx(CalendarOutlined, {}) }) }), _jsx(SAColumn, { md: 6, align: "end", children: _jsxs(Space, { children: [_jsx(Button, { type: "text", icon: _jsx(BellOutlinedCustom, {}), size: "middle", shape: "round", children: "Low Stock Reminder" }), _jsx(SASwitch, { checked: true, style: { backgroundColor: "#4caf50" } })] }) }), summaryView === InventorySummaryView.List ? _jsx(ListInventoryView, {}) : _jsx(GraphInventoryView, {}), _jsx(SAColumn, { children: _jsx(SectionContainer, { children: _jsxs(InventoryEntryContainer, { children: [_jsxs(Space, { children: [_jsx(AddInventory, {}), _jsx(Typography.Title, { level: 5, children: "Inventory Entry" })] }), _jsxs(Space, { children: [_jsx(TextMessageClick, { children: "To add new devices or stock, click here" }), _jsx(Link, { to: "/testing-devices/urine/screen-device", children: _jsxs(Button, { type: "primary", children: ["Device Management", _jsx(RightOutlined, { color: "white" })] }) })] })] }) }) })] }));
};
export default InventorySummary;
const BellOutlinedCustom = styled(BellOutlined) `
  font-size: 18px;
  color: green;
`;
const SectionContainer = styled.div `
  width: 100%;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;
const InventoryEntryContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const TextMessageClick = styled(Typography.Text) `
  color: #01b4d2;
  font-weight: 500;
`;
