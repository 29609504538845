import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DatePicker, Divider, Select, TreeSelect, Typography } from "antd";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import CallOutIcon from "src/assets/svg/job-management/call-out-icon.svg";
import Refresh from "src/assets/svg/job-management/refresh-icon.svg";
import CreateIcon from "src/assets/svg/job-management/create-icon.svg";
import MinusIcon from "src/assets/svg/job-management/minus-icon.svg";
import NewJobIcon from "src/assets/svg/job-management/new-job-icon.svg";
import OnsiteJobIcon from "src/assets/svg/job-management/onsite-job-icon.svg";
import { mainAppColours } from "src/constants";
import { selectCollectionPoints } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { InputWithLabel } from "src/components/base";
import { getListActiveJobs, getListArchiveJobs, getListClients } from "../store/action";
import { listStatusTypes, selectCurrentCollectionPoint, selectDropdownClient, selectListActiveJobs, selectListClients, selectedEndDate, selectedStartDate, selectedStatusTypes, selectPagingMetaData, selectPagingMetaDataArchive, selectIsArchiveStatus, selectListArchiveJobs, } from "../store/selector";
import { setCurrentCollectionPoint, setDropdownSelectedClient, setEndDate, setIsArchiveStatus, setResetStore, setSelectedStatusTypes, setStartDate, } from "../store/slice";
import JobTable from "./JobTable";
export const OnSiteJobs = () => {
    const parentLocation = useLocation();
    const dispatch = useAppDispatch();
    const activeJobs = useAppSelector(selectListActiveJobs);
    const archiveJobs = useAppSelector(selectListArchiveJobs);
    const isArchived = useAppSelector(selectIsArchiveStatus);
    const collectionPoints = useAppSelector(selectCollectionPoints);
    const startDate = useAppSelector(selectedStartDate);
    const endDate = useAppSelector(selectedEndDate);
    const clients = useAppSelector(selectListClients);
    const statusTypes = useAppSelector(listStatusTypes);
    const selectedClient = useAppSelector(selectDropdownClient);
    const selectedCollectionPoint = useAppSelector(selectCurrentCollectionPoint);
    const selectedStatus = useAppSelector(selectedStatusTypes);
    const pagingMetaData = useAppSelector(selectPagingMetaData);
    const pagingMetaDataArchive = useAppSelector(selectPagingMetaDataArchive);
    const refreshData = async () => {
        dispatch(setResetStore());
        dispatch(setIsLoading(true));
        await dispatch(getListActiveJobs({}));
        dispatch(setIsLoading(false));
    };
    useEffect(() => {
        const loadData = async () => {
            dispatch(setResetStore());
            dispatch(setIsLoading(true));
            await dispatch(getListClients());
            await dispatch(getListActiveJobs({}));
            dispatch(setIsLoading(false));
        };
        loadData();
    }, []);
    return (_jsxs(ContentWrapper, { children: [_jsxs(ToolBarWrapper, { children: [_jsxs(MainTitle, { children: [_jsx(OnsiteJobIcon, {}), _jsx(Typography.Title, { level: 5, children: "On-Site Jobs" })] }), _jsxs(Header, { children: [_jsxs(CreateButton, { onClick: refreshData, children: ["Refresh ", _jsx(Refresh, {})] }), _jsx(Link, { to: `${parentLocation.pathname}/create-call-out`, children: _jsxs(CreateButton, { icon: _jsx(CreateIcon, {}), children: ["New Call Out ", _jsx(CallOutIcon, {})] }) }), _jsx(Link, { to: `${parentLocation.pathname}/create-normal`, children: _jsxs(CreateButton, { icon: _jsx(CreateIcon, {}), children: ["New Job ", _jsx(NewJobIcon, {})] }) })] })] }), _jsx(Divider, {}), _jsxs(JobFilter, { children: [_jsxs(LocationList, { children: [_jsx(Typography.Text, { children: "Service Office" }), _jsx(CustomSelect, { value: selectedCollectionPoint.name, options: [
                                    { value: "", label: "All" },
                                    ...collectionPoints.map((cp) => ({
                                        value: cp.id,
                                        label: cp.collectionPointName,
                                    })),
                                ], onChange: async (_, option) => {
                                    const selectedValue = option;
                                    dispatch(setIsLoading(true));
                                    dispatch(setCurrentCollectionPoint({
                                        id: selectedValue.value,
                                        collectionPointName: selectedValue.label,
                                    }));
                                    await dispatch(getListActiveJobs({}));
                                    dispatch(setIsArchiveStatus(false));
                                    dispatch(setIsLoading(false));
                                } })] }), _jsxs(LocationList, { children: [_jsx(Typography.Text, { children: "Client" }), _jsx(CustomSelect, { value: selectedClient.name, options: [
                                    { value: "", label: "All" },
                                    ...clients.map((cp) => ({
                                        value: cp.id,
                                        label: cp.name,
                                    })),
                                ], onChange: async (_, option) => {
                                    const selectedValue = option;
                                    dispatch(setIsLoading(true));
                                    dispatch(setDropdownSelectedClient({
                                        id: selectedValue.value,
                                        name: selectedValue.label,
                                    }));
                                    await dispatch(getListActiveJobs({}));
                                    dispatch(setIsArchiveStatus(false));
                                    dispatch(setIsLoading(false));
                                } })] }), _jsxs(StyledDiv, { children: [_jsx(Typography.Text, { children: "Date" }), _jsx(DatePicker, { suffixIcon: null, value: startDate, onChange: async (date) => {
                                    dispatch(setIsLoading(true));
                                    dispatch(setStartDate(date));
                                    await dispatch(getListActiveJobs({}));
                                    dispatch(setIsArchiveStatus(false));
                                    dispatch(setIsLoading(false));
                                }, placeholder: "Starts" }), _jsx(DatePicker, { suffixIcon: null, value: endDate, onChange: async (date) => {
                                    dispatch(setIsLoading(true));
                                    dispatch(setEndDate(date));
                                    await dispatch(getListActiveJobs({}));
                                    dispatch(setIsArchiveStatus(false));
                                    dispatch(setIsLoading(false));
                                }, placeholder: "Ends" })] }), _jsxs(StyledDiv, { children: [_jsx(Typography.Text, { children: "Status" }), _jsx(InputWithLabel, { theme: "3", value: selectedStatus, onInputChange: async (data) => {
                                    dispatch(setIsLoading(true));
                                    dispatch(setSelectedStatusTypes(data.value));
                                    await dispatch(getListActiveJobs({}));
                                    dispatch(setIsArchiveStatus(false));
                                    dispatch(setIsLoading(false));
                                }, inputProps: {
                                    type: "treeselect",
                                    options: [
                                        {
                                            title: "All",
                                            value: "all",
                                            children: statusTypes.map((item) => ({
                                                title: item.label,
                                                value: item.value,
                                            })),
                                        },
                                    ],
                                    treeCheckable: true,
                                    showCheckedStrategy: TreeSelect.SHOW_CHILD,
                                    treeLine: {},
                                    treeDefaultExpandAll: true,
                                    placeholder: "All",
                                } })] })] }), _jsx(MainJobBoardWrapper, { children: _jsx(JobTable, { jobData: activeJobs, pagingData: pagingMetaData, onTableChange: async (newPage, size, sortField, sortOrder) => {
                        dispatch(setIsLoading(true));
                        await dispatch(getListActiveJobs({
                            newPage: newPage,
                            size: size,
                            field: sortField ?? undefined,
                            order: sortOrder ?? undefined,
                        }));
                        dispatch(setIsArchiveStatus(false));
                        dispatch(setIsLoading(false));
                    } }) }), _jsxs(ArchiveJobBoardWrapper, { children: [_jsxs(ArchiveButton, { onClick: async () => {
                            if (isArchived) {
                                dispatch(setIsArchiveStatus(false));
                            }
                            else {
                                setIsLoading(true);
                                await dispatch(getListArchiveJobs({}));
                                dispatch(setIsArchiveStatus(true));
                                setIsLoading(false);
                            }
                        }, children: [isArchived ? "Hide Archive" : "Show Archive", isArchived ? _jsx(MinusIcon, {}) : _jsx(CreateIcon, {})] }), _jsx(CustomDivider, {}), isArchived && (_jsx(JobTable, { jobData: archiveJobs, pagingData: pagingMetaDataArchive, onTableChange: async (newPage, size, sortField, sortOrder) => {
                            dispatch(setIsLoading(true));
                            await dispatch(getListArchiveJobs({
                                newPage: newPage,
                                size: size,
                                field: sortField ?? undefined,
                                order: sortOrder ?? undefined,
                            }));
                            dispatch(setIsLoading(false));
                        } }))] })] }));
};
const ContentWrapper = styled.div `
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .ant-divider-horizontal {
    border-top: 1px solid #adb3bc;
    margin: 1rem 0 0.5rem 0;
  }
`;
const ToolBarWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
`;
const MainJobBoardWrapper = styled.div `
  width: 100%;
`;
const MainTitle = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 0.5rem;
`;
const JobFilter = styled.div `
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0.5rem;
  white-space: nowrap;
  & > *:nth-child(1),
  & > *:nth-child(2) {
    justify-self: flex-start;
  }

  & > *:nth-child(3),
  & > *:nth-child(4) {
    justify-self: flex-end;
  }
`;
const Header = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 0.5rem;
  flex-grow: 1;
`;
const LocationList = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
`;
const CustomSelect = styled(Select) `
  width: 10rem !important;
`;
const StyledDiv = styled.div `
  display: flex;
  gap: 10px;
  align-items: center;

  .ant-select-selector {
    margin-top: 8px !important;
    width: 15rem !important;
    height: 1.5rem !important;
  }
`;
const CreateButton = styled(Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.063px solid ${mainAppColours.MID_BLUE};
  border-radius: 0.25rem;
  color: ${mainAppColours.MID_BLUE};
  width: 12rem;

  & > svg {
    margin-left: 0.5rem;
  }
`;
const ArchiveJobBoardWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100% !important;
  margin-top: 2rem;
`;
const CustomDivider = styled(Divider) `
  border: 0.2rem solid #01b4d2;
  margin: 0.5rem 0 !important;
`;
const ArchiveButton = styled(Button) `
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${mainAppColours.MID_BLUE} !important;
  background-color: #e6f7fa !important;
  border: 0;
`;
