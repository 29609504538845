import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DollarOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Space } from "antd";
import { isEqual } from "lodash";
import React, { useEffect } from "react";
import styled from "styled-components";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const StockForm = ({ handleSubmit, requireProductName, isEditing, stockData, }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (isEditing && stockData) {
            form.setFieldsValue(stockData);
        }
    }, [isEditing, stockData]);
    return (_jsx(StyledStockForm, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, colon: false, onFinish: () => {
            const updatedStockData = { ...stockData, ...form.getFieldsValue() };
            if (isEqual(updatedStockData, stockData)) {
                message.error("No changes to update");
                return;
            }
            handleSubmit(updatedStockData);
            form.resetFields();
        }, children: _jsxs(SARow, { gutter: [0, 0], children: [_jsx(SAColumn, { md: 24, lg: 21, children: _jsxs(SARow, { gutter: [16, 0], children: [requireProductName && (_jsx(SAColumn, { children: _jsx(SAFormItem, { label: "Product Name", name: "productName", requiredText: "Please fill in the product name", children: _jsx(Input, {}) }) })), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(SAFormItem, { label: "Batch/Order Number", name: "batchNumber", requiredText: "Please fill in the batch number", children: _jsx(Input, {}) }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(SAFormItem, { label: "Number of Units", name: "noOfUnits", requiredText: "Please fill in the number of units", children: _jsx(Input, {}) }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(SAFormItem, { label: "Unit Price", name: "unitPrice", requiredText: "Please fill in the unit price", children: _jsx(Input, { prefix: _jsx(DollarOutlined, {}) }) }) }), _jsx(SAColumn, { children: _jsx(SAFormItem, { label: "Notes", name: "notes", children: _jsx(Input, {}) }) })] }) }), _jsx(SAColumn, { md: 24, lg: 3, justify: "center", children: isEditing ? (_jsxs(Space, { direction: "vertical", children: [_jsx(Button, { type: "primary", htmlType: "submit", children: "Update" }), _jsx(Button, { type: "primary", ghost: true, children: "Cancel" })] })) : (_jsxs(Button, { type: "primary", htmlType: "submit", children: ["Add Stock ", _jsx(PlusOutlined, {})] })) })] }) }));
};
export default StockForm;
const StyledStockForm = styled(Form) `
  background-color: #e1e2e5;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
  margin: 1rem 0;

  .ant-form-item-label {
    text-align: left;
  }
`;
