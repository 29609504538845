import moment from "moment";
import { getCurrentCalendarYear, getCurrentFinancialYear, getCurrentQuarter } from "src/utils";
import { DateRangeName, DateRangeOption } from "./types";
const selectListCollectionPoints = (state) => {
    const { collectionPoints } = state.authentication.app;
    return [{ collectionPointName: "All", id: null }, ...collectionPoints];
};
const selectListClients = (state) => {
    const { listClients } = state.accountingPage.dashboardPage;
    return [{ name: "All", id: null }, ...(listClients ?? [])];
};
const selectDateRangeOptions = () => {
    const currentMonth = moment();
    const { startOfQuarter, endOfQuarter } = getCurrentQuarter();
    const { startOfFinancialYear, endOfFinancialYear } = getCurrentFinancialYear();
    const { startOfYear, endOfYear } = getCurrentCalendarYear();
    const options = [
        {
            label: DateRangeName.ThisMonth,
            startDate: currentMonth,
            endDate: currentMonth,
            key: DateRangeOption.ThisMonth,
        },
        {
            label: DateRangeName.ThisQuarter,
            startDate: startOfQuarter,
            endDate: endOfQuarter,
            key: DateRangeOption.ThisQuarter,
        },
        {
            label: DateRangeName.ThisFinancialYear,
            startDate: startOfFinancialYear,
            endDate: endOfFinancialYear,
            key: DateRangeOption.ThisFinancialYear,
        },
        {
            label: DateRangeName.ThisCalendarYear,
            startDate: startOfYear,
            endDate: endOfYear,
            key: DateRangeOption.ThisCalendarYear,
        },
    ];
    return options;
};
const selectFilterOptions = (state) => state.accountingPage.dashboardPage.filterOptions;
const selectOverallCostSummary = (state) => state.accountingPage.dashboardPage.overallCostSummary;
const selectIsIncludedGst = (state) => state.accountingPage.dashboardPage.isIncludedGst;
const selectCollectionPointName = (state) => {
    const { selectedCollectionPointId } = state.accountingPage.dashboardPage.filterOptions;
    const { collectionPoints } = state.authentication.app;
    if (selectedCollectionPointId)
        return (collectionPoints.find((point) => point.id === selectedCollectionPointId)
            ?.collectionPointName ?? "");
    return "All";
};
const selectOnsiteClientName = (state) => {
    const { filterOptions, listClients } = state.accountingPage.dashboardPage;
    if (filterOptions.selectedOnsiteClientId)
        return (listClients.find((client) => client.id === filterOptions.selectedOnsiteClientId)?.name ?? "");
    return "All";
};
const selectDisplayCost = (state) => {
    const { isIncludedGst } = state.accountingPage.dashboardPage;
    return isIncludedGst ? "totalCostIncludingGST" : "totalCost";
};
export { selectDateRangeOptions, selectFilterOptions, selectListClients, selectListCollectionPoints, selectOverallCostSummary, selectIsIncludedGst, selectCollectionPointName, selectOnsiteClientName, selectDisplayCost, };
