import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { unwrapResult } from "@reduxjs/toolkit";
import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { uuidv4 } from "@antv/xflow-core";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import ViewDetailButton from "src/pages/Accounting/components/ViewDetailButton";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getLicenseTransactions } from "../store/action";
import { selectDisplayCost, selectOverallCostSummary } from "../store/selector";
import PlanOverviewBox from "./PlanOverviewBox";
const CollectAssistBox = forwardRef((_, ref) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const overallCostSummary = useAppSelector(selectOverallCostSummary);
    const displayCost = useAppSelector(selectDisplayCost);
    const { collectAssist } = overallCostSummary;
    const [tableData, setTableData] = useState([]);
    const [isOpenDetailView, setIsOpenDetailView] = useState(false);
    useEffect(() => {
        setIsOpenDetailView(false);
    }, [overallCostSummary]);
    return (_jsxs(PackageWrapper, { ref: ref, children: [_jsxs(PackageContentRow, { children: [_jsx(SAColumn, { lg: 5, md: 24, children: _jsxs(PackageTitleSection, { children: [_jsx(BoxHeading, { children: "COLLECT ASSIST" }), _jsx(SubText, { children: "Digital In-Clinic & On-Site Collections" })] }) }), _jsx(SAColumn, { lg: 5, md: 24, children: _jsx(PlanOverviewBox, { title: "Current No. of Licenses", content: collectAssist.noLicenses, displayManageBtn: true, handleManageClick: () => navigate("/accounting/licensing") }) }), _jsx(SAColumn, { lg: 5, md: 24, offset: 5, pull: 5, children: _jsx(PlanOverviewBox, { title: "No. of Collectors", content: collectAssist.noCollectors }) }), _jsx(SAColumn, { lg: 4, md: 24, children: _jsx(PriceBox, { children: _jsxs(PriceText, { children: [" $ ", collectAssist[displayCost]] }) }) })] }), _jsx(ViewDetailButton, { isOpen: isOpenDetailView, setIsOpen: async () => {
                    if (!isOpenDetailView) {
                        const actionResult = await dispatch(getLicenseTransactions(collectAssist.licenseId));
                        const responseData = unwrapResult(actionResult);
                        setTableData(responseData.data);
                    }
                    setIsOpenDetailView(!isOpenDetailView);
                }, children: "Detail View" }), isOpenDetailView && (_jsxs(PackageTable, { dataSource: tableData?.map((entry) => ({ ...entry, id: uuidv4() })) ?? [], rowKey: "id", pagination: false, children: [_jsx(Table.Column, { title: "Date", dataIndex: "startAt" }, "startAt"), _jsx(Table.Column, { title: "No. of Licenses", dataIndex: "noLicenses" }, "noLicenses"), _jsx(Table.Column, { title: "Billing Cycle", dataIndex: "billingCycleType" }, "billingCycleType"), _jsx(Table.Column, { title: "Unit Cost", dataIndex: "unitCost" }, "unitCost"), _jsx(Table.Column, { title: "Sub Total", dataIndex: "subTotal" }, "subTotal"), _jsx(Table.Column, { title: "GST", dataIndex: "gst" }, "gst"), _jsx(Table.Column, { title: "Total", dataIndex: "total" }, "total")] }))] }));
});
CollectAssistBox.displayName = "CollectAssistBox";
export default CollectAssistBox;
const PackageWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-block: 4rem;
  gap: 2rem;
`;
const PackageContentRow = styled(SARow) `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #00004c;
`;
const PriceBox = styled.div `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #ccf0f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled.p `
  font-size: 1rem;
  font-weight: 700;
  margin: auto;
`;
const SubText = styled.p `
  font-size: 0.875rem;
`;
const PackageTitleSection = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
const PackageTable = styled(Table) `
  width: 100%;
`;
