import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, message, Modal, Space, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { KioskManagerIcon } from "src/assets/svg/welcome-assist";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { selectCollectionOrganization } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getListLicenseByCollectionPoint, inviteLicenseManagers } from "../../store/action";
import { selectCollectionPoint } from "../../store/selector";
import { WelcomeAssistError } from "../../store/types";
const InviteWAManagerModal = ({ isOpen, setIsOpen }) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const selectedCollectionPoint = useAppSelector(selectCollectionPoint);
    const collectionOrg = useAppSelector(selectCollectionOrganization);
    const handleCancel = () => {
        form.resetFields();
        setIsOpen(false);
    };
    return (_jsx(Modal, { open: isOpen, onCancel: handleCancel, footer: null, title: null, centered: true, width: "50%", children: _jsxs(ContentContainer, { children: [_jsxs(Heading, { children: [_jsx(KioskManagerIcon, {}), _jsx(Typography.Text, { children: "Invite Welcome Assist Manager" })] }), _jsxs(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, autoComplete: "off", initialValues: {
                        managers: [
                            {
                                email: "",
                                firstName: "",
                                lastName: "",
                                mobile: "",
                            },
                        ],
                    }, onFinish: async (values) => {
                        const payload = await dispatch(inviteLicenseManagers(values.managers)).unwrap();
                        const { error } = payload;
                        if (error) {
                            message.error(error === WelcomeAssistError.EA001
                                ? payload.message
                                : "There is error when trying to invite");
                            return;
                        }
                        message.success("Invited all managers successfully");
                        form.resetFields();
                        dispatch(getListLicenseByCollectionPoint());
                        setIsOpen(false);
                    }, children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { label: "ORGANISATION", children: _jsx(Input, { value: collectionOrg.collectionName, disabled: true }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { label: "LOCATION (Collection Point)", children: _jsx(Input, { value: selectedCollectionPoint.collectionPointName, disabled: true }) }) })] }), _jsx(ListManagerContainer, { children: _jsx(Form.List, { name: "managers", children: (fields, { add, remove }) => (_jsx(_Fragment, { children: fields.map(({ key, name, ...restField }, index) => (_jsxs(SARow, { children: [_jsx(SAColumn, { span: 1, children: _jsx(SAFormItem, { label: (!index && " ") || "", children: _jsxs(NumberBadge, { children: [index + 1, " "] }) }) }), _jsx(SAColumn, { span: 6, children: _jsx(SAFormItem, { ...restField, name: [name, "email"], label: (!index && "Email") || "", rules: [{ required: true, message: "Email is required" }], children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 5, children: _jsx(SAFormItem, { ...restField, name: [name, "firstName"], label: (!index && "First Name") || "", rules: [{ required: true, message: "First Name is required" }], children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 5, children: _jsx(SAFormItem, { ...restField, name: [name, "lastName"], label: (!index && "Last Name") || "", rules: [{ required: true, message: "Last Name is required" }], children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 5, children: _jsx(SAFormItem, { ...restField, name: [name, "mobile"], label: (!index && "Mobile Number") || "", rules: [
                                                        { required: true, message: "Mobile Number is required" },
                                                        {
                                                            validator: (_, value) => {
                                                                if (!value || /^[0-9]+$/.test(value)) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error("Please enter only numbers"));
                                                            },
                                                        },
                                                    ], children: _jsx(Input, {}) }) }), _jsx(SAColumn, { span: 1, children: _jsx(SAFormItem, { label: (!index && " ") || "", children: index === fields.length - 1 ? (_jsx(Button, { type: "link", onClick: () => add(), icon: _jsx(PlusCircleOutlined, {}) })) : (_jsx(Button, { type: "link", danger: true, onClick: () => remove(name), icon: _jsx(MinusCircleOutlined, {}) })) }) })] }, key))) })) }) }), _jsx(Divider, {}), _jsxs(FooterButton, { children: [_jsx(Button, { type: "primary", ghost: true, onClick: handleCancel, children: "Cancel" }), _jsx(Button, { type: "primary", htmlType: "submit", children: "Send Invitation" })] })] })] }) }));
};
export default InviteWAManagerModal;
const Heading = styled.div `
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #01b4d2;
  border-radius: 50px 10px 10px 50px;
  background-color: #cbf5fc;
  margin-bottom: 1rem;
  svg {
    background-color: #005277;
    border-radius: 50px;
    color: #ffffff;
  }
`;
const ContentContainer = styled.div `
  padding: 2rem 0rem;
`;
const NumberBadge = styled.div `
  background: #cbf5fc;
  color: #00b4d1;
  border-radius: 50%;
  text-align: center;
`;
const ListManagerContainer = styled.div `
  height: 20rem;
  overflow: auto;
`;
const FooterButton = styled(Space) `
  display: flex;
  width: fit-content;
  margin-left: auto;
`;
