import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import { CreateAppointmentPage } from "src/pages/InClinicAppointmentsPage/CreateAppointmentPage";
import PatientListPage from "src/pages/InClinicAppointmentsPage/PatientListPage";
import CreateGroupAppointment from "src/pages/InClinicAppointmentsPage/CreateGroupAppointmentPage";
const breadcrumbs = [
    {
        path: "/in-clinic-appointments",
        name: "In-Clinic Appointments",
        level: 1,
    },
    {
        path: "/create-appointment",
        name: "Individual",
        level: 2,
    },
    {
        path: "/group-booking",
        name: "Group",
        level: 2,
    },
    {
        path: "/patient-list",
        name: "Patient List",
        level: 2,
    },
];
const InClinicAppointmentPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname === "/in-clinic-appointments") {
            navigate("/in-clinic-appointments/create-appointment");
        }
    }, [location]);
    return (_jsx(MainLayout, { children: _jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/create-appointment", element: _jsx(CreateAppointmentPage, { isPopup: false }) }), _jsx(Route, { path: "/group-booking", element: _jsx(CreateGroupAppointment, {}) }), _jsx(Route, { path: "/patient-list", element: _jsx(PatientListPage, {}) })] }) }) }));
};
export default InClinicAppointmentPage;
