import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space } from "antd";
import * as React from "react";
import styled from "styled-components";
import { WelAssistTextLogo } from "src/assets/svg/app-icons";
const NothingSelectMessage = () => (_jsx(NothingSelectMessageContainer, { children: _jsxs(Space, { direction: "vertical", align: "center", children: [_jsx(WelAssistTextLogo, {}), _jsx(KioskMessageTitle, { children: "Kiosk Messages" })] }) }));
export default NothingSelectMessage;
const NothingSelectMessageContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const KioskMessageTitle = styled.p `
  font-size: 1.5rem;
  font-weight: 700;
  color: #01b4d2;
`;
