import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
import * as React from "react";
import styled from "styled-components";
import { useAppSelector } from "src/store/hooks";
import { CollectAssistBox, CollectionManagerBox, DrugAlcoholTestBox, PlatformTrainingBox, ReportAssistBox, RequestLabTestBox, SecureAssistBox, ServiceEstablishmentBox, SupportBox, TrainingBox, WelcomeAssistBox, WorkAssistBox, } from "./components";
import { selectFilterOptions } from "./store/selector";
import { PackageType } from "./typing";
const assignRef = (destinationRefs, index) => (el) => {
    if (destinationRefs.current) {
        destinationRefs.current[index] = el;
    }
};
const DetailedCostBreakdown = React.forwardRef(({ destinationRefs }) => {
    const filterOptions = useAppSelector(selectFilterOptions);
    return (_jsxs(PackagePlanSummaryContainer, { children: [_jsx(DrugAlcoholTestBox, { ref: assignRef(destinationRefs, PackageType.DrugAlcoholTests) }), _jsx(RequestLabTestBox, { ref: assignRef(destinationRefs, PackageType.RequestedLabTests) }), filterOptions.selectedCollectionPointId || filterOptions.selectedOnsiteClientId ? null : (_jsxs(React.Fragment, { children: [_jsx(CollectionManagerBox, { ref: assignRef(destinationRefs, PackageType.CollectionManager) }), _jsx(CollectAssistBox, { ref: assignRef(destinationRefs, PackageType.CollectionAssist) }), _jsx(SecureAssistBox, { ref: assignRef(destinationRefs, PackageType.SecureAssist) }), _jsx(ReportAssistBox, { ref: assignRef(destinationRefs, PackageType.ReportAssist) }), _jsx(WorkAssistBox, { ref: assignRef(destinationRefs, PackageType.WorkAssist) }), _jsx(WelcomeAssistBox, { ref: assignRef(destinationRefs, PackageType.WelcomeAssist) }), _jsx(SupportBox, { ref: assignRef(destinationRefs, PackageType.Support) }), _jsx(TrainingBox, { ref: assignRef(destinationRefs, PackageType.Training) }), _jsx(ServiceEstablishmentBox, { ref: assignRef(destinationRefs, PackageType.SAServiceEstablishment) }), _jsx(PlatformTrainingBox, { ref: assignRef(destinationRefs, PackageType.SAPlatformTraining) })] }))] }));
});
DetailedCostBreakdown.displayName = "DetailedCostBreakdown";
export default DetailedCostBreakdown;
const PackagePlanSummaryContainer = styled.div `
  width: 100%;
`;
