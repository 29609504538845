import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer, } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { mainAppColours, SAColourScheme, supportingColours } from "src/styles";
import { CalendarView } from "../typing";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const CustomEvent = ({ event }) => {
    const navigate = useNavigate();
    return (_jsx(Tooltip, { title: event.allDay ? "" : event.title, placement: "left", children: _jsxs(EventContainer, { allDay: event.allDay, onClick: () => navigate(`/job-management/onsite-jobs/${event.id}/normal`), children: [!event.allDay && _jsx(SideBar, { children: "On Site" }), _jsx(EventDetails, { children: event.title })] }) }));
};
const eventPropGetter = () => {
    const newStyle = {
        backgroundColor: "#ffffff",
        borderColor: "transparent",
        color: `${supportingColours.TEXT_DARK_GRAY}`,
        borderRadius: "0.25rem",
    };
    return {
        className: "",
        style: newStyle,
    };
};
const CustomWeekCalendar = ({ events, currentDate, header, backgroundEvents, ...props }) => {
    const firstEvent = events.sort((a, b) => a.start.getTime() - b.start.getTime())[0]
        ?.start ?? new Date();
    return (_jsxs(WeekCalendarWrapper, { children: [_jsx(MainHeader, { children: header }), _jsx(CustomDnDCalendar, { view: CalendarView.Week, onView: () => void 0, date: currentDate, onNavigate: () => void 0, events: events, localizer: localizer, backgroundEvents: backgroundEvents, defaultDate: firstEvent, scrollToTime: firstEvent, showAllEvents: true, timeslots: 12, step: 5, formats: {
                    eventTimeRangeFormat: () => "",
                }, components: {
                    toolbar: () => _jsx(Toolbar, {}),
                    timeGutterHeader: () => (_jsxs(TimeGutterHeader, { children: [_jsx(EmptyHeader, {}), _jsx(TimeGutterHeaderContent, { children: "On-Site" })] })),
                    dateCellWrapper: (prop) => _jsx(DateCellWrapper, { children: prop.children }),
                    timeSlotWrapper: (prop) => _jsx(TimeSlotWrapper, { children: prop.children }),
                    eventContainerWrapper: (prop) => (_jsx(EventContainerWrapper, { children: prop.children })),
                    event: CustomEvent,
                }, eventPropGetter: eventPropGetter, ...props })] }));
};
export default CustomWeekCalendar;
const WeekCalendarWrapper = styled.div `
  width: 100%;
  .rbc-events-container {
    background-color: #ffffff;
  }

  .rbc-events-container .rbc-background-event {
    background-color: #e4e4e4;
    left: 0 !important;
    color: #000;
  }

  .rbc-time-header.rbc-overflowing {
    border-right: none;
  }
  .rbc-time-header-content {
    border-left: none;
    border-right: none;
    gap: 0.3rem;
    padding-block: 0.3rem;
  }

  .rbc-time-header-content .rbc-event,
  .rbc-time-header-content .rbc-event-content {
    background-color: #cbf5fc;
    border-color: transparent;
    border: none;
  }
  .rbc-timeslot-group {
    min-height: 10rem;
  }
  .rbc-time-view .rbc-allday-cell {
    max-height: 10rem;
    overflow: auto;
  }

  .rbc-event {
    background-color: #eaf8e5;
    border-color: transparent;
    border-left: 0.3rem solid #35b700;
    color: #000;
  }

  .rbc-background-event {
    background-color: #e4e4e4;
    border-color: transparent;
    border-radius: unset;
  }

  .rbc-time-content > * + * > *,
  .rbc-timeslot-group,
  .rbc-day-slot .rbc-time-slot,
  .rbc-header {
    border: 0;
  }

  .rbc-time-header-cell {
    border-bottom: 0.1rem solid #adb3bc;
  }

  .rbc-time-content,
  .rbc-time-header-content {
    .rbc-today {
      background-color: transparent;
      color: ${mainAppColours.MID_BLUE};
    }

    .rbc-current-time-indicator {
      display: none;
    }
  }

  .rbc-time-view .rbc-row {
    min-height: 1.5rem;
  }

  .rbc-time-view {
    border: none;
  }

  .rbc-time-header-gutter {
    padding: 0;
  }
`;
const CustomDnDCalendar = styled(DnDCalendar) `
  height: 70vh;
  border-bottom: 0.063rem solid #bfbdcb;
`;
const Toolbar = styled.div `
  width: 100%;
`;
const MainHeader = styled.div `
  background-color: ${mainAppColours.BLUE};
  font-size: 1rem;
  color: ${SAColourScheme.WHITE};
  border-radius: 0.2rem;
  text-align: center;
  padding: 0.2rem 0;
`;
const TimeGutterHeader = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  border-right: none;
  flex-direction: column;
`;
const EmptyHeader = styled.div `
  min-height: 1.8rem;
  width: 100%;
  border-bottom: 0.1rem solid #adb3bc;
`;
const TimeGutterHeaderContent = styled.p `
  color: #35b700;
  font-weight: bold;
  padding-right: 0.3rem;
`;
const TimeSlotWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: white;
  color: #7f8e9d;
`;
const DateCellWrapper = styled.div `
  display: flex;
  flex-direction: column;
  background-color: #e4f6fb;
  align-items: flex-end;
  padding: 0;
  margin: 0 0.3rem;
`;
const EventContainerWrapper = styled.div `
  background-color: #fcf7f7;
  color: ${supportingColours.TEXT_DARK_GRAY};
`;
const EventContainer = styled.div `
  display: flex;
  background-color: ${({ allDay }) => (allDay ? "#CBF5FC" : "#ebf3ed")};
  color: #000;
  border-radius: 0.25rem;
  height: 100%;
`;
const SideBar = styled.div `
  background-color: #01b4d2;
  color: white;
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
  word-wrap: break-word;
  white-space: normal;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`;
const EventDetails = styled.div `
  padding: 0.5rem;
  word-wrap: break-word;
  white-space: normal;
`;
