import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, message, Popover, Radio, Select, Table } from "antd";
import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DocumentIcon from "src/assets/svg/device-management/document.svg";
import SADivider from "src/components/Form/SADivider";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import TableSizeChanger from "src/components/TableSizeChanger";
import ToggleButton from "src/components/ToggleButton";
import { DefaultPagingMeta } from "src/infra/pagination";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch } from "src/store/hooks";
import { getHistoricalStockRecords } from "../store/action";
import { generateYearRangesAndDates } from "../store/selector";
import { YearFilterOptions } from "../store/types";
const INITIAL_FILTERS = {
    page: 1,
    limit: 10,
};
const HistoricalStockRecords = ({ testTypeRecordIndex, type, testingDeviceId, showHistoricalRecords, setShowHistoricalRecords, requireProductName = false, }) => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(selectIsLoading);
    const displayYears = React.useMemo(() => generateYearRangesAndDates(), []);
    const [records, setRecords] = React.useState({
        data: [],
        pagingMeta: DefaultPagingMeta,
    });
    const [yearSelection, setYearSelection] = React.useState({
        displayedYear: YearFilterOptions.CalendarYear,
        range: displayYears[YearFilterOptions.CalendarYear].listYearRange[0],
    });
    const [filters, setFilters] = React.useState({
        testTypeRecordIndex: testTypeRecordIndex,
        type: type,
        testingDeviceId: testingDeviceId || undefined,
        startAt: yearSelection.range.startAt,
        endAt: yearSelection.range.endAt,
        ...INITIAL_FILTERS,
    });
    React.useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            startAt: yearSelection.range.startAt,
            endAt: yearSelection.range.endAt,
        }));
    }, [yearSelection]);
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            const actionResult = await dispatch(getHistoricalStockRecords(filters));
            const responseData = unwrapResult(actionResult);
            const { data, error } = responseData;
            if (error) {
                message.error("Error when loading historical stock records");
            }
            else {
                setRecords(data);
            }
            dispatch(setIsLoading(false));
        };
        if (showHistoricalRecords)
            loadData();
    }, [filters, showHistoricalRecords]);
    return (_jsxs(ArchiveDeviceWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(ToggleButton, { isOpen: showHistoricalRecords, label: "Historical Stock Records", onClick: async () => {
                    setShowHistoricalRecords(!showHistoricalRecords);
                } }), _jsx(CustomDivider, {}), showHistoricalRecords && (_jsxs(SARow, { gutter: [0, 16], children: [_jsx(SAColumn, { span: 8, children: _jsx(Select, { value: yearSelection.displayedYear, onChange: (newYear) => {
                                setYearSelection({
                                    displayedYear: newYear,
                                    range: displayYears[newYear].listYearRange[0],
                                });
                            }, options: Object.values(displayYears) }) }), _jsx(SAColumn, { span: 16, children: _jsx(YearGroupButton, { value: yearSelection.range, onChange: (e) => {
                                setYearSelection((prev) => ({
                                    ...prev,
                                    range: e.target.value,
                                }));
                            }, children: displayYears[yearSelection.displayedYear].listYearRange.map((range) => (_jsx(Radio.Button, { value: range, children: range.label }, range.label))) }) }), _jsx(SAColumn, { children: _jsxs(TableContainer, { children: [_jsxs(HistoryTable, { dataSource: records?.data, rowKey: "id", bordered: false, pagination: {
                                        current: records.pagingMeta?.page,
                                        pageSize: records.pagingMeta?.limit,
                                        total: records.pagingMeta?.totalItem,
                                    }, onChange: (newPagination) => {
                                        setFilters((prevFilters) => ({
                                            ...prevFilters,
                                            page: newPagination.current ?? 1,
                                            limit: newPagination.pageSize ?? 10,
                                        }));
                                    }, children: [_jsx(Table.Column, { title: "Date", dataIndex: "date" }), requireProductName && (_jsx(Table.Column, { title: "Product Name", dataIndex: "productName" })), _jsx(Table.Column, { title: "Batch/Order No.", dataIndex: "batchNumber" }), _jsx(Table.Column, { title: "Units", dataIndex: "noOfUnits" }), _jsx(Table.Column, { title: "Unit Price", dataIndex: "unitPrice", render: (unitPrice) => `$ ${unitPrice}` }), _jsx(Table.Column, { title: "Total", dataIndex: "total", render: (total) => `$ ${total}` }), _jsx(Table.Column, { title: "Entered By", dataIndex: "enteredBy" }), _jsx(Table.Column, { title: "Note", dataIndex: "notes", align: "center", render: (text) => (_jsx(Popover, { placement: "bottomRight", title: "Note", content: text, trigger: ["click"], children: _jsx(Button, { type: "link", children: _jsx(DocumentIcon, { color: text ? "#1A8CFF" : "#EDEEF0" }) }) })) })] }), _jsx(TableFooterContainer, { "$hidden": !records?.data?.length, children: _jsx(TableSizeChanger, { value: filters.limit, onChange: (size) => setFilters((prevFilters) => ({
                                            ...prevFilters,
                                            limit: size,
                                        })) }) })] }) })] }))] }));
};
export default HistoricalStockRecords;
const CustomDivider = styled(SADivider) `
  border-color: #adb3bc !important;
`;
const ArchiveDeviceWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100% !important;
  margin-top: auto;
`;
const TableContainer = styled.div `
  width: 100%;
  position: relative;
  margin: 1rem 0;
`;
const HistoryTable = styled(Table) `
  width: 100% !important;
`;
const GroupButton = styled(Radio.Group) `
  gap: 1rem;
  margin-right: auto;
  flex-wrap: wrap;
  margin-left: 0.5rem;
  width: fit-content;
  white-space: nowrap;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    margin-right: 1rem;

    span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-checked {
    border-radius: 0.3rem;
    border: 0;
    color: #000000;
    background-color: #f7f8f8;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 10rem;
    box-shadow: 2px -2px 4px 0px #00000012;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #cbf5fc;
  }
`;
const YearGroupButton = styled(GroupButton) `
  width: fit-content;
  white-space: nowrap;
  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-checked {
    border-radius: 0.3rem;
    border: 0;
    color: #000000;
    background-color: #f7f8f8;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    width: 5rem;
    min-width: fit-content;
    text-align: center;
    box-shadow: none;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #cbf5fc;
  }
`;
const TableFooterContainer = styled.div `
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-width: 5rem;
  bottom: 0.5rem;
  white-space: nowrap;
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
`;
