import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer, } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { AccountIcon } from "src/assets/svg/common";
import { mainAppColours, supportingColours } from "src/styles";
import { CollectorCalendarEventType } from "../store/types";
import { CalendarView } from "../typing";
import EventDetailPopover from "./EventDetailPopover";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);
const CustomEvent = ({ event }) => {
    const { adjustedAllDay, title, start, end, note } = event;
    const startDate = moment(start).format("HH:mm");
    const endDate = moment(end).format("HH:mm");
    const eventTime = adjustedAllDay ? "All Day" : `${startDate} - ${endDate}`;
    const isShowInAllDay = event.eventType === CollectorCalendarEventType.Leave;
    return (_jsx(EventDetailPopover, { event: event, children: _jsxs(EventContainer, { allDay: isShowInAllDay, children: [_jsx(SideBar, { allDay: isShowInAllDay }), _jsx(EventDetails, { children: _jsxs(Space, { align: "start", children: [_jsx(AccountIcon, {}), _jsxs(Space, { direction: "vertical", children: [_jsx(Typography.Title, { level: 5, children: title }), _jsx(Typography.Text, { children: eventTime }), _jsx(Typography.Text, { children: note })] })] }) })] }) }));
};
const eventPropGetter = () => {
    const newStyle = {
        backgroundColor: "#ffffff",
        borderColor: "transparent",
        color: `${supportingColours.TEXT_DARK_GRAY}`,
        borderRadius: "0.25rem",
    };
    return {
        className: "",
        style: newStyle,
    };
};
const CollectorWeekCalendar = ({ events, currentDate, ...props }) => {
    const firstEvent = events.sort((a, b) => a.start.getTime() - b.start.getTime())[0]
        ?.start ?? new Date();
    /**
     * Splits an event into multiple events if it spans multiple days.
     * Each split event corresponds to a single day, with adjusted start and end times.
     *
     * @param event The original event object to split
     * @returns Array of split events, one for each day
     */
    const splitEventIntoDays = (event) => {
        const startDate = moment(event.start);
        const endDate = moment(event.end);
        const splitEvents = [];
        let currentEventDate = moment(startDate);
        while (currentEventDate <= endDate) {
            const isFirstDay = currentEventDate.isSame(startDate, "day");
            const isLastDay = currentEventDate.isSame(endDate, "day");
            const eventStart = isFirstDay ? startDate : currentEventDate.clone().startOf("day");
            const eventEnd = isLastDay ? endDate : currentEventDate.clone().endOf("day");
            splitEvents.push({
                ...event,
                start: eventStart.toDate(),
                end: eventEnd.toDate(),
                adjustedAllDay: event.allDay || false,
                allDay: true,
            });
            currentEventDate = currentEventDate.add(1, "day");
        }
        // add last date
        splitEvents.push({
            ...event,
            start: moment(splitEvents[splitEvents.length - 1].end)
                .add(1, "day")
                .startOf("day")
                .toDate(),
            end: endDate.toDate(),
            adjustedAllDay: event.allDay || false,
            allDay: true,
        });
        return splitEvents;
    };
    /**
     * Normalizes a list of events:
     * - Splits multi-day events into separate events for each day.
     * - Ensures single-day events are returned as-is but marked as "all-day".
     *
     * @param events The list of events to normalize
     * @returns Array of normalized events
     */
    const normalizeEvents = events.flatMap((event) => {
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);
        const spansMultipleDays = startDate.toDateString() !== endDate.toDateString();
        if (event.allDay || spansMultipleDays) {
            return splitEventIntoDays(event);
        }
        return {
            ...event,
            adjustedAllDay: event.allDay || false,
            allDay: true,
        };
    });
    return (_jsx(WeekCalendarWrapper, { children: _jsx(CustomDnDCalendar, { view: CalendarView.Week, onView: () => void 0, date: currentDate, onNavigate: () => void 0, events: normalizeEvents, localizer: localizer, defaultDate: firstEvent, scrollToTime: firstEvent, showAllEvents: true, timeslots: 12, step: 5, formats: {
                eventTimeRangeFormat: () => "",
            }, components: {
                toolbar: () => _jsx(Toolbar, {}),
                dateCellWrapper: (prop) => _jsx(DateCellWrapper, { children: prop.children }),
                timeSlotWrapper: (prop) => _jsx(TimeSlotWrapper, { children: prop.children }),
                eventContainerWrapper: (prop) => (_jsx(EventContainerWrapper, { children: prop.children })),
                event: CustomEvent,
            }, eventPropGetter: eventPropGetter, ...props }) }));
};
export default CollectorWeekCalendar;
const WeekCalendarWrapper = styled.div `
  width: 100%;

  .rbc-time-header-content {
    border-left: none;
    border-right: none;
    margin-right: 0.5rem;
  }

  .rbc-timeslot-group,
  .rbc-time-view,
  .rbc-time-content > .rbc-time-gutter > .rbc-timeslot-group,
  .rbc-day-slot .rbc-time-slot {
    border: none;
  }

  .rbc-time-header-content {
    .rbc-today {
      background-color: transparent;
      color: ${mainAppColours.MID_BLUE};
    }

    .rbc-current-time-indicator {
      display: none;
    }
  }

  .rbc-time-view .rbc-allday-cell {
    height: 100%;
  }

  .rbc-time-header-cell .rbc-header,
  .rbc-time-header-gutter {
    border-bottom: 0.063rem solid #01b4d2;
  }

  .rbc-time-content > .rbc-time-gutter,
  .rbc-time-content {
    display: none;
  }
`;
const CustomDnDCalendar = styled(DnDCalendar) `
  height: 70vh;
  border-bottom: 0.063rem solid #01b4d2;
`;
const Toolbar = styled.div `
  width: 100%;
`;
const TimeSlotWrapper = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: white;
  color: #7f8e9d;
`;
const DateCellWrapper = styled.div `
  display: flex;
  flex-direction: column;
  background-color: #01b4d2;
  align-items: flex-end;
  padding: 0;
  margin: 0 5px;
`;
const EventContainerWrapper = styled.div `
  background-color: #ffffff;
  color: ${supportingColours.TEXT_DARK_GRAY};
`;
const EventContainer = styled.div `
  display: flex;
  background-color: ${({ allDay }) => (allDay ? "#F2E5FF" : "#FAE3E2")};
  color: #000;
  border-radius: 0.25rem;
  height: 100%;

  svg {
    color: ${({ allDay }) => (allDay ? "#EB41E4" : "#FD0000")};
  }
`;
const SideBar = styled.div `
  background-color: ${({ allDay }) => (allDay ? "#EB41E4" : "#FD0000")};
  color: white;
  padding: 0.1rem;
  text-align: center;
  font-weight: bold;
  word-wrap: break-word;
  white-space: normal;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`;
const EventDetails = styled.div `
  padding: 0.5rem;
  word-wrap: break-word;
  white-space: normal;
`;
