import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as React from "react";
import styled from "styled-components";
const ViewDetailButton = ({ children, isOpen, setIsOpen, }) => (_jsxs(ToggleButton, { onClick: () => setIsOpen(!isOpen), children: [children, " ", isOpen ? _jsx(UpOutlined, {}) : _jsx(DownOutlined, {})] }));
export default ViewDetailButton;
const ToggleButton = styled(Button) `
  background-color: #e6f7fa;
  border: 0;
  width: 15rem;
  margin-left: auto;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 1rem;
  justify-content: space-between;
`;
