import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Divider, Dropdown } from "antd";
import * as React from "react";
import { DashOutlined, DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { PaginatedDataTable } from "src/components/commonElement/PaginatedDataTable";
import { selectListLaboratory, selectPagingMetaData, } from "src/pages/CollectionOrganization/Laboratory/store/selector";
import { deleteOrganizationLaboratory, getLaboratories, } from "src/pages/CollectionOrganization/Laboratory/store/action";
import { setIsOpen, setSelectedLaboratory, } from "src/pages/CollectionOrganization/Laboratory/store/slice";
const LaboratoryTable = () => {
    const dispatch = useAppDispatch();
    const [openDropdown, setOpenDropdown] = useState(null);
    const listLaboratory = useAppSelector(selectListLaboratory);
    const pagingMetaData = useAppSelector(selectPagingMetaData);
    const handleDropdownToggle = (id) => {
        setOpenDropdown(id);
    };
    const onEdit = (recordProp) => {
        dispatch(setSelectedLaboratory(recordProp));
        dispatch(setIsOpen(true));
        setOpenDropdown(null);
    };
    const onDelete = async (laboratoryId) => {
        await dispatch(deleteOrganizationLaboratory(laboratoryId));
        setOpenDropdown(null);
        await dispatch(getLaboratories({}));
    };
    const columns = [
        {
            title: "Service Office",
            dataIndex: "name",
            key: "name",
            sorter: true,
        },
        { title: "Email", dataIndex: "email", key: "email", sorter: true },
        { title: "Phone Number", dataIndex: "phoneNumber", key: "phoneNumber", sorter: true },
        { title: "Address", dataIndex: "address1", key: "address1", sorter: true },
        { title: "City/Suburb", dataIndex: "suburb", key: "suburb", sorter: true },
        { title: "State", dataIndex: "state", key: "state", sorter: true },
        { title: "Postcode", dataIndex: "postcode", key: "postcode", sorter: true },
        {
            title: "",
            dataIndex: "",
            key: "x",
            render: (_, record) => (_jsx(Dropdown, { open: openDropdown === record.id, onOpenChange: () => handleDropdownToggle(openDropdown === record.id ? null : record.id), overlayStyle: {
                    backgroundColor: "#ffffff",
                }, placement: "bottomRight", dropdownRender: () => (_jsx(_Fragment, { children: _jsxs(DropdownWrapper, { className: "dropdown-content", children: [_jsx(StyledButton, { type: "text", icon: _jsx(EditTwoTone, {}), onClick: () => onEdit(record), children: "Edit" }), _jsx(StyledDivider, {}), _jsx(StyledButton, { type: "text", icon: _jsx(DeleteTwoTone, { twoToneColor: "#FD0000" }), onClick: () => onDelete(record.id), children: "Delete" })] }) })), children: _jsx("a", { onClick: (e) => e.preventDefault(), children: _jsx(DashOutlined, {}) }) }, record.id)),
        },
    ];
    const onTableChange = async (newPage, size, sortField, sortOrder) => {
        dispatch(setIsLoading(true));
        await dispatch(getLaboratories({
            newPage: newPage,
            size: size,
            field: sortField ?? undefined,
            order: sortOrder ?? undefined,
        }));
        dispatch(setIsLoading(false));
    };
    return (_jsx(PaginatedDataTable, { dataSource: listLaboratory, pagingMetaData: pagingMetaData, columns: columns, rowKey: "id", onTableChange: onTableChange }));
};
export default LaboratoryTable;
const DropdownWrapper = styled.div `
  width: 8rem;
  border: 1px solid #1a8cff;
  padding: 10px 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;
const StyledButton = styled(Button) `
  width: 100%;
  text-align: start;
  padding-left: 0px;
`;
const StyledDivider = styled(Divider) `
  margin: 5px 0px;
  color: #01b4d2;
`;
