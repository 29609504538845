import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { uuidv4 } from "@antv/xflow-core";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Divider, message, Radio, Select, Space, Table, Tag, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { DetailIcon, InvoiceIcon } from "src/assets/svg/accounting";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { getApplicationInformation, selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { TimeFormat } from "src/store/app/types";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { breadcrumbs } from "..";
import { InvoiceType } from "../type";
import { downloadInvoicesZip, getListCompletedInvoices } from "./store/action";
import { generateYearRangesAndDates } from "./store/selector";
import { InvoiceDisplayYear, InvoiceStatusOptions, } from "./store/typing";
const AccountingInvoices = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const appInformation = useAppSelector(getApplicationInformation);
    const displayYears = useMemo(() => generateYearRangesAndDates(), []);
    const [selectedDisplayedYear, setSelectedDisplayedYear] = useState(InvoiceDisplayYear.CalendarYear);
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
    const [selectedRange, setSelectedRange] = useState(displayYears[selectedDisplayedYear].listYearRange[0]);
    const [filters, setFilters] = useState({
        invoiceType: InvoiceType.Testing,
        startAt: selectedRange.startAt,
        endAt: selectedRange.endAt,
    });
    const { collectionOrganization } = appInformation;
    useEffect(() => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            startAt: selectedRange.startAt,
            endAt: selectedRange.endAt,
        }));
    }, [selectedRange]);
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            const actionResult = await dispatch(getListCompletedInvoices(filters));
            const responseData = unwrapResult(actionResult);
            const { data, error } = responseData;
            if (error) {
                message.error("Error when loading a new invoice list");
            }
            else {
                setInvoices(data);
            }
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [filters]);
    const downloadInvoicesArchive = async () => {
        if (!selectedInvoiceIds.length) {
            message.error("No invoices selected to download");
            return;
        }
        if (selectedInvoiceIds.length === 1) {
            const selectedInvoice = invoices.find((invoice) => selectedInvoiceIds[0] === invoice.id);
            if (selectedInvoice) {
                downloadFile(selectedInvoice.url, `${selectedInvoice.referenceNumber}.pdf`);
            }
            return;
        }
        const selectedInvoices = invoices.filter((invoice) => selectedInvoiceIds.includes(invoice.id));
        const invoicesZipBlob = await downloadInvoicesZip(selectedInvoices.map((invoice) => ({
            fileName: invoice.referenceNumber,
            fileUrl: invoice.url,
        })));
        const blobUrl = window.URL.createObjectURL(invoicesZipBlob);
        downloadFile(blobUrl, "invoices.zip");
        window.URL.revokeObjectURL(blobUrl);
    };
    const downloadFile = (blobUrl, fileName) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = fileName;
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    const columns = useMemo(() => [
        {
            title: "Date",
            dataIndex: "invoicedAt",
            key: "invoicedAt",
            sorter: true,
            render: (invoicedAt) => (_jsx(Space, { children: invoicedAt &&
                    moment(invoicedAt).format(`DD MMM YYYY, ${collectionOrganization.timeFormat === TimeFormat.TwelveHour ? "hh:mm A" : "HH:mm"}`) })),
        },
        {
            title: "Invoice No.",
            dataIndex: "referenceNumber",
            key: "referenceNumber",
            sorter: true,
        },
        {
            title: "Plan",
            dataIndex: "billingCycleType",
            key: "billingCycleType",
            sorter: true,
        },
        {
            title: "Amount",
            dataIndex: "totalCost",
            key: "totalCost",
            sorter: true,
            render: (totalCost) => `$ ${totalCost}`,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            sorter: true,
            render: (status) => (_jsx(Tag, { color: InvoiceStatusOptions[status].color, children: InvoiceStatusOptions[status].label })),
        },
        {
            title: "Invoice",
            dataIndex: "url",
            key: "url",
            sorter: true,
            align: "center",
            render: (url) => (_jsx(Button, { type: "link", onClick: () => {
                    if (!url)
                        return;
                    window.open(url, "_blank");
                }, children: _jsx(InvoiceIcon, {}) })),
        },
        {
            title: "Details",
            dataIndex: "id",
            key: "id",
            sorter: true,
            align: "center",
            render: () => (_jsx(Button, { disabled: true, type: "link", children: _jsx(DetailIcon, {}) })),
        },
        Table.SELECTION_COLUMN,
    ], []);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(React.Fragment, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(HeaderContainer, { children: _jsxs(SARow, { children: [_jsxs(SAColumn, { span: 16, align: "start", children: [_jsx(Typography.Title, { level: 5, children: collectionOrganization.collectionName }), _jsx(Typography.Text, { children: "Customer Id: SAC-AU-123-456" })] }), _jsx(SAColumn, { span: 8, align: "end", children: _jsxs(Space, { direction: "vertical", children: [_jsx(Typography.Text, { children: "Display" }), _jsx(Select, { value: selectedDisplayedYear, options: Object.values(displayYears), onChange: (year) => {
                                                setSelectedRange(displayYears[year].listYearRange[0]);
                                                setSelectedDisplayedYear(year);
                                            } })] }) }), _jsx(SAColumn, { align: "start", children: _jsx(InvoiceTypeBox, { children: _jsxs(Space, { children: [_jsx(Typography.Title, { level: 5, children: "Invoice Type: " }), _jsxs(GroupButton, { value: filters.invoiceType, onChange: (e) => {
                                                    const invoiceType = e.target.value;
                                                    setFilters((prev) => ({
                                                        ...prev,
                                                        invoiceType,
                                                    }));
                                                }, children: [_jsx(Radio.Button, { value: InvoiceType.Testing, children: "Tests" }), _jsx(Radio.Button, { value: InvoiceType.License, children: "Licensing" }), _jsx(Radio.Button, { value: InvoiceType.ITService, children: "IT Services" })] })] }) }) })] }) }), _jsxs(InvoiceTableContainer, { children: [_jsxs(TableFilter, { children: [_jsxs(Space, { children: [_jsx(Typography.Title, { level: 5, children: "Invoice " }), _jsx(Divider, { type: "vertical" }), _jsx(YearGroupButton, { value: selectedRange, onChange: (e) => {
                                                setSelectedRange(e.target.value);
                                            }, children: displayYears[selectedDisplayedYear].listYearRange.map((range) => (_jsx(Radio.Button, { value: range, children: range.label }, uuidv4()))) })] }), _jsx(Button, { type: "primary", onClick: downloadInvoicesArchive, disabled: !selectedInvoiceIds.length, children: "Download" })] }), _jsx(InvoiceTable, { dataSource: invoices, rowKey: "id", bordered: false, pagination: false, columns: columns, rowSelection: {
                                selectedRowKeys: selectedInvoiceIds,
                                onChange: (newInvoiceIds) => setSelectedInvoiceIds(newInvoiceIds),
                            } })] })] }) }));
};
export default AccountingInvoices;
const HeaderContainer = styled.div `
  background-color: #f7f8f8;
  margin-top: -2rem;
  margin-inline: -2rem;
  padding: 1rem 3rem;
  padding-top: 2rem;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  border-bottom: 0.025rem solid #adb3bc;
  color: #000000 !important;
`;
const InvoiceTypeBox = styled.div `
  margin-bottom: -1rem;
  white-space: nowrap;
`;
const GroupButton = styled(Radio.Group) `
  gap: 1rem;
  margin-right: auto;
  flex-wrap: wrap;
  margin-left: 0.5rem;
  width: fit-content;
  white-space: nowrap;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    margin-right: 1rem;

    span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-checked {
    border-radius: 0.3rem;
    border: 0;
    color: #000000;
    background-color: #f7f8f8;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    width: 10rem;
    box-shadow: 2px -2px 4px 0px #00000012;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #cbf5fc;
  }
`;
const InvoiceTableContainer = styled.div `
  padding: 2rem 1.5rem;
`;
const YearGroupButton = styled(GroupButton) `
  width: fit-content;
  white-space: nowrap;
  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper-checked {
    border-radius: 0.3rem;
    border: 0;
    color: #000000;
    background-color: #f7f8f8;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    width: 5rem;
    min-width: fit-content;
    text-align: center;
    box-shadow: none;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #cbf5fc;
  }
`;
const TableFilter = styled.div `
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const InvoiceTable = styled(Table) `
  width: 100%;
  margin-top: 1rem;
`;
