var KioskMessageType;
(function (KioskMessageType) {
    KioskMessageType["Default"] = "Default";
    KioskMessageType["Event"] = "Event";
})(KioskMessageType || (KioskMessageType = {}));
var KioskMessageStatus;
(function (KioskMessageStatus) {
    KioskMessageStatus["Published"] = "Published";
    KioskMessageStatus["Unpublished"] = "Unpublished";
    KioskMessageStatus["Deleted"] = "Deleted";
})(KioskMessageStatus || (KioskMessageStatus = {}));
var ManageMessageView;
(function (ManageMessageView) {
    ManageMessageView["Create"] = "Create";
    ManageMessageView["Edit"] = "Edit";
    ManageMessageView["View"] = "View";
})(ManageMessageView || (ManageMessageView = {}));
const EmptyDefaultMessage = {
    id: "",
    label: "",
    content: "",
    contentPlainText: "",
    type: KioskMessageType.Default,
    status: KioskMessageStatus.Unpublished,
    collectionPointId: "",
    createdAt: null,
    createdBy: "",
};
const EmptyEventMessage = {
    id: "",
    label: "",
    content: "",
    contentPlainText: "",
    type: KioskMessageType.Event,
    status: KioskMessageStatus.Unpublished,
    collectionPointId: "",
    createdAt: null,
    createdBy: "",
    startAt: null,
    endAt: null,
    displayTime: 5,
};
export { KioskMessageType, KioskMessageStatus, ManageMessageView, EmptyDefaultMessage, EmptyEventMessage, };
