import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { omit } from "lodash";
import { $get, $post } from "src/infra/http";
const getTestingDevices = createAsyncThunk("getUrineScreenTestingDevices", async ({ testTypeId, isArchived = false }, thunkApi) => {
    const state = thunkApi.getState().deviceManagementPage;
    const collectionPointId = state.urineScreenDevicePage.selectedCollectionPointId;
    const queryString = stringify({
        testTypeId,
        isArchived,
        collectionPointId,
    });
    return await $get(`/collection-manager/testing-devices?${queryString}`);
});
const getUrineScreenTestingDeviceById = createAsyncThunk("getUrineScreenTestingDeviceById", async (data) => {
    const queryString = stringify({ page: data.page, limit: data.limit });
    return await $get(`/collection-manager/testing-devices/view/${data.testingDeviceId}?${queryString}`);
});
const createUrineScreenTestingDevice = createAsyncThunk("createUrineScreenTestingDevice", async (newData) => await $post(`/collection-manager/testing-devices/create`, newData));
const updateUrineScreenTestingDevice = createAsyncThunk("updateUrineScreenTestingDevice", async (newData) => await $post(`/collection-manager/testing-devices/update/${newData.id}`, omit(newData, "id")));
const upsertStock = createAsyncThunk("upsertStock", async (newData) => await $post(`/collection-manager/testing-devices/stock/device`, newData));
export { getTestingDevices, createUrineScreenTestingDevice, getUrineScreenTestingDeviceById, updateUrineScreenTestingDevice, upsertStock, };
