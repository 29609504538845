import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { message, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import { useAppDispatch } from "src/store/hooks";
import { breadcrumbs } from "..";
import { LicensingBillingCycleHeader, CollectAssistBox, CollectionManagerBox, ReportAssistBox, SecureAssistBox, WelcomeAssistBox, WorkAssistBox, } from "./components";
import { getCurrentLicensingInvoice, getCurrentPlansByProduct } from "./store/action";
import { SubscribedProductType } from "./store/types";
import { setResetStore } from "./store/slice";
const AccountingLicensing = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [plans, setPlans] = useState({});
    React.useEffect(() => () => {
        dispatch(setResetStore());
    }, [dispatch, location.pathname]);
    useEffect(() => {
        const fetchPlans = async () => {
            const response = await dispatch(getCurrentPlansByProduct()).unwrap();
            if (response.error) {
                message.error("Failed to fetch plans");
                return;
            }
            setPlans(response.data);
        };
        fetchPlans();
    }, []);
    useEffect(() => {
        dispatch(getCurrentLicensingInvoice());
    }, []);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(React.Fragment, { children: [_jsx(LicensingBillingCycleHeader, {}), _jsxs(NotificationMessage, { children: [_jsx(Typography.Title, { level: 5, children: "Current costs as of today " }), _jsx(Typography.Text, { children: "(updated daily 12:00 am)" })] }), _jsxs(Panels, { children: [_jsx(CollectionManagerBox, {}), _jsx(CollectAssistBox, {}), _jsx(SecureAssistBox, { plans: plans[SubscribedProductType.SECURE_ASSIST] }), _jsx(ReportAssistBox, { plans: plans[SubscribedProductType.REPORT_ASSIST] }), _jsx(WorkAssistBox, { plans: plans[SubscribedProductType.WORK_ASSIST] }), _jsx(WelcomeAssistBox, {})] })] }) }));
};
export default AccountingLicensing;
const Panels = styled.div `
  padding: 0;
`;
const NotificationMessage = styled.div `
  margin: 1rem 0;
  margin-top: 2rem;
  background-color: #f7f8f8;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.5rem 1rem;

  h5 {
    color: #1a8cff;
  }
`;
