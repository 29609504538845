import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import SARow from "src/components/Form/Table/SARow";
import { PageLayoutWithTabAndBack } from "src/components/layouts/PageLayout";
import { selectCollectionPoints, selectIsLoading } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { getListLicenseByCollectionPoint } from "../store/action";
import { selectCollectionPoint } from "../store/selector";
import { setCollectionPoint, setResetPageState } from "../store/slice";
import LicenseManagement from "./components/LicenseManagement";
import LicenseManagerManagement from "./components/LicenseManagerManagement";
import NumberKioskForm from "./components/NumberKioskForm";
const CreateAKioskPage = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const collectionPoints = useAppSelector(selectCollectionPoints);
    const selectedCollectionPoint = useAppSelector(selectCollectionPoint);
    const isLoading = useAppSelector(selectIsLoading);
    React.useEffect(() => () => {
        dispatch(setResetPageState());
    }, [dispatch, location.pathname]);
    React.useEffect(() => {
        if (collectionPoints.length > 0 && !selectedCollectionPoint.id)
            dispatch(setCollectionPoint(collectionPoints[0]));
    }, []);
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getListLicenseByCollectionPoint());
            dispatch(setIsLoading(false));
        };
        if (selectedCollectionPoint)
            loadData();
    }, [selectedCollectionPoint]);
    return (_jsx(PageLayoutWithTabAndBack, { heading: "Kiosk Management", children: _jsxs(PageWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(SARow, { gutter: [16, 32], children: [_jsx(NumberKioskForm, {}), _jsx(LicenseManagement, {}), _jsx(LicenseManagerManagement, {})] })] }) }));
};
export default CreateAKioskPage;
const PageWrapper = styled.div `
  width: 100%;
  padding: 2rem;
`;
