export var BillingCycleType;
(function (BillingCycleType) {
    BillingCycleType["Fortnightly"] = "Fortnightly";
    BillingCycleType["Monthly"] = "Monthly";
})(BillingCycleType || (BillingCycleType = {}));
export var BillingCycleStatus;
(function (BillingCycleStatus) {
    BillingCycleStatus["Active"] = "Active";
    BillingCycleStatus["Inactive"] = "Inactive";
    BillingCycleStatus["Pending"] = "Pending";
    BillingCycleStatus["New"] = "New";
})(BillingCycleStatus || (BillingCycleStatus = {}));
export var InvoiceType;
(function (InvoiceType) {
    InvoiceType["License"] = "License";
    InvoiceType["ITService"] = "ITService";
    InvoiceType["Testing"] = "Testing";
})(InvoiceType || (InvoiceType = {}));
