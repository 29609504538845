import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LeftOutlined, MenuOutlined, ReloadOutlined, RightOutlined } from "@ant-design/icons";
import { Button, DatePicker, Select, Space } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { SASelect } from "src/components/Form";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getCollectorCalendarEvents } from "../store/action";
import { selectCollectionPointStaffs, selectCollectorId, selectCurrentDate, selectCurrentView, selectIsListView, selectListCollectionPointStaffs, selectStaffs, } from "../store/selector";
import { setCollectionPoint, setCollectorId, setCurrentDate, setCurrentView, setIsListView, } from "../store/slice";
import { CalendarView, CalendarViewLabelList } from "../typing";
const Toolbar = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentCollectionPoint = useAppSelector(selectCollectionPointStaffs);
    const collectionPoints = useAppSelector(selectListCollectionPointStaffs);
    const currentCollectorId = useAppSelector(selectCollectorId);
    const staffs = useAppSelector(selectStaffs);
    const isListView = useAppSelector(selectIsListView);
    const currentDate = useAppSelector(selectCurrentDate);
    const currentView = useAppSelector(selectCurrentView);
    const getCalendarDateFormat = (date) => {
        switch (currentView) {
            case CalendarView.Day: {
                return date.format(`dd, DD MMM YYYY`);
            }
            case CalendarView.Week: {
                const startOfWeek = date.startOf("week");
                return `${startOfWeek.format("DD MMM")} - ${startOfWeek.add(6, "days").format("DD MMM")} ${date.format("YYYY")}`;
            }
            case CalendarView.Month: {
                return date.format(`MMMM YYYY`);
            }
        }
    };
    useEffect(() => {
        if (searchParams.get("view") !== currentView ||
            searchParams.get("date") !== currentDate ||
            searchParams.get("collectionPointId") !== currentCollectionPoint) {
            setSearchParams({
                view: currentView,
                date: currentDate,
                collectionPointId: currentCollectionPoint ?? "",
            });
        }
    }, [currentDate, currentView, currentCollectionPoint]);
    return (_jsx(CalendarToolbarWrapper, { children: _jsxs(NavigationBar, { children: [_jsx(SAColumn, { xl: 6, lg: 12, md: 24, children: _jsxs(NavigationDate, { children: [_jsx(Button, { onClick: () => {
                                    dispatch(setCurrentDate(moment(currentDate).subtract(1, currentView).toISOString()));
                                }, children: _jsx(LeftOutlined, {}) }), _jsx(TitleBox, { format: getCalendarDateFormat, value: moment(currentDate), picker: currentView === CalendarView.Day ? undefined : currentView, suffixIcon: null, clearIcon: null, onChange: (date) => {
                                    if (!date)
                                        return;
                                    dispatch(setCurrentDate(date.toISOString()));
                                } }), _jsx(Button, { onClick: () => {
                                    dispatch(setCurrentDate(moment(currentDate).add(1, currentView).toISOString()));
                                }, children: _jsx(RightOutlined, {}) })] }) }), _jsx(SAColumn, { xl: 2, lg: 12, md: 24, children: _jsx(CalendarViewOption, { value: currentView, options: CalendarViewLabelList, onChange: (value) => {
                            dispatch(setCurrentView(value));
                            if (!currentCollectionPoint)
                                return;
                            dispatch(getCollectorCalendarEvents());
                        } }) }), _jsx(SAColumn, { xl: 2, lg: 12, md: 24, children: _jsx(Button, { onClick: () => {
                            dispatch(setCurrentDate(moment().toISOString()));
                            dispatch(setCurrentView(CalendarView.Day));
                        }, children: _jsxs(Space, { children: ["Today ", _jsx(ReloadOutlined, {})] }) }) }), _jsx(SAColumn, { xl: 2, lg: 12, md: 24, children: _jsx(Button, { onClick: () => {
                            dispatch(setIsListView(!isListView));
                            dispatch(setCurrentView(CalendarView.Week));
                        }, type: isListView ? "primary" : "default", children: _jsxs(Space, { children: ["List View ", _jsx(MenuOutlined, {})] }) }) }), _jsx(SAColumn, { xl: 6, lg: 12, md: 24, children: _jsx(SASelect, { placeholder: "Please select service office", fieldNames: { label: "collectionPointName", value: "collectionPointId" }, options: [
                            { collectionPointName: "All", collectionPointId: "", collectors: [] },
                            ...collectionPoints,
                        ], value: currentCollectionPoint, onChange: async (collectionPointId) => {
                            dispatch(setIsLoading(true));
                            await dispatch(setCollectorId(""));
                            await dispatch(setCollectionPoint(collectionPointId));
                            await dispatch(getCollectorCalendarEvents());
                            dispatch(setIsLoading(false));
                        }, children: "Service Office" }) }), _jsx(SAColumn, { xl: 6, lg: 12, md: 24, children: _jsx(SASelect, { placeholder: "Please select collector", fieldNames: { label: "collectorName", value: "collectorId" }, options: [{ collectorName: "All", collectorId: "" }, ...staffs], value: currentCollectorId, onChange: async (collectorId) => {
                            dispatch(setIsLoading(true));
                            await dispatch(setCollectorId(collectorId));
                            await dispatch(getCollectorCalendarEvents());
                            dispatch(setIsLoading(false));
                        }, children: "Collector" }) })] }) }));
};
export default Toolbar;
const CalendarToolbarWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  max-height: 25rem;
  margin-bottom: 1rem;
`;
const NavigationBar = styled(SARow) `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
`;
const NavigationDate = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0;
  width: 100%;
`;
const CalendarViewOption = styled(Select) `
  width: 100%;
`;
const TitleBox = styled(DatePicker) `
  width: 100%;
  background: #ffffff;
  border-radius: 0;
  height: 100%;

  .ant-picker-input {
    input {
      text-align: center;
    }
  }
`;
