import { createAsyncThunk } from "@reduxjs/toolkit";
import { $get, $post, $put } from "src/infra/http";
import { Domain } from "src/types";
const BASE_URL = "appointment";
const createAppointment = createAsyncThunk(`${BASE_URL}/create`, async (body) => await $post(`/collection-manager/${BASE_URL}/create`, body));
const cancelAppointment = createAsyncThunk(`${BASE_URL}/cancel`, async (appointmentId) => await $get(`/collection-manager/${BASE_URL}/cancel/${appointmentId}`));
const restoreAppointment = createAsyncThunk(`${BASE_URL}/restore`, async (appointmentId) => await $get(`/collection-manager/${BASE_URL}/restore/${appointmentId}`));
const resendEmailAppointment = createAsyncThunk(`${BASE_URL}/resend-email`, async (appointmentId) => await $get(`/collection-manager/${BASE_URL}/resend-email/${appointmentId}`));
const updateAppointment = createAsyncThunk(`${BASE_URL}/update`, async ({ appointmentId, body }) => await $put(`/collection-manager/${BASE_URL}/update/${appointmentId}`, body));
const updateTimeAppointment = createAsyncThunk(`${BASE_URL}/update-time`, async ({ appointmentId, body }) => await $put(`/collection-manager/${BASE_URL}/update-time/${appointmentId}`, body));
const getAppointmentFromTime = createAsyncThunk(`${BASE_URL}/`, async (startAt) => await $get(`/collection-manager/${BASE_URL}?startAt=${startAt}`));
const getAppointmentById = createAsyncThunk(`${BASE_URL}/id`, async (appointmentId) => await $get(`/collection-manager/${BASE_URL}/${appointmentId}`));
const searchClientByName = createAsyncThunk(`groupBooking/searchClientByName`, async (name) => await $get(`/appointment/client/search?name=${name}`, Domain.CM));
const searchAuthoriseByName = createAsyncThunk(`groupBooking/searchClientByName`, async (query) => await $get(`/appointment/authorise/search?name=${query.name}&&clientId=${query.clientId}`, Domain.CM));
const createGroupAppointment = createAsyncThunk(`groupBooking/searchClientByName`, async (data) => await $post(`/appointment`, data, Domain.CM));
const getGroupAppointment = createAsyncThunk(`groupBooking/searchClientByName`, async (id) => await $get(`/appointment/${id}`, Domain.CM));
export { cancelAppointment, createAppointment, getAppointmentById, getAppointmentFromTime, resendEmailAppointment, restoreAppointment, updateAppointment, updateTimeAppointment, searchClientByName, searchAuthoriseByName, createGroupAppointment, getGroupAppointment, };
