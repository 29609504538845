import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, DatePicker, Form, Input, InputNumber, Select, Switch, TimePicker, Typography, message, } from "antd";
import { keyBy, pick } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import CallOutIcon from "src/assets/svg/job-management/call-out-icon.svg";
import DurationIcon from "src/assets/svg/job-management/duration-icon.svg";
import StartTimeIcon from "src/assets/svg/job-management/start-time-icon.svg";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { getApplicationInformation, selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { timeToMinuteDuration } from "src/utils/helper";
import { TimeFormat } from "src/store/app/types";
import AssignCollectorTable from "../../components/AssignCollectorTable";
import FormItem from "../../components/FormItem";
import Heading from "../../components/Heading";
import { getJobDetail, updateDraftOnsiteJob, updateOnsiteJob } from "../../store/action";
import { selectCreatingJobDetails, selectExistedJobDetail } from "../../store/selector";
import { setJobSetupDetails, setResetStore } from "../../store/slice";
import { OnsiteClientType, OnsiteJobClientStatus, OnsiteJobStatus, OnsiteJobType, } from "../../store/types";
import { OnsiteJobServiceTypeName } from "../../typing";
const UpdateCallOutJob = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { onsiteJobId } = useParams();
    const appInformation = useAppSelector(getApplicationInformation);
    const isLoading = useAppSelector(selectIsLoading);
    const jobSetupDetails = useAppSelector(selectCreatingJobDetails);
    const existedJobDetails = useAppSelector(selectExistedJobDetail);
    const jobSetupDetailData = jobSetupDetails.data;
    const isDisabled = jobSetupDetailData.status !== OnsiteJobStatus.Planned &&
        jobSetupDetailData.status !== OnsiteJobStatus.Draft;
    const allocatedCollectorMaps = keyBy(existedJobDetails.job.allocatedCollectors, "collectorId");
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            dispatch(setResetStore());
            await dispatch(getJobDetail(onsiteJobId ?? ""));
            dispatch(setIsLoading(false));
        };
        loadData();
    }, []);
    useEffect(() => {
        form.setFieldsValue(jobSetupDetailData);
        form.setFieldValue("startAt", moment(jobSetupDetailData.startAt?.toISOString()));
    }, [jobSetupDetails]);
    useEffect(() => {
        if (jobSetupDetailData.type === OnsiteJobType.Normal) {
            navigate(`/job-management/onsite-jobs/${onsiteJobId}/normal`);
        }
    }, []);
    return (_jsxs(CreateFormWrapper, { disabled: isDisabled, children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(CustomForm, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, onFinishFailed: () => {
                    window.scrollTo(0, 0);
                    message.error("The form has not been finished yet");
                }, onFinish: async () => {
                    if (!jobSetupDetailData.startAt?.isBefore(moment())) {
                        dispatch(setIsLoading(true));
                        if (!jobSetupDetailData.collectorIds.length) {
                            const res = await dispatch(updateDraftOnsiteJob({
                                ...pick(jobSetupDetailData, [
                                    "onsiteClientLocationId",
                                    "clientStatus",
                                    "collectorIds",
                                    "startAt",
                                    "noOfDonors",
                                    "generalInstruction",
                                ]),
                                type: OnsiteJobType.Callout,
                                onsiteJobId: onsiteJobId ?? existedJobDetails.job.id,
                                serviceType: jobSetupDetailData.serviceType ?? null,
                                duration: timeToMinuteDuration(jobSetupDetailData.duration),
                            }));
                            dispatch(setIsLoading(false));
                            if (res.payload.status === "success") {
                                navigate(`/job-management/onsite-jobs`);
                            }
                            return;
                        }
                        else {
                            if (jobSetupDetailData.collectorIds.filter((id) => !id).length) {
                                message.error("There is invalid collector");
                                dispatch(setIsLoading(false));
                                return;
                            }
                            const res = await dispatch(updateOnsiteJob({
                                ...pick(jobSetupDetailData, [
                                    "onsiteClientLocationId",
                                    "clientStatus",
                                    "collectorIds",
                                    "startAt",
                                    "noOfDonors",
                                    "generalInstruction",
                                ]),
                                type: OnsiteJobType.Callout,
                                onsiteJobId: onsiteJobId ?? existedJobDetails.job.id,
                                serviceType: +(jobSetupDetailData.serviceType ?? 0),
                                duration: timeToMinuteDuration(jobSetupDetailData.duration),
                            }));
                            dispatch(setIsLoading(false));
                            if (res.payload.status === "success") {
                                navigate(`/job-management/onsite-jobs`);
                            }
                        }
                    }
                    else {
                        message.error("Selected Start Time cannot be before the current time");
                    }
                }, children: [_jsxs(CollectionOrganizationContainer, { children: [_jsx(Heading, { startTitle: "Collection Organisation" }), _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 16, children: _jsx(FormItem, { label: "Collection Organisation", children: _jsx(StyledInput, { disabled: true, value: appInformation.collectionOrganization.collectionName }) }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(FormItem, { label: "Service Office", children: _jsx(StyledInput, { disabled: true, value: existedJobDetails.collectionPoint.name }) }) })] })] }), _jsxs(ClientContainer, { children: [_jsx(Heading, { startTitle: "Client", endTitle: existedJobDetails.onsiteClient.type === OnsiteClientType.Callout
                                    ? "Call Out Client"
                                    : "", endIcon: existedJobDetails.onsiteClient.type === OnsiteClientType.Callout ? (_jsx(CallOutIcon, {})) : (_jsx(_Fragment, {})) }), _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 16, children: _jsx(FormItem, { label: "Client", children: _jsx(StyledInput, { value: existedJobDetails.onsiteClient.name, disabled: true }) }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(FormItem, { label: "Client Reference", children: _jsx(StyledInput, { value: existedJobDetails.onsiteClient.reference, disabled: true }) }) })] }), _jsx(SARow, { children: _jsxs(SAColumn, { md: 24, align: "flex-end", justify: "flex-end", direction: "row", children: [_jsx(Typography.Text, { children: " In-House " }), _jsx(CustomSwitch, { checked: jobSetupDetailData.clientStatus === OnsiteJobClientStatus.MobileClinic, onChange: (isChecked) => {
                                                dispatch(setJobSetupDetails({
                                                    ...jobSetupDetailData,
                                                    clientStatus: isChecked
                                                        ? OnsiteJobClientStatus.MobileClinic
                                                        : OnsiteJobClientStatus.InHouse,
                                                }));
                                            } }), _jsx(Typography.Text, { children: " Mobile In-Clinic " })] }) }), _jsx(Heading, { startTitle: "Authorised Representative" }), _jsxs(SARow, { gutter: [16, 0], children: [_jsx(SAColumn, { md: 24, lg: 16, align: "center", children: _jsx(FormItem, { label: "Authorised Representative", name: "onsiteClientRepresentativeId", requiredText: jobSetupDetailData.collectorIds.length
                                                ? "Please select an authorised representative"
                                                : undefined, children: _jsx(Select, { onChange: (onsiteClientRepresentativeId) => {
                                                    dispatch(setJobSetupDetails({ ...jobSetupDetailData, onsiteClientRepresentativeId }));
                                                }, notFoundContent: "No data or a client has not selected yet", children: existedJobDetails.clientRepresentatives.map((representative) => (_jsx(Select.Option, { value: representative.id, children: representative.name }, representative.id))) }) }) }), existedJobDetails.clientRepresentatives
                                        .filter((representative) => representative.id === jobSetupDetailData.onsiteClientRepresentativeId)
                                        .map((representative) => (_jsxs(React.Fragment, { children: [_jsx(SAColumn, { md: 24, lg: 8, children: _jsx(FormItem, { label: "Position", children: _jsx(StyledInput, { value: representative.position }) }) }), _jsx(SAColumn, { md: 24, lg: 16, children: _jsx(FormItem, { label: "Email", children: _jsx(StyledInput, { value: representative.email }) }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(FormItem, { label: "Mobile", children: _jsx(StyledInput, { value: representative.mobile }) }) })] }, representative.id)))] }), _jsx(Heading, { startTitle: "Job Details" }), _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 4, children: _jsx(FormItem, { label: "Job Date", name: "startAt", requiredText: jobSetupDetailData.collectorIds.length ? "Please select the job date" : undefined, rules: jobSetupDetailData.collectorIds.length
                                                ? [
                                                    {
                                                        validator: (_, value) => {
                                                            if (!value || value.isBefore(moment().startOf("day"))) {
                                                                return Promise.reject(new Error("Date cannot be before today!"));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]
                                                : undefined, children: _jsx(CustomDatePicker, { format: "DD MMM YYYY", disabledDate: (current) => current && current.startOf("day").valueOf() < moment().startOf("day").valueOf(), onChange: (time) => {
                                                    dispatch(setJobSetupDetails({
                                                        ...jobSetupDetailData,
                                                        startAt: moment(time?.toDate().toISOString()),
                                                    }));
                                                } }) }) }), _jsx(SAColumn, { md: 24, lg: 4, children: _jsx(FormItem, { label: "Start Time", name: "startAt", requiredText: jobSetupDetailData.collectorIds.length ? "Please select start time" : undefined, children: _jsx(CustomTimePicker, { suffixIcon: _jsx(StartTimeIcon, {}), format: appInformation.collectionOrganization.timeFormat === TimeFormat.TwelveHour
                                                    ? "hh:mm A"
                                                    : "HH:mm", use12Hours: appInformation.collectionOrganization.timeFormat === TimeFormat.TwelveHour, onChange: (time) => {
                                                    const updatedStartAt = jobSetupDetailData.startAt
                                                        ? moment(jobSetupDetailData.startAt.toISOString()).set({
                                                            hour: time?.hour() ?? 0,
                                                            minute: time?.minute() ?? 0,
                                                        })
                                                        : moment(time?.toISOString());
                                                    if (updatedStartAt.isBefore(moment())) {
                                                        message.error("Selected Start Time cannot be before the current time");
                                                    }
                                                    dispatch(setJobSetupDetails({ ...jobSetupDetailData, startAt: updatedStartAt }));
                                                } }) }) }), _jsx(SAColumn, { md: 24, lg: 4, children: _jsx(FormItem, { label: "Duration", name: "duration", requiredText: jobSetupDetailData.collectorIds.length ? "Please select duration" : undefined, rules: jobSetupDetailData.collectorIds.length
                                                ? [
                                                    {
                                                        validator: (_, value) => {
                                                            if (!value || (value.hours() === 0 && value.minutes() === 0)) {
                                                                return Promise.reject(new Error("Duration cannot be 0 minutes!"));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]
                                                : undefined, children: _jsx(CustomTimePicker, { suffixIcon: _jsx(DurationIcon, {}), format: "HH [hr] mm [min]", onChange: (time) => {
                                                    dispatch(setJobSetupDetails({
                                                        ...jobSetupDetailData,
                                                        duration: moment(time?.toISOString()),
                                                    }));
                                                }, showNow: false, placeholder: "Select duration", addon: () => (_jsxs(ExtraHeader, { children: [_jsx(HeaderTitleTimePicker, { children: "Hours" }), _jsx(HeaderTitleTimePicker, { children: "Mins" })] })) }) }) }), _jsx(SAColumn, { md: 24, lg: 4, children: _jsx(FormItem, { label: "Number of Donors", name: "noOfDonors", requiredText: jobSetupDetailData.collectorIds.length
                                                ? "Please select number of donors"
                                                : undefined, rules: jobSetupDetailData.collectorIds.length
                                                ? [
                                                    {
                                                        validator: (_, value) => {
                                                            if (!value || value <= 0) {
                                                                return Promise.reject(new Error("Number of donors cannot be 0"));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    },
                                                ]
                                                : undefined, children: _jsx(StyledInputNumber, { value: jobSetupDetailData.noOfDonors, onChange: (value) => {
                                                    dispatch(setJobSetupDetails({ ...jobSetupDetailData, noOfDonors: +(value ?? 0) }));
                                                } }) }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(FormItem, { label: "Job Reference", children: _jsx(StyledInput, { value: existedJobDetails?.job.reference, disabled: true }) }) })] }), _jsx(SARow, { children: _jsx(SAColumn, { md: 24, lg: 16, children: _jsx(FormItem, { label: "Type of Service", name: "serviceType", required: true, requiredText: jobSetupDetailData.collectorIds.length
                                            ? "Please select type of services"
                                            : undefined, children: _jsx(Select, { value: jobSetupDetailData.serviceType, onChange: (serviceType) => {
                                                dispatch(setJobSetupDetails({ ...jobSetupDetailData, serviceType }));
                                            }, children: Object.entries(OnsiteJobServiceTypeName).map(([serviceTypeId, serviceTypeName]) => (_jsx(Select.Option, { value: Number(serviceTypeId), children: serviceTypeName }, serviceTypeId))) }) }) }) }), _jsx(Heading, { startTitle: "Location" }), _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 16, children: _jsx(FormItem, { label: "Site", name: "onsiteClientLocationId", requiredText: jobSetupDetailData.collectorIds.length ? "Please select location" : undefined, children: _jsx(Select, { onChange: (onsiteClientLocationId) => {
                                                    dispatch(setJobSetupDetails({
                                                        ...jobSetupDetailData,
                                                        onsiteClientLocationId,
                                                    }));
                                                }, notFoundContent: "No data or a client has not selected yet", children: existedJobDetails.onsiteClientLocations.map((location) => (_jsx(Select.Option, { value: location.id, children: location.name }, location.id))) }) }) }), existedJobDetails.onsiteClientLocations
                                        .find((location) => location.id === jobSetupDetailData.onsiteClientLocationId)
                                        ?.contacts.map((locationContact, index) => (_jsxs(React.Fragment, { children: [_jsx(SAColumn, { md: 24, lg: 16, children: _jsx(FormItem, { label: `Call Out Contact ${index + 1}`, children: _jsx(StyledInput, { value: locationContact.name }) }) }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsx(FormItem, { label: "Call Out Contact Mobile", children: _jsx(StyledInput, { value: locationContact.mobile }) }) })] }, index)))] }), _jsx(Heading, { startTitle: "Call Out Instructions" }), _jsx(SARow, { children: _jsx(SAColumn, { md: 24, lg: 24, children: _jsx(FormItem, { name: "generalInstruction", children: _jsx(StyledInputTextArea, { rows: 6, onChange: (event) => {
                                                dispatch(setJobSetupDetails({
                                                    ...jobSetupDetailData,
                                                    generalInstruction: event.target.value,
                                                }));
                                            } }) }) }) }), _jsx(Heading, { startTitle: "Assign Collectors" }), _jsx(AssignCollectorTable, { availableCollectors: existedJobDetails.collectionPoint.collectors.map((collector) => {
                                    const currentCollector = allocatedCollectorMaps[collector.id];
                                    if (currentCollector) {
                                        return {
                                            ...pick(currentCollector, [
                                                "status",
                                                "completedAt",
                                                "rejectedAt",
                                                "allocatedAt",
                                                "acceptedAt",
                                            ]),
                                            id: currentCollector.collectorId,
                                            firstName: currentCollector.collectorInfo.firstName,
                                            lastName: currentCollector.collectorInfo.lastName,
                                        };
                                    }
                                    return {
                                        id: collector.id,
                                        firstName: collector.firstName,
                                        lastName: collector.lastName,
                                        status: null,
                                    };
                                }), selectedCollectorIds: jobSetupDetailData.collectorIds, updateCollector: (selectedCollectorIds) => {
                                    dispatch(setJobSetupDetails({ ...jobSetupDetailData, collectorIds: selectedCollectorIds }));
                                } }), _jsxs(SARow, { justify: "end", children: [_jsx(SAColumn, { md: 24, lg: 3, children: _jsx(CustomButton, { danger: true, onClick: () => navigate("/job-management/onsite-jobs"), children: "Cancel" }) }), !isDisabled && (_jsx(SAColumn, { md: 24, lg: 3, children: _jsx(CustomButton, { type: "primary", htmlType: "submit", loading: isLoading, children: "Update" }) }))] })] })] })] }));
};
export default UpdateCallOutJob;
const CollectionOrganizationContainer = styled.div `
  padding: 2rem;
  background-color: #e6f7fa;
  border-radius: 1.2rem;
  margin-bottom: 2rem;
`;
const ClientContainer = styled.div `
  padding: 2rem;
  background-color: #edeef0;
  border-radius: 1.2rem;
  margin-bottom: 2rem;
`;
const CreateFormWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;

  ${(props) => props.disabled &&
    `
    pointer-events: none;

    & input,
    & select,
    & textarea {
      background-color: #ffffff !important;
      color: #000000 !important;
    }
    & button {
      opacity: 0.5;
    }

     & button:last-child {
      pointer-events: auto;
      opacity: 1;
    }
  `}
`;
const CustomForm = styled(Form) `
  width: 100%;
`;
const StyledInput = styled(Input) `
  width: 100%;
  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
`;
const StyledInputNumber = styled(InputNumber) `
  width: 100%;
  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
`;
const StyledInputTextArea = styled(Input.TextArea) `
  width: 100%;
  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
  margin-block: 1rem;
`;
const CustomTimePicker = styled(TimePicker) `
  width: 100%;
`;
const CustomDatePicker = styled(DatePicker) `
  width: 100%;
`;
const CustomButton = styled(Button) `
  width: 100%;
  border: 0;
`;
const CustomSwitch = styled(Switch) `
  margin-inline: 1rem;
`;
const ExtraHeader = styled.div `
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1.5715;
  position: absolute;
  z-index: 1060;
  background-color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  border-bottom: 1px solid #f0f0f0;
`;
const HeaderTitleTimePicker = styled.p `
  width: 50%;
  font-size: 14px;
  text-align: center;
`;
