import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EyeOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Menu, Typography } from "antd";
import arrayMove from "array-move";
import * as React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import ICSubmenuItem from "src/assets/svg/dashboard/ic-item-submenu.svg";
import { SAColumn, SARow } from "src/components/Form";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectReorderInventorySummary } from "../../store/selector";
import { setReorderInventorySummary, setSummaryView } from "../../store/slice";
import { InventorySummaryView } from "../../store/types";
const SummaryView = () => {
    const dispatch = useAppDispatch();
    const reorderSummary = useAppSelector(selectReorderInventorySummary);
    const [newOrderSummary, setNewOrderSummary] = React.useState(reorderSummary);
    const [dropdownVisible, setDropdownVisible] = React.useState(false); // State to control dropdown visibility
    React.useEffect(() => {
        setNewOrderSummary(reorderSummary);
    }, [reorderSummary]);
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const currentNewOrderSummary = arrayMove(newOrderSummary, oldIndex, newIndex);
        setNewOrderSummary(currentNewOrderSummary);
    };
    const SortableItem = SortableElement(({ label }) => (_jsxs(SortableItemWrapper, { children: [_jsxs(StyledLabel, { children: [_jsx(EyeOutlined, {}), label] }), _jsx(ICSubmenuItem, {})] })));
    const SortableList = SortableContainer(({ items }) => (_jsx(SortableListWrapper, { children: items.map((item, index) => (_jsx(SortableItem, { index: index, label: item.label }, `item-${index}`))) })));
    return (_jsx(Dropdown, { overlay: _jsx(StyledMenu, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(Button, { type: "primary", ghost: true, onClick: () => {
                                dispatch(setSummaryView(InventorySummaryView.List));
                                setDropdownVisible(false);
                            }, children: "List View" }) }), _jsx(SAColumn, { span: 12, children: _jsx(Button, { type: "primary", onClick: () => {
                                dispatch(setSummaryView(InventorySummaryView.Graph));
                                setDropdownVisible(false);
                            }, children: "Graph View" }) }), _jsx(Divider, {}), _jsx(SAColumn, { span: 18, children: _jsx(Typography.Text, { children: "Show/Hide or Drag&Drop to Reorder" }) }), _jsx(SAColumn, { span: 6, children: _jsx(DefaultButton, { onClick: () => setDropdownVisible(false), children: "Default" }) }), _jsx(SAColumn, { children: _jsx(SortableList, { items: newOrderSummary, onSortEnd: onSortEnd }) }), _jsx(Divider, {}), _jsx(SAColumn, { children: _jsx(ApplyButton, { type: "primary", onClick: () => {
                                dispatch(setReorderInventorySummary(newOrderSummary));
                                setDropdownVisible(false);
                            }, children: "Apply" }) })] }) }), trigger: ["click"], visible: dropdownVisible, onVisibleChange: (visible) => setDropdownVisible(visible), children: _jsx(DropdownButton, { icon: _jsx(MenuOutlined, {}), onClick: () => setDropdownVisible(true) }) }));
};
export default SummaryView;
const DropdownButton = styled(Button) `
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  color: #2e94f2;
  border: none;
  border-radius: 0.3rem;
`;
const StyledMenu = styled(Menu) `
  padding: 2rem 1rem;
  width: 30rem;
`;
const DefaultButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebedf0;
  color: #2f3337;
  border-radius: 0.8125rem;
  border: none;
  padding: 0.5rem;
`;
const ApplyButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a8cff;
  border: none;
  border-radius: 0.3125rem;
  width: 4.375rem;
  margin: auto;
  height: 1.5625rem;
`;
const SortableItemWrapper = styled.div `
  display: flex;
  cursor: grab;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border: 0.0625rem solid #adb3bc;
  margin-bottom: 0.625rem;
  border-radius: 0.3125rem;
  background: white;
  z-index: 9999;
`;
const StyledLabel = styled.p `
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;
const SortableListWrapper = styled.div `
  padding: 0.3125rem 0;
  z-index: 99;
  width: 100%;
`;
