import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from "qs";
import { $get, $post } from "src/infra/http";
import { Domain } from "src/types";
const getListCompletedInvoices = createAsyncThunk("accounting/getListCompletedInvoices", async (filters) => {
    const queryString = qs.stringify(filters, { skipNulls: true });
    return await $get(`/accounting/invoice?${queryString}`, Domain.CM);
});
const downloadInvoicesZip = async (invoiceFiles) => {
    const response = await $post(`v1/cm/accounting-invoice/download`, {
        invoiceFiles,
    }, Domain.Upload, {
        responseType: "blob",
    });
    return response;
};
export { downloadInvoicesZip, getListCompletedInvoices };
