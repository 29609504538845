import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Select, Space } from "antd";
import * as React from "react";
import styled from "styled-components";
import SADivider from "src/components/Form/SADivider";
import SASwitch from "src/components/Form/Table/SASwitch";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { ExportIcon } from "src/assets/svg/accounting";
import { selectIsIncludedGst } from "./store/selector";
import { setIsIncludedGst } from "./store/slice";
const ExportOptionsBar = () => {
    const dispatch = useAppDispatch();
    const isIncludedGst = useAppSelector(selectIsIncludedGst);
    return (_jsxs(FilterBarWrapper, { children: [_jsxs(FilterOptionsContainer, { children: [_jsxs(Space, { children: [_jsx(FilterLabel, { children: "GST" }), _jsx(SASwitch, { uncheckedLabel: "exclusive", checkedLabel: "inclusive", checked: isIncludedGst, onChange: () => dispatch(setIsIncludedGst(!isIncludedGst)) })] }), _jsxs(Space, { children: [_jsx(FilterLabel, { children: "Export" }), _jsx(ExportSelect, { disabled: true, defaultValue: "PDF", options: [
                                    { label: "PDF", value: "PDF" },
                                    { label: "CSV", value: "CSV" },
                                ] })] }), _jsx(Space, { children: _jsx(IconButton, { disabled: true, children: _jsx(ExportIcon, {}) }) })] }), _jsx(SADivider, {})] }));
};
export default ExportOptionsBar;
const FilterBarWrapper = styled.div `
  width: 100%;
`;
const FilterOptionsContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-end;
`;
const FilterLabel = styled.p `
  font-weight: 700;
  font-size: 1rem;
  margin-inline: 0.5rem;
`;
const ExportSelect = styled(Select) `
  width: 5rem;
  border-radius: 0.25rem;
`;
const IconButton = styled(Button) `
  background-color: #ffffff;
  border: 0.025rem solid #adb3bc;
  border-radius: 0.25rem;
`;
