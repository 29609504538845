import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { deactivateLicense, getListLicenseByCollectionPoint } from "./action";
export const initialState = {
    selectedCollectionPoint: {
        collectionPointName: "",
        id: "",
    },
    licensesByCollectionPoint: {
        userList: [],
        currentLicense: [],
        noLicense: 0,
        noNotAssigned: 0,
    },
};
const kioskManagementSlice = createSlice({
    name: "kioskManagement",
    initialState,
    reducers: {
        setCollectionPoint(state, action) {
            state.selectedCollectionPoint = action.payload;
        },
        setResetPageState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListLicenseByCollectionPoint.fulfilled, (state, action) => {
            const { error, data } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
            state.licensesByCollectionPoint = data;
        })
            .addCase(getListLicenseByCollectionPoint.rejected, () => {
            message.error("Failed to fetch licenses");
        })
            .addCase(deactivateLicense.fulfilled, (state, action) => {
            const { error } = action.payload;
            if (error) {
                message.error(error);
                return;
            }
        })
            .addCase(deactivateLicense.rejected, () => {
            message.error("Failed to delete the license");
        });
    },
});
export const { setCollectionPoint, setResetPageState } = kioskManagementSlice.actions;
export default kioskManagementSlice.reducer;
