import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import TestDetailTable from "src/pages/Accounting/Dashboard/components/TestDetailTable";
import MedicalTestOverviewBox from "src/pages/Accounting/components/MedicalTestOverviewBox";
import ViewDetailButton from "src/pages/Accounting/components/ViewDetailButton";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import LabDetailTable from "../../Testing/components/LabDetailTable";
import { getCurrentOnsiteJobDetails } from "../../Testing/store/action";
import { selectDisplayCost, selectOverallCostSummary } from "../store/selector";
const RequestLabTestBox = forwardRef((_, ref) => {
    const dispatch = useAppDispatch();
    const overallCostSummary = useAppSelector(selectOverallCostSummary);
    const displayCost = useAppSelector(selectDisplayCost);
    const [isOpenDetailView, setIsOpenDetailView] = useState(false);
    const [isOpenInClinicDetailView, setIsOpenInClinicDetailView] = useState(false);
    const [isOpenOnsiteDetailView, setIsOpenOnsiteDetailView] = useState(false);
    const [onsiteData, setOnsiteData] = useState([]);
    useEffect(() => {
        setIsOpenDetailView(false);
        setIsOpenInClinicDetailView(false);
        setIsOpenOnsiteDetailView(false);
    }, [overallCostSummary]);
    return (_jsxs(PackageWrapper, { ref: ref, children: [_jsxs(PackageContentRow, { children: [_jsx(SAColumn, { lg: 5, md: 24, children: _jsxs(PackageTitleSection, { children: [_jsx(BoxHeading, { children: "REQUESTED LABORATORY TESTS" }), _jsx(SubText, { children: "Digital Chain of Custody" })] }) }), _jsx(SAColumn, { lg: 7, md: 24, offset: 8, pull: 8, children: _jsx(MedicalTestOverviewBox, { subTitle: "Requested Lab Tests", inClinic: 0, onSite: overallCostSummary.onsiteLab.noSamples }) }), _jsx(SAColumn, { lg: 4, md: 24, children: _jsx(PriceBox, { children: _jsxs(PriceText, { children: [" $ ", overallCostSummary.onsiteLab[displayCost]] }) }) })] }), _jsx(ViewDetailButton, { isOpen: isOpenDetailView, setIsOpen: setIsOpenDetailView, children: "Detail View" }), isOpenDetailView && (_jsxs(TestCollectionTablesContainer, { children: [_jsx(ViewDetailButton, { isOpen: isOpenInClinicDetailView, setIsOpen: setIsOpenInClinicDetailView, children: "In-Clinic Collection" }), isOpenInClinicDetailView && _jsx(TestDetailTable, { dataSource: [] }), _jsx(ViewDetailButton, { isOpen: isOpenOnsiteDetailView, setIsOpen: async (isOpen) => {
                            if (isOpen) {
                                const actionResult = await dispatch(getCurrentOnsiteJobDetails()).unwrap();
                                setOnsiteData(actionResult.data);
                            }
                            setIsOpenOnsiteDetailView(isOpen);
                        }, children: "Onsite Collection" }), isOpenOnsiteDetailView && _jsx(LabDetailTable, { dataSource: onsiteData })] }))] }));
});
RequestLabTestBox.displayName = "RequestLabTestBox";
export default RequestLabTestBox;
const PackageWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-block: 4rem;
  gap: 2rem;
`;
const PackageContentRow = styled(SARow) `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #00004c;
`;
const PriceBox = styled.div `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #ccf0f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled.p `
  font-size: 1rem;
  font-weight: 700;
  margin: auto;
`;
const SubText = styled.p `
  font-size: 0.875rem;
`;
const PackageTitleSection = styled.div `
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
const TestCollectionTablesContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    margin-left: 0 !important;
    margin-right: auto;
  }
`;
