import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dropdown, Input, Modal, Typography, Menu, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isEmpty, uniqueId } from "lodash";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { updateEntryManagementSettings } from "src/pages/PlatformManagementMenuPage/WelcomeAssist/EntryManagement/store/action";
const CategoryModal = ({ setRefresh, id, isOpen, setIsOpen, entryData, }) => {
    if (!id)
        return _jsx(_Fragment, {});
    const { categories } = entryData;
    const [editingCategory, setEditingCategory] = useState(categories?.[id] || "");
    useEffect(() => {
        setEditingCategory(categories?.[id] || "");
    }, [categories]);
    const onConfirm = async () => {
        const newCategoryName = categories ?? [];
        newCategoryName[id] = editingCategory;
        const result = await updateEntryManagementSettings({
            ...entryData,
            categories: newCategoryName,
        });
        if (result?.error) {
            message.error("Failed to update category management");
            return;
        }
        message.success("Update entry management settings successfully");
        setIsOpen(false);
        setRefresh();
    };
    const handleOnCancel = () => {
        setIsOpen(false);
        setEditingCategory(categories?.[id] || "");
    };
    return (_jsxs(StyledModal, { title: categories?.[id] ? "Modify Category" : "Add Category", open: isOpen, onCancel: () => handleOnCancel(), footer: _jsx(Button, { type: "primary", onClick: onConfirm, children: "Confirm" }), children: [_jsx(Typography.Text, { children: categories?.[id] ? "Category Name" : "New Category Name" }), _jsx(Input, { value: editingCategory, onChange: (e) => setEditingCategory(e.target.value) })] }));
};
const CategoryItem = ({ id, label, onAction, entryData, setRefresh, }) => {
    const { categories } = entryData;
    const listCategory = categories ?? [];
    const onDelete = async () => {
        const newCategoryName = listCategory;
        newCategoryName[id] = "";
        const result = await updateEntryManagementSettings({
            ...entryData,
            categories: newCategoryName,
        });
        if (result?.error) {
            message.error("Failed to update category management");
            return;
        }
        message.success("Update entry management settings successfully");
        setRefresh();
    };
    const items = (_jsx(Menu, { children: isEmpty(listCategory[id]) ? (_jsx(Menu.Item, { onClick: onAction, children: "Add" }, "Add")) : (_jsxs(_Fragment, { children: [_jsx(Menu.Item, { onClick: onAction, children: "Modify" }, "Modify"), _jsx(Menu.Divider, {}), _jsx(Menu.Item, { onClick: onDelete, children: "Delete" }, "Delete")] })) }));
    return (_jsx(Dropdown, { overlay: items, trigger: ["click"], children: _jsx(StyledButton, { isEmptyLabel: !listCategory[id], onClick: (e) => e.preventDefault(), children: label }) }));
};
const CategoryList = ({ entryData, setRefresh }) => {
    const [isModalOpen, setIsModalOpen] = useState({
        isOpen: false,
        id: null,
    });
    const { categories } = entryData;
    const paddedCategories = categories
        ? [
            ...categories.map((category) => (category === null ? "Empty" : category)),
            ...Array.from({ length: Math.max(0, 9 - categories.length) }, () => "Empty"),
        ]
        : Array.from({ length: 9 }, () => "Empty");
    return (_jsxs(Container, { children: [_jsx(SARow, { children: paddedCategories.map((category, index) => (_jsx(SAColumn, { span: 8, children: _jsx(CategoryItem, { label: isEmpty(category) ? "Empty" : category, id: index, onAction: () => setIsModalOpen({
                            isOpen: true,
                            id: index,
                        }), entryData: entryData, setRefresh: setRefresh }) }, uniqueId()))) }), _jsx(CategoryModal, { isOpen: isModalOpen.isOpen, setIsOpen: () => setIsModalOpen({ isOpen: false, id: null }), id: isModalOpen.id, entryData: entryData, setRefresh: setRefresh })] }));
};
export default CategoryList;
const Container = styled.div `
  width: 100%;
  border-radius: 1rem;
`;
const StyledButton = styled(Button) `
  background-color: ${({ isEmptyLabel }) => (isEmptyLabel ? "#EDEEF0" : "#1A8CFF")};
  color: ${({ isEmptyLabel }) => (isEmptyLabel ? "#000000" : "#ffffff")};
  border: none;
  width: 100%;
  height: 3rem;
  border-radius: 0.25rem;
`;
const StyledModal = styled(Modal) `
  .ant-modal-header {
    border: none;
  }

  .ant-modal-footer {
    text-align: left !important;
    border: none !important;
    padding-left: 1.5rem;
  }

  .ant-modal-body {
    padding-block: 0;
  }
`;
