import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { DefaultPagingMeta } from "src/infra/pagination";
import { createQREntry, getInventoryDetail, getListDeviceTypes, getScannedQRCode, getStockInventoryDetail, getTransportSatchel, } from "src/pages/InventoryManagement/store/action";
import { InventorySummaryView, } from "src/pages/InventoryManagement/store/types";
import { TestTypeInventory } from "../InventorySections/typing";
export const initialState = {
    inventoryDeviceDetail: {
        data: [],
        pagingMeta: DefaultPagingMeta,
    },
    inventoryOverview: {},
    transportSatchels: {
        activeSatchels: [],
        archivedSatchels: [],
    },
    pinDetail: {},
    barcode: {
        scannedBarcode: "",
        barcodeFormat: undefined,
    },
    currentSummaryView: InventorySummaryView.Graph,
    reorderInventoryOverview: [],
    listTestingDeviceTypes: [],
};
const inventoryDetail = createSlice({
    name: "inventoryDetail",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
        resetScannedQRCode(state) {
            state.barcode = {
                scannedBarcode: "",
                barcodeFormat: undefined,
            };
            state.pinDetail = {};
        },
        setSummaryView(state, action) {
            state.currentSummaryView = action.payload;
        },
        setReorderInventorySummary(state, action) {
            state.reorderInventoryOverview = action.payload;
        },
        setDefaultOrderInventorySummary(state) {
            state.reorderInventoryOverview = Object.entries(state.inventoryOverview).map(([key, item]) => ({
                ...TestTypeInventory[key],
                label: TestTypeInventory[key].name,
                remaining: item.totalDevice - item.deviceUsed,
                used: item.deviceUsed,
            }));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInventoryDetail.fulfilled, (state, action) => {
            const { data, error } = action.payload;
            if (!error && data) {
                state.inventoryOverview = data;
                state.reorderInventoryOverview = Object.entries(data).map(([key, item]) => ({
                    ...item,
                    ...TestTypeInventory[key],
                    label: TestTypeInventory[key].name,
                    remaining: item.remainingDevices,
                    used: item.deviceUsed,
                }));
            }
        })
            .addCase(getInventoryDetail.rejected, () => {
            message.error("Failed to fetch to get inventory. Please try again.");
        })
            .addCase(getTransportSatchel.fulfilled, (state, action) => {
            const { data, error } = action.payload;
            if (!error && data) {
                state.transportSatchels = data;
            }
        })
            .addCase(createQREntry.fulfilled, (state, action) => {
            const { data, status } = action.payload;
            if (status === "success" || data) {
                state.pinDetail = data;
            }
        })
            .addCase(createQREntry.rejected, () => {
            message.error("Failed to fetch to generate QR code. Please try again.");
        })
            .addCase(getScannedQRCode.fulfilled, (state, action) => {
            const { data, error } = action.payload;
            if (!error && data) {
                state.barcode = {
                    scannedBarcode: data.scannedBarcode,
                    barcodeFormat: data.barcodeFormat.toUpperCase().replace(/_/g, ""),
                };
            }
        })
            .addCase(getStockInventoryDetail.fulfilled, (state, action) => {
            const { data, status } = action.payload;
            if (status === "success" || data) {
                state.inventoryDeviceDetail = data;
            }
        })
            .addCase(getStockInventoryDetail.rejected, () => {
            message.error("Failed to fetch stock inventory. Please try again.");
        })
            .addCase(getListDeviceTypes.fulfilled, (state, action) => {
            const { data, status } = action.payload;
            if (status === "success" || data) {
                state.listTestingDeviceTypes = data;
            }
        })
            .addCase(getListDeviceTypes.rejected, () => {
            message.error("Failed to fetch list device types. Please try again.");
        });
    },
});
export const { setResetStore, resetScannedQRCode, setSummaryView, setReorderInventorySummary, setDefaultOrderInventorySummary, } = inventoryDetail.actions;
export default inventoryDetail.reducer;
