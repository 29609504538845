import { useEffect } from "react";
import { useAppDispatch } from "src/store/hooks";
const useResetStoreOnUnmount = (action, dependencies = []) => {
    const dispatch = useAppDispatch();
    useEffect(() => () => {
        if (action) {
            dispatch(action());
        }
    }, [dispatch, action, ...dependencies]);
};
export default useResetStoreOnUnmount;
