import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "antd";
import { uniqueId } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { AccountIcon } from "src/assets/svg/common";
import { generateCalendarMonth } from "src/utils";
import { CollectorCalendarEventType } from "../store/types";
import { CalendarView } from "../typing";
import EventDetailPopover from "./EventDetailPopover";
const localizer = momentLocalizer(moment);
moment.updateLocale("en", {
    week: {
        dow: 1,
    },
});
const MonthCalendarView = (props) => {
    const { events, date } = props;
    const [calendar, setCalendar] = useState(undefined);
    useEffect(() => {
        const currentDate = date ? moment(date) : moment();
        const monthCalendar = generateCalendarMonth(currentDate.year(), currentDate.month());
        const calendarData = [];
        for (const week of monthCalendar) {
            const currentWeek = [];
            for (const day of week) {
                const eventData = events?.filter((event) => moment(day).isSame(event.start, "day"));
                currentWeek.push({
                    date: day,
                    event: eventData ?? [],
                });
            }
            calendarData.push(currentWeek);
        }
        setCalendar(calendarData);
    }, [date]);
    const getOnsiteEvents = (curEvents) => {
        const renderEvents = curEvents.slice(0, 3).map((event) => (_jsx(EventDetailPopover, { event: event, children: _jsxs(EventContainer, { isLeaveEvent: event.eventType === CollectorCalendarEventType.Leave, children: [_jsx(SideBar, { isLeaveEvent: event.eventType === CollectorCalendarEventType.Leave }), _jsxs(EventDetails, { children: [_jsx(AccountIcon, {}), _jsx(Typography.Title, { level: 5, children: event.title })] })] }) }, uniqueId())));
        const additionalEventsCount = curEvents.length - 3;
        if (additionalEventsCount > 0) {
            renderEvents.push(_jsxs(Typography.Text, { children: ["+ ", additionalEventsCount, " more"] }));
        }
        return renderEvents;
    };
    return !calendar ? null : (_jsxs("div", { className: "rbc-month-view", children: [_jsx("div", { className: "rbc-row rbc-month-header", children: moment.weekdays(true).map((day, index) => (_jsx("div", { className: "rbc-header", children: day }, index))) }), calendar.map((week, index) => (_jsx("div", { className: "rbc-month-row", children: _jsx(DayCellContainer, { children: week.map((day) => (_jsxs(DayCellWrapper, { onClick: (e) => {
                            if (props.onSelectEvent) {
                                props.onSelectEvent({ start: day.date }, e);
                            }
                        }, defaultValue: day.date.toISOString(), children: [_jsx(DateCell, { children: day.date.getDate().toString() }), _jsx(ListEventContainer, { children: getOnsiteEvents(day.event) })] }, day.date.toISOString()))) }) }, index)))] }));
};
const CollectorMonthCalendar = ({ events, currentDate, selectEvent, ...props }) => (_jsx(CustomCalendarWrapper, { children: _jsx(CustomCalendar, { view: CalendarView.Month, onView: () => void 0, onNavigate: () => void 0, events: events, localizer: localizer, date: currentDate, selectable: true, showAllEvents: true, onSelectEvent: (event) => {
            selectEvent(moment(event.start));
        }, timeslots: 12, step: 5, formats: {
            weekdayFormat: "dddd",
            eventTimeRangeFormat: () => "",
        }, components: {
            toolbar: () => _jsx(Toolbar, {}),
        }, views: {
            month: MonthCalendarView,
            week: true,
            day: true,
        }, ...props }) }));
export default CollectorMonthCalendar;
MonthCalendarView.navigate = (date, action) => {
    switch (action) {
        case "PREV":
            return moment(date).subtract(1, "month").toDate();
        case "NEXT":
            return moment(date).add(1, "month").toDate();
        default:
            return date;
    }
};
MonthCalendarView.title = (date, option) => option.localizer.format(date, "MMMM YYYY");
const CustomCalendarWrapper = styled.div `
  width: 100%;
  height: 100%;

  .rbc-month-header {
    width: 100%;
    min-width: fit-content;
  }
  .rbc-header {
    padding: 0.3rem;
    border: 0px;
    font-size: 0.875rem;
    font-weight: normal;
    border-bottom: 1px solid;
    min-width: 10rem;
    width: 100%;
  }

  .rbc-month-view {
    border: none;
    border-bottom: 1px solid;
    width: 100%;
    min-width: 80rem;
  }

  .rbc-month-row + .rbc-month-row {
    border-top: #adb3bc;
  }
  .rbc-month-row {
    min-height: 10rem;
    width: 100%;
    min-width: 80rem;
  }

  overflow: auto;
`;
const CustomCalendar = styled(Calendar) `
  height: 70vh;
  border-bottom: 0.063rem solid #bfbdcb;
  min-height: 50rem;
  width: 100%;
  min-width: fit-content;
`;
const Toolbar = styled.div `
  width: 100%;
`;
const DayCellContainer = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  background: #fff;
`;
const DayCellWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border: 0.025rem solid #ebedf0;
  gap: 0.5rem;
  padding: 0.1rem;
  background: #ffffff;
  cursor: pointer;
  height: 100%;
  min-width: 10rem;
`;
const ListEventContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: 0 0.2rem;
  width: 100%;
  height: 100%;
  gap: 0.2rem;
`;
const DateCell = styled.p `
  display: flex;
  flex-direction: column;
  padding-inline: 0.3rem;
  text-align: center;
  font-size: 1rem;
`;
const EventContainer = styled.div `
  display: flex;
  background-color: ${({ isLeaveEvent }) => (isLeaveEvent ? "#F2E5FF" : "#FAE3E2")};
  color: #000;
  border-radius: 0.25rem;
  height: fit-content;
  width: 100%;
  svg {
    color: ${({ isLeaveEvent }) => (isLeaveEvent ? "#EB41E4" : "#FD0000")};
  }
`;
const SideBar = styled.div `
  background-color: ${({ isLeaveEvent }) => (isLeaveEvent ? "#EB41E4" : "#FD0000")};
  color: white;
  padding: 0.1rem;
  text-align: center;
  font-weight: bold;
  word-wrap: break-word;
  white-space: normal;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`;
const EventDetails = styled.div `
  padding: 0.1rem;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  svg {
    margin-top: 0.1rem;
  }
  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;
