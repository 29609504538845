import { DefaultPagingMeta } from "src/infra/pagination";
var UploadFileType;
(function (UploadFileType) {
    UploadFileType[UploadFileType["Pdf"] = 1] = "Pdf";
    UploadFileType[UploadFileType["Image"] = 2] = "Image";
})(UploadFileType || (UploadFileType = {}));
var DeviceManagerView;
(function (DeviceManagerView) {
    DeviceManagerView["Create"] = "create";
    DeviceManagerView["Update"] = "update";
})(DeviceManagerView || (DeviceManagerView = {}));
var FileMenuOption;
(function (FileMenuOption) {
    FileMenuOption["Upload"] = "upload";
    FileMenuOption["View"] = "view";
    FileMenuOption["Delete"] = "delete";
})(FileMenuOption || (FileMenuOption = {}));
var DevicesViewOption;
(function (DevicesViewOption) {
    DevicesViewOption["List"] = "list";
    DevicesViewOption["Card"] = "card";
})(DevicesViewOption || (DevicesViewOption = {}));
const defaultUploadingFile = {
    url: null,
    key: null,
    fileType: null,
};
var InventoryItemStatus;
(function (InventoryItemStatus) {
    InventoryItemStatus[InventoryItemStatus["Active"] = 1] = "Active";
    InventoryItemStatus[InventoryItemStatus["Inactive"] = 2] = "Inactive";
})(InventoryItemStatus || (InventoryItemStatus = {}));
const InitialStockDataResponse = {
    inventoryItems: { data: [], pagingMeta: DefaultPagingMeta },
    inventoryOverview: {
        totalUnits: 0,
        usedUnits: 0,
        remainingUnits: 0,
    },
};
export { DeviceManagerView, DevicesViewOption, FileMenuOption, UploadFileType, defaultUploadingFile, InventoryItemStatus, InitialStockDataResponse, };
