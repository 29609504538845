import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from "antd";
import { useContext } from "react";
import styled from "styled-components";
import { Button, Image, Table, Text } from "src/components/base";
import ContentContext from "src/providers/content";
import { CreateAppointmentPage } from "src/pages/InClinicAppointmentsPage/CreateAppointmentPage";
import PopUpGroupAppointment from "src/pages/InClinicAppointmentsPage/CreateGroupAppointmentPage/popup";
import { PatientListWrapper } from "./index.style";
const { Option } = Select;
const Index = (props) => {
    const { patientListData } = props;
    const { displayColumns } = props;
    const { openPopupBox } = useContext(ContentContext);
    const columns = [
        {
            title: "No.",
            dataIndex: "index",
            key: "index",
            width: 58,
            className: "number-column",
        },
        {
            title: "Date",
            dataIndex: "startAt",
            key: "startAt",
            className: displayColumns.includes("startAt") ? "date-column" : "date-column-none",
            sorter: true,
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            className: displayColumns.includes("time") ? "time-column" : "time-column-none",
            sorter: true,
        },
        {
            title: "Donor/Client Name",
            dataIndex: "name",
            key: "name",
            className: displayColumns.includes("name") ? "last-name-column" : "last-name-column-none",
            sorter: true,
        },
        {
            title: "Collection Point",
            dataIndex: "collectionPointName",
            key: "collectionPointName",
            className: "collection-point-column",
            sorter: true,
        },
        {
            title: "Requested Test",
            dataIndex: "requestTest",
            key: "requestTest",
            width: 250,
            align: "center",
            render: (data) => (_jsx("div", { style: {
                    background: data.background,
                    border: `1px solid ${data.border ?? "white"}`,
                    borderRadius: "4px",
                    height: "29px",
                    width: "100%",
                    minWidth: "150px",
                    textAlign: "center",
                    overflow: "hidden",
                }, children: _jsx(Text, { name: data.text, style: {
                        color: data.text == "Cancelled" ? "white" : "black",
                    } }) })),
            className: displayColumns.includes("requestTest") ? "request-column" : "request-column-none",
            sorter: true,
        },
        {
            title: "View Booking",
            dataIndex: "view",
            align: "center",
            key: "view",
            render: (_, record) => (_jsx(Button, { style: {
                    padding: "0px",
                    border: "none",
                    background: "none",
                }, onClick: () => {
                    record.patientId
                        ? openPopupBox(_jsx(CreateAppointmentPage, { isPopup: true, appointmentId: record.appointmentId, submitCallback: () => {
                                props.reload(true);
                            } }))
                        : openPopupBox(_jsx(PopUpGroupAppointment, { appointmentId: record.appointmentId }), null, {
                            popupStyle: {
                                backgroundColor: "white",
                            },
                        });
                }, disabled: false, children: _jsx(Image, { src: "/assets/images/detail-patient-blue-icon.png", preview: false, width: 30, height: 30 }) })),
            className: "view-column",
        },
    ];
    return (_jsxs(PatientListWrapper, { isEmpty: patientListData?.length === 0, children: [_jsx(Table, { columns: columns, dataSource: patientListData, size: "middle" }), patientListData.length > 0 && (_jsxs(PageNumberSelectWrapper, { children: [_jsx(Text, { name: "Show", style: {
                            fontSize: "16px",
                            color: "#367893",
                            marginRight: "7px",
                        } }), _jsxs(Select
                    // value={pageSize}
                    // onSelect={select}
                    , { 
                        // value={pageSize}
                        // onSelect={select}
                        style: {
                            border: "1px solid #EBEDF0",
                            borderRadius: "2px",
                            width: "70px",
                            height: "28px",
                            fontSize: "16px",
                            color: "#367893",
                        }, suffixIcon: _jsx(Image, { src: "/assets/images/arrow-down-icon.png", preview: false }), children: [_jsx(Option, { value: 10, children: "10" }), _jsx(Option, { value: 15, children: "15" }), _jsx(Option, { value: 20, children: "20" })] })] }))] }));
};
export default Index;
const PageNumberSelectWrapper = styled.div `
  position: absolute;
  margin-top: -2.3rem;

  .ant-select-arrow {
    height: 15px !important;
    width: 15px !important;
  }
`;
