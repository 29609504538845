import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const MedicalTestOverviewBox = ({ subTitle, inClinic, onSite, }) => (_jsx(React.Fragment, { children: _jsxs(BillContainer, { children: [_jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(ColumnName, { children: _jsx(HeadingTitle, { children: "Type of Collection" }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(ColumnName, { children: _jsx(HeadingTitle, { children: subTitle }) }) })] }), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(UnderlineColumn, { children: _jsx(Text, { children: "In-Clinic Collection" }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(UnderlineColumn, { children: _jsx(Text, { children: inClinic }) }) })] }), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(UnderlineColumn, { children: _jsx(Text, { children: "On-Site Collection" }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(UnderlineColumn, { children: _jsx(Text, { children: onSite }) }) })] }), _jsxs(SARow, { gutter: [0, 16], children: [_jsx(SAColumn, { span: 12, children: _jsx(ColumnName, { children: _jsx(BoldHeadingTitle, { children: "Total" }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(ColumnName, { children: _jsx(ValueBox, { children: _jsx(BoldHeadingTitle, { children: inClinic + onSite }) }) }) })] })] }) }));
export default MedicalTestOverviewBox;
const BillContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  height: 100%;
`;
const ColumnName = styled.div `
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0.5rem;
`;
const UnderlineColumn = styled(ColumnName) `
  border-bottom: 0.025rem solid #edeef0;
`;
const HeadingTitle = styled.p `
  font-size: 0.925rem;
  color: #7f8e9d !important;
  font-weight: 500;
`;
const Text = styled.p `
  font-size: 0.875rem;
`;
const ValueBox = styled.div `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 0;
  text-align: center;
  padding: 0.7rem;
  background-color: #ccf0f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BoldHeadingTitle = styled(HeadingTitle) `
  font-weight: 700;
  color: #000000 !important;
`;
