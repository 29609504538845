var ResourceLanguage;
(function (ResourceLanguage) {
    ResourceLanguage[ResourceLanguage["English"] = 0] = "English";
    ResourceLanguage[ResourceLanguage["MandarinCantonese"] = 1] = "MandarinCantonese";
    ResourceLanguage[ResourceLanguage["Arabic"] = 2] = "Arabic";
    ResourceLanguage[ResourceLanguage["Vietnamese"] = 3] = "Vietnamese";
    ResourceLanguage[ResourceLanguage["Punjabi"] = 4] = "Punjabi";
    ResourceLanguage[ResourceLanguage["Greek"] = 5] = "Greek";
    ResourceLanguage[ResourceLanguage["Italian"] = 6] = "Italian";
    ResourceLanguage[ResourceLanguage["Indonesia"] = 7] = "Indonesia";
    ResourceLanguage[ResourceLanguage["Philipino"] = 8] = "Philipino";
    ResourceLanguage[ResourceLanguage["Hindi"] = 9] = "Hindi";
    ResourceLanguage[ResourceLanguage["Spanish"] = 10] = "Spanish";
    ResourceLanguage[ResourceLanguage["Malay"] = 11] = "Malay";
    ResourceLanguage[ResourceLanguage["Thai"] = 12] = "Thai";
})(ResourceLanguage || (ResourceLanguage = {}));
const LanguageOptions = {
    [ResourceLanguage.English]: {
        title: "English",
        content: "Download the Work Assist App",
        link: "/assets/pdf/DownLoadWorkAssist-English.pdf",
        fileName: "DownloadWorkAssist-English.pdf",
    },
    [ResourceLanguage.MandarinCantonese]: {
        title: "Mandarin / Cantonese",
        content: "下载 “Work Assist” 移动应用程序。",
        link: "/assets/pdf/DownLoadWorkAssist-Chinese.pdf",
        fileName: "DownloadWorkAssist-Chinese.pdf",
    },
    [ResourceLanguage.Arabic]: {
        title: "Arabic",
        content: "المحمول ”Work Assist“. قم بتنزيل تطبيق الهاتف",
        link: "/assets/pdf/DownLoadWorkAssist-Arabic.pdf",
        fileName: "DownloadWorkAssist-Arabic.pdf",
    },
    [ResourceLanguage.Vietnamese]: {
        title: "Vietnamese",
        content: "Tải xuống ứng dụng di động “Work Assist”.",
        link: "/assets/pdf/DownLoadWorkAssist-Vietnamese.pdf",
        fileName: "DownloadWorkAssist-Vietnamese.pdf",
    },
    [ResourceLanguage.Punjabi]: {
        title: "Punjabi",
        content: "“Work Assist” ਨੂੰ ਡਾਾਊ ਨਲੋੋਡ ਕਰੋੋ ਕੰੰਮ ਅਸਿ ਸਟ ਐਪ",
        link: "/assets/pdf/DownLoadWorkAssist-Punjabi.pdf",
        fileName: "DownloadWorkAssist-Punjabi.pdf",
    },
    [ResourceLanguage.Greek]: {
        title: "Greek",
        content: "Κατεβάστε την εφαρμογή “Work Assist” για κινητά.",
        link: "/assets/pdf/DownLoadWorkAssist-Greek.pdf",
        fileName: "DownloadWorkAssist-Greek.pdf",
    },
    [ResourceLanguage.Italian]: {
        title: "Italian",
        content: "Scarica l’applicazione mobile “Work Assist”.",
        link: "/assets/pdf/DownLoadWorkAssist-Italian.pdf",
        fileName: "DownloadWorkAssist-Italian.pdf",
    },
    [ResourceLanguage.Indonesia]: {
        title: "Indonesia",
        content: "Unduh aplikasi seluler “Work Assist”.",
        link: "/assets/pdf/DownLoadWorkAssist-Indonesia.pdf",
        fileName: "DownloadWorkAssist-Indonesia.pdf",
    },
    [ResourceLanguage.Philipino]: {
        title: "Philipino",
        content: "I-download ang “Work Assist” na mobile application.",
        link: "/assets/pdf/DownLoadWorkAssist-Philipino.pdf",
        fileName: "DownloadWorkAssist-Philipino.pdf",
    },
    [ResourceLanguage.Hindi]: {
        title: "Hindi",
        content: "“Work Assist” मोबाइल एप्लिकेशन डाउनलोड करें।",
        link: "/assets/pdf/DownLoadWorkAssist-Hindi.pdf",
        fileName: "DownloadWorkAssist-Hindi.pdf",
    },
    [ResourceLanguage.Spanish]: {
        title: "Spanish",
        content: "Descarga la aplicación móvil “Work Assist”.",
        link: "/assets/pdf/DownLoadWorkAssist-Spanish.pdf",
        fileName: "DownloadWorkAssist-Spanish.pdf",
    },
    [ResourceLanguage.Malay]: {
        title: "Malay",
        content: "Muat turun aplikasi mudah alih “Work Assist”.",
        link: "/assets/pdf/DownLoadWorkAssist-Malay.pdf",
        fileName: "DownloadWorkAssist-Malay.pdf",
    },
    [ResourceLanguage.Thai]: {
        title: "Thai",
        content: "ดาวน์โหลดแอปพลิเคชั่นมือถือ “Work Assist”",
        link: "/assets/pdf/DownLoadWorkAssist-Thai.pdf",
        fileName: "DownloadWorkAssist-Thai.pdf",
    },
};
export { LanguageOptions, ResourceLanguage };
