import { TestSummaryIndex } from "../store/types";
const TestTypeInventory = {
    [TestSummaryIndex.UrineScreen]: {
        title: "Urine (Screen)",
        subtitle: "Drug Screen Devices",
        name: "Urine Drug Screen Devices",
        bgColor: "#e6f7ff",
        testIndex: TestSummaryIndex.UrineScreen,
    },
    [TestSummaryIndex.OralScreen]: {
        title: "Oral (Screen)",
        subtitle: "Drug Screen Devices",
        name: "Oral Fluid Drug Screen Devices",
        bgColor: "#e6f7ff",
        testIndex: TestSummaryIndex.OralScreen,
    },
    [TestSummaryIndex.UrineCup]: {
        title: "Urine (Cup)",
        subtitle: "Drug Screen Devices",
        name: "Urine Collection Cups",
        bgColor: "#F7F8F8",
        testIndex: TestSummaryIndex.UrineCup,
    },
    [TestSummaryIndex.UrineVial]: {
        title: "Lab (Urine)",
        subtitle: "Sample Vial Kits",
        name: "Urine Sample Vial Kits (Lab)",
        bgColor: "#E3FFED",
        testIndex: TestSummaryIndex.UrineVial,
    },
    [TestSummaryIndex.OralDrug]: {
        title: "Lab (Oral)",
        subtitle: "Collection Devices",
        name: "Oral Fluid Collection Devices (Lab)",
        bgColor: "#E3FFED",
        testIndex: TestSummaryIndex.OralDrug,
    },
    [TestSummaryIndex.HairTest]: {
        title: "Lab (Hair)",
        subtitle: "Collection Kits",
        name: "Hair Collection Kits (Lab)",
        bgColor: "#E3FFED",
        testIndex: TestSummaryIndex.HairTest,
    },
};
export { TestTypeInventory };
