import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import MainLayout from "src/components/layouts/MainLayout";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import { PageLayoutWithTabAndBack } from "src/components/layouts/PageLayout/PageLayoutWithTabAndBack";
import InventorySections from "./InventorySections";
import { TransportSatchels } from "./TransportSatchels";
import { SatchelForm } from "./TransportSatchels/NewSatchel/SatchelForm";
const InventoryManagement = () => {
    const location = useLocation();
    return (_jsx(MainLayout, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(Navigate, { to: "testing-device", replace: true }) }), _jsx(Route, { path: "testing-device", element: _jsx(InventorySections, {}) }), _jsx(Route, { path: "transport-satchel", element: _jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, tabLevel: 2, children: _jsx(TransportSatchels, {}) }) }), _jsx(Route, { path: "add", element: _jsx(PageLayoutWithTabAndBack, { heading: "Transport Satchel", children: _jsx(SatchelForm, { record: location.state }) }) })] }) }));
};
export default InventoryManagement;
export const breadcrumbs = [
    {
        path: "/inventory",
        name: "Inventory Management",
        level: 1,
    },
    {
        path: "/testing-device",
        name: "Testing Devices Inventory",
        level: 2,
    },
    {
        path: "/transport-satchel",
        name: "Transport Satchels Inventory",
        level: 2,
    },
];
