import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleFilled, SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Space, Table, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import RequestedLabTestIcon from "src/assets/svg/welcome-assist/requested-lab-test-icon.svg";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { PageLayoutWithExtraTab } from "src/components/layouts/PageLayout";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import TableSizeChanger from "src/components/TableSizeChanger";
import { DefaultPagingMeta, DefaultRequestPagination, } from "src/infra/pagination";
import { getDisabledDate } from "src/infra/time";
import { selectCollectionOrganization, selectDateWithSetupTime, selectIsLoading, } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { routes } from "../..";
import { getEntryManagementRegisterData } from "../../KioskManagement/store/action";
import { CheckOutTypeStatusMapping, CustomerTypeMapping, KioskEntryStatusMapping, } from "./typing";
const EntryExitRegisterEntryPage = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const collectionOrg = useAppSelector(selectCollectionOrganization);
    const [pagination, setPagination] = React.useState(DefaultRequestPagination);
    const [selectedStartAt, setSelectedStartAt] = React.useState(moment().startOf("year"));
    const [selectedEndAt, setSelectedEndAt] = React.useState(moment().endOf("year"));
    const [keyword, setKeyword] = React.useState("");
    const [testData, setTestData] = React.useState({
        data: [],
        pagingMeta: DefaultPagingMeta,
    });
    React.useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            const payload = await dispatch(getEntryManagementRegisterData({
                startAt: selectedStartAt.toISOString(),
                endAt: selectedEndAt?.toISOString() || undefined,
                keyword: keyword || undefined,
                ...pagination,
            })).unwrap();
            setTestData(payload.data || []);
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [pagination, selectedStartAt, selectedEndAt, keyword]);
    return (_jsx(PageLayoutWithExtraTab, { routes: routes, tabLevel: 3, children: _jsxs(EntryExitRegisterContainer, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, justify: "center", align: "start", children: _jsxs(Space, { children: [_jsx(ButtonIcon, { children: _jsx(RequestedLabTestIcon, {}) }), _jsx(Typography.Title, { level: 5, children: "Requested Lab Tests" })] }) }), _jsx(SAColumn, { span: 12, justify: "center", children: _jsxs(Space, { children: [_jsx(Typography.Text, { children: "From" }), _jsx(CustomDatePicker, { format: "dd, DD MMM YYYY", value: selectedStartAt, onChange: (date) => {
                                            if (!date)
                                                return;
                                            setSelectedStartAt(date);
                                        }, disabledDate: getDisabledDate(selectedEndAt || undefined, true) }), _jsx(Typography.Text, { children: "To" }), _jsx(CustomDatePicker, { format: "dd, DD MMM YYYY", value: selectedEndAt, onChange: (date) => {
                                            if (!date)
                                                return;
                                            setSelectedEndAt(date);
                                        }, disabledDate: getDisabledDate(selectedStartAt || undefined, false) })] }) }), _jsx(SAColumn, { span: 6, align: "end", justify: "center", children: _jsx(Input, { prefix: _jsx(SearchOutlined, {}), placeholder: "Search", value: keyword, onChange: (e) => setKeyword(e.target.value) }) }), _jsx(SAColumn, { span: 24, children: _jsx(ChartCard, { children: _jsxs(TableContainer, { children: [_jsxs(Table, { dataSource: testData.data, rowKey: "id", pagination: {
                                                current: testData.pagingMeta.page,
                                                pageSize: testData.pagingMeta.limit,
                                                total: testData.pagingMeta.totalItem,
                                                showTotal: () => (_jsxs(Typography.Text, { style: { marginRight: "1rem" }, children: ["Total: ", _jsx(TotalBox, { children: testData.pagingMeta.totalItem })] })),
                                                onChange: (page, limit) => {
                                                    setPagination({ ...pagination, page, limit });
                                                },
                                            }, children: [_jsx(Table.Column, { title: "First Name", dataIndex: "firstName" }), _jsx(Table.Column, { title: "Last Name", dataIndex: "lastName" }), _jsx(Table.Column, { title: "Mobile", dataIndex: "mobile", align: "center" }), _jsx(Table.Column, { title: "Gender", dataIndex: "gender" }), _jsx(Table.Column, { title: "Check-in", dataIndex: "checkInAt", align: "center", render: (checkInAt) => selectDateWithSetupTime(checkInAt, collectionOrg.timeFormat) }), _jsx(Table.Column, { title: "Check-out", dataIndex: "checkOutAt", align: "center", render: (checkOutAt) => selectDateWithSetupTime(checkOutAt, collectionOrg.timeFormat) }), _jsx(Table.Column, { title: "Duration", render: (_, record) => `${moment(record.checkOutAt).diff(moment(record.checkInAt), "minutes") || 0} min(s)` }), _jsx(Table.Column, { title: "Status", dataIndex: "status", align: "center", render: (status) => (_jsx(Button, { type: "link", icon: _jsx(CheckCircleFilled, { style: { color: KioskEntryStatusMapping[status].color } }), children: _jsx(Typography.Text, { children: KioskEntryStatusMapping[status].label }) })) }), _jsx(Table.Column, { title: "Type", dataIndex: "checkOutType", render: (checkOutType) => CheckOutTypeStatusMapping[checkOutType] }), _jsx(Table.Column, { title: "Visitor Type", dataIndex: "customerType", render: (customerType) => CustomerTypeMapping[customerType] })] }), _jsx(TableFooterContainer, { "$hidden": !testData.data.length, children: _jsx(TableSizeChanger, { value: testData.pagingMeta.limit || 0, onChange: (limit) => setPagination((prevFilters) => ({
                                                    ...prevFilters,
                                                    limit,
                                                })) }) })] }) }) })] })] }) }));
};
export default EntryExitRegisterEntryPage;
const EntryExitRegisterContainer = styled.div `
  width: 100%;
`;
const ButtonIcon = styled.div `
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CustomDatePicker = styled(DatePicker) `
  width: 100%;
  border-radius: 0.25rem;
  input {
    text-align: center;
  }
`;
const ChartCard = styled.div `
  width: 100%;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 1rem;
  min-height: 50vh;
  height: 100%;
`;
const TableContainer = styled.div `
  width: 100%;
  position: relative;
  height: fit-content;

  .ant-table-thead > tr > th {
    background-color: #ffffff;
    font-weight: 700;
    border-bottom: 0.02rem solid #000000;
  }

  .ant-pagination {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-pagination > li:first-child {
    order: 99;
    margin-left: 1rem;
  }
`;
const TableFooterContainer = styled.div `
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-width: 5rem;
  bottom: 0.5rem;
  white-space: nowrap;
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
`;
const TotalBox = styled.span `
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  border: 0.025rem solid #ccc;
`;
