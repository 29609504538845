import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Form, message, Space, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { Radio } from "src/components/base";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import SASwitch from "src/components/Form/Table/SASwitch";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { SAColourScheme } from "src/styles";
import { BillingCycleType } from "../../type";
import { updateBillingCycleForITServiceInvoice } from "../store/action";
import { selectIsIncludedGst, selectITServiceInvoice } from "../store/selector";
import { setIsIncludedGst } from "../store/slice";
import { ITServiceError } from "../store/types";
const ITServiceBillingCycleHeader = () => {
    const [form] = Form.useForm();
    const invoice = useAppSelector(selectITServiceInvoice);
    const dispatch = useAppDispatch();
    const isIncludedGst = useAppSelector(selectIsIncludedGst);
    const { billingCycleType } = invoice;
    const [checkedCycle, setCheckedCycle] = useState(billingCycleType);
    React.useEffect(() => {
        if (!billingCycleType)
            return;
        setCheckedCycle(billingCycleType);
    }, [billingCycleType]);
    return (_jsx(ITServiceBillingCycleHeaderContainer, { children: _jsx(CustomForm, { form: form, labelCol: { span: 8 }, wrapperCol: { span: 16 }, colon: false, children: _jsxs(SARow, { children: [_jsxs(SAColumn, { md: 24, lg: 8, children: [_jsx(SAFormItem, { label: "Current Date", children: _jsx(Typography.Title, { level: 5, children: invoice.currentDate }) }), _jsx(SAFormItem, { label: "Billing Cycle", children: _jsxs(GroupButton, { value: checkedCycle, onChange: (e) => {
                                        setCheckedCycle(e.target.value);
                                    }, children: [_jsx(CustomRadio, { value: BillingCycleType.Monthly, checked: checkedCycle === BillingCycleType.Monthly, children: "Monthly" }), _jsx(CustomRadio, { value: BillingCycleType.Fortnightly, checked: checkedCycle === BillingCycleType.Fortnightly, children: "Fortnightly" })] }) }), _jsx(SAFormItem, { label: " ", children: billingCycleType !== checkedCycle && (_jsxs(Space, { children: [_jsx(Button, { type: "primary", onClick: async () => {
                                                const updateResult = await dispatch(updateBillingCycleForITServiceInvoice(checkedCycle)).unwrap();
                                                const { error } = updateResult;
                                                if (error) {
                                                    message.error(error === ITServiceError.EFB001
                                                        ? updateResult.message
                                                        : "There was an error updating the billing cycle");
                                                    return;
                                                }
                                                setCheckedCycle(billingCycleType);
                                                if (updateResult.message) {
                                                    message.success(updateResult.message);
                                                    return;
                                                }
                                                message.success("Updated billing cycle successfully");
                                            }, children: "Update" }), _jsx(Button, { type: "primary", ghost: true, onClick: () => setCheckedCycle(billingCycleType), children: "Cancel" })] })) })] }), _jsx(SAColumn, { md: 24, lg: 16, align: "start", children: _jsxs(NextInvoiceContainer, { children: [_jsxs(Space, { direction: "vertical", children: [_jsx(Typography.Text, { children: "Next Invoice Issue Date" }), _jsx(DueDate, { children: _jsx(Typography.Title, { level: 5, children: invoice.nextInvoiceIssueDate }) })] }), _jsx(SAFormItem, { label: "Billing Period", children: _jsxs(Space, { children: [_jsx(Typography.Text, { children: invoice.startAt }), " -", _jsx(Typography.Text, { children: invoice.endAt })] }) }), _jsx(SAFormItem, { label: " ", children: _jsxs(Space, { children: [_jsx(Typography.Text, { children: "GST" }), _jsx(SASwitch, { uncheckedLabel: "exclusive", checkedLabel: "inclusive", checked: isIncludedGst, onChange: () => dispatch(setIsIncludedGst(!isIncludedGst)) })] }) })] }) })] }) }) }));
};
export default ITServiceBillingCycleHeader;
const ITServiceBillingCycleHeaderContainer = styled.div `
  background-color: #f7f8f8;
  margin-top: -2rem;
  margin-inline: -2rem;
  padding: 1rem 3rem;
  padding-top: 2rem;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  border-bottom: 0.025rem solid #01b4d2;
`;
const CustomForm = styled(Form) `
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
  text-align: left;

  .ant-form-item-label {
    text-align: left;
  }
`;
const GroupButton = styled(Radio.Group) `
  display: flex;
  width: 100%;
  height: fit-content;
`;
const DueDate = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  background-color: #ffffff;
  border: 1px solid #edeef0;
  border-radius: 0.25rem;
`;
const CustomRadio = styled(Radio) `
  padding: 0.5rem 1rem;
  background: ${SAColourScheme.WHITE};
  border-radius: 5px;
  border: ${(props) => (props.checked ? "0.025rem solid #1a8cff" : "0.025rem solid #cbf5fc")};
  display: flex;
  .ant-radio-checked .ant-radio-inner {
    border: 5px solid #1a8cff;
  }
  .ant-radio-inner {
    border: 5px solid #cbf5fc;
  }
  .ant-radio-inner::after {
    display: none;
  }
`;
const NextInvoiceContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
  gap: 0.5rem;
  width: 50%;
  margin-left: auto;
  @media screen and (max-width: 1024px) {
    margin-left: 0;
    align-items: flex-start;
  }
  span {
    white-space: nowrap;
  }
`;
