import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SADivider from "src/components/Form/SADivider";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setIsLoading } from "src/store/app/slice";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout/PageLayoutWithBreadcrumb";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { selectIsLoading } from "src/store/app/selector";
import { breadcrumbs } from "..";
import CostOverviewTable from "./CostOverviewTable";
import FilterToolBar from "./FilterToolBar";
import CostOverviewCards from "./CostOverviewCards";
import DetailedCostBreakdown from "./DetailedCostBreakdown";
import { getListClients, getOverallCostSummary } from "./store/action";
import ExportOptionsBar from "./ExportOptionsBar";
import { setResetStore } from "./store/slice";
const AccountingDashboard = () => {
    const destinationRefs = useRef([]);
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const handleScrollToBox = (index) => {
        const element = destinationRefs.current[index];
        if (element) {
            const elementTop = element.getBoundingClientRect().top + window.scrollY;
            const offset = 100;
            window.scrollTo({
                top: elementTop - offset,
                behavior: "smooth",
            });
        }
    };
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            dispatch(setResetStore());
            await dispatch(getListClients());
            await dispatch(getOverallCostSummary());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, []);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, children: _jsxs(DashboardContainer, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(FilterToolBar, {}), _jsx(ExportOptionsBar, {}), _jsx(CostOverviewTable, {}), _jsx(CostOverviewCards, { handleScrollToBox: handleScrollToBox }), _jsx(SummaryDivider, {}), _jsx(DetailedCostBreakdown, { destinationRefs: destinationRefs })] }) }));
};
export default AccountingDashboard;
const DashboardContainer = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
const SummaryDivider = styled(SADivider) `
  height: 0.5rem;
  margin-block: 3rem;
  background-color: #01b4d2;
`;
