import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Bar, ComposedChart, LabelList, Legend, Line, ResponsiveContainer, XAxis, YAxis, } from "recharts";
import styled from "styled-components";
import { COLOR_SCHEMES } from "../../typing";
const LegendLabels = {
    bas: "Breath Alcohol Screen",
    uds: "Urine Drug Screen",
    ods: "Oral Fluid Drug Screen",
    udt: "Urine Drug Test",
    odt: "Oral Fluid Collection",
    hdt: "Hair Drug Test",
    total: "Total",
};
const CustomLegend = ({ payload }) => (_jsx(LegendContainer, { children: payload?.map((entry, index) => (_jsxs(LegendItem, { children: [_jsx(LegendMarker, { color: entry.color, isSquare: !!index }), _jsx(LegendText, { children: LegendLabels[entry.value] })] }, index))) }));
const renderNonZeroLabel = (props) => {
    const { value, x, y } = props;
    return Number(value) > 0 ? (_jsx("text", { x: x, y: y, dy: -6, dx: 6, fill: "#000", fontSize: 12, textAnchor: "middle", children: value })) : null;
};
export const TestComposedChart = ({ data, colors }) => {
    const currentColors = colors || COLOR_SCHEMES[0]?.colors || [];
    const barKeys = Object.keys(LegendLabels).filter((key) => key !== "total");
    return (_jsx(ResponsiveContainer, { width: "100%", height: "100%", children: _jsxs(ComposedChart, { data: data, margin: { top: 30, right: -40, left: -40, bottom: 0 }, children: [_jsx(XAxis, { dataKey: "label", xAxisId: "top", orientation: "top", tick: false }), _jsx(XAxis, { dataKey: "label", xAxisId: "bottom" }), _jsx(YAxis, { yAxisId: "left", domain: [0, (dataMax) => dataMax + 10], tick: false }), _jsx(YAxis, { yAxisId: "right", orientation: "right", domain: [0, (dataMax) => dataMax + 5], tick: false }), _jsx(Legend, { content: _jsx(CustomLegend, {}), layout: "horizontal", verticalAlign: "top", align: "center" }), _jsx(Line, { type: "monotone", dataKey: "total", stroke: "#001E64", yAxisId: "right", xAxisId: "top", dot: { fill: "#001E64", strokeWidth: 2 }, children: _jsx(LabelList, { dataKey: "total", position: "top" }) }), barKeys.map((key, index) => (_jsx(Bar, { dataKey: key, barSize: 30, fill: currentColors[index], yAxisId: "left", xAxisId: "bottom", children: _jsx(LabelList, { dataKey: key, position: "top", content: (props) => renderNonZeroLabel(props) }) }, key)))] }) }));
};
const LegendContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-left: 3.5rem;
  flex-wrap: wrap;
`;
const LegendItem = styled.div `
  display: flex;
  align-items: center;
  gap: 5px;
`;
const LegendMarker = styled.div `
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: ${({ isSquare }) => (isSquare ? "0px" : "50%")};
`;
const LegendText = styled.span `
  font-size: 14px;
`;
