import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Route, Routes } from "react-router-dom";
import EntryManagementPage from "./EntryManagement";
import WelcomeAssistHomePage from "./HomePage";
import KioskDeviceManagement from "./KioskDeviceManagement";
import KioskManagementPage from "./KioskManagement";
import KioskMessagesPage from "./KioskMessages";
import ResourcesPage from "./Resources";
const WelcomeAssistPage = () => (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(WelcomeAssistHomePage, {}) }), _jsx(Route, { path: "/kiosk-management/*", element: _jsx(KioskManagementPage, {}) }), _jsx(Route, { path: "/kiosk-messages/*", element: _jsx(KioskMessagesPage, {}) }), _jsx(Route, { path: "/resources/*", element: _jsx(ResourcesPage, {}) }), _jsx(Route, { path: "/entry-management/*", element: _jsx(EntryManagementPage, {}) }), _jsx(Route, { path: "/kiosk-device-management/*", element: _jsx(KioskDeviceManagement, {}) })] }));
export default WelcomeAssistPage;
export const routes = [
    {
        path: "/platform-management",
        name: "Platform Management",
        level: 1,
    },
    {
        path: "/welcome-assist",
        name: "Welcome Assist",
        level: 2,
    },
    {
        path: "/kiosk-management",
        name: "Kiosk Management",
        level: 3,
    },
    {
        path: "/kiosk-messages",
        name: "Kiosk Messages",
        level: 3,
    },
    {
        path: "/resources",
        name: "Resources",
        level: 3,
    },
    {
        path: "/entry-management",
        name: "Entry Management",
        level: 3,
        subRoutes: [
            { path: "/dashboard", name: "Dashboard", level: 3 },
            { path: "/register", name: "Entry-Exit Register", level: 3 },
            { path: "/settings", name: "Entry Management Settings", level: 3 },
        ],
    },
    {
        path: "/kiosk-device-management",
        name: "Kiosk Device Management",
        level: 3,
    },
];
