import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { omit } from "lodash";
import { $get, $post } from "src/infra/http";
const getTestingDevices = createAsyncThunk("getBreathalyserTestingDevices", async ({ testTypeId, isArchived = false }, thunkApi) => {
    const state = thunkApi.getState().deviceManagementPage;
    const collectionPointId = state.breathalyserPage.selectedCollectionPointId;
    const queryString = stringify({
        testTypeId,
        isArchived,
        collectionPointId,
    });
    return await $get(`/collection-manager/testing-devices?${queryString}`);
});
const getTestingDeviceById = createAsyncThunk("getBreathalyserTestingDeviceById", async (testingDeviceId) => await $get(`/collection-manager/testing-devices/view/${testingDeviceId}`));
const createBreathalyserTestingDevice = createAsyncThunk("createBreathalyserTestingDevice", async (newData) => await $post(`/collection-manager/testing-devices/create`, newData));
const updateBreathalyserTestingDevice = createAsyncThunk("updateBreathalyserTestingDevice", async (newData) => await $post(`/collection-manager/testing-devices/update/${newData.id}`, omit(newData, "id")));
export { getTestingDevices, createBreathalyserTestingDevice, getTestingDeviceById, updateBreathalyserTestingDevice, };
