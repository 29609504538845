import { $get, $post } from "src/infra/http";
const updateOrganizationDetails = async (organizationDetail) => await $post(`/collection-manager/collection-organization/update`, {
    organizationDetail,
    newCollectionPoints: [],
    oldCollectionPoints: [],
});
const getCollectionOrganizationDetails = async () => {
    const response = await $get(`/collection-manager/collection-organization/detail`);
    return response.data;
};
export { updateOrganizationDetails, getCollectionOrganizationDetails };
