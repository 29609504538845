import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { stringify } from "qs";
import { $get } from "src/infra/http";
import { Domain } from "src/types";
import { DrugClassResultType } from "./types";
const getCurrentState = (state) => state.getState().dashboardPage.collectionDashboardPage.onsiteCollectionDashboard;
const getOnsiteDailySummary = createAsyncThunk("dashboard/getOnsiteDailySummary", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const { selectedDate, selectedCollectionPointId } = state.dailySummary.filters;
    let startDate = null;
    let endDate = null;
    if (selectedDate) {
        const givenMomentUTC = moment.utc(selectedDate);
        startDate = givenMomentUTC.clone().startOf("day").toISOString();
        endDate = givenMomentUTC.clone().endOf("day").toISOString();
    }
    const queryString = stringify({
        collectionPointId: selectedCollectionPointId,
        startDate,
        endDate,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/daily-summary?${queryString}`, Domain.CM);
});
const getProcessedTestByPeriod = createAsyncThunk("dashboard/getProcessedTestByPeriod", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const { period, noOfRecords, selectedCollectionPointId } = state.processedTest.filters;
    const queryString = stringify({
        collectionPointId: selectedCollectionPointId,
        period: period ?? 1,
        noOfRecords,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/processed-test?${queryString}`, Domain.CM);
});
const getTestSampleResultsEachType = createAsyncThunk("dashboard/getTestSampleResultsEachType", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const { startDate, endDate, selectedCollectionPointId } = state.sampleResult.filters;
    const queryString = stringify({
        collectionPointId: selectedCollectionPointId,
        startDate,
        endDate,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/sample-result?${queryString}`, Domain.CM);
});
const getProcessedTestDetails = createAsyncThunk("dashboard/getProcessedTestDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.processedTest.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/processed-tests?${queryString}`, Domain.CM);
});
const getPatientTimeDetails = createAsyncThunk("dashboard/getPatientTimeDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.patientTime.filters, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/patient-time?${queryString}`, Domain.CM);
});
const getTestTimeDetails = createAsyncThunk("dashboard/getTestTimeDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.testTime.filters, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/test-time?${queryString}`, Domain.CM);
});
const getTestCategoryDetails = createAsyncThunk("dashboard/getTestCategoryDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.testCategory.filters, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/test-category?${queryString}`, Domain.CM);
});
const getUrineDrugScreenResultDetails = createAsyncThunk("dashboard/getUrineDrugScreenResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.urineDrugScreen.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/urine-screen?${queryString}`, Domain.CM);
});
const getUrineDrugTestResultDetails = createAsyncThunk("dashboard/getUrineDrugTestResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.urineDrugTest.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/urine-test?${queryString}`, Domain.CM);
});
const getOralDrugScreenResultDetails = createAsyncThunk("dashboard/getOralDrugScreenResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.oralDrugScreen.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/oral-screen?${queryString}`, Domain.CM);
});
const getOralDrugTestResultDetails = createAsyncThunk("dashboard/getOralDrugTestResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.oralDrugTest.filters, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/oral-test?${queryString}`, Domain.CM);
});
const getHairDrugTestResultDetails = createAsyncThunk("dashboard/getHairDrugTestResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.hairDrugTest.filters, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/hair-test?${queryString}`, Domain.CM);
});
const getBreathAlcoholScreenResultDetails = createAsyncThunk("dashboard/getBreathAlcoholScreenResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.breathAlcoholScreen.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/breath-alcohol?${queryString}`, Domain.CM);
});
const getNegativeDrugClassResultDetails = createAsyncThunk("dashboard/getNegativeDrugClassResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify({
        ...state.collectionTables.negativeDrugClass.filters,
        resultType: DrugClassResultType.Negative,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/drug-class?${queryString}`, Domain.CM);
});
const getNonNegativeDrugClassResultDetails = createAsyncThunk("dashboard/getNonNegativeDrugClassResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify({
        ...state.collectionTables.nonNegativeDrugClass.filters,
        resultType: DrugClassResultType.NonNegative,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/drug-class?${queryString}`, Domain.CM);
});
const getInvalidDrugClassResultDetails = createAsyncThunk("dashboard/getInvalidDrugClassResultDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify({
        ...state.collectionTables.invalidDrugClass.filters,
        resultType: DrugClassResultType.Invalid,
    }, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/drug-class?${queryString}`, Domain.CM);
});
const getCollectorPerformanceDetails = createAsyncThunk("dashboard/getCollectorPerformanceDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.collectorPerformance.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/collector-performance?${queryString}`, Domain.CM);
});
const getCollectorRankingDetails = createAsyncThunk("dashboard/getCollectorRankingDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.collectorRanking.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/collector-ranking?${queryString}`, Domain.CM);
});
const getPatientGenderDetails = createAsyncThunk("dashboard/getPatientGenderDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.patientGender.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/patient-genders?${queryString}`, Domain.CM);
});
const getAttendanceDetails = createAsyncThunk("dashboard/getAttendanceDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.attendance.filters, { skipNulls: true });
    return await $get(`/dashboard/collection/onsite/details/attendance?${queryString}`, Domain.CM);
});
const getDonorCommunicationDetails = createAsyncThunk("dashboard/getDonorCommunicationDetails", async (_, thunkApi) => {
    const state = getCurrentState(thunkApi);
    const queryString = stringify(state.collectionTables.donorCommunication.filters, {
        skipNulls: true,
    });
    return await $get(`/dashboard/collection/onsite/details/donor-communication?${queryString}`, Domain.CM);
});
const getListCollectorsByCollectionPoint = createAsyncThunk("dashboard/getListCollectorsByCollectionPoint", async () => await $get(`/dashboard/collection/onsite/collectors`, Domain.CM));
export { getAttendanceDetails, getBreathAlcoholScreenResultDetails, getCollectorPerformanceDetails, getCollectorRankingDetails, getDonorCommunicationDetails, getHairDrugTestResultDetails, getInvalidDrugClassResultDetails, getNegativeDrugClassResultDetails, getNonNegativeDrugClassResultDetails, getOnsiteDailySummary, getOralDrugScreenResultDetails, getOralDrugTestResultDetails, getPatientGenderDetails, getPatientTimeDetails, getProcessedTestByPeriod, getProcessedTestDetails, getTestCategoryDetails, getTestSampleResultsEachType, getTestTimeDetails, getUrineDrugScreenResultDetails, getUrineDrugTestResultDetails, getListCollectorsByCollectionPoint, };
