import { jsx as _jsx } from "react/jsx-runtime";
import { Space } from "antd";
import * as React from "react";
import styled from "styled-components";
import SATable from "src/components/SATable";
import { getApplicationInformation } from "src/store/app/selector";
import { useAppSelector } from "src/store/hooks";
import { getDateByFormat } from "src/utils";
const TestDetailTable = ({ dataSource }) => {
    const appInformation = useAppSelector(getApplicationInformation);
    const groupedDataSource = dataSource.map((item, index, array) => {
        if (index === 0 || item.jobReference !== array[index - 1].jobReference || !item.jobReference) {
            return item;
        }
        return {
            ...item,
            date: "",
            jobReference: "",
            client: "",
        };
    });
    const allColumns = [
        {
            title: "Date",
            dataIndex: "startAt",
            key: "startAt",
            sorter: true,
            render: (startAt) => (_jsx(Space, { children: startAt && getDateByFormat(startAt, appInformation?.collectionOrganization.timeFormat) })),
        },
        {
            title: "Job Reference",
            dataIndex: "onsiteJobReference",
            key: "onsiteJobReference",
            sorter: true,
        },
        {
            title: "Client",
            dataIndex: "onsiteClientName",
            key: "onsiteClientName",
            sorter: true,
        },
        {
            title: "Sample (Specimen)",
            dataIndex: "specimenType",
            key: "specimenType",
            sorter: true,
        },
        {
            title: "No. of Donors",
            dataIndex: "noDonors",
            key: "noDonors",
            sorter: true,
        },
        {
            title: "No",
            dataIndex: "noSamples",
            key: "noSamples",
            sorter: true,
        },
        {
            title: "Unit Cost",
            dataIndex: "unitCost",
            key: "unitCost",
            sorter: true,
        },
        {
            title: "Sub Total",
            dataIndex: "subTotal",
            key: "subTotal",
            sorter: true,
        },
        {
            title: "GST",
            dataIndex: "gst",
            key: "gst",
            sorter: true,
        },
        {
            title: "Total",
            dataIndex: "total",
            key: "total",
            sorter: true,
        },
    ];
    const columns = allColumns.filter((col) => {
        if ("dataIndex" in col) {
            return dataSource.some((item) => item[col.dataIndex] !== undefined);
        }
        return true;
    });
    const containsGroups = groupedDataSource.some((item, index) => index > 0 && item.jobReference === groupedDataSource[index - 1].jobReference);
    // Adds 'group-last-row' class to the last row of each group with the same job reference,
    // for visual separation. A row is the last if it's the final row or the next row has a different job reference.
    const rowClassName = containsGroups
        ? (record, index) => {
            const nextRowReference = groupedDataSource[index + 1]?.jobReference ?? undefined;
            const isLastInGroup = index === groupedDataSource.length - 1 ||
                (nextRowReference && nextRowReference !== record.jobReference);
            return isLastInGroup ? "group-last-row" : "";
        }
        : () => "";
    return (_jsx(CustomTable, { columns: columns, dataSource: groupedDataSource, rowClassName: rowClassName }));
};
export default TestDetailTable;
const CustomTable = styled(SATable) `
  .ant-table-tbody > tr.group-last-row > td {
    border-bottom: 0.1rem solid #01b4d2 !important;
  }
`;
