import moment from "moment";
import { message } from "antd";
import { getCurrentQuarter, getCurrentFinancialYear, getCurrentCalendarYear } from "src/utils";
import { DateRangeName, DateRangeOption, TestTypeRecordIndex, TimeFormat, } from "./types";
const getUserData = (state) => state.authentication.app.user;
const getApplicationInformation = (state) => state.authentication.app;
const getCollectionOrganisationInformation = (state) => state.authentication.app.collectionOrganization;
const selectIsLoading = (state) => state.authentication.isLoading;
const selectCollectionPoints = (state) => state.authentication.app.collectionPoints;
const selectTestTypes = (state) => state.authentication.app.testTypes;
const selectDrugTestTypes = (state) => state.authentication.app.testTypes.filter((testType) => testType.recordIndex !== TestTypeRecordIndex.BreathAlcoholTest);
const selectTestTypeByRecordIndex = (state) => {
    const { testTypes } = state.authentication.app;
    const listTypes = {};
    testTypes.forEach((testType) => {
        listTypes[testType.recordIndex] = testType.id;
    });
    return listTypes;
};
const selectCollectionOrganization = (state) => state.authentication.app.collectionOrganization;
const getCollectionPointInformation = (state) => state.authentication.app.collectionPoints;
const getRequestedTestTypeInformation = (state) => state.authentication.app.testTypes;
const selectDateWithSetupTime = (date, timeFormat) => {
    if (!date)
        return "";
    return moment(date).format(`DD MMM YYYY, ${timeFormat === TimeFormat.TwelveHour ? "hh:mm A" : "HH:mm"}`);
};
const handleAfterSendingRequest = (payload, action, errorStatus) => {
    const { error } = payload;
    const displayMessage = `There is error when trying to ${action.toLowerCase()}`;
    if (error) {
        message.error(error === errorStatus ? payload.message : displayMessage);
        return false;
    }
    message.success(`${action} a message successfully`);
    return true;
};
const selectDateRangeOptions = () => {
    const currentMonth = moment();
    const { startOfQuarter, endOfQuarter } = getCurrentQuarter();
    const { startOfFinancialYear, endOfFinancialYear } = getCurrentFinancialYear();
    const { startOfYear, endOfYear } = getCurrentCalendarYear();
    const options = [
        {
            label: DateRangeName.ThisMonth,
            startDate: currentMonth.clone().startOf("month"),
            endDate: currentMonth.clone().endOf("month"),
            key: DateRangeOption.ThisMonth,
        },
        {
            label: DateRangeName.ThisQuarter,
            startDate: startOfQuarter,
            endDate: endOfQuarter,
            key: DateRangeOption.ThisQuarter,
        },
        {
            label: DateRangeName.ThisFinancialYear,
            startDate: startOfFinancialYear,
            endDate: endOfFinancialYear,
            key: DateRangeOption.ThisFinancialYear,
        },
        {
            label: DateRangeName.ThisCalendarYear,
            startDate: startOfYear,
            endDate: endOfYear,
            key: DateRangeOption.ThisCalendarYear,
        },
    ];
    return options;
};
export { getApplicationInformation, getCollectionOrganisationInformation, getCollectionPointInformation, getRequestedTestTypeInformation, getUserData, selectCollectionOrganization, selectCollectionPoints, selectDateWithSetupTime, selectDrugTestTypes, selectIsLoading, selectTestTypeByRecordIndex, selectTestTypes, handleAfterSendingRequest, selectDateRangeOptions, };
