import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, DatePicker, Divider, Image, Row, Select, Typography } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Text } from "src/components/base";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch } from "src/store/hooks";
import { mainAppColours } from "src/styles";
import { setCurrentTestType, setEndDate, setSelectedToday, setStartDate } from "../store/slice";
export const HeaderFilter = ({ testTypes, selectedTestType, isSelectedToday, startDate, endDate, }) => {
    const dispatch = useAppDispatch();
    const handleTodayClick = () => {
        dispatch(setSelectedToday());
        if (!isSelectedToday) {
            const today = moment();
            dispatch(setStartDate(today));
            dispatch(setEndDate(today));
        }
        else {
            dispatch(setStartDate(null));
            dispatch(setEndDate(null));
        }
    };
    const handleStartDateChange = (date) => {
        dispatch(setStartDate(date));
    };
    const handleEndDateChange = (date) => {
        dispatch(setEndDate(date));
    };
    return (_jsx(HeaderFilterWrapper, { children: _jsxs(Row, { align: "middle", children: [_jsxs(StyledCol, { xs: 24, lg: 6, children: [_jsx(Image, { src: "/assets/svg/ic-invalid-results.svg", preview: false }), _jsx(Text, { name: "INVALID Result Reports", className: "header-text" })] }), _jsx(Col, { xs: 24, lg: 18, className: "show-select", children: _jsxs(React.Fragment, { children: [_jsx(Typography, { className: "bold-text", children: "Show" }), _jsx(Select, { className: "type-select", value: selectedTestType.name, size: "small", options: testTypes, onChange: (_, option) => {
                                    const selectedValue = option;
                                    dispatch(setIsLoading(true));
                                    dispatch(setCurrentTestType({ id: selectedValue.name, name: selectedValue.value }));
                                    setTimeout(() => {
                                        dispatch(setIsLoading(false));
                                    }, 1000);
                                } }), _jsx(Divider, { type: "vertical" }), _jsx(TodayButton, { onClick: handleTodayClick, isSelectedToday: isSelectedToday, children: "Today" }), _jsx(Divider, { type: "vertical" }), _jsx(StyledDiv, { children: _jsx(StyledTypo, { children: "Start Date" }) }), _jsx(DatePicker, { suffixIcon: null, value: startDate, onChange: handleStartDateChange }), _jsx(StyledDiv, { children: _jsx(StyledTypo, { children: "End Date" }) }), _jsx(DatePicker, { suffixIcon: null, value: endDate, onChange: handleEndDateChange })] }) })] }) }));
};
const StyledTypo = styled(Typography) `
  text-wrap: nowrap;
`;
export const HeaderFilterWrapper = styled.div `
  .header-text {
    margin-left: 10px;
    font-size: 1rem;
    color: #000000;
  }

  .add-new {
    border: 1px solid ${mainAppColours.MID_BLUE};
    border-radius: 4px;
    color: #1a8cff;

    .add-icon {
      margin-right: 5px;
    }
  }

  .show-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
  .ant-typography {
    font-size: 14px;
    color: #1a576f !important;
  }

  .bold-text {
    font-weight: 700;
    text-wrap: nowrap;
  }

  .type-select {
    width: 15rem;
  }

  .ant-select-selector {
    border: 1px solid #adb3bc !important;
    border-radius: 4px !important;
    height: 32px !important;
  }

  .ant-select-selection-item {
    line-height: 32px !important;
  }

  .ant-divider {
    border-left: 2px solid #adb3bc !important;
    margin: 0;
    height: 1.5rem !important;
  }

  .ant-picker {
    border-radius: 4px !important;
    border: 1px solid #adb3bc !important;
  }
`;
const StyledDiv = styled.div `
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const StyledCol = styled(Col) `
  display: flex;
  align-items: center;
`;
const TodayButton = styled(Button) `
  border-radius: 4px;
  background: ${(props) => (props.isSelectedToday ? "#1A8CFF" : "white")} !important;
  color: ${(props) => (props.isSelectedToday ? "white" : "rgba(0, 0, 0, 0.65)")} !important;
  border: 1px solid #adb3bc !important;
`;
