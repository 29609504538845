import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, Select, Button, Typography, message } from "antd";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { KioskDeviceIcon } from "src/assets/svg/welcome-assist";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { selectCollectionPoints, selectCollectionOrganization } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectCollectionPoint, selectListLicensesByCP } from "../../store/selector";
import { setCollectionPoint } from "../../store/slice";
import { addNewLicenses, getListLicenseByCollectionPoint } from "../../store/action";
import { WelcomeAssistError } from "../../store/types";
const NumberKioskForm = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const collectionPoints = useAppSelector(selectCollectionPoints);
    const selectedCollectionPoint = useAppSelector(selectCollectionPoint);
    const collectionOrg = useAppSelector(selectCollectionOrganization);
    const listLicenses = useAppSelector(selectListLicensesByCP);
    const [noLicenses, setNoLicenses] = useState(listLicenses.noLicense || 0);
    const [isEditing, setIsEditing] = useState(false);
    useEffect(() => {
        if (selectedCollectionPoint.id) {
            setNoLicenses(listLicenses.noLicense || 0);
        }
    }, [listLicenses]);
    useEffect(() => {
        if (listLicenses.noLicense < noLicenses && !isEditing) {
            setIsEditing(true);
        }
        if (listLicenses.noLicense === noLicenses && isEditing) {
            setIsEditing(false);
        }
    }, [noLicenses, listLicenses.noLicense]);
    return (_jsx(Form, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, children: _jsxs(SARow, { gutter: [16, 32], children: [_jsx(SAColumn, { span: 3, align: "start", children: _jsxs(LogoWrapper, { children: [_jsx(KioskDeviceIcon, {}), _jsx(LogoTitleTypo, { children: "WELCOME ASSIST KIOSKS" })] }) }), _jsxs(SAColumn, { span: 14, children: [_jsx(SAFormItem, { label: "ORGANISATION", children: _jsx(Input, { value: collectionOrg.collectionName, readOnly: true }) }), _jsx(SAFormItem, { label: "LOCATION (Collection Point)", children: _jsx(Select, { options: collectionPoints, fieldNames: { label: "collectionPointName", value: "id" }, value: selectedCollectionPoint.id, onChange: (_, option) => {
                                    const selectedPoint = option;
                                    dispatch(setCollectionPoint({
                                        id: selectedPoint.id,
                                        collectionPointName: selectedPoint.collectionPointName,
                                    }));
                                } }) })] }), _jsx(SAColumn, { span: 7, children: _jsx(SAFormItem, { label: "NUMBER OF KIOSKS", children: _jsxs(SARow, { gutter: [0, 16], children: [_jsx(SAColumn, { span: 10, children: _jsx(NoKioskInput, { readOnly: true, value: noLicenses }) }), _jsx(SAColumn, { span: 14, children: _jsxs(SARow, { gutter: [0, 16], children: [_jsx(SAColumn, { span: 12, children: _jsx(Button, { type: "primary", icon: _jsx(PlusOutlined, {}), disabled: !selectedCollectionPoint.id, onClick: () => setNoLicenses((prev) => prev + 1) }) }), _jsx(SAColumn, { span: 12, children: _jsx(Button, { type: "primary", ghost: true, disabled: !isEditing, children: "Cancel" }) }), _jsx(SAColumn, { span: 12, children: _jsx(Button, { type: "primary", ghost: true, icon: _jsx(MinusOutlined, {}), disabled: !isEditing || listLicenses.noLicense >= noLicenses, onClick: () => setNoLicenses((prev) => prev - 1) }) }), _jsx(SAColumn, { span: 12, children: _jsx(Button, { type: "primary", disabled: !isEditing, onClick: async () => {
                                                        const payload = await dispatch(addNewLicenses(noLicenses - listLicenses.noLicense)).unwrap();
                                                        const { error } = payload;
                                                        if (error) {
                                                            message.error(error === WelcomeAssistError.EA001
                                                                ? payload.message
                                                                : "There is error when trying to update");
                                                            return;
                                                        }
                                                        message.success("Updated numbers of licenses successfully");
                                                        dispatch(getListLicenseByCollectionPoint());
                                                    }, children: "Save" }) })] }) }), _jsx(SAColumn, { span: 10, children: _jsx(NotAssignedInput, { readOnly: true, value: listLicenses.noNotAssigned || 0 }) }), _jsx(SAColumn, { span: 10, justify: "center", children: _jsx(Typography.Text, { children: "Not Assigned" }) })] }) }) })] }) }));
};
export default NumberKioskForm;
const LogoWrapper = styled.div `
  height: 100%;
  border-radius: 0.25rem;
  border: 0.025rem solid #005277;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
`;
const LogoTitleTypo = styled(Typography.Text) `
  background-color: #005277;
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-size: 0.6875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const NoKioskInput = styled(Input) `
  height: 100%;
  text-align: center;
  font-size: 3rem;
`;
const NotAssignedInput = styled(Input) `
  text-align: center;
`;
