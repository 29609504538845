const selectInventoryDetail = (state) => state.inventoryPage.inventoryDeviceDetail;
const selectInventorySummaryView = (state) => state.inventoryPage.currentSummaryView;
const selectInventoryOverview = (state) => state.inventoryPage.inventoryOverview;
const selectTransportSatchel = (state) => state.inventoryPage.transportSatchels;
const selectInventorySummary = (state) => {
    const data = state.inventoryPage.inventoryOverview;
    return Object.keys(data).reduce((acc, key) => {
        const item = data[key];
        return {
            totalDevice: acc.totalDevice + item.totalDevice,
            deviceUsed: acc.deviceUsed + item.deviceUsed,
            remainingDevices: acc.remainingDevices + item.remainingDevices,
        };
    }, { totalDevice: 0, deviceUsed: 0, remainingDevices: 0 });
};
const selectReorderInventorySummary = (state) => state.inventoryPage.reorderInventoryOverview;
const selectListDeviceTypes = (state) => state.inventoryPage.listTestingDeviceTypes;
export { selectInventoryDetail, selectInventoryOverview, selectInventorySummary, selectTransportSatchel, selectInventorySummaryView, selectReorderInventorySummary, selectListDeviceTypes, };
