import moment from "moment";
const selectCurrentDate = (state) => moment(state.platformManagementPage.collectAssistCalendar.currentDate).toISOString();
const selectCollectionPointStaffs = (state) => state.platformManagementPage.collectAssistCalendar.selectedCollectionPoint;
const selectListCollectionPointStaffs = (state) => state.platformManagementPage.collectAssistCalendar.collectionPointStaffs;
const selectCalendarEvents = (state) => state.platformManagementPage.collectAssistCalendar.calendarEvents;
const selectCurrentView = (state) => state.platformManagementPage.collectAssistCalendar.currentView;
const selectStaffs = (state) => state.platformManagementPage.collectAssistCalendar.staffs;
const selectCollectorId = (state) => state.platformManagementPage.collectAssistCalendar.selectedCollectorId;
const selectIsListView = (state) => state.platformManagementPage.collectAssistCalendar.isListView;
export { selectCollectionPointStaffs, selectCurrentDate, selectListCollectionPointStaffs, selectCalendarEvents, selectCurrentView, selectCollectorId, selectStaffs, selectIsListView, };
