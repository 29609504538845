const getDisabledTime = (referenceTime, isStart) => {
    if (!referenceTime)
        return {};
    return {
        disabledHours: () => {
            const referenceHour = referenceTime.hour();
            return Array.from({ length: 24 }, (_, i) => i).filter((hour) => isStart ? hour > referenceHour : hour < referenceHour);
        },
        disabledMinutes: (selectedHour) => {
            const referenceHour = referenceTime.hour();
            if (selectedHour === referenceHour) {
                const referenceMinute = referenceTime.minute();
                return Array.from({ length: 60 }, (_, i) => i).filter((minute) => isStart ? minute > referenceMinute : minute < referenceMinute);
            }
            return [];
        },
    };
};
const getDisabledDate = (referenceTime, isStart) => {
    if (!referenceTime)
        return () => false;
    return (current) => {
        if (!current)
            return false;
        const referenceDate = referenceTime.startOf("day");
        if (isStart) {
            // Disable dates after the reference date
            return current.isAfter(referenceDate, "day");
        }
        else {
            // Disable dates before the reference date
            return current.isBefore(referenceDate, "day");
        }
    };
};
export { getDisabledTime, getDisabledDate };
