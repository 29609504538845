import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, DatePicker, Form, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DeleteCollectorIcon from "src/assets/svg/ic_delete_collector.svg";
import RestoreCollectorIcon from "src/assets/svg/ic_restore_collector.svg";
import SuspendCollectorIcon from "src/assets/svg/ic_suspend_collector.svg";
import { Image, InputWithLabel, TreeSelect } from "src/components/base";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import Heading from "src/pages/JobManagement/components/Heading";
import { getApplicationInformation, selectIsLoading } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getAge } from "src/utils/helper";
import FormItem from "../../CollectAssistPage/components/FormItem";
import { deleteCollector, getCollectorDetail, restoreCollector, suspendCollector, updateCollectorDetail, } from "../store/action";
import { selectCurrentCollector } from "../store/selector";
import { COLLECTOR_STATUS, COLLECTOR_STATUS_TITLE } from "../store/types";
const sexOptions = [
    {
        label: "Male",
        value: "male",
        tooltip: "Male",
    },
    {
        label: "Female",
        value: "female",
        tooltip: "Female",
    },
    {
        label: "X",
        value: "other",
        tooltip: "Intersex/Indeterminate/Unspecified",
    },
];
const CollectorBasicProfile = () => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { collectorId } = useParams();
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const isLoading = useAppSelector(selectIsLoading);
    const collectorInformation = useAppSelector(selectCurrentCollector);
    const app = useAppSelector(getApplicationInformation);
    const [collectionPointTree, setCollectionPointTree] = useState([]);
    useEffect(() => {
        dispatch(getCollectorDetail(String(collectorId)));
    }, [collectorId]);
    useEffect(() => {
        if (app.collectionOrganization.collectionName && app.collectionPoints.length) {
            const curCollectionPointTree = [
                {
                    title: app.collectionOrganization.collectionName,
                    value: app.collectionOrganization.id,
                    children: app.collectionPoints.map((point) => ({
                        title: point.collectionPointName,
                        value: point.id,
                    })),
                },
            ];
            setCollectionPointTree(curCollectionPointTree);
        }
    }, [app]);
    useEffect(() => {
        const birthDay = moment(collectorInformation.dob !== "" ? collectorInformation.dob : new Date());
        form.setFieldsValue({
            ...collectorInformation,
            firstName: collectorInformation?.firstName ? collectorInformation.firstName : "",
            lastName: collectorInformation?.lastName ? collectorInformation.lastName : "",
            email: collectorInformation?.email ? collectorInformation.email : "",
            phone: collectorInformation?.mobile ? collectorInformation.mobile : "",
            collectorName: collectorInformation?.collectorName ? collectorInformation.collectorName : "",
            collectorStatus: collectorInformation?.collectorStatus
                ? collectorInformation.collectorStatus
                : "",
            invitationCode: collectorInformation?.invitationCode
                ? collectorInformation.invitationCode
                : "",
            id: collectorInformation?.id ? collectorInformation.id : "",
            dob: moment(birthDay),
            age: getAge(birthDay.toDate()) ?? 0,
        });
    }, [collectorInformation]);
    const onsubmitUpdateCollector = () => {
        form.validateFields().then((values) => {
            dispatch(updateCollectorDetail({
                id: collectorId,
                firstName: values.firstName,
                lastName: values.lastName,
                sex: values.sex,
                mobile: values.mobile,
                landline: values.landline ?? "",
                address1: values.address1 ?? "",
                address2: values.address2 ?? "",
                suburb: values.suburb ?? "",
                state: values.state ?? "",
                postcode: values.postcode ?? "",
                country: values.country ?? "",
                collectionPointIds: values.collectionPointIds ?? [],
                dob: values.dob.format("YYYY-MM-DD"),
            }));
        });
        setIsEdit(false);
    };
    const suspendCollectorFunc = () => {
        dispatch(suspendCollector(String(collectorId)));
    };
    const restoreCollectorFunc = () => {
        dispatch(restoreCollector(String(collectorId)));
    };
    const deleteCollectorFunc = () => {
        dispatch(deleteCollector(String(collectorId)));
        navigate("/platform-management/collect-assist");
    };
    return (_jsxs(CreateFormWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsx(CustomForm, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, onFinish: onsubmitUpdateCollector, children: _jsxs(SARow, { children: [_jsx(CustomColumn, { span: 6, children: _jsxs(ImageComponent, { children: [_jsx(Avatar, { children: _jsx(Image, { src: collectorInformation.avatarUrl
                                                ? collectorInformation.avatarUrl
                                                : "/assets/svg/ic-user-avatar-placeholder.svg", width: "110px", height: "130px", preview: false }) }), _jsxs(CustomRow, { children: [_jsx(CollectorStatusComponent, { children: COLLECTOR_STATUS_TITLE[collectorInformation.collectorStatus] }), _jsx(CollectorNameContainer, { children: collectorInformation?.firstName
                                                    ? `${collectorInformation.firstName} ${collectorInformation.lastName}`
                                                    : "" })] }), _jsxs(IdRow, { children: [_jsx(FormItem, { label: "Collector Invitation Code", name: "invitationCode", children: _jsx(StyledInput, { disabled: true }) }), _jsx(FormItem, { label: "Collector ID", name: "saId", children: _jsx(StyledInput, { disabled: true }) })] }), _jsx(FormItem, { name: "userId", children: _jsx(QrCode, { children: _jsx(QRCode, { ecLevel: "H", value: collectorInformation.saId, logoImage: "/assets/svg/qr-logo.svg" }) }) }), _jsxs(ButtonWrapper, { children: [collectorInformation.collectorStatus === COLLECTOR_STATUS.SUSPENDED && (_jsx(CustomButtonWithIcon, { icon: _jsx(RestoreCollectorIcon, {}), onClick: restoreCollectorFunc, children: "Restore" })), COLLECTOR_STATUS.ONBOARDED === collectorInformation.collectorStatus && (_jsx(CustomButtonWithIcon, { icon: _jsx(SuspendCollectorIcon, {}), onClick: suspendCollectorFunc, children: "Suspend" })), _jsx(CustomButtonWithIcon, { icon: _jsx(DeleteCollectorIcon, {}), onClick: deleteCollectorFunc, children: "Delete" })] })] }) }), _jsxs(InfoColumn, { span: 18, children: [isEdit ? (_jsxs(SARow, { children: [_jsx(SAColumn, { span: 3, offset: 18, children: _jsx(CustomButton, { danger: true, onClick: () => {
                                                    setIsEdit(false);
                                                }, children: "Cancel" }) }), _jsx(SAColumn, { span: 3, children: _jsx(CustomButton, { type: "primary", htmlType: "submit", children: "Save" }) })] })) : (_jsx(SARow, { children: _jsx(SAColumn, { span: 3, offset: 18, children: _jsx(CustomButton, { type: "primary", onClick: () => {
                                                setIsEdit(true);
                                            }, children: "Edit" }) }) })), _jsxs(SARow, { children: [_jsx(Heading, { startTitle: "Basic Info" }), _jsxs(SAColumn, { span: 12, children: [_jsx(FormItem, { label: "First Name", name: "firstName", children: _jsx(StyledInput, { disabled: !isEdit }) }), _jsx(FormItem, { label: "Date of birth", name: "dob", children: _jsx(CustomDatePicker, { format: "DD MMM YYYY", disabled: !isEdit }) })] }), _jsxs(SAColumn, { span: 12, children: [_jsx(FormItem, { label: "Last Name", name: "lastName", children: _jsx(StyledInput, { disabled: !isEdit }) }), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(FormItem, { label: "Age", name: "age", children: _jsx(StyledInput, { disabled: true }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(FormItem, { label: "Sex", name: "sex", children: _jsx(InputWithLabel, { theme: "3", field: "sex", inputProps: {
                                                                        type: "select",
                                                                        options: sexOptions,
                                                                        disabled: !isEdit,
                                                                    }, rules: [{ required: true }] }) }) })] })] })] }), _jsxs(SARow, { children: [_jsx(Heading, { startTitle: "Contact detail" }), _jsx(SAColumn, { span: 24, children: _jsx(FormItem, { label: "Email Address", name: "email", children: _jsx(StyledInput, { disabled: true }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(FormItem, { label: "Phone Number", name: "mobile", children: _jsx(StyledInput, { disabled: !isEdit }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(FormItem, { label: "Phone Number (Landline)", name: "landline", children: _jsx(StyledInput, { disabled: !isEdit }) }) })] }), _jsxs(SARow, { children: [_jsx(Heading, { startTitle: "Address" }), _jsxs(SAColumn, { span: 12, children: [_jsx(FormItem, { label: "Address 1", name: "address1", children: _jsx(StyledInput, { disabled: !isEdit }) }), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(FormItem, { label: "City/Suburb", name: "suburb", children: _jsx(StyledInput, { disabled: !isEdit }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(FormItem, { label: "State", name: "state", children: _jsx(StyledInput, { disabled: !isEdit }) }) })] })] }), _jsxs(SAColumn, { span: 12, children: [_jsx(FormItem, { label: "Address Line 2", name: "address2", children: _jsx(StyledInput, { disabled: !isEdit }) }), _jsxs(SARow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(FormItem, { label: "Postcode", name: "postcode", children: _jsx(StyledInput, { disabled: !isEdit }) }) }), _jsx(SAColumn, { span: 12, children: _jsx(FormItem, { label: "Country", name: "country", children: _jsx(StyledInput, { disabled: !isEdit }) }) })] })] })] }), _jsxs(SARow, { children: [_jsx(Heading, { startTitle: "Service Office (Work Place)" }), _jsx(SAColumn, { span: 12, children: _jsx(FormItem, { name: "collectionPointIds", label: "Collection Point (where the device will be used)", rules: [
                                                    {
                                                        required: true,
                                                        message: "Please select at least one Collection Point",
                                                    },
                                                ], children: _jsx(InputWithLabel, { theme: "3", field: "collectionPointIds", inputProps: {
                                                        type: "treeselect",
                                                        options: collectionPointTree,
                                                        treeCheckable: true,
                                                        showCheckedStrategy: TreeSelect.SHOW_CHILD,
                                                        treeLine: {},
                                                        treeDefaultExpandAll: true,
                                                        // disabled: !isEdit,
                                                    } }) }) })] })] })] }) })] }));
};
export default CollectorBasicProfile;
const CreateFormWrapper = styled.div `
  width: calc(100% + 4rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: -2rem;
`;
const CustomForm = styled(Form) `
  width: 100%;
`;
export const QrCode = styled.div `
  display: flex;
  flex: 1;
  margin: 25px 25px 35px;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const Avatar = styled.div `
  display: flex;
  flex: 1;
  margin: 25px 25px 35px;
  justify-content: center;
  align-items: center;
`;
export const ImageComponent = styled.div `
  background-color: #f7f8f8;
  height: 100%;
  width: 100%;
`;
const StyledInput = styled(Input) `
  width: 100%;
  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
`;
const CustomButton = styled(Button) `
  width: 100%;
  border: 0;
  margin: 0.5rem 0;
`;
const CollectorStatusComponent = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: #fdba00;
  color: #ffffff;
`;
const CollectorNameContainer = styled.div `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: #00004c;
  color: #ffffff;
`;
const CustomButtonWithIcon = styled(Button) `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
const CustomDatePicker = styled(DatePicker) `
  width: 100%;
`;
const InfoColumn = styled(SAColumn) `
  padding: 0.5rem 2rem !important;
`;
const ButtonWrapper = styled.div `
  padding: 0rem 2.5rem;
`;
const IdRow = styled(SARow) `
  padding: 1rem !important;
`;
const CustomColumn = styled(SAColumn) `
  padding: 0 !important;
`;
const CustomRow = styled(SARow) `
  row-gap: 0 !important;
`;
