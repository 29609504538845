import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, message, Space, Typography } from "antd";
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { HairTestIcon } from "src/assets/svg/test-type";
import { PageLayoutWithExtraTab } from "src/components/layouts/PageLayout";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { DefaultPagingMeta } from "src/infra/pagination";
import { getStockHair, upsertStockHair, } from "src/pages/TestingDevicePage/HairCollectionKit/store/action";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { TestTypeRecordIndex } from "src/store/app/types";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { breadcrumbs } from "..";
import HistoricalStockRecords from "../components/HistoricalStockRecords";
import StockManagement from "../components/StockManagement";
import { TestingDeviceTypes } from "../store/types";
import { InitialStockDataResponse } from "../typing";
const HairCollectionKitStock = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoading);
    const [showHistoricalRecords, setShowHistoricalRecords] = React.useState(false);
    const [stockData, setStockData] = React.useState(InitialStockDataResponse);
    const loadStockData = async (filters) => {
        const response = await dispatch(getStockHair(filters)).unwrap();
        if (response.status !== "success") {
            message.error("Failed to fetch stock data");
            return;
        }
        setStockData(response.data);
        setShowHistoricalRecords(false);
    };
    const onSubmit = async (dataCreateStock) => {
        dispatch(setIsLoading(true));
        const response = await dispatch(upsertStockHair(dataCreateStock)).unwrap();
        if (response.status !== "success") {
            message.error("Failed to create stock");
            dispatch(setIsLoading(false));
            return;
        }
        loadStockData(DefaultPagingMeta);
        dispatch(setIsLoading(false));
    };
    useEffect(() => {
        dispatch(setIsLoading(true));
        loadStockData(DefaultPagingMeta);
        dispatch(setIsLoading(false));
    }, []);
    return (_jsx(PageLayoutWithExtraTab, { routes: breadcrumbs, tabLevel: 2, children: _jsxs(PageContainer, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(Space, { children: [_jsx(HairTestIcon, {}), _jsx(Typography.Title, { level: 5, children: "Hair Collection Kits" })] }), _jsx(Divider, {}), _jsx(StockManagement, { dataSource: {
                        stockData: stockData.inventoryItems,
                        stockOverview: stockData.inventoryOverview,
                    }, onSubmit: onSubmit, requireProductName: true, testTypeRecordIndex: TestTypeRecordIndex.HairDrugTest, type: TestingDeviceTypes.Cup, onChangePagination: (page, limit) => {
                        loadStockData({ page, limit });
                    } }), _jsx(HistoricalStockRecords, { testTypeRecordIndex: TestTypeRecordIndex.HairDrugTest, type: TestingDeviceTypes.Cup, showHistoricalRecords: showHistoricalRecords, setShowHistoricalRecords: setShowHistoricalRecords, requireProductName: true })] }) }));
};
export default HairCollectionKitStock;
const PageContainer = styled.div `
  width: 100%;
  padding: 0 0.5rem;
`;
