import { combineReducers } from "redux";
import accountingLicensingPageReducer from "src/pages/Accounting/Licensing/store/slice";
import dashboardPageReducer from "src/pages/Accounting/Dashboard/store/slice";
import accountingTestingPageReducer from "src/pages/Accounting/Testing/store/slice";
import accountingITServicePageReducer from "src/pages/Accounting/ITServices/store/slice";
const accountingReducer = combineReducers({
    accountingLicensingPage: accountingLicensingPageReducer,
    dashboardPage: dashboardPageReducer,
    accountingTestingPage: accountingTestingPageReducer,
    accountingITServicePage: accountingITServicePageReducer,
});
export default accountingReducer;
