import { DefaultPagingMeta } from "src/infra/pagination";
const initialTestingDeviceDetails = {
    deviceDetail: {
        deviceName: "",
        isInUse: false,
        isArchived: false,
        lotNumber: "",
        expireAt: null,
        isReminderExpire: false,
        reminderAt: null,
        collectionPointIds: [],
        artgNumber: "",
        sponsor: "",
        testTypeId: "",
        fontImageUrl: "",
        sideImageUrls: [],
        artgSummarySheetUrl: "",
        laboratorySheetUrl: "",
        specificationSheetUrl: "",
    },
    stockDetail: {
        inventoryItems: { data: [], pagingMeta: DefaultPagingMeta },
        inventoryOverview: {
            totalUnits: 0,
            usedUnits: 0,
            remainingUnits: 0,
        },
    },
};
export { initialTestingDeviceDetails };
