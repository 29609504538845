var CollectorCalendarLeaveType;
(function (CollectorCalendarLeaveType) {
    CollectorCalendarLeaveType["AnnualLeave"] = "AnnualLeave";
    CollectorCalendarLeaveType["SickLeave"] = "SickLeave";
    CollectorCalendarLeaveType["CarerLeave"] = "CarerLeave";
    CollectorCalendarLeaveType["ParentalLeave"] = "ParentalLeave";
    CollectorCalendarLeaveType["LongServiceLeave"] = "LongServiceLeave";
    CollectorCalendarLeaveType["Other"] = "Other";
})(CollectorCalendarLeaveType || (CollectorCalendarLeaveType = {}));
var CollectorCalendarEventType;
(function (CollectorCalendarEventType) {
    CollectorCalendarEventType["Unavailable"] = "Unavailable";
    CollectorCalendarEventType["Leave"] = "Leave";
})(CollectorCalendarEventType || (CollectorCalendarEventType = {}));
var CollectorCalendarRepeatType;
(function (CollectorCalendarRepeatType) {
    CollectorCalendarRepeatType["Never"] = "Never";
    CollectorCalendarRepeatType["Weekly"] = "Weekly";
    CollectorCalendarRepeatType["Fortnightly"] = "Fortnightly";
    CollectorCalendarRepeatType["Monthly"] = "Monthly";
})(CollectorCalendarRepeatType || (CollectorCalendarRepeatType = {}));
export { CollectorCalendarLeaveType, CollectorCalendarEventType, CollectorCalendarRepeatType, };
