import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { SAColumn, SARow } from "src/components/Form";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import { useResetStoreOnUnmount } from "src/hooks";
import { selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { routes } from "..";
import CollectorDayCalendar from "./components/DayCalendar";
import EventSideBar from "./components/EventSideBar";
import CollectorMonthCalendar from "./components/MonthCalendar";
import Toolbar from "./components/Toolbar";
import CollectorWeekCalendarListView from "./components/WeekCalendarListView";
import { getCollectorCalendarEvents, getStaffCollectionPoints } from "./store/action";
import { selectCalendarEvents, selectCollectionPointStaffs, selectCurrentDate, selectCurrentView, selectIsListView, selectListCollectionPointStaffs, } from "./store/selector";
import { setCollectionPoint, setCurrentDate, setCurrentView, setResetStore } from "./store/slice";
import { CalendarView } from "./typing";
import CollectorWeekCalendar from "./components/WeekCalendar";
const CollectorCalendar = () => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const currentDate = useAppSelector(selectCurrentDate);
    const isLoading = useAppSelector(selectIsLoading);
    const calendarEvents = useAppSelector(selectCalendarEvents);
    const currentView = useAppSelector(selectCurrentView);
    const collectionPoints = useAppSelector(selectListCollectionPointStaffs);
    const currentCollectionPoint = useAppSelector(selectCollectionPointStaffs);
    const isListView = useAppSelector(selectIsListView);
    useResetStoreOnUnmount(setResetStore, [location.pathname]);
    useEffect(() => {
        const view = searchParams.get("view");
        const date = searchParams.get("date");
        const collectionPointId = searchParams.get("collectionPointId");
        if (view && Object.values(CalendarView).includes(view)) {
            dispatch(setCurrentView(view));
        }
        if (date)
            dispatch(setCurrentDate(date));
        const collectionPoint = collectionPoints.find((point) => point.collectionPointId === collectionPointId) ||
            collectionPoints[0];
        if (collectionPoint)
            dispatch(setCollectionPoint(collectionPointId || ""));
    }, []);
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getStaffCollectionPoints());
            await dispatch(getCollectorCalendarEvents());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, []);
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getCollectorCalendarEvents());
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [currentDate, currentView, currentCollectionPoint]);
    const renderCalendarByView = () => {
        switch (currentView) {
            case CalendarView.Day: {
                const events = calendarEvents.map((event) => ({
                    id: event.id,
                    title: event.collectorName,
                    note: event.reasonNote,
                    start: new Date(event.startAt),
                    end: new Date(event.endAt),
                    allDay: event.isAllDay,
                    eventType: event.eventType,
                }));
                return (_jsxs(React.Fragment, { children: [_jsx(SAColumn, { span: 18, children: _jsx(CollectorDayCalendar, { events: events, currentDate: new Date(currentDate) }) }), _jsx(SAColumn, { span: 6, children: _jsx(EventSideBar, {}) })] }));
            }
            case CalendarView.Week: {
                const events = calendarEvents.map((event) => ({
                    id: event.id,
                    title: event.collectorName,
                    note: event.reasonNote,
                    start: new Date(event.startAt),
                    end: new Date(event.endAt),
                    allDay: event.isAllDay,
                    eventType: event.eventType,
                }));
                return (_jsx(SAColumn, { children: isListView ? (_jsx(CollectorWeekCalendarListView, { events: events, currentDate: new Date(currentDate) })) : (_jsx(CollectorWeekCalendar, { events: events, currentDate: new Date(currentDate) })) }));
            }
            case CalendarView.Month: {
                const events = calendarEvents.map((event) => ({
                    id: event.id,
                    title: event.collectorName,
                    start: new Date(event.startAt),
                    end: new Date(event.endAt),
                    note: event.reasonNote,
                    eventType: event.eventType,
                }));
                return (_jsx(SAColumn, { children: _jsx(CollectorMonthCalendar, { events: events, currentDate: new Date(currentDate), selectEvent: (date) => {
                            dispatch(setCurrentView(CalendarView.Day));
                            dispatch(setCurrentDate(date.toISOString()));
                        } }) }));
            }
        }
    };
    return (_jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsxs(CalendarPageWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(SARow, { children: [_jsx(SAColumn, { children: _jsx(Toolbar, {}) }), renderCalendarByView()] })] }) }));
};
export default CollectorCalendar;
const CalendarPageWrapper = styled.div `
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
