import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Radio, Typography } from "antd";
import * as React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import BarIcon from "src/assets/svg/dashboard/bar-chart.svg";
import SADivider from "src/components/Form/SADivider";
import SASelect from "src/components/Form/SASelect";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppDispatch } from "src/store/hooks";
import ColorSchemeSelection from "../components/ColorSchemeSelection";
import ExportDropdown from "../components/ExportDropdown";
import ReorderDropdown from "../components/ReorderDropdown";
import { collectionComponents } from "../typing";
import DailySummaryCard from "./DailySummaryCard";
import OnsiteCollectionMetrics from "./OnsiteCollectionMetrics";
import ProcessedTestCard from "./ProcessedTestCard";
import SampleResultCard from "./SampleResultCard";
import { setResetPageState, setColorScheme } from "./store/slice";
var DashboardPlatform;
(function (DashboardPlatform) {
    DashboardPlatform["All"] = "All";
    DashboardPlatform["InClinic"] = "InClinic";
    DashboardPlatform["Onsite"] = "On-Site";
})(DashboardPlatform || (DashboardPlatform = {}));
var DashboardType;
(function (DashboardType) {
    DashboardType["Collections"] = "collections";
    DashboardType["Devices"] = "devices";
    DashboardType["Satchels"] = "satchels";
})(DashboardType || (DashboardType = {}));
const OnsiteCollection = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { pathname } = location;
    React.useEffect(() => () => {
        dispatch(setResetPageState());
    }, [dispatch, location.pathname]);
    return (_jsx(React.Fragment, { children: _jsxs(DashboardLayout, { children: [_jsxs(DashboardFilterContainer, { children: [_jsx(SAColumn, { lg: 9, md: 12, children: _jsxs(GroupButton, { value: pathname.split("/")[2], children: [_jsxs(Radio.Button, { value: DashboardType.Collections, children: [_jsx(BarIcon, {}), " Collections"] }), _jsxs(Radio.Button, { value: DashboardType.Devices, children: [_jsx(BarIcon, {}), " Devices"] }), _jsxs(Radio.Button, { value: DashboardType.Satchels, children: [_jsx(BarIcon, {}), " Satchels"] })] }) }), _jsx(SAColumn, { lg: 15, md: 12, children: _jsxs(FilterOptions, { children: [_jsx(CustomSelect, { value: DashboardPlatform.Onsite, options: [
                                            { value: DashboardPlatform.All, label: DashboardPlatform.All },
                                            { value: DashboardPlatform.InClinic, label: DashboardPlatform.InClinic },
                                            { value: DashboardPlatform.Onsite, label: DashboardPlatform.Onsite },
                                        ], children: _jsx(PlatformText, { children: "In-Clinic/On-Site" }) }), _jsx(ExportDropdown, { dataSource: collectionComponents }), _jsx(ColorSchemeSelection, { onSelectedScheme: (scheme) => {
                                            dispatch(setColorScheme(scheme));
                                        } }), _jsx(ReorderDropdown, { dataSource: collectionComponents })] }) })] }), _jsx(DividerContainer, { children: _jsx(SADivider, {}) }), _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 6, children: _jsx(DailySummaryCard, {}) }), _jsx(SAColumn, { md: 24, lg: 18, children: _jsxs(SARow, { children: [_jsx(SAColumn, { md: 24, lg: 24, children: _jsx(ProcessedTestCard, {}) }), _jsx(SAColumn, { md: 24, lg: 24, children: _jsx(SampleResultCard, {}) })] }) })] }), _jsx(DividerContainer, { children: _jsx(SADivider, {}) }), _jsx(SARow, { children: _jsx(SAColumn, { children: _jsx(OnsiteCollectionMetrics, {}) }) })] }) }));
};
export default OnsiteCollection;
const DashboardLayout = styled.div `
  width: 100%;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
`;
const DashboardFilterContainer = styled(SARow) `
  .ant-col:not(:first-child) {
    align-items: flex-end !important;
  }
`;
const DividerContainer = styled.div `
  padding: 2rem 0.5rem;
  padding-top: 0;
`;
const GroupButton = styled(Radio.Group) `
  gap: 0.2rem;
  margin-right: auto;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    margin-right: 0.2rem;

    span:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper {
    border-radius: 0.3rem !important;
    border: 0.025rem solid #156cc9;
    color: #156cc9;
    width: 8rem;
    font-size: 0.875rem;
  }

  .ant-radio-button-wrapper-checked {
    background-color: #00004c;
    color: #ffffff;
    border-radius: 0.3rem !important;
    border: 0.025rem solid #00004c !important;
  }
`;
const CustomSelect = styled(SASelect) `
  width: fit-content;
  border-radius: 0.3rem;
  color: #01b4d2;

  .ant-select-selector {
    border-radius: 0.3rem !important;
    border: 0.025rem solid #01b4d2 !important;
  }
  .ant-select-arrow {
    color: #01b4d2;
  }
`;
const PlatformText = styled(Typography.Text) `
  color: #2374cc;
`;
const FilterOptions = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  white-space: nowrap;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
  }
`;
