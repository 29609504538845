import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, } from "recharts";
const PremiseLineChart = ({ dataSource }) => (_jsx(ResponsiveContainer, { width: "100%", height: "100%", children: _jsxs(LineChart, { width: 500, height: 300, data: dataSource, margin: {
            top: 10,
            right: 10,
            left: -5,
            bottom: 10,
        }, children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: "label" }), _jsx(YAxis, {}), _jsx(Tooltip, {}), _jsx(Line, { type: "monotone", dataKey: "count", stroke: "#00004C", dot: { fill: "#00004C", strokeWidth: 6 } })] }) }));
export default PremiseLineChart;
