import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { TestTypeRecordIndex } from "src/store/app/types";
import { applyCommonActionHandlers } from "../../store/slice";
import { initialBreathalyserTestingDeviceDetails } from "./types";
import { createBreathalyserTestingDevice, getTestingDeviceById, getTestingDevices, updateBreathalyserTestingDevice, } from "./action";
export const initialState = {
    listTestingDevices: [],
    listArchivedTestingDevices: [],
    selectedCollectionPointId: "",
    testingDeviceDetails: initialBreathalyserTestingDeviceDetails,
};
const breathalyserDeviceSlice = createSlice({
    name: "breathalyserDevice",
    initialState,
    reducers: {
        setResetStore() {
            return initialState;
        },
        setCollectionPointId(state, action) {
            state.selectedCollectionPointId = action.payload;
        },
        setDeviceDetails(state, action) {
            const oldData = state.testingDeviceDetails.deviceDetail;
            state.testingDeviceDetails.deviceDetail = {
                ...oldData,
                ...action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        applyCommonActionHandlers(builder, TestTypeRecordIndex.BreathAlcoholTest);
        builder
            .addCase(getTestingDevices.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch testing devices");
            }
            else {
                if (action.meta.arg.isArchived) {
                    state.listArchivedTestingDevices = action.payload.data.testingDevicesData;
                    return;
                }
                state.listTestingDevices = action.payload.data.testingDevicesData;
            }
        })
            .addCase(getTestingDevices.rejected, () => {
            message.error("Failed to fetch testing devices");
        })
            .addCase(createBreathalyserTestingDevice.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to create a new device");
            }
            else {
                message.success("Success Created");
            }
        })
            .addCase(createBreathalyserTestingDevice.rejected, () => {
            message.error("Failed to create a new device");
        })
            .addCase(getTestingDeviceById.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to fetch the device information");
            }
            else {
                state.testingDeviceDetails.deviceDetail = action.payload.data?.deviceDetail ?? {};
            }
        })
            .addCase(getTestingDeviceById.rejected, () => {
            message.error("Failed to fetch the device information");
        })
            .addCase(updateBreathalyserTestingDevice.fulfilled, (state, action) => {
            if (action.payload.status === "fail" || !action.payload.data) {
                message.error("Failed to update the device");
            }
            else {
                message.success("Successfully updated the device");
            }
        })
            .addCase(updateBreathalyserTestingDevice.rejected, () => {
            message.error("Failed to update a new device");
        });
    },
});
export const { setResetStore, setCollectionPointId, setDeviceDetails } = breathalyserDeviceSlice.actions;
export default breathalyserDeviceSlice.reducer;
