import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, DatePicker, Form, Input, message, TreeSelect } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { pick } from "lodash";
import { SpaceBetweenContainer } from "src/components/Container/Flex";
import SADivider from "src/components/Form/SADivider";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import SASwitch from "src/components/Form/Table/SASwitch";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import useGoBackToPreviousPath from "src/hooks/useGoBackToPath";
import { deleteFile } from "src/infra/file";
import { UploadPrefix } from "src/infra/file/types";
import { selectCollectionOrganization, selectCollectionPoints, selectIsLoading, selectTestTypeByRecordIndex, } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import InstructionIcon from "src/assets/svg/device-management/instruction.svg";
import OperatedInstructionIcon from "src/assets/svg/device-management/operation-instruction.svg";
import OralCupIcon from "src/assets/svg/device-management/oral-screen.svg";
import PdfIcon from "src/assets/svg/device-management/pdf.svg";
import UploadedPdfIcon from "src/assets/svg/device-management/uploaded-pdf.svg";
import { TestTypeRecordIndex } from "src/store/app/types";
import { PageLayoutWithTabAndBack } from "src/components/layouts/PageLayout";
import { DefaultPagingMeta } from "src/infra/pagination";
import FileOptionsDropdown from "../../components/FileOptionsDropdown";
import FileUploadDrawer from "../../components/FileUploadDrawer";
import { UploadFileType, DeviceManagerView, FileMenuOption, } from "../../typing";
import { getOralScreenTestingDeviceById, updateOralScreenTestingDevice, createOralScreenTestingDevice, } from "../store/action";
import { selectTestingDeviceDetail } from "../store/selector";
import { setDeviceDetails } from "../store/slice";
import StockManagement from "../../components/StockManagement";
import { TestingDeviceTypes } from "../../store/types";
import { upsertStock } from "../../UrineDrugScreenDevice/store/action";
import HistoricalStockRecords from "../../components/HistoricalStockRecords";
import { initialOralTestingDeviceDetails } from "../store/types";
const defaultUploadingFile = {
    url: null,
    key: null,
    fileType: null,
};
const OralDeviceManager = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const goBackToDashboard = useGoBackToPreviousPath();
    const isLoading = useAppSelector(selectIsLoading);
    const deviceInfo = useAppSelector(selectTestingDeviceDetail);
    const collectionPoints = useAppSelector(selectCollectionPoints);
    const collectionOrg = useAppSelector(selectCollectionOrganization);
    const testTypes = useAppSelector(selectTestTypeByRecordIndex);
    const [isOpenUploadPanel, setIsOpenUploadPanel] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(defaultUploadingFile);
    const [searchParams] = useSearchParams();
    const { view } = useParams();
    const [showHistoricalRecords, setShowHistoricalRecords] = useState(false);
    const testingDeviceId = searchParams.get("id");
    useEffect(() => {
        const loadData = async () => {
            dispatch(setIsLoading(true));
            if (view === DeviceManagerView.Update && testingDeviceId) {
                await dispatch(getOralScreenTestingDeviceById({
                    testingDeviceId,
                    ...DefaultPagingMeta,
                }));
            }
            dispatch(setIsLoading(false));
        };
        loadData();
    }, [view]);
    useEffect(() => {
        form.setFieldsValue({ ...deviceInfo.deviceDetail });
    }, [deviceInfo.deviceDetail]);
    const onSubmit = async (dataCreateStock) => {
        if (!testingDeviceId)
            return;
        dispatch(setIsLoading(true));
        const data = await dispatch(upsertStock({
            batchNumber: dataCreateStock.batchNumber,
            noOfUnits: dataCreateStock.noOfUnits,
            unitPrice: dataCreateStock.unitPrice,
            notes: dataCreateStock.notes,
            productName: dataCreateStock.productName || "",
            id: dataCreateStock.id,
            status: dataCreateStock.status,
            deviceId: testingDeviceId,
        })).unwrap();
        if (data.status === "fail") {
            message.error("Failed to create stock");
        }
        else {
            message.success("Stock created successfully");
            if (view === DeviceManagerView.Update && testingDeviceId) {
                await dispatch(getOralScreenTestingDeviceById({
                    testingDeviceId,
                    ...DefaultPagingMeta,
                }));
            }
        }
        setShowHistoricalRecords(false);
        dispatch(setIsLoading(false));
    };
    const onMenuItemClick = async (option) => {
        switch (option) {
            case FileMenuOption.Upload: {
                setIsOpenUploadPanel(true);
                return;
            }
            case FileMenuOption.View: {
                if (!uploadingFile.url)
                    return;
                window.open(uploadingFile.url, "_blank");
                return;
            }
            case FileMenuOption.Delete: {
                if (!uploadingFile.url || !uploadingFile.key)
                    return;
                await deleteFile(uploadingFile.url);
                if (uploadingFile.key === "sideImageUrls" && uploadingFile.url) {
                    const newSideImageUrls = deviceInfo.deviceDetail.sideImageUrls?.filter((url) => url !== uploadingFile.url);
                    dispatch(setDeviceDetails({
                        [uploadingFile.key]: newSideImageUrls,
                    }));
                    setUploadingFile(defaultUploadingFile);
                    return;
                }
                dispatch(setDeviceDetails({
                    [uploadingFile.key]: "",
                }));
                setUploadingFile(defaultUploadingFile);
                return;
            }
        }
    };
    return (_jsx(PageLayoutWithTabAndBack, { heading: "Oral Fluid Drug Screen Devices", children: _jsxs(NewDeviceContainer, { children: [_jsxs(CreateFormWrapper, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(CustomForm, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, onFinishFailed: () => {
                                window.scrollTo(0, 0);
                                message.error("The form has not been finished yet");
                            }, onFinish: async () => {
                                if (!deviceInfo.deviceDetail.expireAt) {
                                    message.error("Please select expiration date");
                                    return;
                                }
                                let currentId = searchParams.get("id");
                                dispatch(setIsLoading(true));
                                if (view === DeviceManagerView.Update) {
                                    await dispatch(updateOralScreenTestingDevice({
                                        ...pick(deviceInfo.deviceDetail, Object.keys(initialOralTestingDeviceDetails.deviceDetail)),
                                        id: deviceInfo.deviceDetail.id,
                                        testTypeId: testTypes[TestTypeRecordIndex.RapidOralFluidDrugScreen],
                                    }));
                                }
                                else {
                                    const payload = await dispatch(createOralScreenTestingDevice({
                                        ...pick(deviceInfo.deviceDetail, Object.keys(initialOralTestingDeviceDetails.deviceDetail)),
                                        testTypeId: testTypes[TestTypeRecordIndex.RapidOralFluidDrugScreen],
                                    })).unwrap();
                                    if (payload.data) {
                                        currentId = payload.data;
                                    }
                                    if (payload.error) {
                                        message.error(payload.error);
                                        dispatch(setIsLoading(false));
                                        return;
                                    }
                                }
                                dispatch(setIsLoading(false));
                                navigate(`/testing-devices/oral/screen-device/update?id=${currentId}`, {
                                    replace: true,
                                });
                            }, children: [_jsxs(HeaderRow, { children: [_jsx(SAColumn, { span: 12, children: _jsx(SAFormItem, { label: "Device Name", name: "deviceName", requiredText: "Please fill in the device name", children: _jsx(StyledInput, { onChange: (event) => {
                                                        dispatch(setDeviceDetails({
                                                            deviceName: event.target.value,
                                                        }));
                                                    } }) }) }), _jsx(SAColumn, { span: 6, children: _jsx(SAFormItem, { name: "isInUse", valuePropName: "checked", children: _jsx(SASwitch, { uncheckedLabel: "In Use", onChange: (isChecked) => {
                                                        dispatch(setDeviceDetails({
                                                            isInUse: isChecked,
                                                        }));
                                                    } }) }) }), _jsx(SAColumn, { span: 3, children: _jsx(SAFormItem, { children: _jsx(CustomButton, { danger: true, onClick: () => goBackToDashboard("oral-screen"), children: "Cancel" }) }) }), _jsx(SAColumn, { span: 3, children: _jsx(SAFormItem, { children: _jsx(CustomButton, { type: "primary", htmlType: "submit", children: "Save" }) }) })] }), _jsxs(SARow, { children: [_jsx(SADivider, {}), _jsxs(SAColumn, { span: 12, children: [_jsx(SAFormItem, { label: "Lot Number", name: "lotNumber", requiredText: "Please fill in the lot number", children: _jsx(StyledInput, { onChange: (event) => {
                                                            dispatch(setDeviceDetails({
                                                                lotNumber: event.target.value,
                                                            }));
                                                        } }) }), _jsx(SAFormItem, { label: "Expiry Date", requiredText: "Please select the expiry date", children: _jsx(CustomDatePicker, { format: "DD MMM YYYY", value: deviceInfo.deviceDetail.expireAt
                                                            ? moment(deviceInfo.deviceDetail.expireAt)
                                                            : null, onChange: (selectedDate) => {
                                                            if (!selectedDate)
                                                                return;
                                                            dispatch(setDeviceDetails({
                                                                expireAt: new Date(selectedDate.toISOString()),
                                                            }));
                                                        } }) }), _jsx(SAFormItem, { label: "Expiry Date Reminder", name: "isReminderExpire", valuePropName: "checked", children: _jsxs(SpaceBetweenContainer, { children: [_jsx(SASwitch, { checked: deviceInfo.deviceDetail.isReminderExpire, onChange: (isChecked) => {
                                                                    dispatch(setDeviceDetails({
                                                                        isReminderExpire: isChecked,
                                                                    }));
                                                                } }), _jsx(CustomDatePicker, { format: "DD MMM YYYY", value: deviceInfo.deviceDetail.reminderAt
                                                                    ? moment(deviceInfo.deviceDetail.reminderAt)
                                                                    : null, disabled: !deviceInfo.deviceDetail.isReminderExpire, onChange: (reminderAt) => {
                                                                    dispatch(setDeviceDetails({
                                                                        reminderAt: reminderAt?.toDate(),
                                                                    }));
                                                                } })] }) }), _jsx(SAFormItem, { label: "Service Office/Clinic", name: "collectionPointIds", requiredText: "Please select an office", children: _jsx(TreeSelect, { treeData: [
                                                            {
                                                                title: collectionOrg.collectionName,
                                                                value: collectionOrg.id,
                                                                children: collectionPoints.map((point) => ({
                                                                    title: point.collectionPointName,
                                                                    value: point.id,
                                                                })),
                                                            },
                                                        ], treeCheckable: true, showCheckedStrategy: TreeSelect.SHOW_CHILD, treeDefaultExpandAll: true, placeholder: "Please select collection points", onChange: (selectedIds) => {
                                                            dispatch(setDeviceDetails({
                                                                collectionPointIds: selectedIds,
                                                            }));
                                                        }, style: { width: "100%" } }) })] }), _jsx(SAColumn, { span: 6, children: _jsx(SAFormItem, { label: "Picture of the Device", name: "fontImageUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.deviceDetail.fontImageUrl, onMenuItemClick: (option) => {
                                                        setUploadingFile({
                                                            url: deviceInfo.deviceDetail.fontImageUrl ?? null,
                                                            key: "fontImageUrl",
                                                            fileType: UploadFileType.Image,
                                                        });
                                                        onMenuItemClick(option);
                                                    }, Fallback: OralCupIcon, size: "large", type: UploadFileType.Image }) }) }), _jsxs(SAColumn, { span: 3, children: [_jsx(SAFormItem, { label: " ", name: "sideImageUrls[0]", children: _jsx(FileOptionsDropdown, { src: deviceInfo.deviceDetail.sideImageUrls
                                                            ? deviceInfo.deviceDetail.sideImageUrls[0]
                                                            : "", onMenuItemClick: (option) => {
                                                            const validUrl = deviceInfo.deviceDetail.sideImageUrls
                                                                ? deviceInfo.deviceDetail.sideImageUrls[0]
                                                                : null;
                                                            setUploadingFile({
                                                                url: validUrl,
                                                                key: "sideImageUrls",
                                                                fileType: UploadFileType.Image,
                                                            });
                                                            onMenuItemClick(option);
                                                        }, Fallback: OralCupIcon, size: "small", type: UploadFileType.Image }) }), _jsx(SAFormItem, { name: "sideImageUrls[1]", children: _jsx(FileOptionsDropdown, { src: deviceInfo.deviceDetail.sideImageUrls
                                                            ? deviceInfo.deviceDetail.sideImageUrls[1]
                                                            : "", onMenuItemClick: (option) => {
                                                            const validUrl = deviceInfo.deviceDetail.sideImageUrls
                                                                ? deviceInfo.deviceDetail.sideImageUrls[1]
                                                                : null;
                                                            setUploadingFile({
                                                                url: validUrl,
                                                                key: "sideImageUrls",
                                                                fileType: UploadFileType.Image,
                                                            });
                                                            onMenuItemClick(option);
                                                        }, Fallback: OralCupIcon, size: "small", type: UploadFileType.Image }) })] }), _jsxs(SAColumn, { span: 3, children: [_jsx(SAFormItem, { label: " ", name: "sideImageUrls[2]", children: _jsx(FileOptionsDropdown, { src: deviceInfo.deviceDetail.sideImageUrls
                                                            ? deviceInfo.deviceDetail.sideImageUrls[2]
                                                            : "", onMenuItemClick: (option) => {
                                                            const validUrl = deviceInfo.deviceDetail.sideImageUrls
                                                                ? deviceInfo.deviceDetail.sideImageUrls[2]
                                                                : null;
                                                            setUploadingFile({
                                                                url: validUrl,
                                                                key: "sideImageUrls",
                                                                fileType: UploadFileType.Image,
                                                            });
                                                            onMenuItemClick(option);
                                                        }, Fallback: OralCupIcon, size: "small", type: UploadFileType.Image }) }), _jsx(SAFormItem, { name: "instructionUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.deviceDetail.instructionUrl, onMenuItemClick: (option) => {
                                                            setUploadingFile({
                                                                url: deviceInfo.deviceDetail.instructionUrl ?? null,
                                                                key: "instructionUrl",
                                                                fileType: UploadFileType.Pdf,
                                                            });
                                                            onMenuItemClick(option);
                                                        }, Fallback: deviceInfo.deviceDetail.instructionUrl
                                                            ? OperatedInstructionIcon
                                                            : InstructionIcon, size: "small", type: UploadFileType.Pdf }) })] }), _jsx(SAColumn, { children: _jsx(SADivider, {}) }), _jsxs(SAColumn, { span: 12, children: [_jsx(SAFormItem, { label: "Sponsor", name: "sponsor", children: _jsx(StyledInput, { onChange: (event) => {
                                                            dispatch(setDeviceDetails({
                                                                sponsor: event.target.value,
                                                            }));
                                                        } }) }), _jsx(SAFormItem, { label: "Manufacturer", name: "manufacturer", children: _jsx(StyledInput, { onChange: (event) => {
                                                            dispatch(setDeviceDetails({
                                                                manufacturer: event.target.value,
                                                            }));
                                                        } }) })] }), _jsx(SAColumn, { span: 4, children: _jsx(SAFormItem, { label: "Laboratory Verification Sheet", name: "laboratorySheetUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.deviceDetail.laboratorySheetUrl, onMenuItemClick: (option) => {
                                                        setUploadingFile({
                                                            url: deviceInfo.deviceDetail.laboratorySheetUrl ?? null,
                                                            key: "laboratorySheetUrl",
                                                            fileType: UploadFileType.Pdf,
                                                        });
                                                        onMenuItemClick(option);
                                                    }, Fallback: deviceInfo.deviceDetail.laboratorySheetUrl ? UploadedPdfIcon : PdfIcon, type: UploadFileType.Pdf }) }) }), _jsx(SAColumn, { span: 4, children: _jsx(SAFormItem, { label: "Specification Sheet", name: "specificationSheetUrl", children: _jsx(FileOptionsDropdown, { src: deviceInfo.deviceDetail.specificationSheetUrl, onMenuItemClick: (option) => {
                                                        setUploadingFile({
                                                            url: deviceInfo.deviceDetail.specificationSheetUrl ?? null,
                                                            key: "specificationSheetUrl",
                                                            fileType: UploadFileType.Pdf,
                                                        });
                                                        onMenuItemClick(option);
                                                    }, Fallback: deviceInfo.deviceDetail.specificationSheetUrl ? UploadedPdfIcon : PdfIcon, type: UploadFileType.Pdf }) }) })] })] }), _jsx(FileUploadDrawer, { isOpen: isOpenUploadPanel, setOpen: setIsOpenUploadPanel, uploadPrefix: UploadPrefix.OralScreenDevice, uploadUrl: uploadingFile.url, type: uploadingFile.fileType, setUploadUrl: async (uploadedUrl) => {
                                setUploadingFile((preState) => ({
                                    ...preState,
                                    url: uploadedUrl,
                                }));
                            }, onSaveFile: async () => {
                                if (!uploadingFile.key)
                                    return;
                                dispatch(setIsLoading(true));
                                if (uploadingFile.key === "sideImageUrls" && uploadingFile.url) {
                                    const sideImageUrls = [
                                        ...(deviceInfo.deviceDetail.sideImageUrls ?? []),
                                    ];
                                    sideImageUrls.push(uploadingFile.url);
                                    dispatch(setDeviceDetails({
                                        [uploadingFile.key]: sideImageUrls,
                                    }));
                                }
                                else {
                                    dispatch(setDeviceDetails({
                                        [uploadingFile.key]: uploadingFile.url,
                                    }));
                                }
                                setIsOpenUploadPanel(false);
                                setUploadingFile(defaultUploadingFile);
                                dispatch(setIsLoading(false));
                            } })] }), testingDeviceId && (_jsxs(_Fragment, { children: [_jsx(StockManagement, { dataSource: {
                                stockData: deviceInfo.stockDetail.inventoryItems,
                                stockOverview: deviceInfo.stockDetail.inventoryOverview,
                            }, onSubmit: onSubmit, testTypeRecordIndex: TestTypeRecordIndex.RapidOralFluidDrugScreen, type: TestingDeviceTypes.Device, testingDeviceId: testingDeviceId, onChangePagination: (page, limit) => {
                                dispatch(getOralScreenTestingDeviceById({ testingDeviceId, page, limit }));
                            } }), _jsx(HistoricalStockRecords, { testTypeRecordIndex: TestTypeRecordIndex.RapidOralFluidDrugScreen, type: TestingDeviceTypes.Device, showHistoricalRecords: showHistoricalRecords, setShowHistoricalRecords: setShowHistoricalRecords, requireProductName: false, testingDeviceId: testingDeviceId })] }))] }) }));
};
export default OralDeviceManager;
const NewDeviceContainer = styled.div `
  background-color: #edeef0;
  margin: 2.5rem 2rem;
  height: 100%;
  padding: 2rem;
  border-radius: 0.25rem;
`;
const CreateFormWrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;
const CustomForm = styled(Form) `
  width: 100%;
`;
const StyledInput = styled(Input) `
  width: 100%;

  &:disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    cursor: not-allowed !important;
  }
`;
const CustomButton = styled(Button) `
  width: 100%;
  border: 0;
`;
const HeaderRow = styled(SARow) `
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
const CustomDatePicker = styled(DatePicker) `
  width: 100%;
`;
