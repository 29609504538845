import { createAsyncThunk } from "@reduxjs/toolkit";
import { omit } from "lodash";
import { stringify } from "qs";
import { $delete, $get, $post, $put } from "src/infra/http";
import { Domain } from "src/types";
const getListLicenseOrganizations = createAsyncThunk("kioskManagement/getListLicenseOrganizations", async () => await $get(`/platform/welcome-assist/license`, Domain.CM));
const getListLicenseByCollectionPoint = createAsyncThunk("kioskManagement/getListLicenseByCollectionPoint", async (_, thunkApi) => {
    const { selectedCollectionPoint } = thunkApi.getState().platformManagementPage.welcomeAssist.kioskManagement;
    return await $get(`/platform/welcome-assist/license/${selectedCollectionPoint.id}`, Domain.CM);
});
const deactivateLicense = createAsyncThunk("kioskManagement/deactivateLicense", async (licenseId) => await $delete(`/platform/welcome-assist/license/deactivate/${licenseId}`, Domain.CM));
const addNewLicenses = createAsyncThunk("kioskManagement/addNewLicenses", async (noNewLicense, thunkApi) => {
    const { selectedCollectionPoint } = thunkApi.getState().platformManagementPage.welcomeAssist.kioskManagement;
    const licenseData = {
        collectionPointId: selectedCollectionPoint.id,
        noNewLicense,
    };
    return await $post(`/platform/welcome-assist/license/add`, licenseData, Domain.CM);
});
const removeLicenseManager = createAsyncThunk("kioskManagement/removeLicenseManager", async (userId) => await $delete(`/platform/welcome-assist/manager/${userId}`, Domain.CM));
const inviteLicenseManagers = createAsyncThunk("kioskManagement/inviteLicenseManagers", async (requestedManagers, thunkApi) => {
    const { selectedCollectionPoint } = thunkApi.getState().platformManagementPage.welcomeAssist.kioskManagement;
    const managers = requestedManagers.map((manager) => ({
        ...manager,
        collectionPointId: selectedCollectionPoint.id,
    }));
    return await $post(`/platform/welcome-assist/invite`, { managers }, Domain.CM);
});
const getManagerInformation = createAsyncThunk("kioskManagement/getManagerInformation", async (userId) => await $get(`/platform/welcome-assist/manager/${userId}`, Domain.CM));
const updateLicenseManager = createAsyncThunk("kioskManagement/updateLicenseManager", async (updateManager) => await $put(`/platform/welcome-assist/manager/${updateManager.userId}`, {
    email: updateManager.email,
    firstName: updateManager.firstName,
    lastName: updateManager.lastName,
    mobile: updateManager.mobile,
}, Domain.CM));
const resetPasswordLicenseManager = createAsyncThunk("kioskManagement/resetPasswordLicenseManager", async (userId) => await $get(`/platform/welcome-assist/manager/${userId}/reset-password`, Domain.CM));
const sendMailUpdatedPassword = createAsyncThunk("kioskManagement/sendMailUpdatedPassword", async (userId) => await $get(`/platform/welcome-assist/manager/${userId}/reset-password/send-mail`, Domain.CM));
const resetPinLicenseManager = createAsyncThunk("kioskManagement/resetPinLicenseManager", async (userId) => await $get(`/platform/welcome-assist/manager/${userId}/reset-pin`, Domain.CM));
const sendMailUpdatedPin = createAsyncThunk("kioskManagement/sendMailUpdatedPin", async (userId) => await $get(`/platform/welcome-assist/manager/${userId}/reset-pin/send-mail`, Domain.CM));
const createKioskMessage = createAsyncThunk("kioskManagement/createKioskMessage", async (messageData) => await $post(`/platform/welcome-assist/message`, messageData, Domain.CM));
const getListKioskMessages = createAsyncThunk("kioskManagement/getListKioskMessages", async (data) => await $post(`/platform/welcome-assist/messages`, data, Domain.CM));
const getKioskMessageById = createAsyncThunk("kioskManagement/getKioskMessageById", async (messageId) => await $get(`/platform/welcome-assist/message/${messageId}`, Domain.CM));
const updateKioskMessage = createAsyncThunk("kioskManagement/updateKioskMessage", async (updateData) => await $put(`/platform/welcome-assist/message/${updateData.id}`, omit(updateData, "id"), Domain.CM));
const getEntryManagementDashboardData = createAsyncThunk("kioskManagement/getEntryManagementDashboardData", async () => await $get(`/platform/welcome-assist/entry/dashboard`, Domain.CM));
const getEntryManagementRegisterData = createAsyncThunk("kioskManagement/getEntryManagementRegisterData", async (data) => await $get(`/platform/welcome-assist/entry/register?${stringify(data)}`, Domain.CM));
export { addNewLicenses, createKioskMessage, deactivateLicense, getEntryManagementDashboardData, getEntryManagementRegisterData, getKioskMessageById, getListKioskMessages, getListLicenseByCollectionPoint, getListLicenseOrganizations, getManagerInformation, inviteLicenseManagers, removeLicenseManager, resetPasswordLicenseManager, resetPinLicenseManager, sendMailUpdatedPassword, sendMailUpdatedPin, updateKioskMessage, updateLicenseManager, };
