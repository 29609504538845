import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Form, Input, Space, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppSelector } from "src/store/hooks";
import { InfoIcon } from "src/assets/svg/accounting";
import { selectIsIncludedGst } from "../../Dashboard/store/selector";
import { selectDisplayCost, selectLicensingInvoice } from "../store/selector";
import EstimateLicensePriceModal from "./EstimateLicensePriceModal";
const WelcomeAssistBox = () => {
    const [form] = Form.useForm();
    const displayCost = useAppSelector(selectDisplayCost);
    const invoice = useAppSelector(selectLicensingInvoice);
    const isIncludedGst = useAppSelector(selectIsIncludedGst);
    const { welcomeAssist, startAt, endAt } = invoice;
    const [totalCost, setTotalCost] = React.useState(0);
    const [isOpenPriceInfo, setIsOpenPriceInfo] = React.useState(false);
    const estimateLicenseTotalCost = (noLicenses, unitCost) => {
        const durationInDays = moment(endAt, "DD MMMM YYYY").diff(moment(startAt, "DD MMMM YYYY"), "days");
        return noLicenses * unitCost * durationInDays || 0;
    };
    React.useEffect(() => {
        if (!welcomeAssist)
            return;
        const licenseTotalCost = estimateLicenseTotalCost(welcomeAssist.noLicenses, Number(welcomeAssist[displayCost]));
        setTotalCost(licenseTotalCost);
    }, [welcomeAssist, displayCost]);
    return (_jsxs(PackageContentRow, { children: [_jsx(SAColumn, { md: 24, lg: 4, align: "start", children: _jsxs(Space, { direction: "vertical", children: [_jsx(BoxHeading, { children: "Welcome Assist" }), _jsx(Typography.Text, { children: "Express Collection Management" })] }) }), _jsx(SAColumn, { md: 24, lg: 20, children: _jsx(CustomForm, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, colon: false, children: _jsxs(SARow, { children: [_jsx(SAColumn, { md: 12, lg: 4, children: _jsx(SAFormItem, { label: "No. Of Users", children: _jsx(Input, { disabled: true, value: welcomeAssist.noDevices }) }) }), _jsx(SAColumn, { md: 12, lg: 4, children: _jsx(SAFormItem, { label: "No. Of Licenses", children: _jsx(Input, { value: welcomeAssist.noLicenses, readOnly: true }) }) }), _jsxs(SAColumn, { md: 12, lg: 8, justify: "end", children: [_jsxs(Space, { children: [_jsx(Typography.Text, { children: "One License per Day" }), _jsxs(Typography.Text, { children: ["$ ", welcomeAssist[displayCost]] }), _jsx(Button, { type: "text", icon: _jsx(InfoIcon, {}), onClick: () => setIsOpenPriceInfo(true) })] }), _jsx(Divider, {})] }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsxs(PriceBox, { children: [_jsxs(PriceText, { span: 21, children: [" $ ", totalCost.toFixed(2)] }), _jsxs(GSTText, { span: 3, children: [" GST ", isIncludedGst ? "inclusive" : "exclusive"] })] }) }), _jsx(SAColumn, { span: 24, children: _jsx(Divider, {}) }), _jsx(SAColumn, { span: 18, align: "start", children: _jsxs(Typography.Text, { children: ["To manage license, please visit ", _jsx(Link, { to: "/kiosk", children: "Kiosk Management" }), "."] }) })] }) }) }), _jsx(EstimateLicensePriceModal, { isOpen: isOpenPriceInfo, setOpen: setIsOpenPriceInfo, unitCost: welcomeAssist[displayCost], isIncludedGst: isIncludedGst, productName: "Welcome Assist" })] }));
};
export default WelcomeAssistBox;
const PackageContentRow = styled(SARow) `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 2rem 1.5rem;
  width: 100%;
  justify-content: space-between;
  margin-block: 2rem;
`;
const CustomForm = styled(Form) `
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: 100%;
  width: 100%;

  input {
    text-align: center;
    padding: 0.5rem 0;
  }
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #00004c;
`;
const PriceBox = styled(SARow) `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #f7f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled(SAColumn) `
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  text-align: center;
  width: 100%;
`;
const GSTText = styled(PriceText) `
  font-size: 0.75rem;
  white-space: break-spaces;
  font-weight: 500;
`;
