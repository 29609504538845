import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { Col, Form, Input, Row, Typography } from "antd";
import * as React from "react";
import { SAColourScheme, supportingColours } from "src/constants";
const GeneralOrganizationInfo = ({ serviceOwnerName = "", serviceManagerName = "", medicalProviderNumber = "", abn = "", organizationName = "", }) => (_jsxs(PageWrapper, { children: [_jsx(Title, { children: organizationName }), _jsx(Form, { labelCol: { span: 24 }, wrapperCol: { span: 24 }, children: _jsx(InputWrapper, { children: _jsxs(Row, { children: [_jsx(Col, { span: 24, children: _jsx(StyledFormItem, { label: "Service Owner", children: _jsx(Input, { readOnly: true, value: serviceOwnerName }) }) }), _jsx(Col, { span: 24, children: _jsx(StyledFormItem, { label: "Service Manager", children: _jsx(Input, { readOnly: true, value: serviceManagerName }) }) }), _jsx(Col, { span: 24, children: _jsx(StyledFormItem, { label: "Medical Provider Number", children: _jsx(Input, { readOnly: true, value: medicalProviderNumber }) }) }), _jsx(Col, { span: 24, children: _jsx(StyledFormItem, { label: "Australia Business Number (ABN)", children: _jsx(Input, { readOnly: true, value: abn }) }) }), _jsxs(Col, { span: 24, children: [_jsx(StyledFormItem, { label: "Sample Assist Account Manager", children: _jsx(Input, { readOnly: true }) }), _jsx(StyledFormItem, { children: _jsx(Input, { readOnly: true }) }), _jsx(StyledFormItem, { children: _jsx(Input, { readOnly: true }) })] })] }) }) })] }));
export default GeneralOrganizationInfo;
const StyledFormItem = styled(Form.Item) `
  .ant-form-item-label {
    padding: 0px;
  }

  label {
    margin-bottom: 5px !important;
    color: ${supportingColours.TEXT_LIGHT_GRAY};
  }
`;
const PageWrapper = styled.div `
  background-color: ${SAColourScheme.GRAY_BRIGHT};
  height: 100%;
`;
const InputWrapper = styled.div `
  padding: 20px 15px;
`;
const Title = styled(Typography) `
  text-align: center;
  padding: 10px;
  background-color: ${SAColourScheme.PLASMA};
  color: white;
  font-size: 16px;
  font-weight: 500;
`;
