var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["NotPaid"] = "NotPaid";
    InvoiceStatus["Paid"] = "Paid";
    InvoiceStatus["OverDue"] = "OverDue";
})(InvoiceStatus || (InvoiceStatus = {}));
const InvoiceStatusOptions = {
    [InvoiceStatus.NotPaid]: { label: "Not Paid", color: "blue" },
    [InvoiceStatus.OverDue]: { label: "Over Due", color: "red" },
    [InvoiceStatus.Paid]: { label: "Paid", color: "green" },
};
var InvoiceDisplayYear;
(function (InvoiceDisplayYear) {
    InvoiceDisplayYear[InvoiceDisplayYear["CalendarYear"] = 1] = "CalendarYear";
    InvoiceDisplayYear[InvoiceDisplayYear["FinancialYear"] = 2] = "FinancialYear";
})(InvoiceDisplayYear || (InvoiceDisplayYear = {}));
export { InvoiceStatus, InvoiceStatusOptions, InvoiceDisplayYear, };
