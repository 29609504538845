import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get } from "src/infra/http";
import { Domain } from "src/types";
const getInventoryDetail = createAsyncThunk("inventory/getInventoryDetail", async (data) => await $get(`/inventory?${stringify(data)}`, Domain.CM));
const getTransportSatchel = createAsyncThunk("inventory/getTransportSatchel", async () => await $get(`/inventory/satchels`, Domain.CM));
const createQREntry = createAsyncThunk("inventory/createQrEntry", async () => await $get(`/collection-manager/inventory/qr/create`));
const getScannedQRCode = createAsyncThunk("inventory/getScannedQRCode", async (id) => await $get(`/inventory/qr-code/${id}`, Domain.CM));
const getStockInventoryDetail = createAsyncThunk("inventory/getStockInventoryDetail", async (data) => await $get(`/inventory/stock-summary?${stringify(data)}`, Domain.CM));
const getListDeviceTypes = createAsyncThunk("inventory/getListDeviceTypes", async () => await $get(`/inventory/device-type`, Domain.CM));
const exportStockRecords = createAsyncThunk("inventory/exportStockRecords", async (filters) => {
    const queryString = stringify(filters);
    return await $get(`/inventory/detail-export?${queryString}`, Domain.CM, {
        responseType: "blob",
    });
});
export { createQREntry, getInventoryDetail, getListDeviceTypes, getScannedQRCode, getStockInventoryDetail, getTransportSatchel, exportStockRecords, };
