import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { MessageDuration, } from "src/pages/CollectionOrganization/ServiceOffice/store/types";
import { EmptyLaboratory, } from "src/pages/CollectionOrganization/Laboratory/store/types";
import { createLaboratory, deleteOrganizationLaboratory, getLaboratories, updateLaboratory, } from "src/pages/CollectionOrganization/Laboratory/store/action";
export const initialState = {
    listLaboratory: [],
    searchKeyWord: "",
    organizationDetail: {},
    selectedLaboratory: EmptyLaboratory,
    isOpen: false,
    pagingMetaData: null,
};
const laboratorySlice = createSlice({
    name: "laboratory",
    initialState,
    reducers: {
        setResetSelectedLaboratory(state) {
            state.selectedLaboratory = initialState.selectedLaboratory;
        },
        setResetStore() {
            return initialState;
        },
        setSearchKeyWord(state, action) {
            state.searchKeyWord = action.payload;
        },
        setSelectedLaboratory(state, action) {
            state.selectedLaboratory = action.payload;
        },
        setIsOpen(state, action) {
            state.isOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLaboratories.fulfilled, (state, action) => {
            if (action.payload.status !== "success" || !action.payload.data) {
                state.listLaboratory = initialState.listLaboratory;
                state.organizationDetail = initialState.organizationDetail;
            }
            else {
                state.listLaboratory = action.payload.data.laboratories;
                state.organizationDetail = action.payload.data.organizationDetail;
                state.pagingMetaData = action.payload.pagingMeta;
            }
        })
            .addCase(getLaboratories.rejected, () => {
            message.error("Failed to fetch laboratory list");
        })
            .addCase(updateLaboratory.fulfilled, (state, action) => {
            if (action.payload.status !== "success") {
                message.error("Failed to update laboratory", MessageDuration.SHORT);
            }
            else {
                message.success("Laboratory updated successfully");
            }
        })
            .addCase(updateLaboratory.rejected, () => {
            message.error("Failed to update laboratory");
        })
            .addCase(createLaboratory.fulfilled, (state, action) => {
            if (action.payload.status !== "success") {
                message.error("Failed to create laboratory", MessageDuration.SHORT);
            }
            else {
                message.success("Laboratory created successfully");
            }
        })
            .addCase(createLaboratory.rejected, () => {
            message.error("Failed to create laboratory", MessageDuration.SHORT);
        })
            .addCase(deleteOrganizationLaboratory.fulfilled, (state, action) => {
            if (action.payload.status !== "success") {
                message.error("Failed to delete laboratory", MessageDuration.SHORT);
            }
            else {
                message.success("Laboratory deleted successfully");
            }
        })
            .addCase(deleteOrganizationLaboratory.rejected, () => {
            message.error("Failed to delete laboratory");
        });
    },
});
export const { setResetStore, setSearchKeyWord, setResetSelectedLaboratory, setSelectedLaboratory, setIsOpen, } = laboratorySlice.actions;
export default laboratorySlice.reducer;
