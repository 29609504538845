/**
 * Triggers the download of a file from a given URL.
 * @param url - The URL of the file to download (can be a Blob URL or direct URL).
 * @param fileName - The name to save the file as.
 */
const triggerFileDownload = (url, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};
/**
 * Downloads a file from a Blob object.
 * @param blob - The Blob object representing the file data.
 * @param fileName - The name to save the file as.
 */
const downloadFromBlob = (blob, fileName) => {
    const blobUrl = window.URL.createObjectURL(blob);
    triggerFileDownload(blobUrl, fileName);
    window.URL.revokeObjectURL(blobUrl); // Clean up memory
};
/**
 * Downloads a file from a direct URL.
 * @param url - The direct URL of the file (e.g., S3 or public link).
 * @param fileName - The name to save the file as.
 */
const downloadFromUrl = (url, fileName) => {
    triggerFileDownload(url, fileName);
};
export { triggerFileDownload, downloadFromBlob, downloadFromUrl };
