// Generate calendar month based on week view.
import moment from "moment";
import { TimeFormat } from "src/store/app/types";
// Return 5 arrays of weeks and 7 days in each week array
export const generateCalendarMonth = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const weeks = [[]];
    let currentWeekIndex = 0;
    const daysInPreviousMonth = new Date(year, month, 0).getDate();
    const startDay = daysInPreviousMonth - firstDayOfMonth + 2;
    for (let day = startDay; day <= daysInPreviousMonth; day++) {
        weeks[currentWeekIndex].push(new Date(year, month - 1, day));
    }
    for (let day = 1; day <= daysInMonth; day++) {
        if (weeks[currentWeekIndex].length === 7) {
            currentWeekIndex++;
            weeks[currentWeekIndex] = [];
        }
        weeks[currentWeekIndex].push(new Date(year, month, day));
    }
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();
    const remainingDays = lastDayOfMonth === 0 ? 6 : 6 - lastDayOfMonth + 1;
    for (let day = 1; day <= remainingDays; day++) {
        if (weeks[currentWeekIndex].length === 7) {
            currentWeekIndex++;
            weeks[currentWeekIndex] = [];
        }
        weeks[currentWeekIndex].push(new Date(year, month + 1, day));
    }
    if (weeks.length > 5)
        weeks.splice(5);
    return weeks;
};
export const getFullName = (data) => {
    const nameParts = [data.firstName, data.lastName];
    return nameParts
        .filter((part) => part && typeof part === "string")
        .join(" ")
        .trim();
};
export const getCurrentFinancialYear = () => {
    const currentDate = moment();
    const financialYearStartMonth = 6;
    let startOfFinancialYear;
    let endOfFinancialYear;
    if (currentDate.month() >= financialYearStartMonth) {
        startOfFinancialYear = moment(currentDate).month(financialYearStartMonth).startOf("month");
        endOfFinancialYear = moment(currentDate)
            .add(1, "year")
            .month(financialYearStartMonth - 1)
            .endOf("month");
    }
    else {
        startOfFinancialYear = moment(currentDate)
            .subtract(1, "year")
            .month(financialYearStartMonth)
            .startOf("month");
        endOfFinancialYear = moment(currentDate)
            .month(financialYearStartMonth - 1)
            .endOf("month");
    }
    return { startOfFinancialYear, endOfFinancialYear };
};
export const getCurrentCalendarYear = () => {
    const startOfYear = moment().startOf("year");
    const endOfYear = moment().endOf("year");
    return { startOfYear, endOfYear };
};
export const getCurrentQuarter = () => {
    const startOfQuarter = moment().startOf("quarter");
    const endOfQuarter = moment().endOf("quarter");
    return { startOfQuarter, endOfQuarter };
};
export const getDateByFormat = (date, format) => moment(date).format(`DD MMM YYYY ${format === TimeFormat.TwelveHour ? "hh:mm A" : "HH:mm"}`);
