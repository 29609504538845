import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import useExtendPath from "src/hooks/useExtendPath";
import ComingSoonPage from "src/pages/PageErrors/ComingSoonPage";
import OnsiteCollection from "./OnsiteCollections";
const CollectionDashboard = () => {
    const location = useLocation();
    const appendPath = useExtendPath();
    React.useEffect(() => {
        if (location.pathname === "/dashboard/collections") {
            appendPath("onsite");
        }
    }, [location]);
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/onsite", element: _jsx(OnsiteCollection, {}) }), _jsx(Route, { path: "/inclinic", element: _jsx(ComingSoonPage, {}) })] }));
};
export default CollectionDashboard;
