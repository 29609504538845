var KioskLicenseStatus;
(function (KioskLicenseStatus) {
    KioskLicenseStatus[KioskLicenseStatus["INACTIVE"] = 0] = "INACTIVE";
    KioskLicenseStatus[KioskLicenseStatus["ACTIVE"] = 1] = "ACTIVE";
})(KioskLicenseStatus || (KioskLicenseStatus = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus[UserStatus["INACTIVE"] = 0] = "INACTIVE";
    UserStatus[UserStatus["ACTIVE"] = 1] = "ACTIVE";
    UserStatus[UserStatus["DELETED"] = 2] = "DELETED";
    UserStatus[UserStatus["TEMPORARY"] = 3] = "TEMPORARY";
})(UserStatus || (UserStatus = {}));
const UserStatusMapping = {
    [UserStatus.INACTIVE]: { status: "Invited", color: "#1A8CFF" },
    [UserStatus.ACTIVE]: { status: "Onboarded", color: "#5CD22B" },
    [UserStatus.TEMPORARY]: { status: "Suspended", color: "#FD0000" },
    [UserStatus.DELETED]: { status: "Deleted", color: "grey" },
};
var WelcomeAssistError;
(function (WelcomeAssistError) {
    WelcomeAssistError["EA001"] = "EA001";
})(WelcomeAssistError || (WelcomeAssistError = {}));
export { KioskLicenseStatus, UserStatus, UserStatusMapping, WelcomeAssistError, };
