import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Space } from "antd";
import React from "react";
import styled from "styled-components";
import AccountIcon from "src/assets/svg/account-icon.svg";
import MyAccountIcon from "src/assets/svg/my-account-icon.svg";
import SignOutIcon from "src/assets/svg/sign-out-icon.svg";
import { Button, Dropdown, Menu, Row, Typography } from "src/components/base";
import { getApplicationInformation } from "src/store/app/selector";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Domain, Environment } from "src/types";
import ICMenuAppointment from "src/assets/svg/side-menu/ic-menu-appointment.svg";
import ICMenuJobs from "src/assets/svg/side-menu/ic-onsite-job.svg";
import { mainAppColours, SAColourScheme } from "src/constants";
import { $get } from "src/infra/http";
import { useHandleNavigation } from "src/hooks/useBeforeNavigate";
export const Header = () => {
    const handleNavigation = useHandleNavigation();
    const appInformation = useAppSelector(getApplicationInformation);
    const dispatch = useAppDispatch();
    const logOut = async () => {
        handleNavigation("/login", async () => {
            await $get("/cm/authenticate/logout", Domain.Auth);
            dispatch({ type: "RESET" });
        });
    };
    const renderMenu = () => (_jsxs(CustomMenu, { children: [_jsxs(Menu.Item, { children: [_jsx(Typography.Text, { children: "Hello! " }), _jsx(Typography.Text, { children: appInformation.user.firstName })] }, "1"), _jsx(CustomDivider, {}), _jsx("div", { onClick: () => {
                    handleNavigation("/user-profile");
                }, children: _jsx(Menu.Item, { children: _jsxs(Row, { justify: "space-between", align: "middle", children: [_jsx(MyAccountIcon, {}), _jsx(Typography.Text, { children: "My Account" })] }) }, "2") }), _jsx(Menu.Item, { onClick: logOut, children: _jsxs(Row, { justify: "space-between", align: "middle", children: [_jsx(SignOutIcon, {}), _jsx(Typography.Text, { children: "Sign Out" })] }) }, "4")] }));
    return (_jsxs(HeaderWrapper, { children: [_jsx(HeaderTitle, { level: 3, children: appInformation.collectionOrganization.collectionName }), process.env.ENV === Environment.sandbox && (_jsxs(UserWarningWrapper, { children: [_jsx(Typography.Text, { children: "USER WARNING \u2013 THIS IS A TEST ENVIRONMENT!" }), _jsx(Typography.Text, { children: "Some features and functions may not operate as intended." })] })), _jsxs(ButtonContainer, { children: [_jsxs(HeaderButton, { onClick: () => {
                            handleNavigation("/in-clinic-appointments/create-appointment");
                        }, children: ["+", _jsx(ICMenuAppointment, { color: "#ffffff" }), "In-Clinic Appointment"] }), _jsxs(HeaderButton, { onClick: () => {
                            handleNavigation("/job-management/onsite-jobs");
                        }, children: ["+", _jsx(ICMenuJobs, { color: "#ffffff" }), "On-Site Job"] }), _jsx(Dropdown, { overlay: () => renderMenu(), placement: "bottomRight", arrow: true, trigger: ["click"], children: _jsxs(HeaderDropdownButton, { children: [_jsxs(Typography.Title, { level: 5, children: [appInformation.user.firstName, " ", appInformation.user.lastName] }), _jsx(AccountIcon, {})] }) })] })] }));
};
const HeaderWrapper = styled.div `
  background-color: #00004c;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 38px 24px 38px;
  padding-block: 1rem;
  width: 100%;
`;
const HeaderTitle = styled(Typography.Title) `
  color: #ffffff !important;
  @media screen and (max-width: 900px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const ButtonContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
`;
const HeaderDropdownButton = styled(Button) `
  color: white;
  background: #156cc9;
  border-radius: 0.25rem;
  border: 0px;
  padding: 0.625rem;
  height: 3.125rem;
  width: 12rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  h5 {
    color: #ffffff !important;
    font-weight: 400;
  }

  &:hover,
  &:active,
  &:focus {
    background: #156cc9 !important;
  }
`;
const CustomMenu = styled(Menu) `
  padding: 0.5rem;
  span {
    color: #00004c !important;
  }
  li {
  }
`;
const CustomDivider = styled(Divider) `
  border-color: #00004c;
  margin: 0;
`;
const UserWarningWrapper = styled(Space) `
  display: flex;
  flex-direction: column;
  span {
    color: #9a1f1f !important;
  }
`;
const HeaderButton = styled(Button) `
  text-align: left;
  font-size: 0.7rem;
  padding: 0.3rem;
  height: 2.8rem;
  width: 10rem;
  background-color: ${mainAppColours.BLUE};
  color: ${SAColourScheme.WHITE};
  border-radius: 8px;
  border: 2px solid ${SAColourScheme.WHITE};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  white-space: break-spaces;
  transition: 0.1s all;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
  &:hover,
  &:active {
    background-color: #156cc5;
    color: ${SAColourScheme.WHITE};
  }

  &[disabled] {
    background-color: ${mainAppColours.BLUE} !important;
    opacity: 0.5;
  }

  @media screen and (max-width: 900px) {
    width: fit-content;
    span {
      display: none;
    }
  }
`;
