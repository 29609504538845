import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Descriptions, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import SADivider from "src/components/Form/SADivider";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { getApplicationInformation } from "src/store/app/selector";
import { useAppSelector } from "src/store/hooks";
import { CompanyLogo } from "src/assets/svg/accounting";
import PriceItem from "./components/PriceItem";
import { selectCollectionPointName, selectDisplayCost, selectFilterOptions, selectIsIncludedGst, selectOnsiteClientName, selectOverallCostSummary, } from "./store/selector";
const CostOverviewTable = () => {
    const appInformation = useAppSelector(getApplicationInformation);
    const onsiteClientName = useAppSelector(selectOnsiteClientName);
    const collectionPointName = useAppSelector(selectCollectionPointName);
    const filterOptions = useAppSelector(selectFilterOptions);
    const overallCostSummary = useAppSelector(selectOverallCostSummary);
    const isIncludedGst = useAppSelector(selectIsIncludedGst);
    const displayCost = useAppSelector(selectDisplayCost);
    // TODO: Customer ID is Tenant ID
    return (_jsxs(SummaryPanelWrapper, { children: [_jsx(SAColumn, { lg: 8, children: _jsxs(CompanyInfoContainer, { children: [_jsx(CompanyLogoWrapper, { children: _jsx(CompanyLogo, {}) }), _jsxs(InfoTextWrapper, { children: [_jsx(MainTitle, { children: appInformation.collectionOrganization.collectionName }), _jsxs(SummaryDescriptions, { column: 1, children: [_jsx(Descriptions.Item, { label: "Customer ID", children: "SAC-AU-123-456" }), _jsx(Descriptions.Item, { label: "Service Office", children: collectionPointName }), _jsx(Descriptions.Item, { label: "Client", children: onsiteClientName }), _jsx(Descriptions.Item, { label: "Currency", children: "AUD $" })] })] })] }) }), _jsx(SAColumn, { lg: 8, children: _jsxs(PriceSummaryContainer, { children: [_jsx(MainTitle, { children: "Summary" }), _jsx(WhiteDivider, {}), _jsxs(PriceCategoryContainer, { children: [_jsx(CategoryTitle, { children: "Tests" }), _jsx(PriceItem, { name: "Drug & Alcohol Tests", cost: overallCostSummary.onsiteDonor[displayCost] }), _jsx(PriceItem, { name: "Requested Laboratory Tests", cost: overallCostSummary.onsiteLab[displayCost] })] }), filterOptions.selectedCollectionPointId ||
                            filterOptions.selectedOnsiteClientId ? null : (_jsxs(React.Fragment, { children: [_jsxs(PriceCategoryContainer, { children: [_jsx(CategoryTitle, { children: "Licensing (Subscriptions)" }), _jsx(PriceItem, { name: "Collection Manager", cost: overallCostSummary.collectionManager[displayCost] }), _jsx(PriceItem, { name: "Collect Assist", cost: overallCostSummary.collectAssist[displayCost] }), _jsx(PriceItem, { name: "Secure Assist", cost: overallCostSummary.secureAssist[displayCost] }), _jsx(PriceItem, { name: "Report Assist", cost: overallCostSummary.reportAssist[displayCost] }), _jsx(PriceItem, { name: "Work Assist", cost: overallCostSummary.workAssist[displayCost] }), _jsx(PriceItem, { name: "Welcome Assist", cost: overallCostSummary.welcomeAssist[displayCost] })] }), _jsxs(PriceCategoryContainer, { children: [_jsx(CategoryTitle, { children: "IT Services (Additional Services)" }), _jsx(PriceItem, { name: "Support", cost: "0.00" }), _jsx(PriceItem, { name: "Ongoing Training", cost: overallCostSummary.training[displayCost] ?? "0.00" }), _jsx(PriceItem, { name: "SA Service Establishment", cost: overallCostSummary.serviceEstablishment[displayCost] }), _jsx(PriceItem, { name: "SA Platform Training", cost: overallCostSummary.platformTraining[displayCost] })] })] }))] }) }), _jsx(SAColumn, { lg: 8, children: _jsxs(CostOverviewContainer, { children: [_jsxs(MainTitle, { children: [filterOptions.startDate?.format("MMM YYYY"), " -", " ", filterOptions.endDate?.format("MMM YYYY"), " | GST", " ", isIncludedGst ? "inclusive" : "exclusive"] }), _jsx(WhiteDivider, {}), _jsx(TotalCostDisplay, { children: _jsxs(Typography.Title, { children: ["$ ", overallCostSummary[displayCost]] }) })] }) })] }));
};
export default CostOverviewTable;
const SummaryPanelWrapper = styled(SARow) `
  background-color: #01b4d2;
  border-radius: 0.25rem;
  min-height: 30rem;
  height: 100%;
  padding: 1rem 0.5rem;
  p {
    color: #ffffff;
  }
`;
const CompanyInfoContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const PriceSummaryContainer = styled.div `
  width: 100%;
  height: 100%;
`;
const CostOverviewContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
const CompanyLogoWrapper = styled.div `
  border: 0.025rem solid #ffffff;
  background-color: transparent;
  border-radius: 0.25rem;
  height: 50%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const InfoTextWrapper = styled.div `
  display: flex;
  width: 100%;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: flex-start;
  height: 50%;
`;
const MainTitle = styled.p `
  font-size: 1rem;
  font-weight: 700;
`;
const SummaryDescriptions = styled(Descriptions) `
  color: #ffffff;
  height: 100%;

  .ant-descriptions-item-container {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
  }

  .ant-descriptions-item-label {
    color: #ffffff;
    min-width: 10rem;
  }
  .ant-descriptions-item-content {
    color: #ffffff;
  }

  .ant-descriptions-item:last-child {
    margin-top: auto;
  }
`;
const TotalCostDisplay = styled.div `
  height: 100%;
  width: 80%;
  background-color: #ccf0f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  margin: 1rem 2rem;
`;
const PriceCategoryContainer = styled.div `
  margin-bottom: 1rem;
`;
const CategoryTitle = styled.p `
  font-size: 0.925rem;
  color: #00004c !important;
`;
const WhiteDivider = styled(SADivider) `
  border-color: #ffffff !important;
`;
