import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InfoCircleFilled, InfoOutlined } from "@ant-design/icons";
import { Button, Popover, Typography } from "antd";
import styled from "styled-components";
import { FlexStartContainer } from "src/components/Container/Flex";
import { SAColourScheme } from "src/styles";
const RemoteWorkPopOverContentWrapper = styled.div `
  max-width: 300px;
`;
const RemoteWorkPopOverContent = () => (_jsx(RemoteWorkPopOverContentWrapper, { children: _jsx(Typography.Text, { children: "The Remote Work Setup allows collectors to prepare their app for use in areas with no internet or Wi-Fi." }) }));
const RemoteWorkPopOverTitle = () => (_jsxs(FlexStartContainer, { children: [_jsx(InfoCircleFilled, { style: {
                fontSize: 20,
                color: SAColourScheme.MID_BLUE,
            } }), _jsx(Typography.Text, { strong: true, children: "Remote Work" })] }));
export const RemoteWorkInfoButton = () => (_jsx(Popover, { content: RemoteWorkPopOverContent, title: RemoteWorkPopOverTitle, children: _jsx(Button, { type: "primary", shape: "circle", icon: _jsx(InfoOutlined, {}), size: "small" }) }));
