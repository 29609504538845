import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Divider, Form, Select, Space, Typography } from "antd";
import * as React from "react";
import styled from "styled-components";
import SAFormItem from "src/components/Form/SAFormItem";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { useAppSelector } from "src/store/hooks";
import { InfoIcon } from "src/assets/svg/accounting";
import { selectIsIncludedGst } from "../store/selector";
const SupportBox = () => {
    const [form] = Form.useForm();
    const isIncludedGst = useAppSelector(selectIsIncludedGst);
    return (_jsxs(PackageContentRow, { children: [_jsxs(SAColumn, { md: 24, lg: 4, align: "start", children: [_jsx(BoxHeading, { children: "Support" }), _jsx(Typography.Text, { children: "Sample Assist Support Plans" })] }), _jsx(SAColumn, { md: 24, lg: 20, children: _jsx(CustomForm, { form: form, labelCol: { span: 24 }, wrapperCol: { span: 24 }, colon: false, children: _jsxs(SARow, { children: [_jsx(SAColumn, { md: 12, lg: 8, children: _jsx(SAFormItem, { label: "Current Subscription", children: _jsx(Select, { options: [], fieldNames: { label: "name", value: "planId" }, value: "No plans" }) }) }), _jsxs(SAColumn, { md: 12, lg: 8, justify: "end", children: [_jsxs(Space, { children: [_jsx(Typography.Text, { children: "Cost per Day" }), _jsx(Typography.Text, { children: "$ 0.00" }), _jsx(Button, { type: "text", icon: _jsx(InfoIcon, {}) })] }), _jsx(Divider, {})] }), _jsx(SAColumn, { md: 24, lg: 8, children: _jsxs(PriceBox, { children: [_jsx(PriceText, { span: 21, children: " $ 0.00" }), _jsxs(GSTText, { span: 3, children: [" GST ", isIncludedGst ? "inclusive" : "exclusive"] })] }) }), _jsx(SAColumn, { span: 24, children: _jsx(Divider, {}) }), _jsx(SAColumn, { span: 18, align: "start", children: _jsx(Typography.Text, { children: "Sample Assist offers a range of ongoing technical support plans. Sample Assist Customers can sign up for and or modify their support plans online. For bespoke training packages please contact your Sample Assist Account Manager. arrive. Get Help" }) }), _jsx(SAColumn, { span: 6, align: "end", children: _jsxs(Space, { children: [_jsx(Button, { type: "primary", disabled: true, children: "Update" }), _jsx(Button, { type: "primary", ghost: true, disabled: true, children: "Cancel" })] }) })] }) }) })] }));
};
export default SupportBox;
const PackageContentRow = styled(SARow) `
  border: 0.025rem solid #01b4d2;
  border-radius: 0.25rem;
  padding: 2rem 1.5rem;
  width: 100%;
  justify-content: space-between;
  margin-block: 2rem;
`;
const CustomForm = styled(Form) `
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: 100%;
  width: 100%;
`;
const BoxHeading = styled.h5 `
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #00004c;
`;
const PriceBox = styled(SARow) `
  border: 0;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.5rem;
  background-color: #f7f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PriceText = styled(SAColumn) `
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  text-align: center;
  width: 100%;
`;
const GSTText = styled(PriceText) `
  font-size: 0.75rem;
  white-space: break-spaces;
  font-weight: 500;
`;
