import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilePdfOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import { uniqueId } from "lodash";
import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import styled from "styled-components";
import { WelAssistTextLogo, WorkAssistTextLogo } from "src/assets/svg/app-icons";
import { Image } from "src/components/base";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import { fontWeight, SAColourScheme } from "src/constants";
import { downloadFromBlob } from "src/infra/export";
import { SARow, SAColumn } from "src/components/Form";
import { routes } from "..";
import { LanguageOptions, ResourceLanguage } from "./typing";
const ResourcesPage = () => {
    const [selectLanguage, setSelectLanguage] = useState(ResourceLanguage.English);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: routes, tabLevel: 3, children: _jsx(ResourceContainer, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { lg: 12, md: 24, children: _jsx(LanguageContainer, { children: Object.entries(LanguageOptions).map(([key, language]) => (_jsxs(Space, { children: [_jsx(FilePdfOutlined, { style: { color: "#FF2116" } }), _jsx(Button, { type: "link", onClick: async () => {
                                            setSelectLanguage(key);
                                            const response = await fetch(language.link);
                                            const blob = await response.blob();
                                            downloadFromBlob(blob, language.fileName);
                                        }, children: language.title })] }, uniqueId()))) }) }), _jsx(SAColumn, { lg: 12, md: 24, children: _jsxs(ResourcePreviewBox, { children: [_jsxs(PreviewContent, { children: [_jsx(SAColumn, { align: "end", children: _jsx(WelAssistTextLogo, {}) }), _jsx(SAColumn, { children: _jsx(Space, { direction: "vertical", children: _jsx(QrTitle, { children: LanguageOptions[selectLanguage].content }) }) }), _jsx(SAColumn, { children: _jsx(LogoContainer, { children: _jsx(WorkAssistTextLogo, {}) }) }), _jsx(SAColumn, { span: 12, children: _jsxs(AppLinksContainer, { children: [_jsx(QrCodeContainer, { children: _jsx(QRCode, { ecLevel: "M", value: "https://apps.apple.com/nz/app/work-assist/id6480581576", size: 120, logoWidth: 48, logoImage: "/assets/svg/qr-logo.svg" }) }), _jsx(AppButton, { src: "/assets/images/app-store.png", alt: "App Store" })] }) }), _jsx(SAColumn, { span: 12, children: _jsxs(AppLinksContainer, { children: [_jsx(QrCodeContainer, { children: _jsx(QRCode, { ecLevel: "M", value: "https://play.google.com/store/apps/details?id=com.sampleassist.workassist", size: 120, logoWidth: 48, logoImage: "/assets/svg/qr-logo.svg" }) }), _jsx(AppButton, { src: "/assets/images/google-play.png", alt: "Google Play" })] }) })] }), _jsx(FooterContainer, { children: _jsx(Image, { src: "/assets/images/power-by-sample-assist.png", preview: false, width: 300 }) })] }) })] }) }) }));
};
export default ResourcesPage;
const ResourceContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const ResourcePreviewBox = styled.div `
  padding-top: 1rem;
  width: 80%;
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
`;
const FooterContainer = styled.div `
  background-color: #00004c;
  border-top: 1vh solid #01b4d2;
  width: 100%;
  padding: 1rem;
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const PreviewContent = styled(SARow) `
  height: 100%;
`;
const QrCodeContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 10px;
  position: relative;
  border: 1px solid #156cc9;
`;
const LanguageContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  button {
    padding-top: 0;
  }
  span {
    font-size: 1.2rem;
    text-decoration: underline;
    color: #1a8cff;
  }
  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;
const LogoContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 15rem;
  border: 0.05rem solid #1a8cff;
  border-radius: 1rem;
`;
const AppLinksContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const AppButton = styled.img `
  width: 8rem;
`;
const QrTitle = styled(Typography.Text) `
  font-weight: ${fontWeight.EXTRA_LIGHT};
  font-size: 2.5rem;
  text-align: center;
  display: block;
  color: ${SAColourScheme.PLASMA};
  width: 60%;
  margin: 0 auto;
`;
