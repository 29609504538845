import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
const PlanOverviewBox = ({ title, content, displayManageBtn, handleManageClick, }) => (_jsxs(StyledPlanBox, { children: [_jsx(CustomSARow, { children: _jsx(SAColumn, { span: 16, align: "center", flex: "center", offset: displayManageBtn ? 8 : 0, pull: displayManageBtn ? 8 : 0, children: _jsx(Heading, { children: title }) }) }), _jsxs(CustomSARow, { children: [_jsx(SAColumn, { span: 16, align: "center", flex: "center", children: _jsx(ColumnContent, { children: _jsx(ContentText, { children: content }) }) }), displayManageBtn ? (_jsx(SAColumn, { span: 8, children: _jsx(ManageButton, { onClick: handleManageClick, children: "Manage" }) })) : null] })] }));
export default PlanOverviewBox;
const StyledPlanBox = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;
const CustomSARow = styled(SARow) `
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ColumnContent = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 0.25rem;
  border-bottom: 0.025rem solid #000000;
`;
const Heading = styled.p `
  font-size: 0.825rem;
  font-weight: 500;
  color: #7f8e9d;
  text-align: center;
`;
const ContentText = styled.p `
  font-size: 0.875rem;
  color: #000;
`;
const ManageButton = styled(Button) `
  color: #01b4d2;
  background-color: #e6f7fa;
  height: fit-content;
  border-radius: 0.5rem;
  border: 0;
  margin: auto;
  padding: 0.2rem 0.4rem;
  font-size: 0.825rem;
`;
