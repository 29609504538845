import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { message, Typography } from "antd";
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { PageLayoutWithExtraTab } from "src/components/layouts/PageLayout";
import { getEntryManagementSettings, updateEntryManagementSettings, } from "src/pages/PlatformManagementMenuPage/WelcomeAssist/EntryManagement/store/action";
import { SARow, SAColumn, SASwitch } from "src/components/Form";
import { routes } from "../..";
import CategoryList from "./components/CategoryList";
const EntryManagementSettingsPage = () => {
    const [entrySettingsData, setEntrySettingsData] = useState({
        isKioskSystem: false,
        isPhotoVerification: false,
        isAutoCheck: false,
    });
    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        getData();
    }, [refresh]);
    const getData = async () => {
        const result = await getEntryManagementSettings();
        if (result?.error) {
            message.error("Failed to get entry management settings");
            return;
        }
        setEntrySettingsData(result.data);
    };
    const setRefreshData = async () => {
        setRefresh((data) => !data);
    };
    const updateSettings = async (data) => {
        const result = await updateEntryManagementSettings(data);
        if (result?.error) {
            message.error("Failed to update entry management settings");
            return;
        }
        message.success("Update entry management settings successfully");
        setRefresh(!refresh);
    };
    return (_jsx(PageLayoutWithExtraTab, { routes: routes, tabLevel: 3, children: _jsx(EntryManagementSettingsPageContainer, { children: _jsx(EntryExitRegisterContainer, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 24, justify: "center", align: "flex-start", children: _jsx(Typography.Title, { level: 5, children: "Category Management" }) }), _jsx(SAColumn, { span: 24, children: _jsx(ChartCard, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, children: _jsx(Typography.Title, { level: 5, children: "Kiosk System" }) }), _jsx(SAColumn, { span: 18, children: _jsx(SASwitch, { checked: entrySettingsData?.isKioskSystem, uncheckedLabel: "Off", checkedLabel: "On", onChange: async (value) => {
                                                    await updateSettings({
                                                        ...entrySettingsData,
                                                        isKioskSystem: value,
                                                    });
                                                } }) })] }) }) }), _jsx(SAColumn, { span: 24, children: _jsx(ChartCard, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, children: _jsx(Typography.Title, { level: 5, children: "Photo Verification" }) }), _jsx(SAColumn, { span: 18, children: _jsx(SASwitch, { checked: entrySettingsData?.isPhotoVerification, uncheckedLabel: "Off", checkedLabel: "On", onChange: async (value) => {
                                                    await updateSettings({
                                                        ...entrySettingsData,
                                                        isPhotoVerification: value,
                                                    });
                                                } }) })] }) }) }), _jsx(SAColumn, { span: 24, children: _jsx(ChartCard, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, children: _jsx(Typography.Title, { level: 5, children: "Auto Checkout Timeframe" }) }), _jsx(SAColumn, { span: 18, children: _jsx(SASwitch, { checked: entrySettingsData?.isAutoCheck, uncheckedLabel: "Off", checkedLabel: "On", onChange: async (value) => {
                                                    await updateSettings({
                                                        ...entrySettingsData,
                                                        isAutoCheck: value,
                                                    });
                                                } }) })] }) }) }), _jsx(SAColumn, { span: 24, children: _jsx(ChartCard, { children: _jsxs(SARow, { children: [_jsx(SAColumn, { span: 6, children: _jsx(Typography.Title, { level: 5, children: "Category Management" }) }), _jsx(SAColumn, { span: 18, children: _jsx(CategoryList, { entryData: entrySettingsData, setRefresh: setRefreshData }) })] }) }) })] }) }) }) }));
};
export default EntryManagementSettingsPage;
const EntryManagementSettingsPageContainer = styled.div `
  width: 100%;
`;
const EntryExitRegisterContainer = styled.div `
  width: 100%;
  margin: 2rem 0;
`;
const ChartCard = styled.div `
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 1rem;
  height: 100%;

  h5,
  div {
    margin-right: auto;
  }
`;
