import { createAsyncThunk } from "@reduxjs/toolkit";
import { stringify } from "qs";
import { $get, $post } from "src/infra/http";
const getLaboratories = createAsyncThunk("getLaboratories", async ({ newPage = 1, size = 10, field, order } = {}, thunkApi) => {
    const { searchKeyWord } = thunkApi.getState().collectionOrganizationPage.laboratoryPage;
    const params = {
        searchKeyWord,
        field,
        order,
        page: newPage,
        limit: size,
    };
    const searchQuery = stringify(params);
    return await $get(`/collection-manager/collection-organization/laboratory/search?${searchQuery}`);
});
const updateLaboratory = createAsyncThunk("updateLaboratory", async (updatedLaboratory) => {
    const response = await $post(`/collection-manager/collection-organization/laboratory`, {
        oldLaboratories: updatedLaboratory,
        newLaboratories: [],
    });
    return response;
});
const createLaboratory = createAsyncThunk("createLaboratory", async (newLaboratory) => {
    const response = await $post(`/collection-manager/collection-organization/laboratory`, {
        oldLaboratories: [],
        newLaboratories: newLaboratory,
    });
    return response;
});
const deleteOrganizationLaboratory = createAsyncThunk("deleteLaboratory", async (laboratoryId) => await $get(`/collection-manager/collection-organization/laboratory/delete/${laboratoryId}`));
export { updateLaboratory, createLaboratory, deleteOrganizationLaboratory, getLaboratories };
