import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Select, Space, Table, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ExcelIcon } from "src/assets/svg/brands";
import SearchInventory from "src/assets/svg/inventory/search-inventory.svg";
import { SASelect } from "src/components/Form";
import SAColumn from "src/components/Form/Table/SAColumn";
import SARow from "src/components/Form/Table/SARow";
import { PageLayoutWithBreadcrumb } from "src/components/layouts/PageLayout";
import { OverlayLoading } from "src/components/Loading/OverlayLoading";
import TableSizeChanger from "src/components/TableSizeChanger";
import { downloadFromBlob } from "src/infra/export";
import { exportStockRecords, getInventoryDetail, getListDeviceTypes, getStockInventoryDetail, } from "src/pages/InventoryManagement/store/action";
import { selectInventoryDetail, selectListDeviceTypes, } from "src/pages/InventoryManagement/store/selector";
import { selectDateRangeOptions, selectIsLoading } from "src/store/app/selector";
import { setIsLoading } from "src/store/app/slice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { breadcrumbs } from "..";
import { InventoryItemStatus } from "../store/types";
import InventorySummary from "./InventorySummary";
const InventorySections = () => {
    const dispatch = useAppDispatch();
    const inventoryDetail = useAppSelector(selectInventoryDetail);
    const listDeviceTypes = useAppSelector(selectListDeviceTypes);
    const isLoading = useAppSelector(selectIsLoading);
    const dateRangeOptions = useMemo(() => selectDateRangeOptions(), []);
    const [filters, setFilters] = useState({
        page: 1,
        limit: 10,
    });
    const [dateRange, setDateRange] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getListDeviceTypes());
            await dispatch(getInventoryDetail({}));
            await dispatch(getStockInventoryDetail(filters));
            dispatch(setIsLoading(false));
        };
        fetchData();
    }, []);
    useEffect(() => {
        const selectedRange = dateRangeOptions.find((option) => option.key === dateRange);
        setFilters((prev) => ({
            ...prev,
            startAt: selectedRange?.startDate.toDate(),
            endAt: selectedRange?.endDate.toDate(),
        }));
    }, [dateRange]);
    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsLoading(true));
            await dispatch(getStockInventoryDetail(filters));
            dispatch(setIsLoading(false));
        };
        fetchData();
    }, [filters]);
    return (_jsx(PageLayoutWithBreadcrumb, { routes: breadcrumbs, tabLevel: 2, children: _jsxs(DeviceInventory, { children: [isLoading && _jsx(OverlayLoading, {}), _jsxs(SARow, { gutter: [32, 32], children: [_jsx(InventorySummary, {}), _jsx(SAColumn, { children: _jsxs(SectionContainer, { children: [_jsxs(TableFilter, { children: [_jsx(SAColumn, { md: 6, align: "start", children: _jsxs(Space, { children: [_jsx(SearchInventory, {}), _jsx(Typography.Title, { level: 5, children: "Stock Inventory" })] }) }), _jsx(SAColumn, { md: 6, children: _jsxs(Select, { value: filters.status || "", onChange: (value) => {
                                                        setFilters((pre) => ({
                                                            ...pre,
                                                            status: value || undefined,
                                                        }));
                                                    }, children: [_jsx(Select.Option, { value: "", children: "Show All Records " }), _jsx(Select.Option, { value: InventoryItemStatus.Active, children: "Active" }), _jsx(Select.Option, { value: InventoryItemStatus.Inactive, children: "Deleted" })] }) }), _jsx(SAColumn, { md: 6, children: _jsx(SASelect, { fieldNames: { label: "name", value: "id" }, options: [{ name: "Show All Categories", id: "" }, ...listDeviceTypes], value: filters.testingDeviceTypeId || "", onChange: (id) => {
                                                        setFilters((pre) => ({ ...pre, testingDeviceTypeId: id || undefined }));
                                                    } }) }), _jsx(SAColumn, { md: 6, children: _jsx(SASelect, { value: dateRange || "", onChange: (value) => setDateRange(value || ""), options: [{ label: "Show All", key: "" }, ...dateRangeOptions], fieldNames: { label: "label", value: "key" } }) })] }), _jsxs(TableContainer, { children: [_jsxs(Table, { dataSource: inventoryDetail?.data, rowKey: "id", bordered: false, pagination: {
                                                    current: inventoryDetail.pagingMeta?.page,
                                                    pageSize: inventoryDetail.pagingMeta?.limit,
                                                    total: inventoryDetail.pagingMeta?.totalItem ?? 0,
                                                    onChange: (page, limit) => {
                                                        setFilters((pre) => ({ ...pre, page, limit }));
                                                    },
                                                }, children: [_jsx(Table.Column, { title: "Date", dataIndex: "date", sorter: true }), _jsx(Table.Column, { title: "Device Name", dataIndex: "deviceName", sorter: true }), _jsx(Table.Column, { title: "Product Name", dataIndex: "productName", sorter: true }), _jsx(Table.Column, { title: "Lot No.", dataIndex: "lotNumber", sorter: true }), _jsx(Table.Column, { title: "Device Category", dataIndex: "deviceCategory", sorter: true }), _jsx(Table.Column, { title: "Batch/Order No.", dataIndex: "batchNumber", sorter: true }), _jsx(Table.Column, { title: "Units", dataIndex: "units", sorter: true }), _jsx(Table.Column, { title: "Unit Price", dataIndex: "unitPrice", sorter: true }), _jsx(Table.Column, { title: "Total", dataIndex: "total", sorter: true }), _jsx(Table.Column, { title: "Entered By", dataIndex: "enteredBy", sorter: true })] }), _jsxs(TableFooterContainer, { "$hidden": !inventoryDetail?.pagingMeta.totalItem, children: [_jsx(TableSizeChanger, { value: inventoryDetail.pagingMeta.limit, onChange: (size) => {
                                                            setFilters({
                                                                ...filters,
                                                                limit: size,
                                                            });
                                                        } }), _jsx(ExportButton, { icon: _jsx(ExcelIcon, {}), onClick: async () => {
                                                            const response = await dispatch(exportStockRecords(filters)).unwrap();
                                                            downloadFromBlob(response, "stocks.xlsx");
                                                        }, children: "Export file" })] })] })] }) })] })] }) }));
};
export default InventorySections;
const SectionContainer = styled.div `
  width: 100%;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;
const TableFooterContainer = styled.div `
  position: absolute;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  min-width: 5rem;
  bottom: 0.5rem;
  white-space: nowrap;
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
`;
const ExportButton = styled(Button) `
  color: #01b4d2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
const DeviceInventory = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  background-color: #ffffff;
`;
const TableContainer = styled.div `
  width: 100%;
  position: relative;
  height: fit-content;

  .ant-pagination-options {
    display: none;
  }
  .ant-table-thead > tr > th {
    background-color: #e6f7fa;
    font-weight: bold;
    text-align: center;
    color: #000000;
  }
`;
const TableFilter = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;
